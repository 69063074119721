import styled, { css } from 'styled-components';

export const EntregaFacilInfo = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
`;
