import React, { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {
  ComplementButtonContainer,
  Decrement,
  Increment,
  ProductName,
} from './styles';
import { DefaultInput } from 'ui-kit-takeat';
import formatValue from '~/utils/formatValue';
import { usePreCart } from '~/context/PreCartContext';

export const ComplementButton = ({ category, complement }) => {
  const {
    preCart,
    incrementOnPreCart,
    decrementOnPreCart,
    changeComplementPreCart,
    getComplementCategoryAmount,
    getComplementAmount,
  } = usePreCart();
  // const allComplements = preCart.complementsCategory?.flatMap(
  //   (category) => category.complements
  // );
  // const complementOnPreCart = allComplements?.find(
  //   (complementOnPreCart) => complementOnPreCart.id === complement.id
  // );

  const complementOnPreCart = preCart.complementsCategory?.find((cat) => cat.id === category.id)?.complements.find((compl) => compl.id === complement.id);

  const handleChange = (e) => {
    if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value))
      return;
  
    let value =
      e.target.value.length < 1 ? 0 : parseInt(e.target.value);
      
    if (complementOnPreCart?.amount) {
      if (category.limit - getComplementCategoryAmount(category.id) + complementOnPreCart.amount <= value) {
        value = category.limit - getComplementCategoryAmount(category.id) + complementOnPreCart.amount;
      }
  
      if (complement.limit - getComplementAmount(complement.id, category.id) + complementOnPreCart.amount <= value) {
        value = complement.limit - getComplementAmount(complement.id, category.id) + complementOnPreCart.amount;
      }
    } else {
      if (category.limit - getComplementCategoryAmount(category.id) <= value) {
        value = category.limit - getComplementCategoryAmount(category.id);
      }
  
      if (complement.limit - getComplementAmount(complement.id, category.id) <= value) {
        value = complement.limit - getComplementAmount(complement.id, category.id);
      }
    }

    changeComplementPreCart(category, complement, value);
  }

  return (
    <ComplementButtonContainer>
      <Decrement
        disabled={!complementOnPreCart || complementOnPreCart?.amount === 0}
        onClick={() => decrementOnPreCart(category, complement)}
      >
        <FaMinus />
      </Decrement>
      <Increment
        disabled={
          getComplementCategoryAmount(category.id) >= category.limit ||
          getComplementAmount(complement.id, category.id) >= complement.limit
        }
        onClick={() => {
          incrementOnPreCart(category, complement);
        }}
      >
        <ProductName>
          <p>{complement.name}</p>
          <span>{formatValue(complement.price)}</span>
        </ProductName>

        <DefaultInput
          onClick={(e) => e.stopPropagation()}
          value={complementOnPreCart?.amount || 0}
          onChange={(e) => handleChange(e)}
          style={{ padding: 0, height: 30 }}
          containerStyles={{ width: 30, minWidth: 30 }}
          inputStyle={{
            fontWeight: 500,
            textAlign: 'center',
            fontSize: 12,
            height: 30,
          }}
        />

        <FaPlus />
      </Increment>
    </ComplementButtonContainer>
  );
};
