import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ButtonUpdate, Container, Header, Card, DatePickerStyledUnify, CardMain, SearchBar, ButtonSearchInput, ButtonDeleteInput, MessageContainer } from './styles';
import RegisterButton from '~/components/Buttons/RegisterButton';
import { MdUpdate } from 'react-icons/md';
import { CardBody, Card as CardStrap, CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { FiSearch, FiX } from 'react-icons/fi';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';
import InputMask from 'react-input-mask';
import CreditRegisterItem from './CreditRegisterItem';
import api from '~/services/api';
import toast from 'react-hot-toast';

import * as Yup from 'yup';
import getValidationErrors from '~/services/getValidationErrors';
import { FaReceipt } from 'react-icons/fa';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import formatValue from '~/services/formatValue';
import { useAuth } from '~/context/AuthContext';
import { Checkbox } from 'ui-kit-takeat';

function CreditRegister() {
  const [registerCredits, setRegisterCredits] = useState([])
  const { user, updateUser } = useAuth();
  const [isModalRegisterAccountOpened, setIsModalRegisterAccountOpened] = useState(false)
  const [creditRegisterWthNfce, setCreditRegisterWthNfce] = useState(user?.credit_register_with_nfce)


  async function handleSubmitProfile(data) {
    try {
      const updateProfile = {
        credit_register_with_nfce: !data
      };

      await updateUser(updateProfile);

    } catch (error) {
      toast.error('Falha ao atualizar o perfil do restaurante.');
      setCreditRegisterWthNfce(!creditRegisterWthNfce)
    }
  }

  useEffect(() => {
    setCreditRegisterWthNfce(user?.credit_register_with_nfce)
  }, [user.credit_register_with_nfce])


  function toggleModalRegisterAccount() {
    setIsModalRegisterAccountOpened(!isModalRegisterAccountOpened)
  }

  const [expiresAt, setExpiresAt] = useState()

  const formRef = useRef(null)

  const handleSubmit = useCallback(async (data) => {
    try {


      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        phone: Yup.string().required('Telefone obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post("/restaurants/credit-register", {
        name: data.name,
        phone: data.phone,
        cnpj: data.cnpj,
        expires_at: expiresAt || null,
        total_limit: data.total_limit || null
      })

      toast.success("Conta cadastrada com sucesso!")


      setIsModalRegisterAccountOpened(false)

      const newRegisters = [...registerCredits, response.data]

      setRegisterCredits(newRegisters)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error("Erro ao criar conta")
      }
    }

  }, [expiresAt, registerCredits])


  const inputAccountRef = useRef(null)

  const [accountSelected, setAccountSelected] = useState([])

  function handleSelectAccount(id) {
    if (accountSelected.includes(id)) {
      setAccountSelected([])
    } else {
      setAccountSelected([id])
    }
  }

  const [registerCreditFiltered, setRegisterCreditFiltered] = useState([])

  function handleSearchInputAccount(e) {
    const accountFind = registerCredits.filter(item => item.name.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        e
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      )).length > 0 ? registerCredits.filter(item => item.name.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          e
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )) : registerCredits.filter(item => item.phone
          .includes(
            e.toString()
          ))
    setRegisterCreditFiltered(accountFind)
  }

  const [totalRegisterToReceive, setTotalRegisterToReceive] = useState(0)

  const getCreditRegisters = useCallback(async () => {
    try {
      const response = await api.get("/restaurants/credit-register?details=true")
      const orderedCredits = response.data.sort((a, b) => Number(a.balance) - Number(b.balance))

      console.log(orderedCredits)

      const totals = response.data.map(item => item.credit_register_items)
        .filter(item => item.length > 0)
        .map(item => item.reduce((acum, item) => acum + Number(item.value), 0))
      const sum = totals.reduce((total, value) => total + value, 0);

      setTotalRegisterToReceive(Math.abs(sum))
      setRegisterCredits(orderedCredits)
    } catch (error) {
      toast.error("Erro ao baixar contas a prazo")
    }
  }, [])

  useEffect(() => {
    getCreditRegisters()
  }, [getCreditRegisters])



  return (
    <Container>
      <Header>

        <h2>Conta a prazo</h2>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

          <Col md="3" style={{ paddingLeft: 0 }}>
            <CardStrap className="card-stats">
              <CardBody>

                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-success">
                      <i className="tim-icons">
                        <GiPayMoney />
                      </i>
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Saldo Total a Receber:</p>
                      <CardTitle tag="h3">
                        {formatValue(parseFloat(totalRegisterToReceive).toFixed(2))}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>


              </CardBody>
            </CardStrap>

            <Row>
              <Checkbox
                label="Permitir emitir NF ao quitar contas a prazo."
                name="credit_register_with_nfce"
                onClick={() => { handleSubmitProfile(creditRegisterWthNfce) }}
                checked={creditRegisterWthNfce}
              />
            </Row>

          </Col>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <SearchBar>
              <input
                name="account"
                type="text"
                placeholder="Nome / Telefone"
                style={{ height: 30 }}
                onChange={(e) => {
                  handleSearchInputAccount(e.target.value);
                }}
                ref={inputAccountRef}
              />

              <ButtonSearchInput
              // type="button"
              // title="Mostrar todos"
              // onClick={() => {
              //   setAccountFiltered('');
              //   inputAccountRef.current.value = '';
              // }}
              >
                <FiSearch size={14} />
              </ButtonSearchInput>

              <ButtonDeleteInput
                type="button"
                title="Mostrar todos"
                onClick={() => {
                  setRegisterCreditFiltered('');
                  inputAccountRef.current.value = '';
                }}
              >
                <FiX size={14} />
              </ButtonDeleteInput>
            </SearchBar>
            <ButtonUpdate onClick={getCreditRegisters}><MdUpdate size={21} /> Atualizar</ButtonUpdate>
            <RegisterButton style={{ width: 189 }} text="Cadastrar conta" onClick={toggleModalRegisterAccount} />
          </div>

        </div>
        <Row>
          <Col style={{ paddingLeft: 0 }}>
            <MessageContainer>
              <p>
                <strong>MARCADO:</strong> Impossibilita adicionar mais de uma conta a prazo por comanda,
                mas permite a emissão de nota fiscal.
              </p>
              <p>
                <strong>NÃO MARCADO:</strong> Permite adicionar mais de uma conta a prazo por comanda, no entanto,
                impossibilita a emissão de nota fiscal, sendo possível apenas a emissão de nota avulsa.
              </p>
            </MessageContainer>
          </Col>

        </Row>

      </Header>
      {registerCredits && registerCredits.length > 0 ? (
        <Card>
          <thead>
            <tr>
              <th>
                Nome da conta
              </th>
              <th>
                Telefone
              </th>
              <th>
                Valor consumido
              </th>
              <th>
                Limite de gasto
              </th>
              <th>
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {
              registerCreditFiltered && registerCreditFiltered.length > 0 ?
                registerCreditFiltered.map(
                  item => (
                    <CreditRegisterItem getCreditRegisters={getCreditRegisters} item={item} registerCredits={registerCreditFiltered} setRegisterCredits={setRegisterCreditFiltered} accountSelected={accountSelected} handleSelectAccount={handleSelectAccount} setTotalRegisterToReceive={setTotalRegisterToReceive} />
                  )
                )
                :
                registerCredits && registerCredits.length > 0 && registerCredits.map(
                  item => (
                    <CreditRegisterItem getCreditRegisters={getCreditRegisters} item={item} registerCredits={registerCredits} setRegisterCredits={setRegisterCredits} accountSelected={accountSelected} handleSelectAccount={handleSelectAccount} setTotalRegisterToReceive={setTotalRegisterToReceive} />
                  )
                )
            }

          </tbody>

        </Card>
      ) : (
        <CardMain>
          <span>
            Não há clientes cadastrados no momento.
          </span>
        </CardMain>


      )}


      <Modal isOpen={isModalRegisterAccountOpened} toggle={toggleModalRegisterAccount}>
        <div style={{ width: "100%", padding: 20, display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontWeight: "bold" }}>Cadastrar conta</span>
          <FiX color='#FF2C3A' onClick={toggleModalRegisterAccount} cursor="pointer" />
        </div>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalBody>
            <Row>
              <Col md="7">
                <Input name="name" label="Nome da conta" />
              </Col>
              <Col Col md="5">
                <InputMask mask="(99) 99999-9999">
                  <Input name="phone" label="Telefone"></Input>
                </InputMask>

              </Col>
            </Row>
            <Row>
              <Input name="cnpj" label="CPF/ CNPJ" />
            </Row>
            <Row>
              <Col md="6">
                <p style={{ fontWeight: "bold" }}>Limite de gasto</p>
                <span>Se esse campo estiver em branco, o saldo nao será limitado</span>
                <Input name="total_limit" placeHolder="- R$" type="number" />
              </Col>
              <Col md="6">
                <p style={{ fontWeight: "bold" }}>Validade do saldo</p>
                <span>Se esse campo estiver em branco, não haverá data limite</span>
                <DatePickerStyledUnify
                  locale="pt"
                  name="expires_at"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="__/__/____"
                  onChange={(date) => setExpiresAt(date)}
                  selected={expiresAt}
                />
              </Col>
            </Row>

          </ModalBody >

          <div style={{ width: "100%", padding: 20, display: "flex", justifyContent: "flex-end" }}>
            <PersonalButton message="Cadastrar" fontColor="#fff" color="#2ec9b7" type="submit" />
          </div>
        </Form>
      </Modal >
    </Container >
  );
}

export default CreditRegister;