import {
  Container,
  CountBadge,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductsNotFound,
} from './styles';
import React, { useState } from 'react';
import imgPlaceholder from '~/assets/img/ImgPlaceholder.png';
import { useCart } from '~/context/OrderingSystem/Cart';
import formatValue from '~/utils/formatValue';

export const BalconyProducts = ({
  products,
  setAdded,
  toggleComplementArea,
}) => {
  const { addToCart, countProducts, productsHasCounted } = useCart();
  const [itemProduct, setItemProduct] = useState();

  const productsFiltered = products?.filter((product) => product.available);

  function handleSelectProduct(product) {
    if (product?.complement_categories.length > 0 || product?.use_weight) {
      // openProductModal(product);

      setItemProduct({
        ...product,
        price: product?.price_promotion || product?.price,
      });

      toggleComplementArea({
        ...product,
        price: product?.price_promotion || product?.price,
      });
    } else {
      countProducts({ id: product.id, amount: 1 });
      const productFormatted = {
        ...product,
        price: product.price_promotion || product.price,
      };

      if (setAdded) {
        setAdded((state) => {
          const exists = state.find(
            (s) => s.product.id === productFormatted.id
          );
          if (exists) {
            return state.map((s) => {
              if (s.product.id === productFormatted.id) {
                return { ...s, amount: s.amount + 1 };
              }
              return s;
            });
          }
          return [
            ...state,
            {
              product: productFormatted,
              amount: 1,
              observation: '',
              complementsCategory: [],
              isProductWeight: product?.use_weight,
              created_at: new Date().toDateString(),
            },
          ];
        });
      }
      addToCart({
        product: productFormatted,
        amount: 1,
        observation: '',
        complementsCategory: [],
        isProductWeight: product?.use_weight,
      });
    }
  }

  return (
    <Container>
      {productsFiltered?.length > 0 ? (
        productsFiltered?.map((product) => {
          const price = product?.price_promotion
            ? product?.price_promotion
            : product?.price;

          const badgeCount = productsHasCounted
            ? productsHasCounted.find((pr) => pr.id === product.id)?.amount
            : 0;

          return (
            <ProductCard
              key={product.id}
              onClick={() => handleSelectProduct(product)}
            >
              <ProductImg
                src={product.image?.url ? product.image.url : imgPlaceholder}
              />
              <ProductInfo>
                <h5>{product.name}</h5>
                <span>{formatValue(price)}</span>
              </ProductInfo>

              {badgeCount > 0 && <CountBadge>{badgeCount}</CountBadge>}
            </ProductCard>
          );
        })
      ) : (
        <ProductsNotFound>Nenhum produto encontrado</ProductsNotFound>
      )}
    </Container>
  );
};
