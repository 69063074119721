import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  BillExplanation,
  OpenOrders,
  CloseOrders,
  PaymentTable,
} from './styles';
import EyeOrderBillOperation from '../EyeOrderBillOperation';
import api from '~/services/api';
import { useBills } from '~/context/BillsContext';

const EyeOrdersBillOperation = (
  {
    inicialDate,
    finalDate,
    setSessions,
    toggleEyeOpen,
    getSessions,
    getTablesAvailable,
    session,
    sessionId,
    handleSearchInputTables,
    setAllTablesAvailable,
    setInputValue,
    setTablesFilteredSearch,
    setIsDivideBillsAreaOpen,
  },
  ref
) => {
  const { getTableBills, closedBills, pendingBills } = useBills();

  const session_id =
    session?.table?.table_number || session?.table?.table_type === 'balcony'
      ? session?.id
      : null;

  useEffect(() => {
    getTableBills(session_id);
  }, [getTableBills, session_id]);

  let length = 0;
  pendingBills.forEach((bill) => {
    if (parseFloat(bill.total_price) > 0) {
      length++;
    }
  });

  return (
    <Container>
      <OpenOrders>
        <h6>
          <strong>Comanda</strong>
        </h6>
        <PaymentTable borderless>
          <tbody>
            {pendingBills &&
              pendingBills.length > 0 &&
              pendingBills.map(
                (bill) =>
                  parseFloat(bill.total_price) > 0 && (
                    <EyeOrderBillOperation
                      bill={bill}
                      session={session}
                      setSessions={setSessions}
                      inicialDate={inicialDate}
                      finalDate={finalDate}
                      toggleEyeOpen={toggleEyeOpen}
                      length={length}
                      getSessions={getSessions}
                      getTablesAvailable={getTablesAvailable}
                      getTableSessions={getTableBills}
                      handleSearchInputTables={handleSearchInputTables}
                      setAllTablesAvailable={setAllTablesAvailable}
                      setInputValue={setInputValue}
                      setTablesFilteredSearch={setTablesFilteredSearch}
                      setIsDivideBillsAreaOpen={setIsDivideBillsAreaOpen}
                    />
                  )
              )}

            {pendingBills.length < 1 && <p>Mesa sem clientes no momento</p>}
          </tbody>
        </PaymentTable>
      </OpenOrders>
      <CloseOrders>
        <h6>
          <strong>Contas Individuais Fechadas</strong>
        </h6>

        <PaymentTable borderless>
          <tbody>
            {closedBills.length > 0 ? (
              closedBills.map((bill) => {
                const userName = bill.buyer
                  ? bill.buyer.phone
                  : bill.waiter.name;
                return (
                  <tr key={bill.id}>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                  </tr>
                );
              })
            ) : (
              <p>Nenhuma conta fechada</p>
            )}
          </tbody>
        </PaymentTable>
      </CloseOrders>
    </Container>
  );
};

export default EyeOrdersBillOperation;
