import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  endOfDay,
  startOfMonth,
  addHours,
} from 'date-fns';
import { Form } from '@unform/web';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Input from '~/components/Form/Input';
import InputSimple from '~/components/Form/InputSimple';
import {
  Wrapper,
  Header,
  Card,
  Content,
  ScrollArea,
  ButtonsDiv,
} from './styles';

import NfesEntry from '~/components/NfesEntry';

import api from '~/services/api';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { DownloadButton, FullCalendar, Button } from 'ui-kit-takeat';
import { HiOutlineMail } from 'react-icons/hi';

export default function NFeEntry() {
  const [searchDate, setSearchDate] = useState({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
  });

  const [, setNfes] = useState([]);
  const [nfesFiltered, setNfesFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setNfcesReport] = useState([]);

  const [search, setSearch] = useState('');

  const updateNfe = useCallback(async () => {
    await api.get('/restaurants/nfe-received/pull');
  }, []);

  const getNfes = useCallback(async () => {
    try {
      const response = await api.get('restaurants/nfe-received', {
        params: {
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          pendente: false,
        },
      });

      setNfes(response.data);
      setNfesFiltered(response.data);
      setNfcesReport(response.data.report);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [searchDate]);

  const [html, ] = useState(null);

  useEffect(() => {
    getNfes();
    setLoading(false);
  }, [getNfes]);

  async function handleUpdate() {
    setLoading(true);
    updateNfe();
    getNfes();
    setLoading(false);
  }

  const [modal, setModal] = useState(false);
  async function toggleEmail() {
    setModal(!modal);
  }

  const sendXMLEmail = useCallback(
    async (data) => {
      try {
        await api.post('/restaurants/nfe-received/multiple/send-email', {
          email: data.email,
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        });
        toast.success('E-mail enviado com sucesso!');
        toggleEmail();
        // document.getElementById('close_modal').click();
      } catch (err) {
        toast.error('Não foi possível enviar o e-mail;');
      }
    },
    [searchDate.start, searchDate.end, toggleEmail]
  );

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalInputNF'}`
      );

      const { can_read } = response.data.FiscalInputNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <p>Acompanhe a entrada de notas do seu estabelecimento.</p>

        <ButtonsDiv>
          <FullCalendar value={searchDate} onDateSelected={setSearchDate} />
        </ButtonsDiv>
      </Header>
      <Card>
        <Content>
          <Row>
            <Col md="10">
              <Form>
                <Input
                  name="search"
                  type="text"
                  placeholder="Pesquise pelo número, chave da nota ou fornecedor"
                  onChange={e => setSearch(e.target.value)}
                />
              </Form>
            </Col>
            <Col md="2">
              <div style={{ float: 'right', marginRight: 10, marginTop: 15 }}>
                <DownloadButton
                  onClick={toggleEmail}
                  text="XML por E-mail"
                  buttonWidth={115}
                  icon={<HiOutlineMail size={14} />}
                  fixed="open"
                />
              </div>
            </Col>
          </Row>

          <ScrollArea>
            <NfesEntry
              nfes={nfesFiltered}
              loading={loading}
              handleUpdate={handleUpdate}
              html={html}
              getNfes={getNfes}
              search={search}
            />
          </ScrollArea>
        </Content>
      </Card>

      <Modal isOpen={modal} toggle={toggleEmail}>
        <Form onSubmit={sendXMLEmail}>
          <ModalBody>
            <InputSimple id="email" label="E-mail" type="email" name="email" />
            <p>Será enviado os arquivos XML do período selecionado.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={toggleEmail}
              id="close_modal"
              type="button"
              style={{ background: '#dc3545' }}
              title="Cancelar"
            />  
            <Button type="submit" onClick={() => {}} buttonColor='#2ec9b7' title="Enviar E-mail" />
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}
