import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { FiTrash } from 'react-icons/fi';
import { Button } from 'ui-kit-takeat';

import { ActionButton, DownloadButton } from 'ui-kit-takeat';
import { TiPlus, TiTrash } from 'react-icons/ti';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Container } from './styles';

export default function NfeAvulsaVolume({
  item,
  itens,
  setItens,
  key,
  number,
  nextVolumeId,
  setNextVolumeId
}) {
  const formEditVolume = useRef(null);

  const [volumeOptions, setVolumeOptions]= useState([
    {label: 'Quantidade', value: 'quantidade'},
    {label: 'Espécie', value: 'especie'},
    {label: 'Marca', value: 'marca'},
    {label: 'Número', value: 'numero'},
    {label: 'Peso Líquido', value: 'peso_liquido'},
    {label: 'Peso Bruto', value: 'peso_bruto'},
    {label: 'Lacres', value: 'lacres'},
  ]);

  const [thisItens, setThisItens] = useState([]);

  const [selectValue, setSelectValue] = useState({label: 'Quantidade', value: 'quantidade'});
  const [inputValue, setInputValue] = useState('');

  const [modalEdit, setModalEdit] = useState(false);
  function toggleEdit() {
    setModalEdit(!modalEdit);
  }

  const [modalDelete, setModalDelete] = useState(false);
  function toggleDeleteVolume() {
    setModalDelete(!modalDelete);
  }

  const handleEdit = useCallback(async (data) => {
    try {
      let data = {
        _numero_volume: item._numero_volume,
        quantidade: formEditVolume.current.getFieldValue('quantidade'),
        especie: formEditVolume.current.getFieldValue('especie'),
        marca: formEditVolume.current.getFieldValue('marca'),
        numero: formEditVolume.current.getFieldValue('numero'),
        peso_liquido: formEditVolume.current.getFieldValue('peso_liquido'),
        peso_bruto: formEditVolume.current.getFieldValue('peso_bruto'),
      };

      const newItens = [...itens.filter(it => it._numero_volume !== item._numero_volume), data];

      setItens(newItens.sort((a, b) => b._numero_volume - a._numero_volume));
      localStorage.setItem('@gddashboard:volumesNotaAvulsa', JSON.stringify(newItens));
      toggleEdit();
    }catch(err){
      console.log(err);
      toast.error('Erro ao editar volume')
    }
  }, [itens, setItens, toggleEdit]);

  const deleteVolume = useCallback(() => {
    const newItens = itens.filter(it => it._numero_volume !== item._numero_volume);
    let i = 1;

    newItens.forEach(it => {
      it._numero_volume = i;
      i++;
    });

    setItens(newItens);
    localStorage.setItem('@gddashboard:volumesNotaAvulsa', JSON.stringify(newItens));

    setNextVolumeId(nextVolumeId - 1);
    toggleDeleteVolume();
  }, [itens, item, setItens, nextVolumeId, setNextVolumeId, toggleDeleteVolume]);

  return (
    <Container>
      <Row>
        <Col md="1">
          <strong>Vol. {Number(number) + 1}</strong>
        </Col>
        <Col md="3">
          Quantidade: {item.quantidade}
        </Col>
        <Col md="6" style={{textAlign: 'right'}}>
           Peso Líquido: {item.peso_liquido}
        </Col>
        <Col md="2" style={{textAlign: 'right'}}>
          <FaPen
            onClick={toggleEdit}
            title="Editar"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            color="#666666"
          />
          <FaTrash
            color="#ff403d"
            title="Deletar"
            style={{ cursor: 'pointer', marginLeft: 8 }}
            onClick={toggleDeleteVolume}
          />
        </Col>
      </Row>

      <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
        <Form onSubmit={handleEdit} initialData={item} ref={formEditVolume}>
          <ModalBody>
            Volume {item._numero_volume}
            <Row>
              <Col md="4">
                <Input
                  name="quantidade"
                  label="Quantidade"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="especie"
                  label="Espécie"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Input
                  name="marca"
                  label="Marca"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  name="numero"
                  label="Número"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Input
                  name="peso_liquido"
                  label="Peso Líquido"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                  step={0.001}
                />
              </Col>

              <Col md="4">
                <Input
                  name="peso_bruto"
                  label="Peso Bruto"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                  step={0.001}
                />
              </Col>              
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              id="close_modal"
              type="button"
              onClick={toggleEdit}
              style={{ background: '#dc3545' }}
              title="Cancelar"
            />
            <Button 
              id="submit_edit_volume"
              type="button" 
              onClick={handleEdit} 
              buttonColor='#2ec9b7' 
              title="Salvar" 
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDeleteVolume}>
        <ModalBody>
          Tem certeza que deseja remover o <strong>volume {item._numero_volume}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={toggleDeleteVolume}
            title="Continuar com volume"
            buttonColor="#2ec9b7"
          />
          <Button
            type="button"
            onClick={deleteVolume}
            buttonColor="#dc3545"
            title="Remover" 
          />
        </ModalFooter>
      </Modal>
    </Container>
  );

  return (
    <div style={{padding: 15}}>
      <Form>
        <Row>
          <Col md="1" style={{marginTop: 15}}>
            <strong>Vol. {Number(number) + 1}) </strong>
          </Col>
          <Col md="4">
            <Select 
              options={volumeOptions}
              name="option"
              value={selectValue}
              onChange={e => {
                setSelectValue(e);
              }}
            />
          </Col>
          <Col md="5">
            <Input
              name={`volume_name_${item?.numero_item}`}
              id={`volume_name_${item?.numero_item}`}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Col>
          <Col md="2">
            <button name="add_campo_item" 
              onClick={e =>  {
                setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                setVolumeOptions(volumeOptions.filter(it => it.value !== selectValue.value));
                setSelectValue(volumeOptions.filter(it => it.value !== selectValue.value)[0] || []);
                setInputValue('');
                const hasDoc = document.getElementById(`volume_name_${item.numero_item}`);
                if(hasDoc){
                  hasDoc.focus();
                }
              }}
              type="button"
              style={{
                borderRadius: '25px',
                height: 40,
                width: 40,
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: '#2AB7A6',
                color: 'white',
                textAlign: 'center',
                marginTop: 10,
                fontSize: 20,
                fontWeight: 600
              }}
              disabled={volumeOptions.length === 0}
            > + </button>

            <button
              name="remove_campo_volume"
              onClick={e =>  {
                setItens(itens.filter(it => it.numero_item !== item.numero_item));
              }}
              type="button"
              style={{
                borderRadius: '25px',
                height: 40,
                width: 40,
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: 'red',
                color: 'white',
                textAlign: 'center',
                marginTop: 10,
                marginLeft: 15,
              }}
            > <FiTrash size={17}/> </button>
          </Col>
        </Row>
        <div>
          {thisItens.map(it => (
            <Row style={{textAlign: 'left', paddingLeft: 20}}>
              <Col md="1" style={{textAlign: 'right'}}>
                <FiTrash size={16} color="red" style={{cursor: 'pointer'}} onClick={e => {
                  setThisItens(thisItens.filter(th => th.name !== it.name));
                  setVolumeOptions([...volumeOptions, {label: it.label, value: it.name}]);
                  setSelectValue([...volumeOptions, {label: it.label, value: it.name}][0] || []);
                }}/>
              </Col>
              <Col md="11">
                <strong>{it.label}:</strong> {it.value} 
              </Col>
              {/* <Col md="6">
                {it.value}  
              </Col> */}
            </Row>
          ))}
        </div>
      </Form>
    </div>
  );
}
