import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';
import {
  Collapse,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { endOfDay, format, startOfDay, subHours } from 'date-fns';
import { DownloadButton, FullHourCalendar } from 'ui-kit-takeat';
import { SiIfood } from 'react-icons/si';

import {
  Container,
  Header,
  StyledLink,
  MotoboysTable,
  Delete,
  Actions,
  MotoboyRow,
  DetailHeader,
  DisplayNoneImprimir,
  MotoboysDetailsTable,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import { generateSheet } from '~/services/SheetGenerate/generateMotoboysSheet';
import FileSaver from 'file-saver';

import api from '~/services/api';
import CreateMotoboy from './Create';
import { useMotoboys } from '~/context/MotoboysContext';
import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { FaAngleDown, FaPlus, FaPrint } from 'react-icons/fa';
import PrintMotoboysDetails from '~/components/Prints/PrintMotoboysDetails';
import { FullCalendar, Button } from 'ui-kit-takeat';
import { ModalConfirmation } from '~/components/ModalConfirmation';

function Motoboy() {
  const [isModalRegisterMotoboyOpen, setIsModalRegisterMotoboyOpen] =
    useState(false);
  const [loadingMotoboys, setLoadingMotoboys] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [motoboyToDelete, setMotoboyToDelete] = useState(null);
  const [initialDate, setInitialDate] = useState(
    startOfDay(subHours(new Date(), 3))
  );
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  const { detailedMotoboy, getDetailedMotoboy } = useMotoboys();
  const { user } = useAuth();

  const [permission, setPermission] = useState();

  const toggleConfirmDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryMotoboys'}`
      );

      const { can_read } = response.data.DeliveryMotoboys;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  function toggleModalRegisterMotoboy() {
    setIsModalRegisterMotoboyOpen(!isModalRegisterMotoboyOpen);
    setTimeout(() => {
      return document.getElementById('input_motoboy_name')?.focus();
    }, 1);
  }

  const handleDelete = useCallback(async () => {
    console.log('to delete inside func > ', motoboyToDelete);
    try {
      await api.delete(`/restaurants/motoboys/${motoboyToDelete.id}`);

      getDetailedMotoboy(initialDate, endDate);
      toggleConfirmDelete();
      setMotoboyToDelete(null);
      toast.success('Motoboy deletado com sucesso!');
    } catch (error) {
      toast.error('Erro ao deletar motoboy');
    }
  }, [motoboyToDelete]);

  const componentRef = useRef();

  const getDetailedMotoboys = useCallback(async () => {
    setLoadingMotoboys(true);

    await getDetailedMotoboy(initialDate.toISOString(), endDate.toISOString());

    setLoadingMotoboys(false);
  }, [getDetailedMotoboy, initialDate, endDate]);

  useEffect(() => {
    getDetailedMotoboys();
  }, [initialDate, endDate, getDetailedMotoboys]);

  const [motoboySelected, setMotoboySelected] = useState({});
  const [motoboyPrint, setMotoboyPrint] = useState({});

  function toggleMotoboySelected(motoboy) {
    if (motoboy.id === motoboySelected.id) {
      setMotoboySelected({});
    } else {
      setMotoboySelected({ phone: motoboy.phone, id: motoboy.id });
    }
  }

  function toggleMotoboyPrint(motoboy) {
    setMotoboyPrint({ phone: motoboy.phone, id: motoboy.id });
  }

  const handleDownload = async () => {
    try {
      const sheet = await generateSheet(detailedMotoboy);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Motoboys.xlsx`);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const [IfoodOnDemandMotoboy, setIfoodOnDemandMotoboy] = useState(null);

  useEffect(() => {
    const exists = detailedMotoboy?.find((moto) => moto.is_ifood_on_demand);
    if (exists) {
      setIfoodOnDemandMotoboy(exists);
    } else {
      setIfoodOnDemandMotoboy(null);
    }
  }, [detailedMotoboy]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <FullHourCalendar
            value={{ start: initialDate, end: endDate }}
            onDateSelected={(e) => {
              setInitialDate(e.start);
              setEndDate(e.end);
            }}
          />
          <FullHourCalendar
            isEnd
            value={{ start: initialDate, end: endDate }}
            onDateSelected={(e) => {
              setInitialDate(e.start);
              setEndDate(e.end);
            }}
          />
          <DownloadButton onClick={() => handleDownload()} />
        </div>
        <Button
          title="Cadastrar"
          icon={<FaPlus />}
          onClick={toggleModalRegisterMotoboy}
          containerStyles={{ height: 40 }}
        />
      </Header>

      <Modal
        isOpen={isModalRegisterMotoboyOpen}
        toggle={toggleModalRegisterMotoboy}
      >
        <CreateMotoboy
          toggleModalRegisterMotoboy={toggleModalRegisterMotoboy}
        />
      </Modal>

      <div
        style={{
          borderRadius: 8,
          backgroundColor: '#ffffff',
          padding: 20,
          overflowY: 'auto',
        }}
      >
        {loadingMotoboys && <Spinner style={{ marginLeft: 9 }} />}

        <MotoboysTable borderless>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Entregas</th>
              <th>Arrecadou</th>
              {IfoodOnDemandMotoboy && <th>Taxa Entrega Fácil</th>}
              <th>Ações</th>
            </tr>

            <tr style={{ background: '#f4f4f4', borderRadius: '7px' }}>
              <td style={{ padding: 20, fontWeight: 'bold' }}>Total</td>
              <td style={{ fontWeight: 'bold' }}>-</td>
              <td style={{ fontWeight: 'bold' }}>
                {(detailedMotoboy &&
                  detailedMotoboy?.length > 0 &&
                  detailedMotoboy
                    .map((motoboy) => motoboy?.sessions?.length)
                    .reduce((accum, curr) => accum + curr, 0)) ||
                  0}
              </td>
              <td style={{ fontWeight: 'bold' }}>
                R${' '}
                {parseFloat(
                  detailedMotoboy &&
                    detailedMotoboy?.length > 0 &&
                    detailedMotoboy
                      .map((motoboy) =>
                        motoboy?.sessions?.reduce(
                          (tot, s) => tot + parseFloat(s.delivery_tax_price),
                          0
                        )
                      )
                      .reduce((accum, curr) => accum + curr, 0)
                ).toFixed(2)}
              </td>
              {IfoodOnDemandMotoboy && (
                <td style={{ fontWeight: 'bold' }}>
                  R${' '}
                  {parseFloat(
                    IfoodOnDemandMotoboy.sessions?.reduce(
                      (tot, s) =>
                        tot + parseFloat(s.ifood_on_demand_value || 0),
                      0
                    )
                  ).toFixed(2)}
                </td>
              )}
              <td
                style={{
                  fontWeight: 'bold',
                  textAlign: 'right',
                  paddingRight: 16,
                }}
              >
                -
              </td>
            </tr>
          </thead>
          <tbody>
            {detailedMotoboy && detailedMotoboy.length > 0 ? (
              detailedMotoboy?.map((motoboy) => (
                <>
                  <MotoboyRow
                    key={motoboy.id}
                    hasSessions={motoboy.sessions?.length > 0}
                  >
                    <td
                      onClick={() => {
                        toggleMotoboySelected(motoboy);
                      }}
                      style={{
                        cursor: 'pointer',
                        pointerEvents: motoboy.sessions?.length === 0 && 'none',
                        fontWeight:
                          motoboySelected.id === motoboy.id ? 'bold' : 'normal',
                      }}
                    >
                      <FaAngleDown
                        style={{
                          color: motoboy.sessions?.length <= 0 && 'transparent',
                          transform:
                            motoboySelected.id === motoboy.id
                              ? 'rotate(-90deg)'
                              : 'rotate(0deg)',
                        }}
                      />{' '}
                      {motoboy.is_ifood_on_demand && (
                        <SiIfood
                          color="#FF2C3A"
                          size={17}
                          style={{ marginRight: 5 }}
                        />
                      )}
                      {motoboy.name}
                    </td>
                    <td>{motoboy.phone || ' - '}</td>
                    <td>{motoboy.sessions?.length || 0}</td>
                    <td>
                      R${' '}
                      {motoboy.sessions
                        ?.reduce(
                          (tot, s) => tot + parseFloat(s.delivery_tax_price),
                          0
                        )
                        .toFixed(2) || '0.00'}
                    </td>

                    {motoboy.is_ifood_on_demand && (
                      <td>
                        R${' '}
                        {parseFloat(
                          motoboy.sessions?.reduce(
                            (tot, s) =>
                              tot + parseFloat(s.ifood_on_demand_value || 0),
                            0
                          )
                        ).toFixed(2)}
                      </td>
                    )}

                    {IfoodOnDemandMotoboy && !motoboy.is_ifood_on_demand && (
                      <td>-</td>
                    )}

                    <Actions>
                      <ReactToPrint
                        onBeforeGetContent={async () => {
                          await toggleMotoboyPrint(motoboy);
                        }}
                        trigger={() => (
                          <FaPrint
                            style={{ marginRight: 10 }}
                            color="#FF2C3A"
                            size={20}
                            cursor="pointer"
                            title="Imprimir relatório"
                          />
                        )}
                        content={() => componentRef.current}
                      />
                      {!motoboy.is_ifood_on_demand && (
                        <>
                          <StyledLink
                            to={{
                              pathname: '/delivery/motoboys/edit',
                              state: {
                                motoboy,
                              },
                            }}
                          >
                            <MdEdit size={20} />
                          </StyledLink>
                          <Delete
                            onClick={() => {
                              setMotoboyToDelete(motoboy);
                              toggleConfirmDelete();
                            }}
                          >
                            <MdDelete size={20} />
                          </Delete>
                        </>
                      )}
                    </Actions>
                  </MotoboyRow>

                  <Collapse isOpen={motoboySelected.id === motoboy.id} tag="tr">
                    <td colSpan={6}>
                      <MotoboysDetailsTable style={{ width: '100%' }}>
                        {motoboy?.sessions === false ? (
                          <thead>
                            <tr>
                              <DetailHeader colSpan={4}>
                                Não houveram entregas desse motoboy nesse
                                período
                              </DetailHeader>
                            </tr>
                          </thead>
                        ) : (
                          <>
                            <thead>
                              <tr>
                                <th>Cliente</th>
                                <th>Bairro</th>
                                <th>Data</th>
                                <th>Taxa</th>
                                {motoboy.is_ifood_on_demand && (
                                  <th>Taxa Entrega Fácil iFood</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {motoboy?.sessions
                                ?.sort((a, b) =>
                                  b.created_at?.localeCompare(a.created_at)
                                )
                                .map((session) => {
                                  const date = session.created_at
                                    ? format(
                                        new Date(session.created_at),
                                        'HH:mm - dd/MM/yy'
                                      )
                                    : session.createdAt
                                    ? format(
                                        new Date(session.createdAt),
                                        'HH:mm - dd/MM/yy'
                                      )
                                    : ' - ';
                                  return (
                                    <tr key={session.id}>
                                      <td>
                                        {session?.buyer_name ||
                                          session?.buyer_phone}
                                      </td>
                                      <td>{session?.neighborhood}</td>
                                      <td>{date}</td>
                                      <td>R${session.delivery_tax_price}</td>
                                      {motoboy.is_ifood_on_demand &&
                                        session.ifood_on_demand_value && (
                                          <td>
                                            R${session.ifood_on_demand_value}
                                          </td>
                                        )}
                                      {/* <td style={{ textAlign: 'right' }}>
                                    
                                  </td> */}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </>
                        )}
                      </MotoboysDetailsTable>
                    </td>
                  </Collapse>
                </>
              ))
            ) : (
              <tr>
                <DetailHeader colSpan={4}>
                  Não houveram entregas de motoboys nesse período
                </DetailHeader>
              </tr>
            )}
          </tbody>
        </MotoboysTable>
      </div>
      <DisplayNoneImprimir>
        <PrintMotoboysDetails
          item={detailedMotoboy}
          restaurant={user}
          ref={componentRef}
          motoboy={motoboyPrint}
          dates={{ initialDate, endDate }}
        />
      </DisplayNoneImprimir>
      <ModalConfirmation
        title={`Deseja realmente deletar o motoboy ${motoboyToDelete?.name}?`}
        isOpen={modalConfirmDelete}
        toggle={toggleConfirmDelete}
        confirm={() => handleDelete()}
        cancel={toggleConfirmDelete}
      />
    </Container>
  );
}

export default Motoboy;
