import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { v4 } from 'uuid';

import { FiTrash } from 'react-icons/fi';

import posIcon from '../../assets/img/pos-icon.svg';

import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  DeletePaymentForm,
  PaymentMethodButton,
  BlueButton,
  RescueButton,
  RescueContainer,
  RescueDate,
} from './styles';

import { Button, DefaultInput } from 'ui-kit-takeat';

import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';
import { useAuth } from '~/context/AuthContext';
import CheckboxInput from '../Form/Checkbox';
import Select from '../Form/SelectInput';
import Input from '../Form/Input';
import InputMask from 'react-input-mask';
import apiClube from '~/services/apiClube';
import { format } from 'date-fns';
import { usePos } from '~/context/PosContext';
import { BsCheckLg } from 'react-icons/bs';
import { useClube } from '~/context/ClubeContext';
import PersonalButton from '../Buttons/PersonalButton';
import formatValue from '~/utils/formatValue';

export default function PaymentForm({
  session,
  setSession,
  payments_bill,
  setPaymentsBill,
  all_payments,
  setAllPayments,
  getPaymentsBill,
  setCount,
  getSessions,
  getBillsSession

}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [metodo, setMetodo] = useState('Outros');
  const {
    toastUpdateSystemMessage3,
    setToastUpdateSystemMessage3,
    restaurantDiscountObs,
    user,
    setData,
  } = useAuth();

  const {
    stoneTransactions,
    cancelStoneTransactions,
    createStoneTransactions,
  } = usePos();

  const [modal, setModal] = useState(false);
  function toggle(payment_id) {
    setPaymentIdToCancelStone(payment_id);
    setModal(!modal);
  }

  const [paymentIdToCancelStone, setPaymentIdToCancelStone] = useState(null);

  const { rescues, setRescues } = useClube();
  const [applyDiscount, setApplyDiscount] = useState(session.discount_total);
  const [discountObs, setDiscountObs] = useState(null);
  const [discountObservation, setDiscountObservation] = useState(false);
  const [discountText, setDiscountText] = useState(false);
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discount, setDiscount] = useState(session.discount_total);
  const [discountPercent, setDiscountPercent] = useState(session.discount_percent);
  const [clubInfo, setClubInfo] = useState({
    phone: session.client?.phone,
    date: '',
  });
  const [clientCashback, setClientCashback] = useState({
    date: null,
    value: 0,
  });
  const [loadingCashback, setLoadingCashback] = useState(false);

  const [paymentOption, setPaymentOption] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [hasMoneyMethod, setHasMoneyMethod] = useState(false);
  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);

  const [porcent, setPorcent] = useState(0);

  const [priceToShow, setPriceToShow] = useState(session.table.table_type !== "delivery" ? session.total_service_price : session.total_delivery_price)

  const [favoriteMethods, setFavoriteMethods] = useState([]);

  async function getClient(phone) {
    try {
      const response = await api.post('restaurants/get-clients', {
        phone,
      });

      if (
        response.data.hasClient &&
        session.client?.phone !== response.data.client.phone
      ) {
        submitClient(response.data.client);
      } else if (!response.data.hasClient) {
        if (phone[14] && phone[14] !== '_') {
          try {
            const response = await api.post('restaurants/clients', {
              session_id: session.id,
              phone,
              name: '',
              cpf: '',
              email: '',
              birthday: '',
              cep: '',
              bairro: '',
              street: '',
              number: '',
              complement: '',
            });

            console.log('Cadastro cliente novo res > ', response.data);
            toast.success('Cliente cadastrado com sucesso!');
          } catch (error) {
            toast.error(
              'Não foi possível finalizar o cadastro! tente novamente'
            );
          }
        } else {
          toast.error('Digite um número de telefone válido');
        }
      }
    } catch (error) {
      toast.error(
        'Não foi possível obter informações do cliente! tente novamente'
      );
    }
  }

  async function submitClient(client) {
    try {
      const response = await api.post('restaurants/clients/relate', {
        session_id: session.id,
        id: client.id,
      });

      toast.success('Cliente adicionado a comanda com sucesso!');
    } catch (error) {
      toast.error(
        'Não foi possível adicionar o cliente a comanda! tente novamente'
      );
    }
  }

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('restaurants/payment-methods');

    const methods = response.data.payment_methods.filter(
      (method) => method.available
    );

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setAllPaymentMethods(parsedCompleteData);

    const favoriteMethodsSplice = methods.splice(0, 8);

    const parsedDataFav = favoriteMethodsSplice.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setFavoriteMethods(parsedDataFav);

    const parsedData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const [adminPassword, setAdminPassword] = useState(null);
  const [isSendingDiscount, setIsSendigDiscount] = useState(false)

  const addDiscountToSession = async (e) => {
    e.preventDefault();
    if (!discountObs || !discount) {
      toast.error('Selecione um valor e uma justificativa para o desconto');
    } else if (discount &&
      user.order_cancel_password) {

      if (
        discount &&
        user.order_cancel_password &&
        (!adminPassword || adminPassword === '')
      ) {
        toast.error('Insira a senha administrativa.');
        return;
      }

      if (discount && user.order_cancel_password && adminPassword) {
        if (adminPassword !== user.order_cancel_password) {
          toast.error('Senha administrativa incorreta.');
          return;
        }
      }

      try {
        setIsSendigDiscount(true)
        const response = await api.put('/restaurants/table-session/discount', {
          discount,
          discountObs: discountObservation ? `${discountObs} - ${discountObservation}` : discountObs,
          userId: user.user_id,
          sessionId: session.id,
        });


        getSessions && getSessions()
        // getBillsSession && getBillsSession()
        const aleatoryId = v4()
        setCount && setCount(aleatoryId)

        setSession && setSession(state => {
          return {
            ...state,
            total_service_price: response.data.total_service_price,
            total_price: response.data.total_price,
            discount: response.data.discount,
            discount_percent: response.data.discount_percent,
            discount_total: response.data.discount_total,
            old_total_price: response.data.old_total_price,
            discount_obs: response.data.discount_obs
          }
        })
        toast.success('Desconto aplicado com sucesso!');
        setIsSendigDiscount(false)
      } catch (err) {
        console.log('addDiscountToSession error: ', err);
        toast.error('Não foi possível aplicar o desconto');
        setIsSendigDiscount(false)
      }

    } else {
      try {
        setIsSendigDiscount(true)
        const response = await api.put('/restaurants/table-session/discount', {
          discount,
          discountObs,
          userId: user.user_id,
          sessionId: session.id,
        });


        getSessions && getSessions()
        // getBillsSession && getBillsSession()
        const aleatoryId = v4()
        setCount && setCount(aleatoryId)

        setSession && setSession(state => {
          return {
            ...state,
            total_service_price: response.data.total_service_price,
            total_price: response.data.total_price,
            discount: response.data.discount,
            discount_percent: response.data.discount_percent,
            discount_total: response.data.discount_total,
            old_total_price: response.data.old_total_price,
            discount_obs: response.data.discount_obs
          }
        })
        toast.success('Desconto aplicado com sucesso!');
        setIsSendigDiscount(false)
      } catch (err) {
        console.log('addDiscountToSession error: ', err);
        toast.error('Não foi possível aplicar o desconto');
        setIsSendigDiscount(false)
      }
    }
  };

  const getPayments = useCallback(async () => {
    let total = 0;
    payments_bill.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });
    setPaid(total.toFixed(2).toString());

    const localLeft = session?.is_delivery
      ? session?.total_delivery_price - total
      : session?.total_service_price - total;
    setLeft(parseFloat(localLeft.toFixed(2)));

    const troco =
      total > session?.total_service_price
        ? total - session?.total_service_price
        : 0;

    const porcentPaid = session?.is_delivery
      ? (total / session?.total_delivery_price) * 100
      : ((total - troco) / session?.total_service_price) * 100;
    setPorcent(parseFloat(porcentPaid.toFixed(2)));

    getPaymentsBill();
  }, [getPaymentsBill, payments_bill, session]);

  const deletePayments = useCallback(
    async (id) => {
      if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        try {

          const response = await api.delete(`restaurants/payments/${id}`);

          const payments_bill_find = payments_bill.filter(
            (payment) => payment.id === id
          );

          const all_payments_bill = payments_bill.filter(
            (payment) => payment.id !== id
          );
          all_payments = all_payments.filter((payment) => payment.id !== id);
          getPayments();
          setAllPayments(all_payments);
          setPaymentsBill(all_payments_bill);

          document.getElementById('value').value = (
            Number(left) + Number(payments_bill_find[0].payment_value)
          ).toFixed(2);

          // setPaymentsSession(payments_bill);

          const aleatoryId = v4();

          setCount(aleatoryId);

          toast.success('Pagamento removido com sucesso!');
        } catch (error) {
          if (error.response.data.errorType === "credit_register_already_finished") {
            toast.error('Essa conta a prazo já foi quitada, não pode ser removida.');
          } else {
            toast.error('Erro ao deletar pagamento');
          }

        }
      }
    },
    [getPayments, left]
  );

  let total = 0;
  payments_bill.forEach((payment) => {
    total += parseFloat(payment.payment_value);
  });

  const totalValue = session?.is_delivery
    ? session?.total_delivery_price - total
    : session?.total_service_price - total;
  const [value, setValue] = useState(totalValue.toFixed(2));

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '');

    const price = session.old_total_price ? parseFloat(session.total_service_price) + parseFloat(session.discount_total) : session.total_service_price;

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setPriceToShow(price);
      setLeft(price - paid);
      setValue(price - paid);
    } else if (target.name === 'flat_discount') {
      const service_tax = parseFloat(session.total_service_price) - parseFloat(session.total_price)

      setDiscount(targetValue.replace(',', '.'));
      setDiscountPercent(
        ((parseFloat(targetValue.replace(',', '.')) / (price - service_tax)) * 100).toFixed(
          2
        ) || '-'
      );
      setPriceToShow(
        (price - parseFloat(targetValue.replace(',', '.'))).toFixed(2)
      );
      setLeft(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
      setValue(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
    } else {
      const service_tax = parseFloat(session.total_service_price) - parseFloat(session.total_price)
      const pricePercent = session.old_total_price ? parseFloat(session.old_total_price) : parseFloat(session.total_price);

      setDiscount(
        ((parseFloat(targetValue.replace(',', '.')) * pricePercent) / 100).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setPriceToShow(
        (
          pricePercent + service_tax -
          (parseFloat(targetValue.replace(',', '.')) * pricePercent) / 100
        ).toFixed(2)
      );
      setLeft(
        (
          pricePercent + service_tax -
          (parseFloat(targetValue.replace(',', '.')) * pricePercent) / 100 -
          paid
        ).toFixed(2)
      );
      setValue(
        (
          pricePercent + service_tax -
          (parseFloat(targetValue.replace(',', '.')) * pricePercent) / 100 -
          paid
        ).toFixed(2)
      );
    }
  };

  const [insertingPayment, setInsertingPayment] = useState(false);

  //register credit
  const [modalRegisterCreditPayment, setModalRegisterCreditPayment] = useState(false)
  const [creditRegisterOptions, setCreditRegisterOptions] = useState()


  function toggleModalInsertCreditRegisterPayment() {
    setModalRegisterCreditPayment(!modalRegisterCreditPayment)
  }


  const getCreditRegisters = useCallback(async () => {
    try {
      const response = await api.get("/restaurants/credit-register?details=true")

      const creditRegisterOptions = response.data.map(item => {
        return {
          value: item.id,
          label: item.name,
          total_limit: item.total_limit,
          balance: item.balance
        }
      })

      setCreditRegisterOptions(creditRegisterOptions)
    } catch (error) {
      toast.error("Erro ao baixar contas a prazo")
    }
  }, [])

  useEffect(() => {
    if (modalRegisterCreditPayment) {
      getCreditRegisters()
    }

  }, [getCreditRegisters, modalRegisterCreditPayment])

  const submitRegisterCreditPayment = useCallback(async (data) => {
    try {
      if (paymentOption.id) {
        await api.post("/restaurants/credit-register/verify", {
          value: Number(value),
          credit_register_id: data.credit_register_id
        })

        setInsertingPayment(true);
        const response = await api.post('restaurants/payments', {
          payment_value:
            paymentOption.id === 238 ? clientCashback.value : Number(value),
          table_session_id: session.id,
          payment_method_id: paymentOption.id,
          credit_register_id: data.credit_register_id

        });

        payments_bill.push(response.data);
        getPayments();
        setPaymentsBill(payments_bill);
        setAllPayments(payments_bill);
        document.getElementById('value').value = '';

        const aleatoryId = v4();

        const totalProduct = session?.is_delivery
          ? session?.total_delivery_price
          : session?.total_service_price;

        if (left === 0) {
          document.getElementById('value').value = (
            totalProduct - Number(value)
          ).toFixed(2);
        } else if (left < 0) {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        } else {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        }
        setInsertingPayment(false);
        setCount(aleatoryId);

        setClientCashback((state) => {
          return { ...state, value: 0 };
        });

        toast.success('Pagamento inserido com sucesso!');

        setModalRegisterCreditPayment(false)
      }
    } catch (error) {
      if (!error.response.ok) {
        if (error.response.data.errorType === 'cashier_opening_not_found') {
          toast.error(
            'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
          );
        } else if (error.response.data.errorType === 'credit_register_expired') {
          toast.error(
            "Conta a prazo com tempo expirado."
          );
        } else if (error.response.data.errorType === 'credit_register_limit_reached') {
          toast.error(
            'Limite insuficiente nesta conta a prazo.'
          );
        } else {
          setInsertingPayment(false);
          toast.error(error.response.data.message || 'Selecione o método de pagamento.');
        }
      }
    }
  }, [
    getPayments,
    paymentOption.id,
    payments_bill,
    setAllPayments,
    setPaymentsBill,
    left,
    session,
    value,
  ]);

  const newPayments = useCallback(async () => {
    try {
      if (paymentOption.id && paymentOption.keyword === 'prazo') {
        toggleModalInsertCreditRegisterPayment()
      } else if (paymentOption.id) {
        setInsertingPayment(true);
        const response = await api.post('restaurants/payments', {
          payment_value:
            paymentOption.id === 238 ? clientCashback.value : Number(value),
          table_session_id: session.id,
          payment_method_id: paymentOption.id,
        });

        payments_bill.push(response.data);
        getPayments();
        setPaymentsBill(payments_bill);
        setAllPayments(payments_bill);
        document.getElementById('value').value = '';

        const aleatoryId = v4();

        const totalProduct = session?.is_delivery
          ? session?.total_delivery_price
          : session?.total_service_price;

        if (left === 0) {
          document.getElementById('value').value = (
            totalProduct - Number(value)
          ).toFixed(2);
        } else if (left < 0) {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        } else {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);
        }
        setInsertingPayment(false);
        setCount(aleatoryId);

        setClientCashback((state) => {
          return { ...state, value: 0 };
        });

        toast.success('Pagamento inserido com sucesso!');
      }
    } catch (error) {
      if (!error.response.ok) {
        if (error.response.data.errorType === 'cashier_opening_not_found') {
          toast.error(
            'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
          );
        } else if (error.response.data.errorType === 'credit_register_limit_reached') {
          toast.error(
            'Limite insuficiente nesta conta a prazo.'
          );
        } else {
          setInsertingPayment(false);
          toast.error(error.response.data.message || 'Selecione o método de pagamento.');
        }
      }
    }
  }, [
    getPayments,
    paymentOption.id,
    payments_bill,
    setAllPayments,
    setPaymentsBill,
    left,
    session,
    value,
  ]);

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);
      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({
            ...user,
            club_login: login_club.data.token,
            minimo: login_club.data.user?.settings[0]?.minimo,
          })
        );

        setData((state) => {
          return {
            ...state,
            user: {
              ...user,
              club_login: login_club.data.token,
              minimo: login_club.data.user?.settings[0]?.minimo,
            },
          };
        });
      }
      setLoadingCashback(true);
      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });

      setClientCashback(response.data);
    } catch (err) {
      if (err.response?.data?.errorType === 'default_error') {
        toast.error('Este telefone não está cadastrado no clube');
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
      setClientCashback({
        date: null,
        value: 0,
      });
      console.log('getCashback error: ', err);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    if (!applyDiscount) {
      setDiscount(null);
      setDiscountObs(null);
      setDiscountPercent(null);
    }
  }, [applyDiscount]);

  useEffect(() => {
    newPayments();
  }, [paymentOption]);

  useEffect(() => {
    setValue(parseFloat(left).toFixed(2));

    setMetodo('Outros');
  }, [left]);

  useEffect(() => {
    if (user.has_clube && clubInfo.phone?.length === 15) {
      getCashback();
      getClient(clubInfo.phone);
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      format(new Date(clientCashback.date), 'dd/MM/yyyy') !== clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  async function setMetodoAndPaymentOption(payment) {
    if (
      payment.id === 238 &&
      parseFloat(clientCashback.value) < parseFloat(user.minimo)
    ) {
      toast.error('Valor do resgate abaixo do mínimo do clube');
      return;
    }

    if (payment.keyword === 'prazo' && payments_bill.length > 0 && user.credit_register_with_nfce) {
      toast.error('O método Prazo só pode ser escolhido caso não tenham outros métodos inseridos');
      return;
    }
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label, keyword: payment.keyword });

    if (payment.id === 238) {
      const newRescues = [
        ...rescues,
        { phone: clubInfo.phone, clientCashback: clientCashback.value },
      ];
      setRescues(newRescues);
    }
  }

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);

    setToastMessage(false);
  }

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    const input = document.getElementById('value')?.focus();
  }, []);

  const [loadingPos, setLoadingPos] = useState(false);

  const handleStoneTransactions = useCallback(async (payment_id) => {
    setLoadingPos(true);
    try {
      await createStoneTransactions({
        payment_id,
        stone_device_id: user.stone_device_id,
      });
    } catch (err) { }
    setLoadingPos(false);
  }, []);

  const handleCancelStoneTransactions = useCallback(async () => {
    setLoadingPos(true);
    try {
      await cancelStoneTransactions({ payment_id: paymentIdToCancelStone });
      toggle();
    } catch (err) { }
    setLoadingPos(false);
  }, [paymentIdToCancelStone]);

  useEffect(() => {
    const paymentMethodFiltered = payments_bill.filter(
      (payment) => payment.payment_method.keyword === 'dinheiro'
    );
    if (paymentMethodFiltered.length > 0) {
      setHasMoneyMethod(true);
    } else {
      setHasMoneyMethod(false);
    }


  }, [payments_bill, left]);

  const [creditRegisterSelected, setCreditRegisterSelected] = useState(false)

  function handleSetCreditRegisterSelected(e) {
    const userFiltered = creditRegisterOptions.find(item => Number(item.value) === Number(e))

    setCreditRegisterSelected(userFiltered)
  }


  const [hasTax, setHasTax] = useState(!session.has_tax)

  const handleRemoveTax = useCallback(
    async (data) => {
      setHasTax(data)

      const response = await api.put('restaurants/update-session-tax', {
        session_key: session?.key,
        has_tax: hasTax,
      });

      const newPrice = response.data.session.total_service_price
      setPriceToShow(newPrice)

      const newValue = (parseFloat(newPrice) - paid).toFixed(2)
      setValue(newValue)

      setSession && setSession(state => {
        return {
          ...state,
          total_service_price: newPrice
        }
      })

      getSessions();

    },
    [session, getSessions, paid, setSession]
  );

  async function handleCancelDiscount() {
    try {
      const response = await api.post('restaurants/cancel-discount', {
        session_key: session.key,
      });
      getSessions && getSessions()
      getBillsSession && getBillsSession()
      const newPrice = response.data.total_service_price
      setPriceToShow(newPrice)

      const newValue = (parseFloat(newPrice) - paid).toFixed(2)
      setValue(newValue)

      const aleatoryId = v4()
      setCount && setCount(aleatoryId)
      setSession && setSession(state => {
        return { ...state, total_service_price: response.data.total_service_price, total_price: response.data.total_price, discount: null, discountPercent: null, discount_total: null, old_total_price: null, discount_obs: null }
      })
      setDiscount(0)
      setDiscountPercent(0)
      toast.success('Desconto removido!');

    } catch (error) {
      toast.error('Erro ao remover desconto');
    }
  }

  function handleApplyDiscount() {
    setApplyDiscount(!applyDiscount)

    if (discount) {
      handleCancelDiscount()
    }
  }



  return (
    <Container>
      <BillExplanation>
        <h4 >Adicionar Pagamento(s)</h4>
        <h7>
          Aqui você adiciona o(s) pagamento(s) já feitos da comanda e confere
          quanto falta a ser pago.
        </h7>
      </BillExplanation>

      <Modal isOpen={modalRegisterCreditPayment} toggle={toggleModalInsertCreditRegisterPayment}>
        <Form onSubmit={submitRegisterCreditPayment}>
          <ModalBody>
            <p style={{ fontWeight: "bold" }}>Fiado</p>
            <Select options={creditRegisterOptions} name="credit_register_id" label="Selecione a conta a prazo" placeholder="Selecione ..." onChange={e => handleSetCreditRegisterSelected(e.value)} />
          </ModalBody>
          <ModalFooter style={{ display: "flex", flexjustifyContent: "space-between" }}>
            {creditRegisterSelected && creditRegisterSelected.total_limit > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>

                <p style={{ color: Number(creditRegisterSelected.total_limit) === Math.abs(Number(creditRegisterSelected.balance)) ? "#FF2C3A" : "#3BD2C1" }}>
                  Limite disponível: R$ {creditRegisterSelected && (creditRegisterSelected.total_limit - Math.abs(creditRegisterSelected.balance)).toFixed(2)}
                </p>


                {Number(creditRegisterSelected.total_limit) === Math.abs(Number(creditRegisterSelected.balance)) && (
                  <p style={{ color: "#FF2C3A", fontWeight: "bold" }}>
                    LIMITE INSUFICIENTE
                  </p>
                )}
              </div>)}


            <PersonalButton type="submit" message="Inserir pagamento" color="#2ec9b7" />
          </ModalFooter>
        </Form>
      </Modal>

      {user.has_clube && (
        <Form
          style={{
            borderBottom: '1px solid #dee2e6',
            transition: 'all 0.4s',
            width: 'fit-content',
          }}
        >
          <RescueContainer>
            {loadingCashback && (
              <Spinner
                style={{
                  position: 'absolute',
                  top: 49,
                  left: 135,
                  zIndex: 10,
                  height: 15,
                  width: 15,
                }}
              />
            )}
            <InputMask
              mask="(99) 99999-9999"
              maskPlaceholder={null}
              defaultValue={clubInfo.phone}
              value={clubInfo.phone}
              onChange={(e) => {
                const newText = e.target.value;
                setClubInfo((state) => {
                  return { ...state, phone: newText };
                });
              }}
            >
              <Input
                label="Celular:"
                name="club-phone"
                type="text"
                placeholder="Celular para pontuar no clube"
              />
            </InputMask>

            <RescueDate>
              <InputMask
                id="date"
                mask="99/99/9999"
                maskPlaceholder={null}
                defaultValue={clubInfo.date}
                value={clubInfo.date}
                onChange={(e) => {
                  const newText = e.target.value;
                  setClubInfo((state) => {
                    return { ...state, date: newText };
                  });
                }}
              >
                <Input
                  label="Aniversário:"
                  name="club-birthday"
                  type="text"
                  placeholder="dd/mm/aaaa"
                />
              </InputMask>

              <RescueButton
                disabled={
                  format(new Date(clientCashback.date), 'dd/MM/yyyy') !==
                  clubInfo.date || parseFloat(clientCashback.value) <= 0
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (all_payments.find((p) => p.payment_method_id === 238)) {
                    toast.error('Resgate do clube já foi adicionado');
                  } else if (parseFloat(clientCashback.value) > 0) {
                    setMetodoAndPaymentOption({
                      label: 'Resgate Clube',
                      id: 238,
                    });
                  }
                }}
              >
                {`Resgatar R$${clientCashback.value}`}
              </RescueButton>
            </RescueDate>
          </RescueContainer>
        </Form>
      )}

      {session?.table?.table_type !== "pos" && session?.table?.table_type !== "delivery" && (
        <>
          <Form>
            {user?.has_service_tax && (

              <CheckboxInput
                name="has_tax"
                checked={hasTax}
                value={hasTax}
                onChange={(e) => {
                  handleRemoveTax(!hasTax);

                }}
                label="Retirar taxa de serviço"
              />
            )}
            <CheckboxInput
              label="Aplicar desconto"
              name="apply"
              value={applyDiscount}
              checked={applyDiscount}
              onChange={() => handleApplyDiscount()}
            />
          </Form>
          {applyDiscount && (
            <>
              <span style={{ color: 'grey' }}>Desconto</span>
              <div
                style={{
                  display: 'flex',
                  margin: '10px 0 0',
                  position: 'relative',
                }}
              >
                <input
                  name="flat_discount"
                  type="text"
                  placeholder="R$"
                  style={{ width: '50%', padding: '3px 10px' }}
                  value={discount ? 'R$' + discount : ''}
                  onChange={(e) => handleChangeDiscount(e.target)}
                />
                <input
                  name="discount"
                  placeholder="%"
                  type="number"
                  style={{
                    width: '50%',
                    padding: '3px 10px',
                    marginLeft: '5px',
                  }}
                  value={discountPercent ? discountPercent : ''}
                  onChange={(e) => handleChangeDiscount(e.target)}
                />
                {discount && (
                  <p
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '5px',
                    }}
                  >
                    %
                  </p>
                )}
              </div>
              <Form>
                <span style={{ color: 'grey', marginTop: '10px' }}>Motivo</span>
                <Select
                  label={null}
                  name="discount_obs"
                  options={discountObsOptions}
                  onChange={(e) => {
                    if (e.value === 'other') {
                      setDiscountText(true);
                    } else {
                      setDiscountText(false);
                      setDiscountObs(e.label);
                    }
                  }}
                />
                {discountText && (
                  <Input
                    type="text"
                    placeholder="Escreva o motivo"
                    id="discount_obs"
                    name="discount_obs"
                    onChange={(e) => setDiscountObs(e.target.value)}
                  />
                )}

                <DefaultInput
                  type="text"
                  placeholder="Observação"
                  id="observation"
                  name="observation"
                  style={{ height: 40, padding: '7px 14px' }}
                  onChange={(e) => setDiscountObservation(e.target.value)}
                />

                {user.order_cancel_password !== '' && (
                  <Input
                    placeholder="Senha administrativa"
                    id="cancel_password"
                    name="cancel_password"
                    type="password"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                  />
                )}
                <BlueButton onClick={(e) => addDiscountToSession(e)} disabled={isSendingDiscount}>
                  Aplicar
                </BlueButton>
              </Form>
            </>
          )}
        </>
      )}


      <InformationDiv>
        <Total>
          <span>Total</span>
          <h4>{formatValue(priceToShow)}</h4>
        </Total>
        <Paid>
          <span>
            {left > 0 ? 'Restante' : hasMoneyMethod ? 'Troco' : 'Extra'}
          </span>
          <h4>{formatValue(left > 0 ? left : left * -1)}</h4>
        </Paid>
        <Form>
          <InputPaymentForm
            placeholder="R$"
            type="number"
            step="0.01"
            min="0"
            name="value"
            id="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form>
      </InformationDiv>

      <PaymentDiv>
        <div
          style={{
            position: 'fixed',
            top: 300,
            left: 290,
            width: '415px',
            height: 210,
            backgroundColor: '#fff',
            borderRadius: 7,
            padding: 20,
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            display: toastMessage ? 'flex' : 'none',
            zIndex: 2000000,
          }}
        >

          <div style={{}}>
            <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
              <FaExclamationTriangle color="darkOrange" />{' '}
              <strong>Atenção, nova Atualização!</strong>
            </p>
            <span>
              Agora é possível favoritar os métodos de pagamento para que eles
              apareçam nessa lista e agilize ainda mais a sua escolha. Se quiser
              alterar o método de pagamento favorito,{' '}
              <Link to="/box/payment"> clique aqui.</Link>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              style={{
                background: '#3BD2C1',
                color: '#fff',
                width: 54,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: 7,
                marginLeft: 10,
                fontWeight: 'bold',
                border: 'none',
              }}
              onClick={(e) => toggleToast(false)}
            >
              OK
            </button>
          </div>
        </div>



        <AddPaymentForm>
          {favoriteMethods.map((payment) =>
            payment.available &&
              payment.keyword !== 'clube' &&
              payment.keyword !== 'pix_auto' &&
              payment.keyword !== 'stone_debit' &&
              payment.keyword !== 'online_ifood' &&
              payment.keyword !== 'credit_card_auto' &&
              payment.keyword !== 'stone_credit' ? (
              <div key={payment.id}>
                <PaymentMethodButton
                  onClick={() => setMetodoAndPaymentOption(payment)}
                  disabled={insertingPayment || (!!payments_bill.find(pay => pay?.payment_method?.keyword === 'prazo') && user.credit_register_with_nfce)}
                >
                  {payment.label}
                </PaymentMethodButton>
              </div>
            ) : (
              <></>
            )
          )}

          {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
              <DropdownToggle
                color="danger"
                caret
                style={{ width: '100px', height: '40px', margin: 0 }}
              >
                {metodo}
              </DropdownToggle>
              <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
                {paymentMethods.map((payment) =>
                  payment.available &&
                    payment.keyword !== 'clube' &&
                    payment.keyword !== 'pix_auto' &&
                    payment.keyword !== 'online_ifood' &&
                    payment.keyword !== 'credit_card_auto' &&
                    payment.keyword !== 'stone_debit' &&
                    payment.keyword !== 'stone_credit' ? (
                    <div key={payment.id}>
                      <DropdownItem
                        onClick={() => setMetodoAndPaymentOption(payment)}
                        disabled={insertingPayment || (!!payments_bill.find(pay => pay?.payment_method?.keyword === 'prazo') && user.credit_register_with_nfce)}
                      >
                        {payment.label}
                      </DropdownItem>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </AddPaymentForm>

        <PaymentTable borderless>
          <tbody>
            {session.payments?.map((payment) =>
              payment.payment_method_id === 238 ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Resgate Clube</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : payment.payment_method.keyword === 'pix_auto' ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Pix Online</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : payment.payment_method.keyword === 'stone_credit' ? (
                <tr key={payment.id} style={{ color: '#017957' }}>
                  <td>Stone Crédito</td>
                  <td>R${payment.payment_value}</td>
                </tr>
              ) : (
                payment.payment_method.keyword === 'stone_debit' && (
                  <tr key={payment.id} style={{ color: '#017957' }}>
                    <td>Stone Débito</td>
                    <td>R${payment.payment_value}</td>
                  </tr>
                )
              )
            )}

            {payments_bill.map(
              (payment) =>
                payment.payment_method_id !== 238 &&
                payment.payment_method.keyword !== 'pix_auto' &&
                payment.payment_method.keyword !== 'stone_credit' &&
                payment.payment_method.keyword !== 'stone_debit' && (
                  <tr key={payment.id}>
                    {payment.payment_method ?
                      <td key={payment.id}>{payment.payment_method.name}</td>
                      : allPaymentMethods
                        .filter(
                          (method) => method.id === payment.payment_method_id
                        )
                        .map((method) => (
                          <td key={method.id}>{method.label}</td>
                        ))}
                    <td>R${payment.payment_value}</td>
                    <td />
                    <td>
                      <div>
                        <FiTrash size={15} onClick={() => deletePayments(payment.id)} color='#ff2b3a' cursor="pointer" style={{
                          display: (payment.payment_method.keyword === 'credit_card_auto' || payment.payment_method.keyword === 'pix_auto' || payment.payment_method.keyword === 'online_ifood') ? 'none' : 'block',
                        }} />
                      </div>
                    </td>
                    {user.has_stone_pdv &&
                      ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                        payment.payment_method.method
                      ) &&
                      parseFloat(payment.payment_value) > 0 && (
                        <td style={{ textAlign: 'right' }}>
                          {!stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.status !== 'canceled'
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  onClick={() =>
                                    !loadingPos &&
                                    handleStoneTransactions(payment.id)
                                  }
                                  style={
                                    loadingPos ? { pointerEvents: 'none' } : {}
                                  }
                                >
                                  <img src={posIcon} style={{ height: 18 }} />
                                </DeletePaymentForm>
                              </div>
                            )}

                          {stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.status === 'pending'
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  onClick={() => toggle(payment.id)}
                                  disabled={loadingPos}
                                >
                                  <Spinner
                                    size="sm"
                                    color="#3BD2C1"
                                    style={{
                                      width: 15,
                                      height: 15,
                                      marginBottom: 4,
                                      color: '#3BD2C1',
                                    }}
                                  />
                                </DeletePaymentForm>
                              </div>
                            )}

                          {stoneTransactions.find(
                            (trans) =>
                              trans.payment_id === payment.id &&
                              trans.paid_at !== null
                          ) && (
                              <div>
                                <DeletePaymentForm
                                  // onClick={() => handleStoneTransactions(payment.id)}
                                  disabled={loadingPos}
                                >
                                  <BsCheckLg
                                    style={{
                                      width: 20,
                                      height: 20,
                                      color: '#3BD2C1',
                                    }}
                                  />
                                </DeletePaymentForm>
                              </div>
                            )}
                        </td>
                      )}
                  </tr>
                )
            )}
          </tbody>
        </PaymentTable>
      </PaymentDiv>
      <FinalDiv>
        <Total>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>{porcent}%</h4>
        </Paid>
      </FinalDiv>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="12">Deseja cancelar esse pagamento na POS?</Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ marginLeft: 'auto' }}>
          <Button
            title="Sim, cancelar"
            buttonColor="#2ec9b7"
            disabled={loadingPos}
            onClick={(e) => handleCancelStoneTransactions()}
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
}
