import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';
import { AiOutlineEye } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';
import { BsArrowLeftRight } from 'react-icons/bs';
import { BiMailSend } from 'react-icons/bi';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { addMinutes, isBefore, format } from 'date-fns';

import PrintNFCeHistory from '~/components/Prints/PrintNFCeHistory';
import Input from '~/components/Form/Input';
import NFeInfo from '~/services/NFeInfo';

import {
  Actions,
  ActionsInside,
  DeleteAndEditActions,
  CloseButton,
  SeeButton,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';

import api from '~/services/api';
import NfeDevolucao from '../NfeDevolucao';
import { useAuth } from '~/context/AuthContext';
import { FaEllipsisV } from 'react-icons/fa';
import NfeAvulsaItem from '../NfeAvulsaItem';
import NfeEntryInfoModal from '../NfeEntryInfoModal';

export default function NfeEntry({ nfe, handleUpdate, index, length }) {
  const { user } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const [modalHtml, setModalHtml] = useState(false);
  function toggleHtml() {
    if (modalHtml) {
      setHtml(null);
    }
    setModalHtml(!modalHtml);
  }

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  const [modalCancel, setModalCancel] = useState(false);
  function toggleCancel() {
    setModalCancel(!modalCancel);
  }

  const [modalEmail, setModalEmail] = useState(false);
  function toggleEmail() {
    setModalEmail(!modalEmail);
  }

  const [modalDevolucao, setModalDevolucao] = useState(false);
  function toggleDevolucao() {
    setModalDevolucao(!modalDevolucao);
  }

  const componentRef = useRef(React.createRef);
  const expireDate = addMinutes(new Date(nfe.created_at), 30);
  const isAllowCancel = isBefore(new Date(), expireDate);

  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const [loadingDevolucao, setLoadingDevolucao] = useState(false);

  const sentEmail = useCallback(async (data) => {
    setLoadingEmail(true);

    try {
      await api.post('/restaurants/nfe-received/single/send-email', {
        id: nfe.id,
        email: data.email,
      });
      toast.success('E-mail enviado com sucesso!');
    } catch (err) {
      toast.error('Erro ao enviar E-mail.');
    }

    setLoadingEmail(false);
    document.getElementById('close_email_modal').click();
  }, []);

  const [html, setHtml] = useState(null);

  const [nfeInfo, setNfeInfo] = useState(null);
  const [nfeReq, setNfeReq] = useState(null);

  const openModal = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await api.get(`/restaurants/nfe-received/info/${id}`);
      setNfeInfo(response.data);
      setNfeReq(
        response.data.requisicao_nota_fiscal
          ? response.data.requisicao_nota_fiscal
          : null
      );
      toggle();
      setLoading(false);
    } catch (error) {
      toast.error('Não foi possível carregar a Nfe desejada, tente novamente');
    }
  }, []);

  const [itens, setItens] = useState([]);
  const [dataDevolucao, setDataDevolucao] = useState([]);

  const openModalDevolucao = useCallback(async (id) => {
    try {
      setLoadingDevolucao(true);
      const response = await api.get(`/restaurants/nfe-received/info/${id}`);
      setNfeInfo(response.data);
      const nfeReq = response.data.requisicao_nota_fiscal || null;

      setNfeReq(
        response.data.requisicao_nota_fiscal
          ? response.data.requisicao_nota_fiscal
          : null
      );

      //colocar mais um campo de local_destino
      const thisData = {
        local_destino: '1',
        natureza_operacao: 'NOTA DE DEVOLUCAO',
        data_emissao: new Date(),
        data_entrada_saida: new Date(),
        tipo_documento: 1,
        finalidade_emissao: 4,
        consumidor_final: 0,
        presenca_comprador: 0,
        informacoes_adicionais_contribuinte: '',
        // icms_aliquota: nfeReq?.icms_aliquota || 0,
        // icms_base_calculo: nfeReq?.icms_base_calculo || 0,
        // icms_valor_total: nfeReq?.icms_valor_total || 0,
        // issqn_aliquota: nfeReq?.issqn_aliquota || 0,
        // issqn_base_calculo: nfeReq?.issqn_base_calculo || 0,
        // issqn_valor_total: nfeReq?.issqn_valor_total || 0,
        // icms_aliquota_st: nfeReq?.icms_aliquota_st || 0,
        // icms_base_calculo_st: nfeReq?.icms_base_calculo_st || 0,
        // icms_valor_total_st: nfeReq?.icms_valor_total_st || 0,
        // valor_frete: nfeReq?.valor_frete || '0.00',
        // valor_desconto: nfeReq?.valor_desconto || '0.00',
        // valor_ipi: nfeReq?.valor_ipi || '0.00',
        // pis_aliquota_porcentual: nfeReq?.pis_aliquota_porcentual || 0,
        // valor_pis: nfeReq?.valor_pis || '0.00',
        // cofins_aliquota_porcentual: nfeReq?.cofins_aliquota_porcentual || 0,
        // valor_cofins: nfeReq?.valor_cofins || '0.00',
        // valor_total: nfeReq?.valor_total || '0.00',
        modalidade_frete: nfeReq?.modalidade_frete || 0,

        nome_emitente: nfeReq?.nome_destinatario || '',
        cnpj_emitente: nfeReq?.cnpj_destinatario || '',
        inscricao_estadual_emitente:
          nfeReq?.inscricao_estadual_destinatario || '',
        logradouro_emitente: nfeReq?.logradouro_destinatario || '',
        numero_emitente: nfeReq?.numero_destinatario || '',
        complemento_emitente: nfeReq?.complemento_destinatario || '',
        bairro_emitente: nfeReq?.bairro_destinatario || '',
        municipio_emitente: nfeReq?.municipio_destinatario || '',
        uf_emitente: nfeReq?.uf_destinatario || '',
        cep_emitente: nfeReq?.cep_destinatario || '',
        regime_tributario_emitente: user.regime_tributario,

        nome_destinatario: nfeReq?.nome_emitente || '',
        cnpj_destinatario: nfeReq?.cnpj_emitente || '',
        logradouro_destinatario: nfeReq?.logradouro_emitente || '',
        numero_destinatario: nfeReq?.numero_emitente || '',
        complemento_destinatario: nfeReq?.complemento_emitente || '',
        bairro_destinatario: nfeReq?.bairro_emitente || '',
        municipio_destinatario: nfeReq?.municipio_emitente || '',
        uf_destinatario: nfeReq?.uf_emitente || '',
        pais_destinatario: nfeReq?.pais_emitente || '',
        cep_destinatario: nfeReq?.cep_emitente || '',
        indicador_inscricao_estadual_destinatario:
          nfeReq?.indicador_inscricao_estadual_emitente || 1,
        inscricao_estadual_destinatario: nfeReq?.inscricao_estadual_emitente || '',
        notas_referenciadas: [
          {
            chave_nfe: nfe?.chave_nfe,
          },
        ],
      };

      setDataDevolucao(thisData);

      const thisItens = response.data.requisicao_nota_fiscal
        ? (response.data.requisicao_nota_fiscal?.itens || response.data.requisicao_nota_fiscal?.items).map((item) => {
            return {
              numero_item: item.numero_item,
              send: false,
              codigo_produto: item.codigo_produto,
              descricao: item.descricao,
              cfop: null, //item.cfop,
              quantidade_comercial: item.quantidade_comercial,
              quantidade_tributavel: item.quantidade_tributavel,
              valor_unitario_comercial: item.valor_unitario_comercial,
              valor_unitario_tributavel: item.valor_unitario_tributavel,
              unidade_comercial: item.unidade_comercial,
              unidade_tributavel: item.unidade_tributavel,
              valor_frete: item.valor_frete || '0.00',
              valor_bruto: item.valor_bruto,
              valor_desconto: item.valor_desconto || '0.00',
              codigo_ncm: item.codigo_ncm,
              inclui_no_total: item.inclui_no_total,

              icms_origem: item.icms_origem,
              icms_modalidade_base_calculo: item.icms_modalidade_base_calculo,
              icms_situacao_tributaria: item.icms_situacao_tributaria,
              // icms_situacao_tributaria: 
              //   user.regime_tributario === '1' ? 
              //   (NFeInfo.csosn.find(csosn => csosn.csosn === item.icms_modalidade_base_calculo) ? item.icms_modalidade_base_calculo : null)
              //   : 
              //   (NFeInfo.cst.find(csosn => csosn.cst === item.icms_modalidade_base_calculo) ? item.icms_modalidade_base_calculo : null),
              icms_aliquota: item.icms_aliquota,
              icms_valor: item.icms_valor,
              icms_base_calculo: item.icms_base_calculo,

              pis_situacao_tributaria: item.pis_situacao_tributaria,
              pis_aliquota_porcentual: item.pis_aliquota_porcentual,
              pis_base_calculo: item.pis_base_calculo,
              pis_valor: item.pis_valor,

              cofins_situacao_tributaria: item.cofins_situacao_tributaria,
              cofins_aliquota_porcentual: item.cofins_aliquota_porcentual,
              cofins_base_calculo: item.cofins_base_calculo,
              cofins_valor: item.cofins_valor,
              valor_ipi_devolvido: item.ipi_valor,
              _quantidade_original: item.quantidade_comercial
            };
          })
        : [];

      setItens(thisItens);
      toggleDevolucao();
      setLoadingDevolucao(false);
    } catch (error) {
      setLoadingDevolucao(false);
      console.log(error);
      toast.error('Não foi possível carregar a Nfe desejada, tente novamente');
    }
  }, []);

  function formatAsDateTime(date) {
    if (date) {
      return format(new Date(date + 'T12:00-03:00'), 'dd/MM/yyyy');
    }

    return format(new Date(0), 'dd/MM/yyyy');
  }

  return (
    <>
      <tr>
        <td>{nfe?.nome_emitente}</td>
        <td>{formatCompleteDate(nfe?.data_emissao)}</td>
        {/* <td>{nfe?.chave_nfe}</td> */}
        <td>R${nfe?.valor_total}</td>
        <td>{nfe?.numero}</td>
        
        <td>
          <div
            style={{
              display: 'flex',
              alignItems: 'right'
            }}
          >
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              direction={index === 0 ? "left" : length > 1 && index + 1 === length ? 'up' : 'down'}
              style={{ marginLeft: 'auto' }}
            >
              <DropdownToggle tag="span">
                {' '}
                <FaEllipsisV
                  title="Mostrar Opções"
                  cursor="pointer"
                />
              </DropdownToggle>
              <DropdownMenu flip hidden={dropDownHidden}>
                <DropdownItem
                  onClick={() => {
                    openModal(nfe.id);
                  }}
                >
                  <div>
                    <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                    {loading ? 'Aguarde...' : 'Detalhes'}
                  </div>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    toggleEmail();
                  }}
                >
                  <div>
                    <BiMailSend size={16} style={{ marginRight: 3 }} />
                    Enviar por E-mail
                  </div>
                </DropdownItem>

                
                <DropdownItem
                  onClick={() => {
                    openModalDevolucao(nfe.id);
                  }}
                >
                  <div>
                    <BsArrowLeftRight size={15} style={{ marginRight: 3 }} />
                    {loadingDevolucao ? 'Aguarde...' : 'Devolução'}
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </td>
      </tr>

      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Chave:</strong> #{nfe?.chave_nfe}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Status:</strong> {nfe?.situacao}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Criada em:</strong>{' '}
              {formatCompleteDate(nfe?.data_emissao)}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Manifestação:</strong> {nfe?.manifestacao_destinatario}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Valor:</strong> R${nfe?.valor_total}
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}

      <NfeDevolucao
        nfe={nfe}
        nfeReq={nfeReq}
        toggle={toggleDevolucao}
        modal={modalDevolucao}
        itens={itens}
        setItens={setItens}
        data={dataDevolucao}
        setDataDevolucao={setDataDevolucao}
        user={user}
        nfeData={nfeInfo}
      />

      <NfeEntryInfoModal modal={modal} toggle={toggle} nfeReq={nfeReq} />

      {/* <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <Form initialData={nfeReq}>
            {!nfeReq && (
              <Row>
                <Col>
                  <h5 style={{ color: 'red' }}>
                    <strong>
                      Informações da nota ainda não processada pela Sefaz. Tente
                      novamente mais tarde.
                    </strong>
                  </h5>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
              >
                <h5>Dados do Fornecedor:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_fantasia_emitente"
                  label="Fornecedor"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="6">
                <Input
                  name="nome_emitente"
                  label="Razão Social"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input name="cnpj_emitente" label="CNPJ" type="text" disabled />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados da Nota:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="valor_total"
                  label="Valor Total (R$)"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="data_emissao"
                  label="Data Emissão"
                  value={format(
                    new Date(nfeReq?.data_emissao || null),
                    'dd/MM/yyyy'
                  )}
                  type="text"
                  disabled
                />
              </Col>
              <Col md="8">
                <Input
                  name="chave_nfe"
                  label="Chave NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input name="serie" label="Série" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="numero" label="Número" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="modelo" label="Modelo" type="text" disabled />
              </Col>
              <Col md="3">
                <Input
                  name="codigo_unico"
                  label="Código Único"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input name="versao" label="Versão" type="text" disabled />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Duplicatas:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.duplicatas && nfeReq?.duplicatas.length > 0 ? (
            nfeReq?.duplicatas.map((dup) => (
              <Form initialData={dup}>
                <Row>
                  <Col md="4">
                    <Input name="numero" label="Número" type="text" disabled />
                  </Col>
                  <Col md="4">
                    <Input
                      name="data_vencimento"
                      label="Data Vencimento"
                      value={formatAsDateTime(dup.data_vencimento)}
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      name="valor"
                      label="Valor (R$)"
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
          )}

          <Form initialData={nfeReq}>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Totais da Nota :</h5>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_produtos"
                  label="Valor dos Produtos"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo"
                  label="Base de Cálculo do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_valor_total"
                  label="Valor do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo_st"
                  label="Base de Cálc. do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="icms_valor_total_st"
                  label="Valor Total do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_pis"
                  label="Valor do PIS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_cofins"
                  label="Valor do COFINS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_ipi"
                  label="Valor do IPI"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_frete"
                  label="Valor Total do Frete"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_outras_despesas"
                  label="Valor de Outras Despesas"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_desconto"
                  label="Valor Total de Desconto"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_total"
                  label="Valor Total da NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Produtos:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.itens && nfeReq?.itens.length > 0 ? (
            nfeReq?.itens.map((item) => (
              <Form
                initialData={item}
                style={{ paddingTop: 15, borderBottom: '1px solid #EBEBEB' }}
              >
                <Row>
                  <Col>
                    <h5>Produto #{item.numero_item}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input
                      name="codigo_produto"
                      label="Cód. Forn."
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      name="descricao"
                      label="Produto"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="quantidade_comercial"
                      label="Qnt. Nota"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_comercial"
                      label="Unidade"
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Input
                      name="valor_unitario_comercial"
                      label="Valor de Custo"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_bruto"
                      label="Valor Total"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input name="codigo_ncm" label="NCM" type="text" disabled />
                  </Col>
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => toggle()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal> */}

      <Modal isOpen={modalHtml} toggle={toggleHtml}>
        <ModalBody style={{ padding: 20 }}>
          <PrintNFCeHistory html={html} ref={componentRef} />
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <SeeButton>
                <div>
                  <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                  Imprimir Html
                </div>
              </SeeButton>
            )}
            content={() => componentRef.current}
            onAfterPrint={() => toggleHtml()}
          />
        </ModalFooter>
      </Modal>

      {/* <DisplayNoneImprimir>
        <PrintNFCe html={html} ref={componentRef} />
      </DisplayNoneImprimir> */}

      <Modal isOpen={modalEmail} toggle={toggleEmail}>
        <Form onSubmit={sentEmail}>
          <ModalBody>
            <h6>Enviar Nota Fiscal por E-mail</h6>
            <Row>
              <Col sm="12" lg="12">
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  placeholder="exemplo@gmail.com"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={toggleEmail}
              id="close_email_modal"
              style={{ background: '#dc3545' }}
            >
              Fechar
            </Button>
            <Button type="submit" style={{ background: 'green' }}>
              {loadingEmail ? (
                <Spinner
                  color="light"
                  style={{ width: '1em', height: '1em' }}
                />
              ) : (
                'Enviar'
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
