import styled from 'styled-components';

const red = '#ff2d3a';

export const Container = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 200px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;

  &::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 8px;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const IconDiv = styled.div`
  min-width: 48px;
  height: 100%;
  color: white;
  background-color: ${red};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionDiv = styled.div`
  display: flex;
  padding: 0 10px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 48px;

  span {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  svg {
    color: #ff2d3a;
    width: 18px;
    height: 18px;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  padding: 20px 0;

  img {
    width: 82px;
    height: 82px;
  }

  span {
    color: #666;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;

    height: fit-content;
    margin: 0;
  }
`;

export const RestaurantName = styled.h1`
  color: #666;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
`;

export const FillBar = styled.div`
  flex-grow: 1;
  background-color: ${red};
  width: 48px;
  min-height: 50px;
`;

export const NoImg = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 41px;
  background-color: #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 41px;
    height: 51px;
  }
`;

export const OtherFunctionsDiv = styled.div`
  display: flex;
  cursor: pointer;
  border-top: 1px solid white;
`;

export const OtherFuncIcon = styled.div`
  min-width: 48px;
  height: 48px;
  background-color: #ff2d3a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ hovered }) => hovered && `background-color: #FFECEC;`}

  svg {
    ${({ hovered }) => hovered && `color: #ff2d3a`}
  }
`;

export const OtherFuncText = styled.div`
  height: 48px;
  width: -webkit-fill-available;
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding: 10px;

  ${({ hovered }) => hovered && `background-color: #FFECEC;`}
`;
