import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  Modal,
} from 'reactstrap';

export const Wrapper = styled.div`
  overflow-x: auto;
  height: 100%;
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
`;

export const LogoTakeatStyled = styled.img`
  width: 120px;
  position: absolute;
  left: 50%;
  margin-left: -60px;
  filter: opacity(0.8);

  @media (max-width: 1025px) {
    display: none;
  }
`;

export const LiveButton = styled.button`
  background-color: transparent;
  color: ${(props) => (props.active ? '#2EC9B7' : '#C0C0C0')};
  border: none;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;
export const LiveTextContent = styled.div`
  position: absolute;
  right: 0;
  top: 22px;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  width: fit-content;

  width: 322px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

  p {
    font-weight: 500;
    font-size: 16px;
  }
`;
export const PaymentAdvice = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;
  height: 60px;
  background-color: #fff0f2;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  gap: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);

  p {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }
`;

export const RatingContent = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 300px;
`;

export const RatingHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #424242;
  span {
    text-align: center;
  }
`;

export const RatingBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: #424242;

  button {
    width: 54px;
    height: 54px;
    border: 1px solid #fff;
    border-radius: 8px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;

    :hover {
      transform: scale(1.1);
    }
  }
`;

export const SaveButton = styled.button`
  margin-top: 16px;
  border: 0;
  color: #fff;
  background-color: #ff403d;
  border-radius: 7px;
  padding: 10px 15px;
  float: right;
  :hover {
    filter: brightness(1.2);
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;

  margin-top: -5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const CarouselStyled = styled(Carousel)`
  width: 100%;

  img {
    width: 500px;
  }
`;

export const CarouselIndicatorsStyled = styled(CarouselIndicators)`
  margin-bottom: 80px;
  button {
    background-color: #33333370 !important;
  }
`;

export const CarouselControlStyled = styled(CarouselControl)`
  button {
    fill: #2ec9b7 !important;
    color: #2ec9b7 !important;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 10px auto 10px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: transparent;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 10px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #a8b4cc;
      padding-bottom: 10px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const DateSelectorDiv = styled.div`
  width: 90%;
  height: 60px;
  display: flex;
  clear: both;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 20px;
  flex-direction: row;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
  font-size: 14px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: none;

  @media (max-width: 410px) {
    flex-direction: column;
  }
`;

export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  background-color: transparent;
  border-radius: 8px;
  overflow-x: auto;
`;

export const BtnWhatsapp = styled.div`
  width: 180px;
  display: flex;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  cursor: pointer;

  div {
    width: 150px;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-weight: bold;
    box-shadow: 10px 5px 5px #33333320;
    border: 1px solid #33333310;
    color: #33333390;
  }

  img {
    width: 60px;
    margin-left: -16px;
  }
`;

export const ButtonChangeOrdenation = styled.button`
  margin-top: 16px;
  border-radius: 7px;
  border: none;
  background-color: #ff403d;
  color: #fff;
  font-size: 10px;
  width: 110px;
  height: 30px;

  & + button {
    margin-left: 8px;
  }
`;

export const ButtonCompletedBills = styled.p`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  /* margin-bottom: -70px; */

  button {
    border: 0;
    background-color: #ff403d;
    color: #fff;
    border-radius: 7px;
    padding: 8px 16px;
    font-size: 12px;
    box-shadow: 2px 2px 5px #33333320;
    cursor: pointer;
  }
`;

export const OperationArea = styled.div`
  width: 100%;
  min-height: 500px;
  height: calc(100vh - 48px);

  display: flex;
  justify-content: space-around;
  padding: 10px;
  flex-wrap: wrap;
`;

export const TablesCardArea = styled.div`
  width: 50%;
  height: 100%;
  padding: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const OrdersCardArea = styled.div`
  width: 50%;
  height: 100%;

  padding: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
`;

export const ButtonShowQrcode = styled.button`
  text-align: center;
  display: flex;
  align-items: center;
  background: #ff403d;
  margin-left: 15px;
  color: #fff;
  position: relative;

  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;

  text-decoration: none;
  list-style: none;

  svg {
    margin-right: 6px;
  }

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
    list-style: none;
    color: #fff;
  }
`;

export const ButtonDelivery = styled(Link)`
  text-align: center;
  display: flex;
  align-items: center;
  background: #ff403d;
  margin-left: 8px;
  color: #fff;
  position: relative;

  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;

  text-decoration: none;
  list-style: none;

  svg {
    margin-right: 6px;
  }

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
    list-style: none;
    color: #fff;
  }
`;

export const BadgeDelivery = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: orange;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
`;

export const ModalCES = styled(Modal)`
  .modal-content {
    width: 628px;
    padding: 40px 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
  }

  h1 {
    font-size: 30px;
    color: #f10d27;
    font-weight: 700;
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
  }

  img {
    width: 48px;
    height: 48px;
  }

  img,
  svg {
    cursor: pointer;
    transition: transform 0.3s;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid;
  }

  img:hover {
    transform: scale(1.1);
  }

  svg:hover {
    transform: scale(1.1);
  }

  textarea {
    width: 100%;
    resize: none;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 16px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  button {
    width: 145px;
    height: 50px;
    background: #ff3440;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: none;
    margin-top: 20px;
  }
`;

export const NotificationsButton = styled.button`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: #f4f4f4;
  color: #ff2c3a;
  border: 1px solid transparent;
  margin-top: 5px;
  transition: all 0.2s;

  &:hover {
    border-color: #ff2c3a;
  }
`;

export const PendingNotification = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background-color: #ffa814;
`;

export const WppButton = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #33333320;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: transparent;
  margin: 5px 10px 0 0;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.connected ? 'green' : '#ff403d')};
    text-align: center;
  }

  svg {
    color: ${(props) => (props.connected ? 'green' : '#ff403d')};
  }
`;

export const WppReconnect = styled.div`
  z-index: 50;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 46px;
  right: 10px;
  width: 390px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 12px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
`;

export const WppModal = styled(Modal)`
  .modal-content {
    width: 600px;
    border-radius: 10px;
  }

  img {
    border-radius: 10px;
  }

  h1 {
    margin: 20px 0;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  p {
    width: 505px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;

export const ReconnectButton = styled.button`
  width: 233px;
  height: 48px;
  line-height: 48px;
  background: #ff2c3a;
  border-radius: 5px;
  border: none;
  margin: 80px auto 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const WppModalText = styled.div`
  ol {
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  h1 {
    color: #3d3d3d;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 700;
    margin: 0 0 15px;
  }

  p {
    color: #3d3d3d;
    font-size: 14px;
    font-family: Poppins;
    margin: 0;
  }
`;

export const TablesContainer = styled.div`
  background-color: #fff;
  width: 100%;
  padding-top: 20px;

  height: calc(100vh - 155px);
  overflow: auto;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.1);

  border-radius: 7px;
  border-top-left-radius: ${(props) => props.isFirst && '0px'};
  transition: border-radius 0.2s ease-in;
`;

export const ArrowContainer = styled.div`
  position: absolute;
  ${({ rightArrow }) => (rightArrow ? 'right: 0px;' : 'left: 0px;')}
  top: 50%;
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    color: #f6f6f6;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 1));
    ${({ rightArrow }) => (rightArrow ? 'transform: rotate(180deg);' : '')};
    stroke-width: 20;
  }
`;

export const BalconyLink = styled(Link)`
  font-size: 15px;
  font-weight: 600;
  color: #58ceb1;
  padding-bottom: 0px;
  text-decoration: underline;

  &:hover {
    color: #58ceb1;
    text-decoration: underline;
  }
`;

export const ModalErrorNf = styled(Modal)`
  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 16px !important;
  }
`;
