import React, { useState, useEffect, useCallback } from 'react';
import { Col, CardHeader, Table, CardBody, CardTitle, Row } from 'reactstrap';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Line } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import { toast } from 'react-hot-toast';
import { Card, Container, Header, ErrorContainer } from './styles';
import api from '~/services/api';
import ReportCard from '~/components/Reports/ReportCard';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { DownloadButton } from 'ui-kit-takeat';
import { generateCategoryCostReportSheet } from '~/services/generateCategoryCostReportSheet';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { PageTitle } from '~/components/PageTitle';

function ReportsExpenses() {
  const [loaded, setLoaded] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [thisMonthExp, setThisMonthExp] = useState([]);
  const [lastMonthExp, setLastMonthExp] = useState([]);
  const [mostExpensiveCat, setMostExpensiveCat] = useState('');
  const [monthNames, setMonthsNames] = useState([]);
  const getExpensesDetailed = useCallback(async () => {
    setLoaded(true);
    try {
      const response = await api.get('/restaurants/reports/expenses-detailed');

      setExpenses(response.data.data);
      setThisMonthExp(
        response.data.data.reduce(
          (acc, cur) => acc + cur.values[cur.values.length - 1],
          0
        )
      );
      setLastMonthExp(
        response.data.data.reduce(
          (acc, cur) => acc + cur.values[cur.values.length - 2],
          0
        )
      );

      setMostExpensiveCat(
        response.data.data.reduce(
          (acc, e) => {
            const totalSpent = e.values.reduce((acc2, cur) => acc2 + cur, 0);
            acc[0] = totalSpent > acc[1] ? e.category : acc[0];
            acc[1] = totalSpent > acc[1] ? totalSpent : acc[1];
            return acc;
          },
          ['', 0]
        )
      );
      setMonthsNames(response.data?.labels);
    } catch (err) {
      console.log('getExpensesDetailed error: ', err);
    }
  }, []);

  const [collapse, setCollapse] = useState([]);

  const handleCollapse = (row) => {
    if (collapse.includes(row)) {
      const newCollapse = collapse.filter((c) => c !== row);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, row]);
    }
  };

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsCosts'}`
      );

      const { can_read } = response.data.ReportsCosts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateCategoryCostReportSheet({
        expenses,
        monthNames,
      });

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Custos-por-categoria(${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    if (!loaded) {
      getExpensesDetailed();
    }
  }, [getExpensesDetailed]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <PageTitle>Relatório de Custos</PageTitle>
          <p>Acompanhe os custos mensais de seu estabelecimento!</p>
        </div>
      </Header>
      <Card
        className="card-chart"
        style={{
          width: '100%',
          backgroundColor: '#ffffff',
          boxShadow: '#dddddd 2px 2px 10px',
          padding: '30px 10px',
        }}
      >
        <CardHeader style={{ backgroundColor: '#ffffff' }}>
          <CardTitle tag="h3">Gráfico de custos por categoria</CardTitle>
        </CardHeader>
        <Line
          style={{ padding: '0 10px' }}
          height={100}
          data={{
            labels: monthNames,
            datasets: [
              {
                data: expenses[0]?.values.map((e, i) =>
                  expenses.reduce((acc, cur) => acc + cur.values[i], 0)
                ),
                label: 'Total',
                borderColor: 'green',
              },
              ...expenses?.map((c) => {
                return {
                  data: c.values,
                  label: c.category,
                  borderColor: randomColor(),
                  hidden: true,
                };
              }),
            ],
          }}
          options={{
            responsive: true,
            tension: 0.4,
            plugins: {
              legend: {
                display: true,
                labels: {
                  pointStyle: 'line',
                  usePointStyle: true,
                },
              },
            },
          }}
        />
      </Card>

      <Row>
        <Col lg="4">
          <ReportCard
            name="Custos deste mês"
            total={thisMonthExp}
            type="cash"
          />
        </Col>
        <Col lg="4">
          <ReportCard
            name="Em relação ao último mês"
            type="growth"
            total={
              lastMonthExp === 0 || thisMonthExp === 0
                ? 0
                : (thisMonthExp / lastMonthExp - 1) * 100
            }
          />
        </Col>
        <Col lg="4">
          <ReportCard
            name="Categoria com mais gastos em 12 meses"
            total={mostExpensiveCat[1]}
            category={mostExpensiveCat[0]}
            type="category"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card
            className="card-chart"
            style={{
              width: '100%',
              backgroundColor: '#ffffff',
              boxShadow: '#dddddd 2px 2px 10px',
              padding: '10px',
            }}
          >
            <CardHeader
              color="#ffffff"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                padding: '10px',
              }}
            >
              <div>
                <CardTitle tag="h4">Custos por categoria</CardTitle>
                <h4 className="card-category">
                  Acompanhe a evolução do seu centro de custo por categoria.
                </h4>
              </div>
              <div>
                <DownloadButton onClick={() => handleDownloadSheet()} />
              </div>
            </CardHeader>
            <CardBody>
              {expenses.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th />
                      {monthNames?.map((m) => (
                        <th
                          style={{ position: 'relative', textAlign: 'center' }}
                        >
                          {m}{' '}
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '-1px',
                              height: '50%',
                              borderLeft: '1px solid #525f7f',
                              width: '0px',
                            }}
                          />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {expenses.map((e) => {
                      const check = collapse.includes(e.category);
                      return (
                        <>
                          <tr
                            style={{
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                            className="btn-outline-warning"
                            onClick={() => handleCollapse(e.category)}
                          >
                            <td style={{ width: 100 }}>
                              <MdKeyboardArrowRight
                                size={20}
                                style={{
                                  transition: 'all 0.2s',
                                  transform: check
                                    ? 'rotate(90deg)'
                                    : 'rotate(0)',
                                }}
                              />{' '}
                              {e.category}
                            </td>
                            {e.values.map((v) => {
                              return (
                                <td
                                  style={{
                                    borderLeft: '1px solid lightgrey',
                                    textAlign: 'center',
                                    fontSize: 13,
                                  }}
                                >
                                  {v.toFixed(2)}
                                </td>
                              );
                            })}
                          </tr>
                          {e.data.map((sub) => {
                            return (
                              <tr style={{ border: !check && 'none' }}>
                                <td
                                  style={{
                                    minWidth: '110px',
                                    padding: check ? '12px 7px' : '0px',
                                    transform: check
                                      ? 'scaleY(1)'
                                      : 'scaleY(0)',
                                    borderBottom: !check && 'none',
                                    transition:
                                      'padding-top 0.3s, padding-bottom 0.3s, transform 0.3s',
                                  }}
                                >
                                  {check ? sub.subcategory : ''}
                                </td>

                                {sub.data.map((value) => {
                                  return (
                                    <td
                                      style={{
                                        padding: '0px',
                                        borderLeft: '1px solid #cccfd7',
                                        textAlign: 'center',
                                        borderBottom: !check && 'none',
                                        fontSize: 13,
                                      }}
                                    >
                                      {check ? value.toFixed(2) : ''}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <h3 style={{ margin: '0 auto', width: 'fit-content' }}>
                  Não existem dados sobre seus custos
                </h3>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ReportsExpenses;
