import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { Container, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { TiDelete } from 'react-icons/ti';
import { toast } from 'react-hot-toast';
import { MdDone } from 'react-icons/md';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  CancelButton,
  SeeButton,
  OrdersInfo,
  Actions,
  DeleteAndEditActions,
  Badge,
  AcceptButton,
  InfoDiv,
  CardColumn,
  CardColumnItem,
  CardRow,
} from '../styles';
import {
  Eye,
  Print,
  DisplayNoneImprimir,
  DoneButton,
  ButtonCancel,
} from './styles';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye, FaTimes } from 'react-icons/fa';

export default function OrderFinished({ item, handleCancelItem }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [orders, setOrders] = useState([]);
  const [ordersNotGrouped, setOrdersNotGrouped] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getOrdersNotGrouped = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}?group=false`
      );

      setOrdersNotGrouped(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  useEffect(() => {
    if (modalDelete) {
      getOrdersNotGrouped();
    }
  }, [modalDelete, getOrdersNotGrouped]);

  const { user } = useAuth();
  const componentRef = useRef(null);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  const userName = item?.buyer
    ? formatPhone(item?.buyer ? item?.buyer.phone : item?.buyer?.ifood_phone) ||
      item?.buyer.name
    : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.phone
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  return (
    <Pedido>
      <ContentBody>
        <CardRow>
          <CardColumnItem onStart style={{ marginTop: 5, marginLeft: 2 }}>
            <TableNumber>
              {item?.table.table_type === 'command' ? (
                `Comanda  ${item?.table.table_number} / Mesa ${
                  item?.basket?.command_table_number || 'não informada'
                } `
              ) : (
                <>
                  {item?.basket?.ifood_table
                    ? item?.basket?.ifood_table
                    : searchTableName(item?.table)}
                </>
              )}
            </TableNumber>
          </CardColumnItem>
          <CardColumnItem>
            <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
          </CardColumnItem>
        </CardRow>
        <CardRow>
          <CardColumnItem onStart style={{ marginLeft: 2 }}>
            <PhoneNumber>{userName}</PhoneNumber>
          </CardColumnItem>
          <CardColumnItem onStart style={{ marginLeft: 2 }}>
            <PhoneNumber>{item?.bill?.buyer?.name}</PhoneNumber>
          </CardColumnItem>
          <CardColumnItem>
            <Time>{formatTime(item?.basket.start_time)}</Time>
          </CardColumnItem>
        </CardRow>
        <CardRow>
          {item?.table.table_type !== 'delivery' && (
            <div>
              <OrderStatusTag text="Finalizado" color="#2EC9B7" width="150px" />
            </div>
          )}
          <DeleteAndEditActions>
            <OrderButton
              icon={<FaTimes />}
              title="Cancelar"
              onClick={toggleDelete}
              disabled={btnDisable}
              color="#FF2C3A"
            />

            <OrderButton icon={<FaEye />} onClick={toggle} color="#ffa814" />
          </DeleteAndEditActions>
        </CardRow>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
        <ModalFooter>
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <AiOutlinePrinter color="black" size={20} />
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <Print onClick={printClick}>
              <AiOutlinePrinter color="black" size={20} />
            </Print>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'finished',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="finished"
              orders={ordersNotGrouped}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'finished',
              })
            }
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
