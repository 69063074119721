import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';
import OutlineButton from '~/components/Buttons/OutlineButton';
import SaveButton from '~/components/Buttons/DefaultButton';

import CreateSubcategory from '../Subcategories/Create';
import EditSubcategory from '../Subcategories/Edit';
import Subcategories from '../Subcategories';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import { Button } from 'ui-kit-takeat';
import { set } from 'date-fns';

function Category({
  category,
  getCategories,
  handleDeleteCategory,
  subCategories,
  getSubCategories,
}) {
  const [isModalCreateSubcategoryOpen, setIsModalCreateSubcategoryOpen] =
    useState(false);
  const [isModalEditSubcategoryOpen, setIsModalEditSubcategoryOpen] =
    useState(false);
  const [parentSubcategory, setParentSubcategory] = useState(null);
  const cashFlowTypes = [
    { value: 'financeiro', label: 'Financeiro' },
    { value: 'operacional', label: 'Operacional' },
    { value: 'insumos', label: 'Insumos e Produtos' },
  ];
  const [isModalEditCategoryOpen, setIsModalEditCategoryOpen] = useState(false);
  const [isModalAssociateSubcategoryOpen, setIsModalAssociateSubcategoryOpen] =
    useState(false);

  const formRef = useRef(null);
  const formAssociateRef = useRef(null);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState({});

  const selecSubCategoriesOptions =
    subCategories.length > 0 &&
    subCategories?.map((sub) => {
      return {
        value: sub.id,
        label: sub.name,
      };
    });

  const subCategoriesEdit = category.cash_flow_subcategories.map((sub) => {
    return {
      value: sub.id,
      label: sub.name,
    };
  });

  const initialData = {
    name: category.name,
    subcategories: subCategoriesEdit,
    type: cashFlowTypes.find((cf) => cf.value === category.type),
  };

  function toggleModalCreateSubcategory() {
    setIsModalCreateSubcategoryOpen(!isModalCreateSubcategoryOpen);
  }

  function toggleModalEditSubcategory() {
    setIsModalEditSubcategoryOpen(!isModalEditSubcategoryOpen);
  }

  const toggleModalAssociateSubcategory = useCallback(() => {
    setIsModalAssociateSubcategoryOpen(!isModalAssociateSubcategoryOpen);
  }, [isModalAssociateSubcategoryOpen]);

  const toggleModalEditCategory = useCallback(() => {
    setIsModalEditCategoryOpen(!isModalEditCategoryOpen);
  }, [isModalEditCategoryOpen]);

  const handleEditCategory = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/restaurants/cash-flows/categories/${category.id}`, {
          name: data.name,
          subcategories: data.subcategories,
          type: data.type,
        });

        toast.success('Subcategoria associada com sucesso!');

        getCategories();
        toggleModalEditCategory();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else if (!error.response.ok) {
          if (
            error.response.data.errorType ===
            'can_not_delete_cash_flow_category'
          ) {
            toast.error(
              'O usuário não pode editar categorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao editar categoria');
          }
        }
      }
    },
    [getCategories, toggleModalEditCategory, category]
  );

  const handleAssociateCategory = useCallback(
    async (data) => {
      try {
        await api.put(`/restaurants/cash-flows/categories/${category.id}`, {
          name: data.name,
          subcategories: data.subcategories,
          type: data.type,
        });

        toast.success('Categoria editada com sucesso!');

        getCategories();
        toggleModalAssociateSubcategory();
      } catch (error) {
        if (!error.response.ok) {
          if (
            error.response.data.errorType ===
            'can_not_delete_cash_flow_category'
          ) {
            toast.error(
              'O usuário não pode editar categorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao editar categoria');
          }
        }
      }
    },
    [getCategories, toggleModalAssociateSubcategory, category]
  );

  return (
    <>
      <tr>
        <td
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            color: '#ff2c3a',
            cursor: 'pointer',
          }}
        >
          {category?.name}
          <br />
          <span style={{ textTransform: 'capitalize', fontSize: 14 }}>
            {category?.type}
          </span>
        </td>
        {!category?.is_multistore_child ? (
          <td>
            <FaPen
              title="Editar categoria"
              onClick={toggleModalEditCategory}
              style={{ cursor: 'pointer' }}
            />
            <FaTrash
              color="#ff2c3a"
              title="Deletar Categoria"
              style={{ cursor: 'pointer', marginLeft: 8 }}
              onClick={() => {
                handleDeleteCategory(category.id);
              }}
            />
          </td>
        ) : (
          <td></td>
        )}
      </tr>
      <tr>
        <td colSpan="2" style={{ borderTop: 'none' }}>
          {
            !category.is_multistore_child &&
            <Button
              title="Subcategoria"
              icon={<FaPlus />}
              onClick={() => {
                setParentSubcategory(null);
                toggleModalCreateSubcategory();
              }}
            />
          }
          {/* <OutlineButton
            text="Subcategoria"
            title="Cadastrar sub-categoria"
            onClick={toggleModalCreateSubcategory}
            includeIcon
          /> */}

          <Subcategories
            category={category}
            setSubcategory={setSubcategoryToEdit}
            getCategories={getCategories}
            getSubCategories={getSubCategories}
            setParentSubcategory={setParentSubcategory}
            toggle={toggleModalCreateSubcategory}
            toggleEdit={toggleModalEditSubcategory}
          />
        </td>
      </tr>

      <Modal
        isOpen={isModalCreateSubcategoryOpen}
        toggle={toggleModalCreateSubcategory}
      >
        <ModalHeader style={{ padding: '20px 20px 0' }}>
          <h2 style={{ margin: 0, fontWeight: 600 }}>
            Criar Subcategoria
            {parentSubcategory !== null && ` de ${parentSubcategory.name}`}
          </h2>
        </ModalHeader>
        <ModalBody style={{ padding: '0 20px 20px' }}>
          <CreateSubcategory
            category={category}
            parentSubcategory={parentSubcategory}
            getCategories={getCategories}
            getSubCategories={getSubCategories}
            toggleModalCreateSubcategory={toggleModalCreateSubcategory}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isModalEditSubcategoryOpen}
        toggle={toggleModalEditSubcategory}
      >
        <ModalHeader>
          {' '}
          Editar Subcategoria{' '}
          {subcategoryToEdit !== null && subcategoryToEdit.name}
        </ModalHeader>
        <ModalBody>
          <EditSubcategory
            category={category}
            parentSubcategory={parentSubcategory}
            getCategories={getCategories}
            getSubCategories={getSubCategories}
            subcategory={subcategoryToEdit}
            toggleEdit={toggleModalEditSubcategory}
            setSubcategory={setSubcategoryToEdit}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalEditCategoryOpen} toggle={toggleModalEditCategory}>
        <ModalHeader> Editar Categoria</ModalHeader>
        <Form
          onSubmit={handleEditCategory}
          ref={formRef}
          initialData={initialData}
        >
          <ModalBody>
            <Input label="Nome do categoria" name="name" />
            <Select
              label="Subcategorias"
              name="subcategories"
              isMulti
              options={selecSubCategoriesOptions}
            />
            <Select label="Tipo" name="type" options={cashFlowTypes} />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalAssociateSubcategoryOpen}
        toggle={toggleModalAssociateSubcategory}
      >
        <ModalHeader> Associar Subcategoria</ModalHeader>
        <Form
          onSubmit={handleAssociateCategory}
          ref={formAssociateRef}
          initialData={initialData}
        >
          <ModalBody>
            <Select
              label="Subcategorias"
              name="subcategories"
              isMulti
              options={selecSubCategoriesOptions}
            />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Category;
