import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { uuid } from 'uuidv4';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import iconTake from '~/assets/img/icon-site.svg';
import { LoadingOrder } from './styles';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import PersonalButton from '~/components/Buttons/PersonalButton';

const OrderContext = createContext();

const OrderingSystemProvider = ({ children }) => {
  const {
    user,
    waiterId,
    buyerSelectedByWaiter,
    buyerCreatedByWaiter,
    setBuyerCreatedByWaiter,
    setBuyerSelectedByWaiter,
  } = useAuth();
  const [isMakingOrder, setIsMakingOrder] = useState(false);

  const [orders, setOrders] = useState(() => {
    const orderStorage = localStorage.getItem('@garcomdigital:order');

    if (orderStorage) {
      return JSON.parse(orderStorage);
    }

    return [];
  });

  const [isModalProductsErrorOpened, setIsModalProductssErrorOpened] =
    useState(false);

  const [productsNotAvailable, setProductsNotAvailable] = useState([]);

  function toggleModalProductsError() {
    setIsModalProductssErrorOpened(!isModalProductsErrorOpened);
  }

  function openModalProductsError(data) {
    setProductsNotAvailable(data);
    setIsModalProductssErrorOpened(true);
  }

  useEffect(() => {
    localStorage.setItem('@garcomdigital:order', JSON.stringify(orders));
  }, [orders]);

  const addToOrder = useCallback(
    async (
      cart,
      tableId,
      clientId,
      orderType,
      hasUsedDelivery,
      data,
      closeCart,
      toggleModalOperation,
      paymentsSession,
      isTablePrintable,
      commandTableNumber,
      getMenu,
      discount,
      discountObs,
      user_id,
      phone = null,
      scheduled_time = null,
      adminPassword,
      details = null
    ) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);
      const lastOrder = [...cart];
      switch (orderType) {
        case 'delivery':
          if (hasUsedDelivery) {
            try {
              setIsMakingOrder(true);
              const response = await api.post('/restaurants/buyers', {
                name: data.name,
                phone: data.phone,
                email: data.email,
              });

              const { id: buyer_id } = response.data;
              console.log(data);

              if (!data.address) {
                toast.error('Favor cadastrar o endereço do cliente.');
                setIsMakingOrder(false);
              } else if (data.address.id) {
                if (scheduled_time) {
                  await api.post('/restaurants/scheduling/orders', {
                    will_receive_sms: data.will_receive_sms || false,
                    payment_method_id: data.payment_method_id || null,
                    with_withdrawal: false,
                    user_change: data.user_change,
                    buyer_address_id: data.address.id,
                    buyer_id,
                    order: lastOrder,
                    discount,
                    discountObs,
                    user_id,
                    scheduled_time,
                    details,
                  });
                } else {
                  await api.post('restaurants/delivery/orders', {
                    will_receive_sms: data.will_receive_sms || false,
                    payment_method_id: data.payment_method_id || null,
                    with_withdrawal: false,
                    user_change: data.user_change,
                    buyer_address_id: data.address.id,
                    buyer_id,
                    order: lastOrder,
                    discount,
                    discountObs,
                    user_id,
                    details,
                  });
                }

                closeCart();
                toggleModalOperation();
                toast.success('Pedido feito com sucesso');
                setIsMakingOrder(false);
              } else {
                const responseAddress = await api.post(
                  '/restaurants/buyer/delivery-addresses',
                  {
                    buyer_id,
                    country: 'BR',
                    state: data.address.state || user.adress.state,
                    city: data.address.city,
                    neighborhood: data.address.neighborhood,
                    street: data.address.street,
                    number: data.address.number,
                    complement: data.address.complement || '',
                    reference: data.address.reference || '',
                    zip_code: data.address.zip_code,
                  }
                );

                const { id: address_id } = responseAddress.data;
                if (scheduled_time) {
                  await api.post('/restaurants/scheduling/orders', {
                    will_receive_sms: data.will_receive_sms || false,
                    payment_method_id: data.payment_method_id || null,
                    with_withdrawal: false,
                    user_change: data.user_change,
                    buyer_address_id: address_id,
                    buyer_id,
                    order: lastOrder,
                    discount,
                    discountObs,
                    user_id,
                    scheduled_time,
                    details,
                  });
                } else {
                  await api.post('restaurants/delivery/orders', {
                    will_receive_sms: data.will_receive_sms || false,
                    payment_method_id: data.payment_method_id || null,
                    with_withdrawal: false,
                    user_change: data.user_change,
                    buyer_address_id: address_id,
                    buyer_id,
                    order: lastOrder,
                    discount,
                    discountObs,
                    user_id,
                    details,
                  });
                }

                closeCart();
                toggleModalOperation();
                toast.success('Pedido feito com sucesso');
                setIsMakingOrder(false);
              }
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_delivery_not_enabled':
                    toast.error('Delivery fechado no momento');
                    break;
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  default:
                    console.log(error);
                    toast.error('Erro ao fazer pedido');
                    setIsMakingOrder(false);
                }
              }
              setIsMakingOrder(false);
              console.log(error);
            }
          } else {
            try {
              setIsMakingOrder(true);
              const response = await api.post('/restaurants/buyers', {
                name: data.name,
                phone: data.phone,
                email: data.email,
              });

              const { id: buyer_id } = response.data;

              const responseAddress = await api.post(
                '/restaurants/buyer/delivery-addresses',
                {
                  buyer_id,
                  country: 'BR',
                  state: data.state || user.adress.state,
                  city: data.city || data.cities,
                  neighborhood:
                    data.neighborhood ||
                    data.neihgboorhoods ||
                    data.neighboorhoodInput,
                  street: data.street,
                  number: data.number,
                  complement: data.complement || '',
                  reference: data.reference || '',
                  zip_code: data.zip_code,
                }
              );

              const { id: address_id } = responseAddress.data;

              if (scheduled_time) {
                await api.post('/restaurants/scheduling/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method || null,
                  with_withdrawal: false,
                  user_change: data.user_change || 0,
                  buyer_address_id: address_id,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  scheduled_time,
                  details,
                });
              } else {
                await api.post('restaurants/delivery/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method || null,
                  with_withdrawal: false,
                  user_change: data.user_change || 0,
                  buyer_address_id: address_id,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  details,
                });
              }

              closeCart();
              toggleModalOperation();
              toast.success('Pedido feito com sucesso');
              setIsMakingOrder(false);
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_delivery_not_enabled':
                    toast.error('Delivery fechado no momento');
                    break;
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  default:
                    console.log(error);
                    toast.error('Erro ao fazer pedido');
                    setIsMakingOrder(false);
                }
              }

              setIsMakingOrder(false);
            }
          }
          break;

        case 'withdrawal':
          if (hasUsedDelivery) {
            try {
              setIsMakingOrder(true);
              const response = await api.post('/restaurants/buyers', {
                name: data.name,
                phone: data.phone,
                email: data.email || null,
              });

              const { id: buyer_id } = response.data;

              if (scheduled_time) {
                await api.post('/restaurants/scheduling/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method_id || null,
                  with_withdrawal: true,
                  user_change: data.user_change || 0,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  scheduled_time,
                  details,
                });
              } else {
                await api.post('restaurants/delivery/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method_id || null,
                  with_withdrawal: true,
                  user_change: data.user_change || 0,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  details,
                });
              }

              closeCart();
              toggleModalOperation();
              toast.success('Pedido feito com sucesso');
              setIsMakingOrder(false);
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  default:
                    console.log(error);
                    toast.error('Erro ao fazer pedido');
                    setIsMakingOrder(false);
                }
              }

              setIsMakingOrder(false);
            }
          } else {
            try {
              setIsMakingOrder(true);
              const response = await api.post('/restaurants/buyers', {
                name: data.name,
                phone: data.phone,
                email: data.email,
              });

              const { id: buyer_id } = response.data;

              if (scheduled_time) {
                await api.post('/restaurants/scheduling/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method || null,
                  with_withdrawal: true,
                  user_change: data.user_change || 0,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  scheduled_time,
                  details,
                });
              } else {
                await api.post('restaurants/delivery/orders', {
                  will_receive_sms: data.will_receive_sms || false,
                  payment_method_id: data.payment_method || null,
                  with_withdrawal: true,
                  user_change: data.user_change || 0,
                  buyer_id,
                  order: lastOrder,
                  discount,
                  discountObs,
                  user_id,
                  details,
                });
              }

              closeCart();
              toggleModalOperation();
              toast.success('Pedido feito com sucesso');
              setIsMakingOrder(false);
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  default:
                    console.log(error);
                    toast.error('Erro ao fazer pedido');
                    setIsMakingOrder(false);
                }
              }

              setIsMakingOrder(false);
            }
          }
          break;

        case 'in_person':
          if (buyerSelectedByWaiter.id && buyerSelectedByWaiter.id > 0) {
            try {
              const res = await api.post('/restaurants/orders/buyer', {
                order: lastOrder,
                table_id: Number(tableId),
                buyer_id: buyerSelectedByWaiter.id,
                command_table_number: commandTableNumber,
              });

              closeCart();
              setBuyerCreatedByWaiter('');
              setBuyerSelectedByWaiter('');
              toggleModalOperation && toggleModalOperation();
              toast.success('Pedido feito com sucesso');
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  case 'cashier_opening_not_found':
                    toast.error(
                      'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
                    );
                    break;
                  case 'user_with_open_session':
                    toast.error('Usuário já logado em outra mesa.');
                    break;
                  case 'incorrect_order_cancel_password':
                    toast.error(error.response.data.message);
                    break;
                  default:
                    console.log(error);
                    toast.error(
                      error?.response?.data?.message || 'Erro ao fazer pedido'
                    );
                    setIsMakingOrder(false);
                }
              }
            }
          } else if (buyerCreatedByWaiter) {
            try {
              console.log('aqqq', buyerCreatedByWaiter);
              const buyer = await api.post('/restaurants/waiter-buyer', {
                name: buyerCreatedByWaiter.name,
                phone: buyerCreatedByWaiter.phone,
                table_id: Number(tableId),
                waiter_id: waiterId || null,
                clientData: buyerCreatedByWaiter?.clientData || null
              });

              const res = await api.post('/restaurants/orders/buyer', {
                order: lastOrder,
                table_id: Number(tableId),
                buyer_id: buyer.data.id,
                command_table_number: commandTableNumber,
              });
              // setBuyerSelectedByWaiter({
              //   id: buyer.data.id,
              //   name: buyer?.data?.name,
              //   phone: buyer?.data?.phone
              // })
              closeCart();
              setBuyerCreatedByWaiter('');
              setBuyerSelectedByWaiter('');
              toggleModalOperation && toggleModalOperation();
              toast.success('Pedido feito com sucesso');
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  case 'cashier_opening_not_found':
                    toast.error(
                      'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
                    );
                    break;
                  case 'user_with_open_session':
                    toast.error('Usuário já logado em outra mesa.');
                    break;
                  case 'incorrect_order_cancel_password':
                    toast.error(error.response.data.message);
                    break;
                  default:
                    console.log(error);
                    toast.error(
                      error?.response?.data?.message || 'Erro ao fazer pedido'
                    );
                    setIsMakingOrder(false);
                }
              }
              // setBuyerCreatedByWaiter("")
              // setBuyerSelectedByWaiter('')
            }
          } else {
            try {
              const res = await api.post('restaurants/orders', {
                order: lastOrder,
                table_id: tableId,
                client_id: clientId,
                payments: paymentsSession,
                print_table: isTablePrintable,
                waiter_id: waiterId || null,
                command_table_number: commandTableNumber,
                discount,
                discountObs,
                user_id,
                phone,
                adminPassword,
              });
              toggleModalOperation && toggleModalOperation();
              toast.success('Pedido feito com sucesso');
              return res;
            } catch (error) {
              if (!error.response.ok) {
                switch (error.response.data.errorType) {
                  case 'restaurant_closed':
                    toast.error('Restaurante fechado no momento');
                    break;
                  case 'products_not_available':
                    openModalProductsError(error?.response.data.payload);
                    getMenu();
                    break;
                  case 'minimum_delivery_price':
                    toast.error('Pedido abaixo do minimo para o delivery');
                    break;
                  case 'buyer_address_not_found':
                    toast.error('Endereço do cliente não encontrado');
                    break;
                  case 'not_delivered_on_address':
                    toast.error('Este restaurante não entrega neste endereço');
                    break;
                  case 'buyer_outside_bounds':
                    toast.error('Endereço do cliente fora da área de entrega');
                    break;
                  case 'delivery_max_distance':
                    toast.error(
                      'Endereço de entrega muito distantante deste restaurante'
                    );
                    break;
                  case 'cashier_opening_not_found':
                    toast.error(
                      'Não foi encontrado caixa aberto. Favor abrir seu caixa.'
                    );
                    break;
                  case 'incorrect_order_cancel_password':
                    toast.error(error.response.data.message);
                    break;
                  default:
                    console.log(error);
                    toast.error(
                      error?.response?.data?.message || 'Erro ao fazer pedido'
                    );
                    setIsMakingOrder(false);
                }
              }

              setIsMakingOrder(false);
            }
          }

          break;
        default:
          console.log('erro ao fazer pedido');
      }
    },

    [orders, user, waiterId, buyerCreatedByWaiter, buyerSelectedByWaiter]
  );

  const closeOrder = useCallback(() => {
    localStorage.removeItem('@garcomdigital:order');
    localStorage.removeItem('@garcomdigital:cart');
    localStorage.removeItem('@garcomdigital:basketId');

    setOrders([]);
  }, []);

  const value = useMemo(
    () => ({
      addToOrder,
      orders,
      closeOrder,
    }),
    [addToOrder, orders, closeOrder]
  );

  return (
    <OrderContext.Provider value={value}>
      {isMakingOrder ? (
        <LoadingOrder>
          <img src={iconTake} alt="Icone Takeat" />
          <h1>Fazendo seu pedido ...</h1>
        </LoadingOrder>
      ) : (
        <Modal
          isOpen={isModalProductsErrorOpened}
          toggle={toggleModalProductsError}
        >
          <ModalBody>
            <p>
              Esse(s) produto(s) não tem a quantidade suficiente disponível:
            </p>
            {productsNotAvailable &&
              productsNotAvailable.map((item) => (
                <p>
                  <strong>Item:</strong> {item.product} -{' '}
                  <strong>Disponível: </strong>
                  {parseInt(item.available)}
                </p>
              ))}
          </ModalBody>
          <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <PersonalButton
                color="#2ec9b7"
                message="Ok, entendi"
                onClick={toggleModalProductsError}
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
      {children}
    </OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    console.log('useOrder must be within a provider');
  }

  return context;
}

export { OrderingSystemProvider, useOrder };
