import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
} from 'reactstrap';
import { FiX } from 'react-icons/fi';
import {
  Wrapper,
  Header,
  LugarPedidos,
  DeliveryLink,
  LinkAndVideoDiv,
  LiveTextContent,
  LiveButton,
  VideoContainer,
  SquareButton,
  SearchInput,
} from './styles';
import YouTube from 'react-youtube';
import { useOrder } from '~/context/orderContext';
import CardOrdersAndamento from '~/components/Orders/Cards/CardOrdersAndamento';
import CardOrdersNew from '~/components/Orders/Cards/CardOrdersNew';
import CardOrdersCanceled from '~/components/Orders/Cards/CardOrdersCanceled';
import CardOrdersReady from '~/components/Orders/Cards/CardOrdersReady';
import CardOrdersFinishedDelivery from '~/components/Orders/Cards/CardOrderFinishedDelivery';
import { useMotoboys } from '~/context/MotoboysContext';
import { useAuth } from '~/context/AuthContext';
import CardOrdersSchedulingNew from '~/components/Orders/Cards/CardOrdersSchedulingNew';
import CardOrdersSchedulingReady from '~/components/Orders/Cards/CardOrdersSchedulingReady';
import CardOrdersSchedulingAndamento from '~/components/Orders/Cards/CardOrdersSchedulingAndamento';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Button, Hyperlink, Tabs } from 'ui-kit-takeat';
import { FaCircle, FaCopy } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdUpdate } from 'react-icons/md';
import BannerLive from '~/assets/img/banner_live.png';
import { FaMagnifyingGlass, FaX, FaGear } from 'react-icons/fa6';
import { getDay, getHours } from 'date-fns';
import bannerDecret from '~/assets/img/decreto.png';
import { IoIosArrowBack } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';

function OrdersPage({
  handleItemFinished,
  handleCancelItem,
  handleItemReady,
  handlePrintItem,
  setItemIfoodId,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    readyBaskets,
    loading,
    getAllOrders,
  } = useOrder();
  const {
    user,
    modalLiveBanner4,
    setModalLiveBanner4,
    modalBannerDecret,
    setModalBannerDecret,
  } = useAuth();
  const history = useHistory();
  const secondPath = history.location.pathname.split('/')[2];
  const [isModalLiveBannerOpened, setIsModalLiveBannerOpened] = useState(
    modalLiveBanner4.toString() !== 'false'
  );
  const [player, setPlayer] = useState(null);
  const [clickHere, setClickHere] = useState(false);
  const [hasOrder, setHasOrder] = useState(true);
  const [filter, setFilter] = useState('');
  const [visiblefilter, setVisibleFilter] = useState('');
  const [pendingBasketsCount, setPendingBasketsCount] = useState(0);
  const [acceptedBasketsCount, setAcceptedBasketsCount] = useState(0);
  const [readyBasketsCount, setReadyBasketsCount] = useState(0);
  const [pendingBasketSchedulingCount, setPendingBasketSchedulingCount] = useState(0);
  const [acceptedBasketSchedulingCount, setAcceptedBasketSchedulingCount] = useState(0);
  const [readyBasketSchedulingCount, setReadyBasketSchedulingCount] = useState(0);
  const [canceledBasketsCount, setCanceledBasketsCount] = useState(0);

  useEffect(() => {
    const count_orders_new = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length; //cards new order e esse

    const count_orders_accepted = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length; // accepted

    const count_orders_ready = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    ).length;

    const count_orders_scheduling_new = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_scheduling_accepted = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_scheduling_ready = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    ).length;

    const count_orders_canceled = canceledBaskets.filter(
      (order) => order.table.table_type === 'delivery'
    ).length;

    setPendingBasketsCount(count_orders_new);
    setAcceptedBasketsCount(count_orders_accepted);
    setReadyBasketsCount(count_orders_ready);

    setPendingBasketSchedulingCount(count_orders_scheduling_new);
    setAcceptedBasketSchedulingCount(count_orders_scheduling_accepted);
    setReadyBasketSchedulingCount(count_orders_scheduling_ready);

    setCanceledBasketsCount(count_orders_canceled);

    if (
      count_orders_new == 0 &&
      count_orders_accepted == 0 &&
      count_orders_ready == 0
    ) {
      setHasOrder(false);
    } else {
      setHasOrder(true);
    }
  }, [acceptedBaskets, readyBaskets, pendingBaskets]);

  function toggleModalLiveBanner() {
    setIsModalLiveBannerOpened(!isModalLiveBannerOpened);
  }
  const { getMotoboys } = useMotoboys();

  async function handleUpdate() {
    getAllOrders();
  }

  useEffect(() => {
    if (user.delivery_info.is_delivery_allowed) {
      getMotoboys();
    }
  }, [getMotoboys]);

  //live conditional

  const [isLiveOn, setIsLiveOn] = useState(
    ((getDay(new Date()) === 1 || getDay(new Date()) === 3) &&
      getHours(new Date()) >= 15 &&
      getHours(new Date()) < 17) ||
    ((getDay(new Date()) === 2 || getDay(new Date()) === 4) &&
      getHours(new Date()) >= 9 &&
      getHours(new Date()) < 11)
  );
  const [isLiveTextContextOpened, setIsLiveTextContextOpened] = useState(false);

  function toggleLiveTextContext() {
    setIsLiveTextContextOpened(!isLiveTextContextOpened);
  }

  function handleGoToLive() {
    window.open('https://meet.google.com/adz-ummu-brb');
  }

  //banner decret
  function acceptsDdds(phone) {
    if (phone) {
      const ddds = ['65', '66'];

      const ddd = phone.slice(1, 3);

      if (ddds.includes(ddd)) {
        return true;
      } else {
        return false;
      }
    }
  }

  const [isModalBannerDecretOpened, setIsModalBannerDecretOpened] = useState(
    modalBannerDecret.toString() !== 'false' &&
    (user.adress?.state?.toLowerCase() === 'rs' || acceptsDdds(user?.phone))
  );

  function toggleModalBannerDecret() {
    setIsModalBannerDecretOpened(!isModalBannerDecretOpened);
  }

  const handleSearchCommand = (arrayToFilter, filterString) => {
    const filteredArray = arrayToFilter.filter((basket) => {
      const searchString = filterString.toLowerCase();
      for (const key in basket) {
        if (basket.hasOwnProperty(key)) {
          if (
            key === 'attendance_password' &&
            basket[key] !== null &&
            basket[key].toString().startsWith(searchString)
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('name') &&
            basket[key].name?.toLowerCase().startsWith(searchString)
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            (basket[key].phone?.includes(" ") && basket[key].phone?.split(" ")[1].startsWith(searchString))
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            (basket[key].phone?.includes("-") && basket[key].phone?.split("-")[1].startsWith(searchString))
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('phone') &&
            basket[key].phone?.replace(/[\s\-\(\)]/g, "").startsWith(searchString)
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('ifood_phone') &&
            basket[key].ifood_phone?.replace(/\s/g, "").startsWith(searchString)
          ) {
            return true;
          } else if (
            key === 'buyer' &&
            basket[key] !== null &&
            basket[key].hasOwnProperty('ifood_phone') &&
            basket[key].ifood_phone?.startsWith(searchString)
          ) {
            return true;
          }
        }
      }
      return false;
    });
    return filteredArray;
  };

  const [pendingBasketsFiltered, setPendingBasketsFiltered] = useState([]);
  const [acceptedBasketsFiltered, setAcceptedBasketsFiltered] = useState([]);
  const [readyBasketsFiltered, setReadyBasketsFiltered] = useState([]);
  const [pendingBasketsSchedulingFiltered, setPendingBasketsSchedulingFiltered] = useState([]);
  const [acceptedBasketsSchedulingFiltered, setAcceptedBasketsSchedulingFiltered] = useState([]);
  const [readyBasketsSchedulingFiltered, setReadyBasketsSchedulingFiltered] = useState([]);
  const [canceledBasketsFiltered, setCanceledBasketsFiltered] = useState([]);

  const [finishedBasketsFiltered, setFinishedBasketsFiltered] = useState([]);
  const [finishedBasketsSession, setFinishedBasketsSession] = useState([]);
  const [finishedBasketsSessionCounter, setFinishedBasketsSessionCounter] = useState(0);

  useEffect(() => {
    const finished_baskets_counter = finishedBasketsSession.length

    setFinishedBasketsSessionCounter(finished_baskets_counter)
  }, [finishedBasketsSession])

  useEffect(() => {
    const deliveryReadyBaskets = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    )
    const deliveryPendingBaskets = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    )
    const deliveryAcceptedBaskets = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && !order.scheduled_to
    )

    const deliveryReadySchedulingBaskets = readyBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    )
    const deliveryPendingSchedulingBaskets = pendingBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    )
    const deliveryAcceptedSchedulingBaskets = acceptedBaskets.filter(
      (order) => order.table.table_type === 'delivery' && order.scheduled_to
    )

    const deliveryCanceledBaskets = canceledBaskets.filter(
      (order) => order.table.table_type === 'delivery'
    )

    const deliveryReadyBasketFiltered = handleSearchCommand(deliveryReadyBaskets, filter);
    const deliveryPendingBasketFiltered = handleSearchCommand(deliveryPendingBaskets, filter);
    const deliveryAcceptedBasketFiltered = handleSearchCommand(deliveryAcceptedBaskets, filter);

    const deliveryReadySchedulingBasketFiltered = handleSearchCommand(deliveryReadySchedulingBaskets, filter);
    const deliveryPendingSchedulingBasketFiltered = handleSearchCommand(deliveryPendingSchedulingBaskets, filter);
    const deliveryAcceptedSchedulingBasketFiltered = handleSearchCommand(deliveryAcceptedSchedulingBaskets, filter);

    const finishedBasketsFiltered = handleSearchCommand(finishedBasketsSession, filter);

    const deliveryCanceledBasketsFiltered = handleSearchCommand(deliveryCanceledBaskets, filter);

    setPendingBasketsFiltered(deliveryPendingBasketFiltered);
    setAcceptedBasketsFiltered(deliveryAcceptedBasketFiltered);
    setReadyBasketsFiltered(deliveryReadyBasketFiltered);

    setPendingBasketsSchedulingFiltered(deliveryPendingSchedulingBasketFiltered);
    setAcceptedBasketsSchedulingFiltered(deliveryAcceptedSchedulingBasketFiltered);
    setReadyBasketsSchedulingFiltered(deliveryReadySchedulingBasketFiltered);

    setFinishedBasketsFiltered(finishedBasketsFiltered);

    setCanceledBasketsFiltered(deliveryCanceledBasketsFiltered);
  }, [readyBaskets, pendingBaskets, acceptedBaskets, finishedBasketsSession, filter, activeTab])

  useEffect(() => {
    setFilter('');
  }, [activeTab])

  function formatFilter(filtro) {
    const formatedFilter = filtro.replace(/^0+/, '');
    setFilter(formatedFilter); 
    setVisibleFilter(filtro)
    
  }

  return (
    <Wrapper isOrders={secondPath === 'orders'}>
      {user.only_delivery && (
        <>
          <Modal
            isOpen={isModalBannerDecretOpened}
            toggle={toggleModalBannerDecret}
            style={{ padding: 0 }}
          >
            <VideoContainer>
              <img
                style={{ width: '100%' }}
                src={bannerDecret}
                alt="Banner mostrando ao usuário que a Takeat está de acordo com o decreto do Rio grrande do Sul"
              />
              <div style={{ position: 'absolute', left: 63, bottom: 85 }}>
                <YouTube
                  videoId={'QfjiN2Tp-Rg'}
                  onReady={(e) => setPlayer(e.target)}
                  onPlay={() => {
                    // toggleModalYoutube();
                    // setIsHidden(true);
                    setClickHere(false);
                  }}
                  opts={{
                    width: 380,
                    height: 200,
                    playerVars: { enablejsapi: 1, autoplay: 1 },
                  }}
                />
              </div>
            </VideoContainer>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                onClick={() => {
                  setModalBannerDecret(false);
                  setIsModalBannerDecretOpened(false);
                }}
                title='Não desejo ver novamente'
                inverted
              />

              <a
                href="https://api.whatsapp.com/send?phone=5527998431975"
                target="blank"
              >
                <Button
                  type="button"
                  buttonColor='#2ec9b7'
                  title='Eu quero!'
                />
              </a>
            </div>

          </Modal>
        </>
      )}

      {user.only_delivery && (
        <Modal
          isOpen={isModalLiveBannerOpened}
          toggle={toggleModalLiveBanner}
          style={{ padding: 0 }}
        >
          <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
            <FiX
              onClick={toggleModalLiveBanner}
              color="red"
              cursor="pointer"
              size={17}
              title="Fechar Modal"
            />
          </ModalHeader>
          <a href="https://meet.google.com/adz-ummu-brb" target="blank">
            <img
              src={BannerLive}
              alt="Banner mostrando ao usuário o que faremos live terça e quinta feira"
            />
          </a>

          <ModalFooter>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                className="btn"
                type="button"
                onClick={() => {
                  setIsModalLiveBannerOpened(false);
                  setModalLiveBanner4(false);
                }}
                style={{ background: '#ff403d' }}
              >
                Não desejo ver novamente
              </button>
              <a href="https://meet.google.com/adz-ummu-brb" target="blank">
                <button
                  className="btn"
                  type="button"
                  style={{ background: '#2ec9b7' }}
                >
                  Acessar a Live!
                </button>
              </a>
            </div>
          </ModalFooter>
        </Modal>
      )}

      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxHeight: 65,
          }}
        >
          <LinkAndVideoDiv>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: 426 }}>
              <DeliveryLink>
                {`Link do seu delivery: `}
                <Hyperlink
                  fontSize={14}
                  onClick={() =>
                    window.open(
                      `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                      }`
                    )
                  }
                >
                  {`https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                    }`}
                </Hyperlink>
                <Hyperlink
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `https://pedido.takeat.app/${user?.nickname ? user?.nickname : user?.name
                        }`
                      )
                      .then(() => toast.success('Link copiado com sucesso!'))
                  }
                >
                  <FaCopy />
                </Hyperlink>
              </DeliveryLink>
              <LiveButton active={isLiveOn} onClick={toggleLiveTextContext}>
                <FaCircle color={isLiveOn ? '#2EC9B7' : '#C0C0C0'} />
                PLANTÃO COM ESPECIALISTA
              </LiveButton>
              <div style={{ position: 'relative' }}>
                {isLiveOn && isLiveTextContextOpened ? (
                  <LiveTextContent>
                    <FaX
                      onClick={toggleLiveTextContext}
                      color="#f10d27"
                      cursor="pointer"
                      style={{ float: 'right' }}
                    />
                    <p>
                      Esclarecemos as suas dúvidas em tempo real. Junte-se à nossa
                      Live!{' '}
                    </p>

                    <p style={{ fontSize: 14, marginTop: 10 }}>
                      {' '}
                      Programação: Todas as segundas e quartas, das 15h às 17h e
                      terças e quintas, de 9h as 11h.
                    </p>

                    <Button
                      title="Ir para a live"
                      containerStyles={{ width: '100%' }}
                      onClick={handleGoToLive}
                    />
                  </LiveTextContent>
                ) : (
                  isLiveTextContextOpened && (
                    <LiveTextContent>
                      <FaX
                        onClick={toggleLiveTextContext}
                        color="#f10d27"
                        cursor="pointer"
                        style={{ float: 'right' }}
                      />
                      <p>
                        Para um atendimento de qualidade, nossos especialistas estão
                        em live às segundas e quartas, de 15h as 17h e terças e
                        quintas, de 9h às 11h.
                      </p>
                      <p>Traga suas dúvidas!</p>
                    </LiveTextContent>
                  )
                )}
              </div>
            </div>
          </LinkAndVideoDiv>
          <VideoPlayer id={67} containerStyles={{ marginLeft: 0, maxHeight: 45 }} />
        </div>


        {activeTab === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SquareButton color="#6C6C6C">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => history.push('/delivery/config')}
              >
                <FaGear size={20} />
                <span>Config.</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => {
                  formatFilter(e.target.value);
                  
                }}
              />
              <FiX
                color="#CDCDCD"
                size={22}
                onClick={() => setFilter("")}
              />
            </SearchInput>
          </div>
        )}
        {activeTab === 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SquareButton color="#6C6C6C">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => history.push('/delivery/scheduling')}
              >
                <FaGear size={20} />
                <span>Config. Agend.</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX
                color="#CDCDCD"
                size={22}
                onClick={() => setFilter("")}
              />
            </SearchInput>
          </div>
        )}

        {activeTab === 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX
                color="#CDCDCD"
                size={22}
                onClick={() => setFilter("")}
              />
            </SearchInput>
          </div>
        )}

        {activeTab === 3 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <SquareButton color="#2EC9B7">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={handleUpdate}
              >
                <MdUpdate size={22} />
                <span>Atualizar</span>
              </div>
            </SquareButton>
            <SearchInput>
              <FaMagnifyingGlass color="#666" size={16} />
              <input
                type="text"
                placeholder="Buscar pedido"
                value={visiblefilter}
                onChange={(e) => formatFilter(e.target.value)}
              />
              <FiX
                color="#CDCDCD"
                size={22}
                onClick={() => setFilter("")}
              />
            </SearchInput>
          </div>
        )}
      </Header>

      <Tabs
        onChangeTab={(tab) => setActiveTab(tab)}
        selected={activeTab}
        tabs={[
          'Pedidos Novos e Em Andamento',
          'Pedidos Agendados',
          'Pedidos Entregues',
          'Pedidos Cancelados',
        ]}
        counter={[
          null,
          pendingBaskets.filter((order) => order.scheduled_to).length,
          null,
          null,
        ]}
        mediaQueries={[
          {
            maxWidth: 1150,
            styles: `font-size: 12px; padding: 5px 15px;`,
          },
        ]}
      />

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0} style={{ height: '100%' }}>
          <LugarPedidos first>
            <CardOrdersNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBasketsFiltered}
              loading={loading}
              setItemIfoodId={setItemIfoodId}
              hasOrderActive={hasOrder}
              user={user}
              handleUpdate={handleUpdate}
              countOrders={pendingBasketsCount}
            />
            <CardOrdersAndamento
              baskets={acceptedBasketsFiltered}
              handleItemFinished={handleItemReady}
              handleCancelItem={handleCancelItem}
              loading={loading}
              handleItemReady={handleItemReady}
              handleUpdate={handleUpdate}
              hasOrderActive={hasOrder}
              countOrders={acceptedBasketsCount}
            />
            <CardOrdersReady
              baskets={readyBasketsFiltered}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
              getAllOrders={getAllOrders}
              hasOrderActive={hasOrder}
              handleUpdate={handleUpdate}
              countOrders={readyBasketsCount}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId={1} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersSchedulingNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBasketsSchedulingFiltered}
              loading={loading}
              setItemIfoodId={setItemIfoodId}
              handleUpdate={handleUpdate}
              countOrders={pendingBasketSchedulingCount}
            />
            <CardOrdersSchedulingAndamento
              baskets={acceptedBasketsSchedulingFiltered}
              handleItemFinished={handleItemReady}
              handleCancelItem={handleCancelItem}
              loading={loading}
              handleItemReady={handleItemReady}
              handleUpdate={handleUpdate}
              countOrders={acceptedBasketSchedulingCount}
            />
            <CardOrdersSchedulingReady
              baskets={readyBasketsSchedulingFiltered}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
              getAllOrders={getAllOrders}
              handleUpdate={handleUpdate}
              countOrders={readyBasketSchedulingCount}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId={2} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersFinishedDelivery
              baskets={finishedBaskets}
              loading={loading}
              handleCancelItem={handleCancelItem}
              getAllOrders={getAllOrders}
              countOrders={readyBasketSchedulingCount}
              setFinishedBasketsSession={setFinishedBasketsSession}
              finishedBasketsFiltered={finishedBasketsFiltered}
              finishedBasketsSessionCounter={finishedBasketsSessionCounter}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId={3} style={{ height: '100%' }}>
          <LugarPedidos>
            <CardOrdersCanceled
              loading={loading}
            />
          </LugarPedidos>
        </TabPane>
      </TabContent>
    </Wrapper>
  );
}

export default OrdersPage;