import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Modal, ModalBody, Row, Col, Table } from 'reactstrap';
import { subMonths, subYears } from 'date-fns';
import ReactSelect from 'react-select';
import api from '~/services/api';
import { Container, Content, OpeninBoxReportArea } from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import PrintOpeningBoxAudit from '../PrintOpeningBoxAudit';
import SendCashierReportButton from '~/components/Buttons/SendCashierReportButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import { useAuth } from '~/context/AuthContext';

function ReportOpeningBox() {
  const [boxes, setBoxes] = useState();
  const [modalPrintAudit, setModalPrintAudit] = useState();
  const [totalManualDeposits, setTotalManualDeposits] = useState();
  const [totalAutomaticsDeposit, setTotalAutomaticDeposits] = useState();
  const [totalWithdrawalDeposits, setTotalWithdrawalDeposits] = useState();
  const [paymentsAutomatics, setPaymentsAutomatics] = useState();
  const [paymentsManual, setPaymentsManuals] = useState();
  const [boxInitialValue, setBoxInitialValue] = useState();
  const [boxFinalValue, setBoxFinalValue] = useState();
  const [boxTotalValue, setBoxTotalValue] = useState();
  const [manualWithdrawals, setManualWithdrawals] = useState();
  const [boxClosingTime, setBoxClosingTime] = useState();
  const [boxOpeningTime, setBoxOpeningTime] = useState();
  const [boxId, setBoxId] = useState();
  const [userOpen, setUserOpen] = useState();
  const [userClose, setUserClose] = useState();
  const { user } = useAuth()

  const periods = [
    { value: 0, label: 'últimos 10 caixas' },
    { value: 1, label: 'Último mês' },
    { value: 2, label: 'últimos 2 meses' },
    { value: 3, label: 'últimos 3 meses' },
    { value: 4, label: 'último ano' },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState(periods[0]);

  function toggleModalPrintAudit() {
    setModalPrintAudit((oldState) => !oldState);
  }
  const handleGetDataBoxes = useCallback(
    async (
      id,
      initial_value,
      final_value,
      total_value,
      closed_at,
      user_open,
      user_close,
      opened_at
    ) => {
      setBoxFinalValue(final_value);
      setBoxInitialValue(initial_value);
      setBoxTotalValue(total_value);
      setBoxClosingTime(closed_at);
      setBoxOpeningTime(opened_at);
      setBoxId(id);
      setUserOpen(user_open);
      setUserClose(user_close);
      try {
        const response = await api.get(
          `/restaurants/cashier-opening-event/summary/${id}`
        );

        setTotalAutomaticDeposits(response.data.totals?.automatic_deposit);
        setTotalManualDeposits(response.data.totals?.manual_deposit);
        setTotalWithdrawalDeposits(response.data.totals?.manual_withdrawal);
        setPaymentsAutomatics(response?.data.automatic_deposits);
        setPaymentsManuals(response.data.manual_deposits);
        setManualWithdrawals(response.data.manual_withdrawals);
      } catch (error) {
        toast.error('Erro ao carregar informações');
      }
      setModalPrintAudit(true);
    },
    []
  );

  const getBoxes = useCallback(async () => {
    try {
      const params = {};

      switch (selectedPeriod.value) {
        case 0:
          params.amount = 10;
          break;
        case 1:
          params.end_date = new Date();
          params.start_date = subMonths(new Date(),1);
          break;
        case 2:
          params.end_date = new Date();
          params.start_date = subMonths(new Date(), 2);
          break;
        case 3:
          params.end_date = new Date();
          params.start_date = subMonths(new Date(), 3);
          break;
        case 4:
          params.end_date = new Date();
          params.start_date = subYears(new Date(), 1);
          break;
        default:
          params.amount = 10;
          break;
      }

      const response = await api.get('restaurants/cashier-opening', {
        params,
      });

      setBoxes(response.data);
    } catch (error) {
      toast.error('Erro ao carregar caixas');
    }
  }, [selectedPeriod]);

  function handleDeleteBoxStates() {
    setBoxFinalValue('');
    setBoxInitialValue('');
    setBoxTotalValue('');
    setManualWithdrawals('');
    toggleModalPrintAudit();
  }

  useEffect(() => {
    getBoxes();
  }, [getBoxes]);

  return (
    <Container>
      <Content>
        <Row>
          <Col md="7">
            <h1>Caixas recentes</h1>
          </Col>
          <Col md="5">
            <ReactSelect
              placeholder="Selecione um período"
              options={periods}
              onChange={(e) => {
                setSelectedPeriod(e);
              }}
              value={selectedPeriod}
              name="period"
            />
          </Col>
        </Row>

        <OpeninBoxReportArea>
          <Table>
            <thead>
              <th>Abertura</th>
              <th>Fechamento</th>
              <th>Ações</th>
              <th />
              {user.use_cashier_by_user && (
                <th>Responsável</th>
              )}

            </thead>
            <tbody>
              {boxes &&
                boxes.length > 0 &&
                boxes.map((box) => (
                  <tr>
                    <td>{formatCompleteDate(box.opened_at)}</td>
                    <td>
                      {box.closed_at ? formatCompleteDate(box.closed_at) : '-'}
                    </td>
                    <td>
                      <PersonalOutlineButton
                        message="Relatório"
                        fontColor="#FF2C3A"
                        borderColor="#FF2C3A"
                        color="transparent"
                        type="button"
                        onClick={() =>
                          handleGetDataBoxes(
                            box.id,
                            box.initial_value,
                            box.final_value,
                            box.total_value,
                            box.closed_at,
                            box.user_open?.name || '-',
                            box.user_close?.name || '-',
                            box.opened_at
                          )
                        }

                      />

                    </td>
                    <td>
                      <SendCashierReportButton boxId={box.id} key={box.id} />
                    </td>
                    {user?.use_cashier_by_user && (
                      <td>
                        {box?.owner_user?.name}
                      </td>
                    )}

                  </tr>
                ))}
            </tbody>
          </Table>
        </OpeninBoxReportArea>
        <Modal isOpen={modalPrintAudit} toggle={toggleModalPrintAudit}>
          <ModalBody>
            <PrintOpeningBoxAudit
              paymentsAutomatics={paymentsAutomatics}
              paymentsManual={paymentsManual}
              boxInitialValue={boxInitialValue}
              boxTotalValue={boxTotalValue}
              boxFinalValue={boxFinalValue}
              totalAutomaticsDeposit={totalAutomaticsDeposit}
              totalManualDeposits={totalManualDeposits}
              totalWithdrawalDeposits={totalWithdrawalDeposits}
              handleDeleteBoxStates={handleDeleteBoxStates}
              manualDeposits={paymentsManual}
              manualWithdrawals={manualWithdrawals}
              boxClosingTime={boxClosingTime}
              boxOpeningTime={boxOpeningTime}
              userClose={userClose}
              userOpen={userOpen}
              modalPrintAudit={modalPrintAudit}
              boxId={boxId}
              isClosing={false}
            />
          </ModalBody>
        </Modal>
      </Content>
    </Container>
  );
}

export default ReportOpeningBox;
