import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { FiMail, FiLock, FiEye } from 'react-icons/fi';

import { FaUserMinus } from 'react-icons/fa';
import logo from '~/assets/img/loggoTitle.svg';
import devices from '~/assets/img/loginImage.svg';
import {
  Container,
  StyledForm,
  Card,
  LeftSide,
  RightSide,
  FiEyeStyled,
  InputArea,
  ButtonWaiter,
  DeviceImg,
  Content,
  OutlineButton,
  ClientArea,
  LogoImg
} from './styles';
import LoginInput from '~/components/Form/LoginInput';
import { useAuth } from '~/context/AuthContext';
import { Button, DefaultInput, DefaultModal } from 'ui-kit-takeat';
import api from '~/services/api';
import { toast } from 'react-hot-toast';



function SignIn() {
  const formRef = useRef(null);
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const { signIn, loading } = useAuth();

  const [forgotPassOpen, setForgotPassOpen] = useState(false);
  const [emailToSent, setEmailToSent] = useState('');
  const toggleForgotPass = () => {
    setForgotPassOpen(!forgotPassOpen);
  };
  const [emailLoading, setEmailLoading] = useState(false);
  const sendForgotPassword = async () => {
    setEmailLoading(true);
    try {
      await api.post('/public/forgot-password', { email: emailToSent });

      setEmailLoading(false);
      setForgotPassOpen(false);
      toast.success('Sua nova senha foi enviada para o e-mail informado!');
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Algo deu errado ao enviar o e-mail, consulte o suporte');
      }
      setEmailLoading(false);
    }
  };

  async function handleSubmit({ email, password }) {
    try {
      await signIn({ email, password });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current.setFieldError('email', 'Insira um e-mail válido');
      }
    }

    const user = localStorage.getItem('@gddashboard:user');
  }

  function togglePasswordType() {
    const password = document.querySelector('#password');

    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';

    password.setAttribute('type', type);
  }

  function handleGoToSite() {
    window.open("https://www.takeat.app")
  }
  return (
    <Container>
      <Content>
        <LeftSide>

          <DeviceImg src={devices} alt="Logo takeat Dashboard" browserZoomLevel={browserZoomLevel} />
        </LeftSide>
        <RightSide>
          <Card browserZoomLevel={browserZoomLevel}>
            <h1>Faça seu login</h1>
            <StyledForm ref={formRef} onSubmit={handleSubmit}>
              <LoginInput
                icon={FiMail}
                name="email"
                type="email"
                placeholder="E-mail"
              />

              <InputArea>
                <LoginInput
                  icon={FiLock}
                  name="password"
                  type="password"
                  placeholder="Senha"
                  id="password"
                />

                <FiEyeStyled onClick={togglePasswordType} title="Exibir senha" />
              </InputArea>

              <button type="submit">
                {loading ? 'Carregando...' : 'Acessar'}
              </button>
              <a
                onClick={() => setForgotPassOpen(true)}
                style={{ textDecoration: 'underline', color: 'grey' }}
              >
                Esqueci minha senha
              </a>
              <DefaultModal
                width="350px"
                height="fit-content"
                toggle={toggleForgotPass}
                isOpen={forgotPassOpen}
              >
                <DefaultInput
                  type="email"
                  style={{ marginBottom: 10 }}
                  placeholder="E-mail"
                  value={emailToSent}
                  onChange={(e) => setEmailToSent(e.target.value)}
                  title="Informe o e-mail da sua conta:"
                />
                <Button
                  title="Enviar"
                  isLoading={emailLoading}
                  buttonColor="#2ec9b7"
                  disabled={emailLoading}
                  onClick={() => sendForgotPassword()}
                />
              </DefaultModal>
            </StyledForm>
          </Card>
          <ButtonWaiter href="https://gm.takeat.app">
            <FaUserMinus /> Área do garçom
          </ButtonWaiter>

          <ClientArea>
            <p>Não é cliente?</p>
            <p style={{ fontWeight: "normal" }}>Acesse nosso site e faça seu cadastro</p>
            <OutlineButton onClick={handleGoToSite}>www.takeat.app</OutlineButton>
          </ClientArea>
        </RightSide>
      </Content>

    </Container>
  );
}

export default SignIn;