import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';
import api from '~/services/api';
import { Button } from 'ui-kit-takeat';
import { FaCheck } from 'react-icons/fa';

function CreateSubcategory({
  category,
  getCategories,
  toggleModalCreateSubcategory,
  getSubCategories,
  parentSubcategory,
}) {
  const formRef = useRef(null);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const responseCategory = await api.post(
          `/restaurants/cash-flows/subcategories`,
          {
            name: data.name,
          }
        );

        const { id } = responseCategory.data;

        await api.post(`/restaurants/cash-flows/subcategories/add-category`, {
          id,
          cash_flow_categories: [category.id],
          cash_flow_new_subcategory_id: parentSubcategory?.id,
        });

        toast.success('Subcategoria cadastrada com sucesso!');

        getCategories();
        getSubCategories();
        toggleModalCreateSubcategory();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else if (!error.response?.ok) {
          if (
            error.response?.data?.errorType ===
            'some_cash_flow_category_is_protected'
          ) {
            toast.error(
              'O usuário não pode editar categorias padrões do sistema'
            );
          } else {
            toast.error('Erro ao cadastrar subcategoria');
          }
        }
      }
    },
    [getCategories, toggleModalCreateSubcategory, category, getSubCategories]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input label="Nome da subcategoria" name="name" />
        <Button
          title="Salvar"
          icon={<FaCheck />}
          type="submit"
          onClick={() => {}}
          style={{ float: 'right' }}
        />
        {/* <SaveButton style={{ float: 'right' }} type="submit" /> */}
      </Form>
    </>
  );
}

export default CreateSubcategory;
