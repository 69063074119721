import React, { useState, useEffect, useCallback, useRef } from 'react';

import { CategoryList, CategoryThumbnailLink } from './styles';
import ImgPlaceholder from '~/assets/img/ImgPlaceholder.png'
function CategoryThumbnail({ category, handleChangeCategory }) {
  const [imageCover, setImageCover] = useState();
  const [active, setActive] = useState(false);

  const changeCover = useCallback(() => {
    const photo = category?.products.filter((product) => product.image?.url_thumb || product.image?.url);
    const categoryImage = category?.image?.url_thumb || category.image?.url

    setImageCover(categoryImage || photo[0]?.image?.url_thumb || photo[0]?.image?.url);
  }, [category]);

  useEffect(() => {
    changeCover();
  }, [changeCover]);

  return (
    <>
      <CategoryList
        onClick={() => {
          handleChangeCategory(category?.name);
        }}
      >
        <CategoryThumbnailLink
          style={{
            backgroundImage: `url(${imageCover || ImgPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          actives={active}
        />
        <p>{category?.name}</p>
      </CategoryList>
    </>
  );
}

export default CategoryThumbnail;
