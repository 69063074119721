import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  background-color: #3bd2c1;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 0 10px 0 0;

  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const Bloco = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0 12px 12px 0;
  border: solid 1px #3bd2c1;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  text-align: center;
  flex-basis: 33%;
  max-width: 33%;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;


export const StyleKanbanWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;