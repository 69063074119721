import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
} from 'date-fns';

import { FaDownload } from 'react-icons/fa';

import FileSaver from 'file-saver';

import { toast } from 'react-hot-toast';
import {
  Container,
  Header,
  DownloadSheetButton,
  Card,
  ScrollArea,
} from './styles';

import AuditCashierTable from './AuditCashierTable';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import { generateSheet } from '~/services/SheetGenerate/generateCashierAuditSheet';
import { FullCalendar } from 'ui-kit-takeat';

function AuditCashier() {
  const [cashiers, setCashiers] = useState([]);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
  }

  const getCashiers = useCallback(async () => {
    const response = await api.get('/restaurants/cashier-audit', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    setCashiers(response.data);
  }, [finalDate, inicialDate]);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceCashierAudits'}`
      );

      const { can_read } = response.data.FinanceCashierAudits;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    try {
      getCashiers();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCashiers]);

  const handleDownloadSheet = () => {
    try {
      if (cashiers.length === 0) {
        return;
      }

      const sheet = generateSheet(cashiers);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Conferência de Caixa(${format(
          new Date(inicialDate),
          'dd-MM'
        )}_${format(new Date(finalDate), 'dd-MM')}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p>Acompanhe o fluxo do seu caixa.</p>
          <DownloadSheetButton onClick={handleDownloadSheet}>
            <FaDownload style={{ marginRight: '3px' }} />
            Baixar planilha
          </DownloadSheetButton>
        </div>

        <div>
          <FullCalendar
            onDateSelected={(e) => {
              setInicialDate(e.start);
              setFinalDate(e.end);
            }}
            value={{ start: new Date(), end: null }}
          />
        </div>
      </Header>

      {cashiers && cashiers.length > 0 ? (
        cashiers.map((cashier) => (
          <AuditCashierTable
            cashier={cashier}
            key={cashier.id}
            getCashiers={getCashiers}
          />
        ))
      ) : (
        <Card>
          <p>Não há caixas fechados no momento</p>
        </Card>
      )}
    </Container>
  );
}

export default AuditCashier;
