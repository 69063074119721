import React, { useEffect, useRef, useState } from "react";

import {
  IconContainer,
  ItemContainer,
  ModalContainer,
  ModalHeader,
  RestaurantImage,
  SearchInput,
  SearchLink,
  SecondLine,
  Subitem,
  SubitemsDiv,
  ThreadLine,
} from "./styles";

import { FaCircle } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa6";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";

import { FiX } from "react-icons/fi";
import { useAuth } from "~/context/AuthContext";

// import clubIconWhite from "~/assets/clubIconWhite.png";
// import clubIconRed from "~/assets/clubIconRed.svg";

export const NewSidebarItem = ({
  item,
  color,
  user,
  isOpen,
  isProfile,
  isSearch,
  setIsOpen,
  searchArray,
}) => {
  const { signOut } = useAuth()
  const [columnsArray, setColumnsArray] = useState(
    []
  );

  const [selected, setSelected] = useState(false);


  const generateColumns = () => {
    const columns = [];

    let currentColumn = 0;
    let currentIndex = 0;

    if (item.subitems) {
      item.subitems.forEach((subitem) => {
        if (subitem.condition === false) return;
        currentColumn += 40;
        if (columns[currentIndex] === undefined) {
          columns[currentIndex] = [subitem];
        } else {
          columns[currentIndex].push(subitem);
        }

        if (subitem.subitems) {
          subitem.subitems.forEach((subsubitem) => {
            currentColumn += 40;
            if (currentColumn > 160) {
              currentColumn = 40;
              currentIndex++;
            }
            if (columns[currentIndex] === undefined) {
              columns[currentIndex] = [{ ...subsubitem, isSubSub: true }];
            } else {
              columns[currentIndex].push({ ...subsubitem, isSubSub: true });
            }
          });
        }

        if (currentColumn >= 160) {
          currentColumn = 0;
          currentIndex++;
        }
      });
    }

    setColumnsArray(columns);
  };

  const getSubitemPath = (subitem) => {
    if (subitem.external) return "#"
    if (subitem.overridePath) {
      return subitem.overridePath;
    }

    let firstSubsubPath = null;
    if (item.path && subitem.subitems && subitem.subitems.length > 0) {
      firstSubsubPath = item.path + subitem.subitems[0].path;
    }

    if (firstSubsubPath) {
      return firstSubsubPath;
    }

    if (item.path && subitem.path) {
      return item.path + subitem.path;
    }

    return "#";
  };

  useEffect(() => {
    generateColumns();
  }, []);

  useEffect(() => {
    if (setIsOpen) {
      window.addEventListener("click", () => {
        setIsOpen("");
      });
    }

    generateColumns();

    return () => {
      window.removeEventListener("click", () => {
        setIsOpen("");
      });
    };
  }, []);

  const inputRef = useRef(null);

  const page = window.location.pathname.split("/")[1];

  useEffect(() => {
    switch (page) {
      case "operation":
        setSelected(item.text === "Operação");
        break;
      case "delivery":
        setSelected(item.text === "Delivery");
        break;
      case "products":
        setSelected(item.text === "Cardápio");
        break;
      case "register":
        setSelected(item.text === "Cadastros");
        break;
      case "settings":
        setSelected(item.text === "Impressoras");
        break;
      case "help":
        setSelected(item.text === "Ajuda");
        break;
      case "search":
        setSelected(item.text === "Buscar");
        break;
      case "profile":
        setSelected(item.text === "Perfil");
        break;
      default:
        setSelected(false);
        break;
    }
  }, [page]);

  useEffect(() => {
    if (isSearch && isOpen) {
      inputRef.current.focus();
    }
  }, [inputRef, isOpen]);


  const [filter, setFilter] = useState("");

  return (
    <ItemContainer
      color={color}

      disabled={item.disabled}
      to={
        (!item.subitems || item.subitems?.length <= 0) && item.path
          ? item.path
          : "#"
      }
      onClick={(e) => {
        if (item.external) {
          window.open(item.external, item.externalRef);
        }
        if ((!item.subitems || item.subitems?.length <= 0) && (!isSearch || (isSearch && item.subitems))) {
        } else {
          e.stopPropagation();
          if (isSearch && inputRef.current === e.target) return;
          setIsOpen(isOpen ? "" : item.text);
          setFilter("");
        }
      }}
    >
      <IconContainer
        color={color}
        selected={selected}
      >
        {item.icon}
        <div>
          <span style={{ width: '100%', textAlign: 'center' }}>{item.text}</span>
        </div>
      </IconContainer>

      <ModalContainer open={isOpen}>
        {isProfile && user ? (
          <>
            <ModalHeader color={color} >{user.fantasy_name}</ModalHeader>
            <div style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}>
              {user.avatar && user.avatar.url && (
                <RestaurantImage src={user.avatar.url} />
              )}
            </div>
          </>
        ) : (
          <ModalHeader color={color} >
            <FaChevronLeft color={color} />
            {item.text}
          </ModalHeader>
        )}
        {isSearch && (
          <div style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
            <SearchInput
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <input
                ref={inputRef}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              <div
                style={{ height: 42, width: 42, display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFilter("")
                }}
              >
                <FiX
                  color="#CDCDCD"
                  size={22}
                />
              </div>
            </SearchInput>
            <SearchLink to={`/search`}>
              Ver tudo
              <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                <HiMiniArrowTopRightOnSquare color="#666" size={18} />
              </div>
            </SearchLink>
            {filter && searchArray?.filter((item) => item.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(filter.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))).map((item, idx) => {
              if (item.condition === false) return;
              return (
                <SearchLink
                  to={item.external ? "#" : item.path}
                  key={idx}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    if (item.external) {
                      window.open(item.external, item.externalRef);
                    }
                  }}
                >
                  {item.text}
                </SearchLink>
              )
            })}

          </div>
        )}
        <div style={{ display: "flex" }}>
          {columnsArray.map((col, colIdx) => {
            const lastCol = colIdx === columnsArray.length - 1;
            return (
              <SubitemsDiv key={colIdx}>
                {col.map((subitem, idx) => {
                  if (subitem.condition === false) return;

                  return (
                    <Subitem
                      to={getSubitemPath(subitem)}
                      key={idx}
                      isSubSub={subitem.isSubSub}
                      color={color}
                      onClick={() => {
                        if (subitem.text === "Sair" && signOut())
                          if (subitem.external) {
                            window.open(subitem.external, subitem.externalRef);
                          }
                      }}
                    >
                      {subitem.isSubSub && <ThreadLine isFirst={idx === 0} />}
                      {((subitem.subitems && subitem.subitems.length > 0) ||
                        subitem.isSubSub) && <FaCircle />}
                      {subitem.text}
                      {idx === 3 &&
                        !lastCol &&
                        (subitem.isSubSub ||
                          (subitem.subitems &&
                            subitem.subitems.length > 0)) && <SecondLine />}
                    </Subitem>
                  );
                })}
              </SubitemsDiv>
            );
          })}
        </div>
      </ModalContainer>
    </ItemContainer>
  );
};