/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Form } from '@unform/web';
import { FaPlus } from 'react-icons/fa';

import {
  Container,
  Content,
  DayButton,
  Header,
  MethodsTable,
  NavLinkSpan,
  Titles,
} from './styles';
import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useAuth } from '~/context/AuthContext';
import formatTime from '~/services/formatTime';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Tabs, ToggleButton } from 'ui-kit-takeat';
import { PageTitle } from '~/components/PageTitle';

function Scheduling() {
  const [activeTab, setActiveTab] = useState(0);

  const [deliveryDays, setDeliveryDays] = useState([]);
  const [withdrawalDays, setWithdrawalDays] = useState([]);
  const { user, updateUser } = useAuth();

  const [permission, setPermission] = useState();
  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryOrderScheduling'}`
      );

      const { can_read } = response.data.DeliveryOrderScheduling;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  function handleSelectDeliveryDays(day) {
    const newArray = [...deliveryDays];
    const daysFind = deliveryDays.findIndex((item) => item.id === day.id);
    if (deliveryDays[daysFind].available === 't') {
      deliveryDays[daysFind].available = 'f';
    } else {
      deliveryDays[daysFind].available = 't';
    }

    setDeliveryDays(newArray);
  }

  function handleSelectWithDrawalDays(day) {
    const newArray = [...withdrawalDays];
    const daysFind = newArray.findIndex((item) => item.id === day.id);
    if (withdrawalDays[daysFind].available === 't') {
      withdrawalDays[daysFind].available = 'f';
    } else {
      withdrawalDays[daysFind].available = 't';
    }

    setWithdrawalDays(newArray);
  }

  const [openingHoursSchedulingData, setOpeningHoursSchedulingData] =
    useState();

  const formWithdrawalRef = useRef(null);

  const [isInputsWithdrawalBlocked, setIsInputWithdrawalBloqued] =
    useState(false);
  const getSchedules = useCallback(async () => {
    const deliveryDaysOptions = [
      { id: 1, name: 'Dom' },
      { id: 2, name: 'Seg' },
      { id: 3, name: 'Ter' },
      { id: 4, name: 'Qua' },
      { id: 5, name: 'Qui' },
      { id: 6, name: 'Sex' },
      { id: 7, name: 'Sab' },
    ];
    const response = await api.get('restaurants/order-scheduling');

    setOpeningHoursSchedulingData(response.data);

    const { active_days } = response.data;
    const newArray = active_days.split('');

    const daysArray = newArray.map((item, index) => {
      return {
        id: deliveryDaysOptions[index].id,
        name: deliveryDaysOptions[index].name,
        available: item,
      };
    });

    setDeliveryDays(daysArray);

    // set withdrawall states
    const { withdrawal_active_days } = response.data;
    const newArrayWithdrawal = withdrawal_active_days.split('');

    const daysArrayWithdrawal = newArrayWithdrawal.map((item, index) => {
      return {
        id: deliveryDaysOptions[index].id,
        name: deliveryDaysOptions[index].name,
        available: item,
      };
    });

    setWithdrawalDays(daysArrayWithdrawal);
  }, []);

  function setInitialHour(e) {
    openingHoursSchedulingData[
      e.target.id
    ] = `2021-01-09T${e.target.value}:00-03:00`;
  }

  function setFinalHour(e) {
    openingHoursSchedulingData[
      e.target.id
    ] = `2021-01-09T${e.target.value}:00-03:00`;
  }

  function handleChangeToSameDeliveryOptions() {
    setIsInputWithdrawalBloqued(!isInputsWithdrawalBlocked);
  }

  const [isSchedulingActive, setIsSchedulingActive] = useState(
    user?.is_order_scheduling_active
  );

  function handleUpdateScheduling() {
    setIsSchedulingActive(!isSchedulingActive);

    const userUpdated = {
      is_order_scheduling_active: !isSchedulingActive,
    };

    updateUser(userUpdated);
  }

  useEffect(() => {
    try {
      getSchedules();
    } catch (e) {
      console.log(e.message);
    }
  }, [getSchedules]);

  const handleSubmitWithdrawal = useCallback(
    async (data) => {
      try {
        if (isInputsWithdrawalBlocked) {
          //   const parsedInterval =
          //     data.withdrawal_interval_type === 'hours'
          //       ? data.withdrawal_interval * 60
          //       : data.withdrawal_interval;

          //   const parsedTimeInAdvance =
          //     data.withdrawal_time_in_advance_type === 'hours'
          //       ? data.withdrawal_time_in_advance * 60
          //       : data.withdrawal_time_in_advance;

          //   const activeDaysParsed = withdrawalDays
          //     .map((item) => item.available)
          //     .join('');

          await api.put('/restaurants/order-scheduling', {
            withdrawal_active_days: openingHoursSchedulingData.active_days,
            withdrawal_sunday_start_time:
              openingHoursSchedulingData.sunday_start_time,
            withdrawal_sunday_end_time:
              openingHoursSchedulingData.sunday_end_time,
            withdrawal_monday_start_time:
              openingHoursSchedulingData.monday_start_time,
            withdrawal_monday_end_time:
              openingHoursSchedulingData.monday_end_time,
            withdrawal_tuesday_start_time:
              openingHoursSchedulingData.tuesday_start_time,
            withdrawal_tuesday_end_time:
              openingHoursSchedulingData.tuesday_end_time,
            withdrawal_wednesday_start_time:
              openingHoursSchedulingData.wednesday_start_time,
            withdrawal_wednesday_end_time:
              openingHoursSchedulingData.wednesday_end_time,
            withdrawal_thursday_start_time:
              openingHoursSchedulingData.thursday_start_time,
            withdrawal_thursday_end_time:
              openingHoursSchedulingData.thursday_end_time,
            withdrawal_friday_start_time:
              openingHoursSchedulingData.friday_start_time,
            withdrawal_friday_end_time:
              openingHoursSchedulingData.friday_end_time,
            withdrawal_saturday_start_time:
              openingHoursSchedulingData.saturday_start_time,
            withdrawal_saturday_end_time:
              openingHoursSchedulingData.saturday_end_time,
            withdrawal_interval: openingHoursSchedulingData.interval,
            withdrawal_time_in_advance:
              openingHoursSchedulingData.time_in_advance,
            withdrawal_max_time_in_advance:
              openingHoursSchedulingData.max_time_in_advance,
            withdrawal_limit_per_interval:
              openingHoursSchedulingData.limit_per_interval,
          });

          toast.success('Agendamento salvo com sucesso');
          getSchedules();
          setIsInputWithdrawalBloqued(false);
          setActiveTab('1');
        } else {
          const parsedInterval =
            data.withdrawal_interval_type === 'hours'
              ? data.withdrawal_interval.replace(',', '.') * 60
              : data.withdrawal_interval;

          const parsedTimeInAdvance =
            data.withdrawal_time_in_advance_type === 'hours'
              ? data.withdrawal_time_in_advance.replace(',', '.') * 60
              : data.withdrawal_time_in_advance;

          const parsedMaxTimeInAdvance =
            data.withdrawal_max_time_in_advance_type === 'week'
              ? data.withdrawal_max_time_in_advance * 10080
              : data.withdrawal_max_time_in_advance * 43800;

          const activeDaysParsed = withdrawalDays
            .map((item) => item.available)
            .join('');
          await api.put('/restaurants/order-scheduling', {
            withdrawal_active_days: activeDaysParsed,
            withdrawal_sunday_start_time:
              openingHoursSchedulingData.withdrawal_sunday_start_time,
            withdrawal_sunday_end_time:
              openingHoursSchedulingData.withdrawal_sunday_end_time,
            withdrawal_monday_start_time:
              openingHoursSchedulingData.withdrawal_monday_start_time,
            withdrawal_monday_end_time:
              openingHoursSchedulingData.withdrawal_monday_end_time,
            withdrawal_tuesday_start_time:
              openingHoursSchedulingData.withdrawal_tuesday_start_time,
            withdrawal_tuesday_end_time:
              openingHoursSchedulingData.withdrawal_tuesday_end_time,
            withdrawal_wednesday_start_time:
              openingHoursSchedulingData.withdrawal_wednesday_start_time,
            withdrawal_wednesday_end_time:
              openingHoursSchedulingData.withdrawal_wednesday_end_time,
            withdrawal_thursday_start_time:
              openingHoursSchedulingData.withdrawal_thursday_start_time,
            withdrawal_thursday_end_time:
              openingHoursSchedulingData.withdrawal_thursday_end_time,
            withdrawal_friday_start_time:
              openingHoursSchedulingData.withdrawal_friday_start_time,
            withdrawal_friday_end_time:
              openingHoursSchedulingData.withdrawal_friday_end_time,
            withdrawal_saturday_start_time:
              openingHoursSchedulingData.withdrawal_saturday_start_time,
            withdrawal_saturday_end_time:
              openingHoursSchedulingData.withdrawal_saturday_end_time,
            withdrawal_interval: parsedInterval,
            withdrawal_time_in_advance: parsedTimeInAdvance,
            withdrawal_max_time_in_advance: parsedMaxTimeInAdvance,
            withdrawal_limit_per_interval:
              data.withdrawal_limit_per_interval_active
                ? data.withdrawal_limit_per_interval
                : -1,
          });

          toast.success('Agendamento salvo com sucesso');
        }
      } catch (error) {
        toast.error('Erro ao salvar agendamento');
      }
    },
    [
      openingHoursSchedulingData,
      withdrawalDays,
      isInputsWithdrawalBlocked,
      getSchedules,
    ]
  );

  const handleSubmitDelivery = useCallback(
    async (data) => {
      const parsedInterval =
        data.interval_type === 'hours'
          ? data.interval.replace(',', '.') * 60
          : data.interval;

      console.log(data.time_in_advance_type);

      const parsedTimeInAdvance =
        data.time_in_advance_type === 'hours'
          ? data.time_in_advance.replace(',', '.') * 60
          : data.time_in_advance;

      const parsedMaxTimeInAdvance =
        data.max_time_in_advance_type === 'week'
          ? data.max_time_in_advance * 10080
          : data.max_time_in_advance * 43800;

      const activeDaysParsed = deliveryDays
        .map((item) => item.available)
        .join('');

      try {
        await api.put('/restaurants/order-scheduling', {
          active_days: activeDaysParsed,
          sunday_start_time: openingHoursSchedulingData.sunday_start_time,
          sunday_end_time: openingHoursSchedulingData.sunday_end_time,
          monday_start_time: openingHoursSchedulingData.monday_start_time,
          monday_end_time: openingHoursSchedulingData.monday_end_time,
          tuesday_start_time: openingHoursSchedulingData.tuesday_start_time,
          tuesday_end_time: openingHoursSchedulingData.tuesday_end_time,
          wednesday_start_time: openingHoursSchedulingData.wednesday_start_time,
          wednesday_end_time: openingHoursSchedulingData.wednesday_end_time,
          thursday_start_time: openingHoursSchedulingData.thursday_start_time,
          thursday_end_time: openingHoursSchedulingData.thursday_end_time,
          friday_start_time: openingHoursSchedulingData.friday_start_time,
          friday_end_time: openingHoursSchedulingData.friday_end_time,
          saturday_start_time: openingHoursSchedulingData.saturday_start_time,
          saturday_end_time: openingHoursSchedulingData.saturday_end_time,
          interval: parsedInterval,
          time_in_advance: parsedTimeInAdvance,
          max_time_in_advance: parsedMaxTimeInAdvance,
          limit_per_interval: data.limit_per_interval_active
            ? data.limit_per_interval
            : -1,
        });

        toast.success('Agendamento salvo com sucesso');
        getSchedules();
      } catch (error) {
        toast.error('Erro ao salvar agendamento');
      }
    },
    [openingHoursSchedulingData, deliveryDays, getSchedules]
  );

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle>Agendamento</PageTitle>
          <VideoPlayer id={70} />
        </div>
      </Header>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Tabs
          tabs={['Delivery', 'Retirada']}
          counter={[null, null]}
          onChangeTab={setActiveTab}
          selected={activeTab}
        />
        <span
          style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
        >
          <ToggleButton
            value={isSchedulingActive}
            onClick={handleUpdateScheduling}
          />

          <span style={{ fontWeight: 'bold', marginLeft: 8 }}>
            Ativar agendamento
          </span>
        </span>
      </div>
      {/* <Nav tabs style={{ marginLeft: '18px' }}>
        {user.delivery_info.is_delivery_allowed && (
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => setActiveTab('1')}
              style={{ cursor: 'pointer', color: '#333' }}
            >
              <NavLinkSpan>Delivery</NavLinkSpan>{' '}
            </NavLink>
          </NavItem>
        )}

        {user.delivery_info.is_withdrawal_allowed && (
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => {
                setActiveTab('2');
              }}
              style={{ cursor: 'pointer', color: '#333' }}
            >
              <NavLinkSpan>Retirada</NavLinkSpan>{' '}
            </NavLink>
          </NavItem>
        )}
      </Nav> */}
      <Card style={{ marginBottom: 0 }}>
        {activeTab === 0 &&
          user?.delivery_info?.is_delivery_allowed &&
          (isSchedulingActive ? (
            <Content>
              <Titles>Informe os dias a serem agendados</Titles>
              <span>Informe abaixo as datas para esse agendamento:</span>
              <div style={{ display: 'flex', marginTop: 20 }}>
                {deliveryDays &&
                  deliveryDays.map((day) => (
                    <DayButton
                      id={day.id}
                      isSelected={
                        deliveryDays &&
                        deliveryDays.find((item) => item.id === day.id)
                          .available === 't'
                      }
                      onClick={() => handleSelectDeliveryDays(day)}
                    >
                      {day.name}
                    </DayButton>
                  ))}
              </div>

              <Form
                onSubmit={handleSubmitDelivery}
                initialData={openingHoursSchedulingData}
              >
                <Titles style={{ marginTop: 20 }}>
                  Horários de agendamento:
                </Titles>
                <span>
                  De acordo com os dias selecionados na etapa anterior, escolha
                  receber agendamentos nos horários de funcionamento ou horário
                  específico.
                </span>

                <MethodsTable borderless>
                  <thead>
                    <tr>
                      <th>Dia</th>

                      <th>Início</th>
                      <th>Fim</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 1)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Domingo
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="sunday_start_time"
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 1)
                              ?.available !== 't'
                          }
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.sunday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="sunday_end_time"
                          type="text"
                          name="finalHour"
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 1)
                              ?.available !== 't'
                          }
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.sunday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 2)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Segunda
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="monday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.monday_start_time
                                )
                              : 'HH:MM'
                          }
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 2)
                              ?.available !== 't'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="monday_end_time"
                          type="text"
                          name="finalHour"
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 2)
                              ?.available !== 't'
                          }
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.monday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 3)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Terça
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="tuesday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.tuesday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 3)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="tuesday_end_time"
                          type="text"
                          name="finalHour"
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 3)
                              ?.available !== 't'
                          }
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.tuesday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 4)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Quarta
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="wednesday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.wednesday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 4)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="wednesday_end_time"
                          type="text"
                          name="finalHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.wednesday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 4)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 5)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Quinta
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="thursday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.thursday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 5)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="thursday_end_time"
                          type="text"
                          name="finalHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.thursday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 5)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 6)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Sexta
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="friday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.friday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 6)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="friday_end_time"
                          type="text"
                          name="finalHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.friday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 6)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          color:
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 7)
                              ?.available !== 't' &&
                            '#d9d9d9',
                        }}
                      >
                        Sábado
                      </td>

                      <td>
                        <InputMask
                          mask="99:99"
                          id="saturday_start_time"
                          type="text"
                          name="initialHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.saturday_start_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setInitialHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 7)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                      <td>
                        <InputMask
                          mask="99:99"
                          id="saturday_end_time"
                          type="text"
                          name="finalHour"
                          maskPlaceholder={
                            openingHoursSchedulingData
                              ? formatTime(
                                  openingHoursSchedulingData?.saturday_end_time
                                )
                              : 'HH:MM'
                          }
                          placeholder="HH:MM"
                          onChange={(e) => {
                            setFinalHour(e);
                          }}
                          disabled={
                            deliveryDays &&
                            deliveryDays.find((item) => item.id === 7)
                              ?.available !== 't'
                          }
                        />{' '}
                      </td>
                    </tr>
                  </tbody>
                </MethodsTable>

                <Titles style={{ marginTop: 20 }}>
                  Os pedidos devem ser feitos com antecedência de:
                </Titles>
                <span>
                  Atenção! Leve em conta os tempos de preparo, e se for o caso,
                  de entrega do pedido.
                </span>
                <Row>
                  <Titles style={{ display: 'flex' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: 8, fontWeight: 'normal' }}>
                        Mínimo:
                      </span>
                      <Input
                        name="time_in_advance"
                        placeholder="2"
                        type="text"
                        onKeyPress={(event) => {
                          if (!/^[\.,0-9]*$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={{ width: 80, marginRight: 20 }}
                        defaultValue={
                          openingHoursSchedulingData?.time_in_advance > 60
                            ? openingHoursSchedulingData?.time_in_advance / 60
                            : openingHoursSchedulingData?.time_in_advance
                        }
                      />
                      {openingHoursSchedulingData && (
                        <span style={{ width: 120 }}>
                          <Select
                            name="time_in_advance_type"
                            options={[
                              { label: 'Horas', value: 'hours' },
                              { label: 'Minutos', value: 'minutes' },
                            ]}
                            defaultValue={
                              openingHoursSchedulingData &&
                              openingHoursSchedulingData?.time_in_advance > 60
                                ? { label: 'Horas', value: 'hours' }
                                : { label: 'Minutos', value: 'minutes' }
                            }
                          />
                        </span>
                      )}
                    </span>

                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 50,
                      }}
                    >
                      <span style={{ marginRight: 8, fontWeight: 'normal' }}>
                        Máximo:
                      </span>
                      <Input
                        name="max_time_in_advance"
                        placeholder="1"
                        type="number"
                        style={{ width: 80, marginRight: 20 }}
                        defaultValue={
                          openingHoursSchedulingData?.max_time_in_advance
                            ? openingHoursSchedulingData?.max_time_in_advance >=
                              43800
                              ? (
                                  openingHoursSchedulingData?.max_time_in_advance /
                                  43800
                                ).toFixed(2)
                              : (
                                  openingHoursSchedulingData?.max_time_in_advance /
                                  10080
                                ).toFixed(2)
                            : 1
                        }
                      />
                      {openingHoursSchedulingData && (
                        <span style={{ width: 130 }}>
                          <Select
                            name="max_time_in_advance_type"
                            options={[
                              { label: 'Semanas', value: 'week' },
                              { label: 'Meses', value: 'month' },
                            ]}
                            defaultValue={
                              openingHoursSchedulingData &&
                              openingHoursSchedulingData?.max_time_in_advance >=
                                43800
                                ? [{ label: 'Meses', value: 'month' }]
                                : [{ label: 'Semana', value: 'week' }]
                            }
                          />
                        </span>
                      )}
                    </span>
                  </Titles>
                  <Titles style={{ display: 'flex' }}>
                    {' '}
                    <CheckboxInput
                      name="limit_per_interval_active"
                      label="Limitar quantidade de pedidos por agendamento"
                      defaultChecked={
                        openingHoursSchedulingData &&
                        openingHoursSchedulingData.limit_per_interval > 0
                      }
                    />
                    <Input
                      name="limit_per_interval"
                      type="number"
                      style={{ marginLeft: 10, width: 80 }}
                    />
                  </Titles>
                </Row>

                <Titles style={{ marginTop: 20 }}>
                  Intervalo entre agendamentos:
                </Titles>
                <span>
                  Determine os intervalos disponíveis para agendamento.
                </span>

                <Titles style={{ display: 'flex' }}>
                  <Input
                    name="interval"
                    placeholder="2"
                    type="text"
                    style={{ width: 80, marginRight: 20 }}
                    defaultValue={
                      openingHoursSchedulingData?.interval > 60
                        ? openingHoursSchedulingData?.interval / 60
                        : openingHoursSchedulingData?.interval
                    }
                    onKeyPress={(event) => {
                      if (!/^[\.,0-9]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {openingHoursSchedulingData && (
                    <span style={{ width: 120 }}>
                      <Select
                        name="interval_type"
                        options={[
                          { label: 'Horas', value: 'hours' },
                          { label: 'Minutos', value: 'minutes' },
                        ]}
                        defaultValue={
                          openingHoursSchedulingData?.interval > 60
                            ? { label: 'Horas', value: 'hours' }
                            : { label: 'Minutos', value: 'minutes' }
                        }
                      />
                    </span>
                  )}
                </Titles>
                <PersonalButton
                  message="Salvar"
                  color="#3BD2C1"
                  Icon={FaPlus}
                  style={{ float: 'right' }}
                  type="submit"
                />
              </Form>
            </Content>
          ) : (
            <Content>
              <div>
                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  A função agendamento por Delivery está desativada.
                </p>
                <p style={{ textAlign: 'center' }}>
                  Para ativar a função de agendamento, ligue a chave localizada
                  no canto superior direito da sua tela
                </p>
              </div>
            </Content>
          ))}
        {activeTab === 1 &&
          user.delivery_info.is_withdrawal_allowed &&
          (isSchedulingActive ? (
            <Content>
              <Form
                onSubmit={handleSubmitWithdrawal}
                initialData={openingHoursSchedulingData}
                ref={formWithdrawalRef}
              >
                <CheckboxInput
                  name="teste3"
                  label="Mesma configuração do agendamento de Delivery"
                  onChange={handleChangeToSameDeliveryOptions}
                />
                <div
                  style={{
                    display: isInputsWithdrawalBlocked ? 'none' : 'block',
                  }}
                >
                  <Titles style={{ marginTop: 20 }}>
                    Informe os dias a serem agendados
                  </Titles>
                  <span>Informe abaixo as datas para esse agendamento:</span>
                  <div style={{ display: 'flex', marginTop: 20 }}>
                    {withdrawalDays &&
                      withdrawalDays.map((day) => (
                        <DayButton
                          id={day.id}
                          isSelected={
                            withdrawalDays &&
                            withdrawalDays.find((item) => item.id === day.id)
                              .available === 't'
                          }
                          onClick={() => handleSelectWithDrawalDays(day)}
                          type="button"
                        >
                          {day.name}
                        </DayButton>
                      ))}
                  </div>

                  <Titles style={{ marginTop: 20 }}>
                    Horários de agendamento:
                  </Titles>
                  <span>
                    De acordo com os dias selecionados na etapa anterior,
                    escolha receber agendamentos nos horários de funcionamento
                    ou horário específico.
                  </span>

                  <MethodsTable borderless>
                    <thead>
                      <tr>
                        <th>Dia</th>

                        <th>Início</th>
                        <th>Fim</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 1)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Domingo
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_sunday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_sunday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 1)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_sunday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_sunday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 1)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 2)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Segunda
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_monday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_monday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 2)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_monday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_monday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 2)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 3)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Terça
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_tuesday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_tuesday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 3)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_tuesday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_tuesday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 3)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 4)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Quarta
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_wednesday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_wednesday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 4)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_wednesday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_wednesday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 4)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 5)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Quinta
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_thursday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_thursday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 5)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_thursday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_thursday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 5)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 6)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Sexta
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_friday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_friday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 6)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_friday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_friday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 6)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color:
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 7)
                                ?.available !== 't' &&
                              '#d9d9d9',
                          }}
                        >
                          Sábado
                        </td>

                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_saturday_start_time"
                            type="text"
                            name="initialHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_saturday_start_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setInitialHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 7)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                        <td>
                          <InputMask
                            mask="99:99"
                            id="withdrawal_saturday_end_time"
                            type="text"
                            name="finalHour"
                            maskPlaceholder={
                              openingHoursSchedulingData
                                ? formatTime(
                                    openingHoursSchedulingData?.withdrawal_saturday_end_time
                                  )
                                : 'HH:MM'
                            }
                            placeholder="HH:MM"
                            onChange={(e) => {
                              setFinalHour(e);
                            }}
                            disabled={
                              withdrawalDays &&
                              withdrawalDays.find((item) => item.id === 7)
                                ?.available !== 't'
                            }
                          />{' '}
                        </td>
                      </tr>
                    </tbody>
                  </MethodsTable>

                  <Titles style={{ marginTop: 20 }}>
                    Os pedidos devem ser feitos com antecedência de:
                  </Titles>
                  <span>
                    Atenção! Leve em conta os tempos de preparo, e se for o
                    caso, de entrega do pedido.
                  </span>
                  <Row>
                    <Titles style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: 8, fontWeight: 'normal' }}>
                          Mínimo
                        </span>
                        <Input
                          name="withdrawal_time_in_advance"
                          placeholder="2"
                          type="text"
                          onKeyPress={(event) => {
                            if (!/^[\.,0-9]*$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          style={{ width: 80, marginRight: 20 }}
                          defaultValue={
                            openingHoursSchedulingData?.withdrawal_time_in_advance >
                            60
                              ? openingHoursSchedulingData?.withdrawal_time_in_advance /
                                60
                              : openingHoursSchedulingData?.withdrawal_time_in_advance
                          }
                        />
                        {openingHoursSchedulingData && (
                          <span style={{ width: 120 }}>
                            <Select
                              name="withdrawal_time_in_advance_type"
                              options={[
                                { label: 'Horas', value: 'hours' },
                                { label: 'Minutos', value: 'minutes' },
                              ]}
                              defaultValue={
                                openingHoursSchedulingData &&
                                openingHoursSchedulingData?.withdrawal_time_in_advance >
                                  60
                                  ? { label: 'Horas', value: 'hours' }
                                  : { label: 'Minutos', value: 'minutes' }
                              }
                            />
                          </span>
                        )}
                      </span>

                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 50,
                        }}
                      >
                        <span style={{ marginRight: 8, fontWeight: 'normal' }}>
                          Máximo
                        </span>
                        <Input
                          name="withdrawal_max_time_in_advance"
                          placeholder="1"
                          type="number"
                          style={{ width: 80, marginRight: 20 }}
                          defaultValue={
                            openingHoursSchedulingData?.withdrawal_max_time_in_advance
                              ? openingHoursSchedulingData?.withdrawal_max_time_in_advance >=
                                43800
                                ? (
                                    openingHoursSchedulingData?.withdrawal_max_time_in_advance /
                                    43800
                                  ).toFixed(2)
                                : (
                                    openingHoursSchedulingData?.withdrawal_max_time_in_advance /
                                    10080
                                  ).toFixed(2)
                              : 1
                          }
                        />
                        {openingHoursSchedulingData && (
                          <span style={{ width: 130 }}>
                            <Select
                              name="withdrawal_max_time_in_advance_type"
                              options={[
                                { label: 'Semanas', value: 'week' },
                                { label: 'Meses', value: 'month' },
                              ]}
                              defaultValue={
                                openingHoursSchedulingData?.withdrawal_max_time_in_advance &&
                                openingHoursSchedulingData?.withdrawal_max_time_in_advance >=
                                  43800
                                  ? [{ label: 'Meses', value: 'month' }]
                                  : [{ label: 'Semana', value: 'Week' }]
                              }
                            />
                          </span>
                        )}
                      </span>
                    </Titles>
                    <Titles style={{ display: 'flex' }}>
                      {' '}
                      <CheckboxInput
                        name="withdrawal_limit_per_interval_active"
                        label="Limitar quantidade de pedidos por agendamento"
                        defaultChecked={
                          openingHoursSchedulingData &&
                          openingHoursSchedulingData.withdrawal_limit_per_interval >
                            0
                        }
                      />
                      <Input
                        name="withdrawal_limit_per_interval"
                        type="number"
                        style={{ marginLeft: 10, width: 80 }}
                      />
                    </Titles>
                  </Row>

                  <Titles style={{ marginTop: 20 }}>
                    Intervalo entre agendamentos:
                  </Titles>
                  <span>
                    Determine os intervalos disponíveis para agendamento.
                  </span>

                  <Titles style={{ display: 'flex' }}>
                    <Input
                      name="withdrawal_interval"
                      placeholder="2"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/^[\.,0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ width: 80, marginRight: 20 }}
                      defaultValue={
                        openingHoursSchedulingData?.withdrawal_interval > 60
                          ? openingHoursSchedulingData?.withdrawal_interval / 60
                          : openingHoursSchedulingData?.withdrawal_interval
                      }
                    />
                    {openingHoursSchedulingData && (
                      <span style={{ width: 120 }}>
                        <Select
                          name="withdrawal_interval_type"
                          options={[
                            { label: 'Horas', value: 'hours' },
                            { label: 'Minutos', value: 'minutes' },
                          ]}
                          defaultValue={
                            openingHoursSchedulingData?.withdrawal_interval > 60
                              ? { label: 'Horas', value: 'hours' }
                              : { label: 'Minutos', value: 'minutes' }
                          }
                        />
                      </span>
                    )}
                  </Titles>
                </div>
                <PersonalButton
                  message="Salvar"
                  color="#3BD2C1"
                  Icon={FaPlus}
                  style={{ float: 'right' }}
                  type="submit"
                />
              </Form>
            </Content>
          ) : (
            <Content>
              <div>
                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  A função agendamento por Delivery está desativada.
                </p>
                <p style={{ textAlign: 'center' }}>
                  Para ativar a função de agendamento, ligue a chave localizada
                  no canto superior direito da sua tela
                </p>
              </div>
            </Content>
          ))}
      </Card>
    </Container>
  );
}

export default Scheduling;