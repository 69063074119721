import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'ui-kit-takeat';
import { Container } from './styles';
import { NavBar } from './styles';
import { FaUsers } from 'react-icons/fa';
import { MdPermDeviceInformation, MdTableRestaurant } from 'react-icons/md';
import { FiUserMinus } from 'react-icons/fi';
import { PageTitle } from '~/components/PageTitle';
import { useAuth } from '~/context/AuthContext';

function Header() {
  const location = useLocation(); // Obtém a localização atual
  const [pathname, setPathname] = useState('');
  const { user } = useAuth()
  // Atualize o estado "pathname" sempre que a localização mudar
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  function getTitleName(path) {
    switch (path) {
      case '/register/tables':
        return 'Mesas';

      case '/register/waiters':
        return 'Garçons';

      case '/register/users':
        return 'Usuários';

      case '/register/stone-devices':
        return 'POS Stone';

      default:
        break;
    }
  }

  return (
    <Container>
      <div>
        <PageTitle>{getTitleName(pathname)}</PageTitle>
      </div>

      <NavBar>
        <Link to={'/register/tables'}>
          <Button
            title="Mesas"
            icon={<MdTableRestaurant />}
            inverted={pathname !== '/register/tables'}
            onClick={() => { }}
          />
        </Link>
        <Link to={'/register/users'}>
          <Button
            title="Usuários"
            icon={<FaUsers />}
            inverted={pathname !== '/register/users'}
            onClick={() => { }}
          />
        </Link>
        <Link to={'/register/waiters'}>
          <Button
            title="Garçons"
            icon={<FiUserMinus />}
            inverted={pathname !== '/register/waiters'}
            onClick={() => { }}
          />
        </Link>
        {user.has_stone_pos && (
          <Link to={'/register/stone-devices'}>
            <Button
              title="Maquininhas POS"
              icon={<MdPermDeviceInformation />}
              inverted={pathname !== '/register/stone-devices'}
              onClick={() => { }}
            />
          </Link>
        )}

      </NavBar>
    </Container>
  );
}

export default Header;
