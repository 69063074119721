import React, { useState, useRef } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import {
  FiTrash2,
  FiInfo,
  FiPlusCircle,
  FiMinusCircle,
  FiMessageSquare,
} from 'react-icons/fi';

import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import {
  Container,
  MenuItemBody,
  MenuItemDescription,
  MenuItemComplements,
  MenuItemQuantity,
  ConfirmButton,
  CancelButton,
} from './styles';
import TextArea from '~/components/Form/Textarea';
import { useCart } from '~/context/OrderingSystem/Cart';
import { Button } from 'ui-kit-takeat';
import { ModalConfirmation } from '~/components/ModalConfirmation';

const MenuItemList = ({ item }) => {
  const { removeFromCart, increment, decrement, addObservation } = useCart();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalObservation, setIsModalObservation] = useState(false);

  const formRef = useRef(null);

  function toggleModal() {
    setIsOpen(!modalIsOpen);
  }

  function toggleModalOservation() {
    setIsModalObservation(!modalObservation);
  }

  function handleRemoveFromCart(id, item_id) {
    removeFromCart(id, item_id);

    toggleModal();
  }

  async function handleSubmit(data) {
    try {
      await addObservation(
        item?.id,
        item?.ordering_system_id,
        data.observation
      );
      setIsModalObservation(false);
    } catch (error) {
      toast.error(`Erro ao inserir observação!`);
      console.log(error);
    }
  }

  function replaceValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  return (
    <Container>
      <MenuItemBody key={item.basket_id}>
        <ModalConfirmation
          isOpen={modalIsOpen}
          toggle={toggleModal}
          cancel={toggleModal}
          confirm={() => handleRemoveFromCart(item.basket_id, item.id)}
          title="Deseja realmente excluir esse produto?"
        />

        <Modal isOpen={modalObservation} toggle={toggleModalOservation}>
          <ModalBody>
            <p>Adicione a observação para o produto</p>

            <Form onSubmit={handleSubmit} ref={formRef}>
              <TextArea
                name="observation"
                type="text"
                placeholder="Digite a observação"
                id="observations_input"
              />
              <ModalFooter>
                <ConfirmButton type="submit">Enviar</ConfirmButton>
                <CancelButton onClick={toggleModalOservation}>
                  Cancelar
                </CancelButton>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
        <MenuItemDescription>
          <strong>{item.name}</strong>

          {item?.observation && (
            <div>
              <FiInfo color="#fb4a20" />

              <span>{item?.observation}</span>
            </div>
          )}
          <MenuItemComplements>
            {item?.complement_categories?.map((category) =>
              category.complements
                .filter((comp) => comp.amount > 0)
                .map((items) => <p key={items.name}>{items.name}</p>)
            )}
          </MenuItemComplements>
        </MenuItemDescription>
        <MenuItemQuantity>
          {item?.use_weight ? (
            <strong> {`${replaceValue(item.weight)} kg`} </strong>
          ) : (
            <>
              {!item?.is_unitary && (
                <FiMinusCircle
                  color="#fb4a20"
                  size={18}
                  onClick={() => {
                    decrement(item.id, item.ordering_system_id);
                  }}
                />
              )}

              <strong> {`${item?.amount}`} </strong>

              {!item?.is_unitary && (
                <FiPlusCircle
                  color="#fb4a20"
                  size={18}
                  onClick={() => {
                    increment(item.id, item.ordering_system_id);
                  }}
                />
              )}
            </>
          )}

          <FiTrash2
            color="#fb4a20"
            size={18}
            onClick={toggleModal}
            title="Deletar produto"
          />
          <FiMessageSquare
            color="#fb4a20"
            size={18}
            title="Adicionar observação"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setIsModalObservation(true);
              setTimeout(() => {
                return document.getElementById('observations_input')?.focus();
              }, 1);
            }}
          />
        </MenuItemQuantity>
      </MenuItemBody>
    </Container>
  );
};

export default MenuItemList;
