import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  addHours,
  differenceInDays,
} from 'date-fns';

import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';

import { Card, Container, TableReport, ButtonsSelectDate } from './styles';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import { generateSheet } from '~/services/SheetGenerate/generateOrderCanceledSheet';
import { DownloadButton, FullCalendar } from 'ui-kit-takeat';

function ReportsOrderCanceled() {
  const [rangeOn, setRangeOn] = useState(true);
  const [orders, setOrders] = useState([]);
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsCanceledProducts'}`
      );

      const { can_read } = response.data.ReportsCanceledProducts;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    const phrase = `De ${format(
      new Date(inicialDate),
      'dd/MM/yyyy, HH:mm'
    )} até ${format(new Date(finalDate), 'dd/MM/yyyy, HH:mm')}`;
    setHourPhrase(phrase);
  }, [inicialDate, finalDate]);

  const getOrders = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/reports/orders-canceled', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setOrders(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    if (permission) {
      getOrders();
    }
  }, [getOrders, permission]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function getTableName(table) {
    const { table_number, table_type } = table;

    switch (table_type) {
      case 'delivery':
        return 'Delivery';
      case 'balcony':
        return 'Balcão';
      case 'table':
        return table_number;
      default:
        return table_number;
    }
  }

  const handleDownloadSheet = () => {
    try {
      if (!orders || orders.length === 0) {
        return;
      }

      const sheet = generateSheet(inicialDate, finalDate, orders);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Pedidos Cancelados(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ButtonsSelectDate>
        {/* <div>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setToday}
            color="info"
          >
            Hoje
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setYesterday}
            color="info"
          >
            Ontem
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setWeek}
            color="info"
          >
            Essa semana
          </SelectDateButton>

          <DataPickerArea>
            <DatePickerStyled
              selected={monthYearDate}
              onChange={(date) => {
                setMonthYearDate(date);
                setMonth(date);
                setRangeOn(true);
              }}
              locale="pt"
              showMonthYearPicker
              customInput={
                <SelectDateButton className="btn-round mr-auto" color="info">
                  Mês/Ano <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              }
            />
          </DataPickerArea>

          <SelectDateButton
            className="btn-round mr-auto"
            onClick={toggle}
            color="info"
          >
            Selecionar Período
            <MdArrowDropDown color="white" size={20} />
          </SelectDateButton>
          <FaQuestion
            color="#ff2c3a"
            title="Selecione o intervalo entre as datas com máximo de 31 dias"
            size={15}
            style={{ marginTop: -45, marginLeft: -10, cursor: 'help' }}
          />
        </div> */}
        <p style={{ width: 190, flexGrow: 1 }}>{hourPhrase}</p>
        <DownloadButton
          text="Baixar planilha"
          onClick={() => handleDownloadSheet()}
          fixed="open"
          buttonWidth={110}
        />
        <FullCalendar
          value={{ start: new Date(inicialDate), end: new Date(finalDate) }}
          hideFields={['this_year']}
          onDateSelected={(e) => {
            const days = differenceInDays(new Date(e.end), new Date(e.start));

            if (days > 31) {
              setRangeOn(false);
            } else {
              setRangeOn(true);
              setInicialDate(e.start);
              setFinalDate(e.end);
            }
          }}
        />
      </ButtonsSelectDate>
      <p
        style={{
          textAlign: 'right',
          color: 'red',
          opacity: rangeOn ? 0 : 1,
        }}
      >
        Favor selecionar o intervalo máximo de 31 dias entre as datas.
      </p>

      {/* <div>
        <Collapse isOpen={isOpen}>
          <Form
            onSubmit={handleChangeDate}
            ref={formRef}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <DateSelectorDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Inicial"
                  type="datetime-local"
                  name="initialDate"
                  onChange={(e) => setInitialPre(e.target.value)}
                  defaultValue={defaultInicial}
                  className="data"
                />
              </DateDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Final"
                  type="datetime-local"
                  name="finalDate"
                  onChange={(e) => setFinalPre(e.target.value)}
                  defaultValue={defaultFinal}
                  className="data"
                />
              </DateDiv>
              <div>
                <SubmitDate type="submit">Filtrar</SubmitDate>
              </div>
            </DateSelectorDiv>
          </Form>
        </Collapse>
        <p
          style={{
            textAlign: 'right',
            color: 'red',
            display: rangeOn ? 'none' : 'block',
            paddingRight: 20,
          }}
        >
          Favor selecionar o intervalo máximo de 31 dias entre as datas.
        </p>
      </div> */}
      <Card>
        <TableReport borderless>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Data/Hora</th>
              <th>Cliente/Garçom</th>
              <th>Qtd.</th>
              <th>Produto</th>
              <th>Valor</th>
              <th>Justificativa</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{getTableName(order.basket.bill.session.table)}</td>
                <td>{formatCompleteDate(order.canceled_at)}</td>
                {order.basket.bill.waiter && (
                  <td>{order.basket.bill.waiter.name}</td>
                )}
                {order.basket.bill.buyer && (
                  <td>
                    {order.basket.bill.buyer.name ||
                      order.basket.bill.buyer.phone}
                  </td>
                )}
                <td>{order.amount}</td>
                <td>{order.product.name}</td>
                <td>
                  R$
                  {parseFloat(order.total_price).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ color: 'red' }}>{order.cancel_reason}</td>
                <td>{order.users.name}</td>
              </tr>
            ))}
          </tbody>
        </TableReport>
      </Card>
    </Container>
  );
}

export default ReportsOrderCanceled;
