import React, { useState, useEffect, useCallback } from 'react';

import { FaDownload, FaEye, FaPlus, FaWhatsapp } from 'react-icons/fa';

import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';

import {
  format,
  endOfDay,
  startOfMonth,
  addHours,
  differenceInDays,
} from 'date-fns';

import { Row, Col } from 'reactstrap';

import {
  Card,
  Container,
  Header,
  TableReport,
  ButtonsSelectDate,
  DownloadSheetButton,
} from './styles';

import CreateClient from './CreateClient';

import Select from '~/components/Form/SelectInput';
import ClientsRow from '~/components/Reports/ClientsRow';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import { generateSheet } from '~/services/SheetGenerate/generateClientsSheet';

import { useAuth } from '~/context/AuthContext';
import { PageTitle } from '~/components/PageTitle';
import {
  Button,
  DownloadButton,
  FilterTable,
  FullCalendar,
} from 'ui-kit-takeat';

ReactModal.setAppElement('#root');

function ReportsClient() {
  const [report, setReport] = useState([]);
  const [reportFiltered, setReportFiltered] = useState([]);
  const [filterType, setFilterType] = useState(null);

  const [searchDate, setSearchDate] = useState({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
  });

  const { user } = useAuth();
  const { has_service_tax } = user;

  const [showClientCreateModal, setShowClientCreateModal] = useState(false);

  const [permission, setPermission] = useState(false);

  const getFilter = (a, b) => {
    if (filterType.id === 1) {
      return (
        (a.name || a.client?.name || 'ZZZ').localeCompare(
          b.name || b.client?.name || 'ZZZ'
        ) * (filterType.type === 'asc' ? 1 : -1)
      );
    } else if (filterType.id === 2) {
      return (a.total - b.total) * (filterType.type === 'asc' ? 1 : -1);
    } else {
      return (
        (new Date(a.last_visit) - new Date(b.last_visit)) *
        (filterType.type === 'asc' ? 1 : -1)
      );
    }
  };

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsClients'}`
      );

      const { can_read } = response.data.ReportsClients;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const getReport = useCallback(async () => {
    const days = differenceInDays(
      new Date(searchDate.end),
      new Date(searchDate.start)
    );

    if (days > 31) {
      toast.error('Selecione um período de no máximo 31 dias.');
      return;
    }

    try {
      const response = await api.get('/restaurants/clients/report', {
        params: {
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setReport(response.data);
      setReportFiltered(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [searchDate]);

  useEffect(() => {
    if (permission) {
      getReport();
    }
  }, [getReport, permission]);

  const [inputValue, setInputValue] = useState('');
  const options_filter = [
    { value: 'by_last_visit', label: 'Última Visita mais Recente' },
    {
      value: 'by_total',
      label: 'Mais gastou até hoje',
    },
  ];

  async function handleSearchInput(target) {
    const pos = target.indexOf('_');

    const phone = pos !== -1 ? target.substr(0, pos) : target;

    const reportFiltered = report.filter((report) => {
      const report_phone = report?.phone || '';

      return report_phone.startsWith(phone);
    });

    setReportFiltered(reportFiltered);
  }

  const [filter, setFilter] = useState('by_last_visit');

  const handleDownloadSheet = () => {
    try {
      const sheet = generateSheet(
        new Date(searchDate.start),
        new Date(searchDate.end),
        reportFiltered
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Clientes(${format(new Date(searchDate.start), 'dd-MM')}_${format(
          new Date(searchDate.end),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ReactModal
        onRequestClose={() => setShowClientCreateModal(false)}
        isOpen={showClientCreateModal}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <CreateClient onExit={() => setShowClientCreateModal(false)} />
      </ReactModal>

      <Header>
        <div>
          <PageTitle>Relatório de Clientes</PageTitle>
          <p>Acompanhe seus clientes!</p>
        </div>

        <Button
          title="Cadastrar cliente"
          icon={<FaPlus />}
          onClick={() => setShowClientCreateModal(true)}
        />

        {/* <PersonalButton
          color="#FF2C3A"
          message="+ Cadastrar cliente"
          style={{ margin: '0px' }}
          onClick={() => setShowClientCreateModal(true)}
        /> */}
      </Header>

      <ButtonsSelectDate>
        <FullCalendar value={searchDate} onDateSelected={setSearchDate} />
      </ButtonsSelectDate>

      <Card>
        <Row style={{ marginBottom: '5px' }}>
          <Col md="8">
            <InputMask
              mask="(99) 99999-9999"
              onChange={(e) => {
                handleSearchInput(e.target.value);
                setInputValue(e.target.value);
              }}
              value={inputValue}
            >
              <input
                placeholder="Busque pelo telefone"
                style={{ height: '40px', borderRadius: '4px' }}
              />
            </InputMask>
          </Col>

          <Col
            md="4"
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
            }}
          >
            <DownloadButton
              onClick={handleDownloadSheet}
              text="Baixar planilha"
              fixed="open"
              buttonWidth={120}
            />
          </Col>
        </Row>
        <FilterTable
          clickableHeaders={[1, 2, 6]}
          onChangeFilter={(e) => setFilterType(e)}
          defaultFilter={{
            id: 6,
            type: 'desc',
          }}
          header={[
            'Número',
            'Nome',
            'Gastou até hoje',
            'Gastou até hoje (com taxas)',
            'Visitas',
            'Última avaliação',
            'Última visita',
            'Ações',
          ]}
        >
          <tbody>
            {reportFiltered
              .sort((a, b) => getFilter(a, b))
              .map((report, idx) => {
                return (
                  <ClientsRow
                    key={idx}
                    report={report}
                    has_service_tax={has_service_tax}
                  />
                );
              })}
          </tbody>
        </FilterTable>
        {/* <Row>
          <TableReport borderless>
            <thead>
              <tr>
                <th>Número</th>
                <th>Nome</th>
                <th>Gastou até hoje</th>
                {has_service_tax && <th>Gastou até hoje (com taxas)</th>}
                <th>Visitas</th>
                <th>Última Avaliação</th>
                <th>Última Visita</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {reportFiltered
                .sort((a, b) => {
                  if (filter === 'by_total') {
                    return b.total - a.total;
                  } else {
                    return new Date(b.last_visit) - new Date(a.last_visit);
                  }
                })
                .map((report) => (
                  <ClientsRow
                    report={report}
                    has_service_tax={has_service_tax}
                  />
                ))}
            </tbody>
          </TableReport>
        </Row> */}
      </Card>
    </Container>
  );
}

export default ReportsClient;
