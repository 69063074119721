/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import { format } from 'date-fns';

async function getData(isNot, isEarning) {
  const sheet_body_isnot = isNot.map((trans) => {
    const sheet_item = {
      due_date: trans.due_date
        ? format(new Date(trans.due_date), 'dd/MM/yyyy')
        : '-',
      description: trans.description,
      provider: trans.provider?.name || '-',
      cash_flow: trans.text || '-',
      bank_account: trans.bank_account?.name || '-',
      paid: trans.paid ? 'Sim' : 'Não',
      value: parseFloat(trans.value || 0),
      paid_at: trans.paid_at
        ? format(new Date(trans.paid_at), 'dd/MM/yyyy')
        : '-',
    };

    return [
      sheet_item.due_date,
      sheet_item.description,
      sheet_item.provider,
      sheet_item.cash_flow,
      sheet_item.bank_account,
      sheet_item.paid,
      sheet_item.value,
      sheet_item.paid_at,
    ];
  });

  const sheet_header_isnot = [
    'Vencimento',
    'Descrição',
    'Fornecedor',
    'Centro de custo',
    'Conta Bancária',
    'Pago',
    'Valor',
    'Pago em'
  ];

  const sheet_body_isearning = isEarning.map((trans) => {
    const sheet_item = {
      due_date: trans.due_date
        ? format(new Date(trans.due_date), 'dd/MM/yyyy')
        : '-',
      cash_flow_payment_method: trans.cash_flow_payment_method?.name || '-',
      description: trans.description || '-',
      bank_account: trans.bank_account?.name || '-',
      paid: trans.paid ? 'Sim' : 'Não',
      value: parseFloat(trans.value || 0),
      paid_at: trans.paid_at
        ? format(new Date(trans.paid_at), 'dd/MM/yyyy')
        : '-',
    };

    return [
      sheet_item.due_date,
      sheet_item.cash_flow_payment_method,
      sheet_item.description,
      sheet_item.bank_account,
      sheet_item.paid,
      sheet_item.value,
      sheet_item.paid_at
    ];
  });

  const sheet_header_isearning = [
    'Recebimento',
    'Método',
    'Competência',
    'Conta Bancária',
    'Recebido',
    'Valor',
    'Recebido em'
  ];

  return {
    dataIsNot: [sheet_header_isnot, ...sheet_body_isnot],
    dataisEarning: [sheet_header_isearning, ...sheet_body_isearning],
  };
}

export async function generateTransactionsSheet(
  isNotEarningTransactions,
  isEarningTransactions
) {
  const { dataIsNot, dataisEarning } = await getData(
    isNotEarningTransactions,
    isEarningTransactions
  );

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = dataIsNot[0].length;
  const cols_comps = dataisEarning[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataIsNot);
  const comp_sheet = XLSX.utils.aoa_to_sheet(dataisEarning);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });
  comp_sheet['!cols'] = new Array(cols_comps);
  comp_sheet['!cols'].fill({ wch: 15 });

  const num_lines = dataIsNot.length;
  const comp_num_lines = dataisEarning.length;

  const red = 'ff6d6d';
  const black = '000000';

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];

      const s = style();

      if (i === 1) {
        s.Bg(red).Borders('tb');
      }

      if (j === num_cols - 1) {
        s.Font(black, '', 'center').Number();
      } else if (j > 0) {
        s.Font(black, '', 'center');
      }

      if (cell) {
        cell.s = s.Get();
      }

    }
  }

  for (let j = 0; j < cols_comps; j++) {
    for (let i = 1; i <= comp_num_lines; i++) {
      const cell = comp_sheet[columns[j] + i];

      const s = style();

      if (i === 1) {
        s.Bg(red).Borders('tb');
      }

      if (j === num_cols - 1) {
        s.Font(black, '', 'center').Number();
      } else if (j > 0) {
        s.Font(black, '', 'center');
      }

      if (cell) {
        cell.s = s.Get();
      }

    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Contas a Pagar');
  XLSX.utils.book_append_sheet(workbook, comp_sheet, 'Contas a Receber');

  return XLSX.write(workbook, { type: 'buffer' });
}
