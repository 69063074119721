import { Form } from '@unform/web';
import React, { useCallback, useEffect, useState } from 'react';
import { FaClock, FaUserCircle } from 'react-icons/fa';
import { Badge } from 'reactstrap';
import { compareAsc, compareDesc, differenceInMinutes } from 'date-fns';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import OrderItem from './OrderItem';

import {
  BuyerInfo,
  ConfirmButton,
  Container,
  Header,
  Orders,
  OrdersInfo,
} from './styles';
import PhoneString from '~/services/formatPhoneString';
import formatCompleteDate from '~/services/formatCompleteDate';

function OrderCard({ item, baskets, setBaskets }) {
  const [cardExpanded, setCardExpanded] = useState(false);

  function toggleCardExpand() {
    setCardExpanded(!cardExpanded);
  }

  const buttonStatus = item?.status === 'pending' ? 'Começar' : 'Pronto';
  function getBadgeStatus(status) {
    if (status === 'pending') {
      return 'bg-secondary';
    }

    if (status === 'doing') {
      return 'bg-info';
    }

    if (status === 'finished') {
      return 'bg-success';
    }

    if (status === 'canceled') {
      return 'bg-danger';
    }

    return false;
  }

  function getBadgeText(status) {
    if (status === 'pending') {
      return 'NOVO';
    }

    if (status === 'doing') {
      return 'EM PREPARO';
    }

    if (status === 'finished') {
      return 'PRONTO';
    }

    if (status === 'canceled') {
      return 'CANCELADO';
    }

    return false;
  }



  const handleChangeStatus = useCallback(
    async (order_status, id) => {
      await api.put(`/restaurants/kds/basket/status/${item?.id}`, {
        status: order_status === 'pending' ? 'doing' : 'finished',
      });

      const newBaskets = [...baskets];

      const basketFind = newBaskets?.findIndex((item) => item?.id === id);

      if (basketFind !== -1) {
        newBaskets[basketFind].status =
          order_status === 'pending' ? 'doing' : 'finished';
      }

      setBaskets(newBaskets);
    },
    [item, baskets, setBaskets]
  );

  const [isLate, setIsLate] = useState([]);
  const [averageTime, setAverageTime] = useState();

  useEffect(() => {
    const average_time = item?.kds_orders;

    average_time.sort((a, b) => {
      if (a.average_time > b.average_time) {
        return 1;
      }

      if (a.average_time === b.average_time) {
        return 0;
      }

      if (a.average_time < b.average_time) {
        return -1;
      }
    });

    if (average_time) {
      setAverageTime(average_time[0]);
    }
  }, [item]);

  const verifyIfIsLate = useCallback(() => {
    if (item?.scheduled_to) {
      if (
        compareAsc(new Date(), new Date(item?.scheduled_to)) === 1
      ) {
        setIsLate([item?.id]);
      }
    } else {
      if (
        differenceInMinutes(new Date(), new Date(item?.arrived_at)) >
        averageTime?.average_time
      ) {
        setIsLate([item?.id]);
      }
    }

  }, [averageTime, item]);

  useEffect(() => {
    verifyIfIsLate();
  }, [verifyIfIsLate]);

  useEffect(() => {
    const verifyIfProductIsLate = setInterval(verifyIfIsLate, 60000);
    return () => {
      clearInterval(verifyIfProductIsLate);
    };
  }, [isLate, item, averageTime, verifyIfIsLate]);

  function getTableData(table) {
    switch (table.table_type) {
      case 'table':
        return `Mesa ${table.table_number}`;
      case 'delivery':
        return `Delivery`;
      case 'balcony':
        if (item.command_table_number) {
          return `Balcão ${item.command_table_number}`;
        }
        return `Balcão`;
      default:
        return '';
    }
  }

  return (
    <Container>
      <Form>
        <Header>
          {item?.table.table_type === 'command' ?
            (<span style={{ fontWeight: 'bold' }}>
              <div> Comanda {item.table.table_number}</div>
              <div style={{ marginTop: -3 }}>
                Mesa {item.command_table_number || 'n/a'}
              </div>
            </span>
            ) : (
              <span style={{ fontWeight: 'bold' }}>
                {getTableData(item?.table)}
              </span>
            )
          }

          <Badge pill className={getBadgeStatus(item?.status)}>
            {getBadgeText(item?.status)}
          </Badge>
          <span style={{ fontWeight: 'bold', fontSize: 18 }}>
            #
            {item?.table.table_type === 'delivery'
              ? item?.attendance_password
              : item?.basket_key}
          </span>
        </Header>
        <BuyerInfo
          isProductLate={
            isLate.includes(item?.id) &&
            (item?.status === 'doing' || item?.status === 'pending')
          }
        >
          <span>
            <FaUserCircle />{' '}
            <span>
              {item?.buyer
                ? item?.buyer?.name
                  ? item?.buyer?.name
                  : PhoneString(item?.buyer?.phone)
                : item?.waiter?.name}
            </span>
          </span>
          {item?.scheduled_to && <span>Agendado para: {formatCompleteDate(item?.scheduled_to)}</span>}

          {!item?.scheduled_to && (
            <span>
              <FaClock /> <span>{formatTime(item?.arrived_at)}</span>
            </span>
          )}


        </BuyerInfo>
        <Orders>
          {item &&
            item?.kds_orders &&
            item?.kds_orders.length > 0 &&
            item?.kds_orders.sort((a, b) => {
              if (a.canceled_at) {
                return 1;
              } else if (b.canceled_at) {
                return -1;
              } else {
                return a.id - b.id;
              }
            })
              .map((product, index) => (
                <OrderItem
                  product={product}
                  basket={item}
                  isLate={
                    isLate.includes(item?.id) &&
                    (item?.status === 'doing' || item?.status === 'pending')
                  }
                  index={index}
                  key={product.id}

                />
              ))}
        </Orders>

        {/* {item.products.length > 3 && (
          <ExpandButton type="button" onClick={toggleCardExpand}>
            <FaAngleDown color="#3D3D3D" /> Mostrar mais
          </ExpandButton>
        )} */}
        {/* <ExpandButton type="button">
          <FaAngleDown color="#3D3D3D" /> Mostrar mais
        </ExpandButton> */}
        {(item?.status === 'pending' || item?.status === 'doing') && (
          <ConfirmButton
            type="button"
            status={item?.status}
            onClick={() => handleChangeStatus(item?.status, item?.id)}
          >
            {buttonStatus}{' '}
          </ConfirmButton>
        )}
      </Form>
    </Container>
  );
}

export default OrderCard;