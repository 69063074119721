import React from 'react';
import OrderCard from '../OrderCard';

import { Container } from './styles';



function OrdersFinished({ baskets, kdsViewMode }) {
  const priorities = {
    pending: 0,
    doing: 1,
    finished: 2,
  };

  return (
    <Container kdsViewMode={kdsViewMode}>
      {baskets?.length > 0 &&
        baskets
          .sort((a, b) => {
            if (a.status === b.status) {
              return (
                new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
              );
            }

            return priorities[b.status] - priorities[a.status];
          })
          ?.filter((item) => item.status === 'finished')
          .map((item) => <OrderCard item={item} key={item.id} />)}
    </Container>
  );
}

export default OrdersFinished;
