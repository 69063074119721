import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import Switch from 'react-switch';
import {
  InnerArea,
  AreaSwitcher,
  Container,
  ButtonsArea,
  NavDivTeste,
  NavDiv,
  NavLinkSpan,
  FiscalMessage,
  StyledForm,
} from './styles';
import api from '~/services/api';

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
  TabPane,
  TabContent,
} from 'reactstrap';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import InputMask from 'react-input-mask';
import NFeInfo from '~/services/NFeInfo';
import nfeInfo from '~/services/NFeInfo';
import { useAuth } from '~/context/AuthContext';
import { Button, DefaultInput } from 'ui-kit-takeat';
import { PersonalizedHoursContainer } from '../../Edit/styles';
import { format } from 'date-fns';
import ReactModal from 'react-modal';
import englishFlag from "~/assets/img/englishFlag.png"
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import { FaArrowLeft } from 'react-icons/fa';
import EnglishMenuButton from '~/components/Buttons/EnglishMenuButton';
import { TextareaContainer } from '~/components/Form/styles';

function EditComplementCategory({
  category,
  toggle,
  getCategories,
  productsCategories,
  setProductsCategories,
  setCategories,
  categories,
  setComplementsCategoriesFiltered = false,
  fiscalGroups,
}) {
  const { user } = useAuth();

  const [complements, setComplements] = useState([]);
  const [moreExpensiveChecked, setMoreExpensiveChecked] = useState(
    category?.more_expensive_only
  );
  const [useAverageChecked, setUseAverageChecked] = useState(
    category?.use_average
  );

  const [availableCategory, setAvailableCategory] = useState(
    category?.available
  );

  const [enableTimes, setEnableTimes] = useState(category?.enable_times);
  const [daysSelected, setDaysSelected] = useState(
    category?.active_days.split('')
  );
  const [hourSelected, setHourSelected] = useState({
    start: format(new Date(category?.start_time), 'HH:mm'),
    end: format(new Date(category?.end_time), 'HH:mm'),
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  const formRef = useRef(null);
  const initialData = {
    name: category?.name,
    question: category?.question,
    minimum: category?.minimum || 1,
    limit: category?.limit,
    available: category?.available,
    optional: category?.optional,
    single_choice: category?.single_choice,
    additional: category?.additional,
    more_expensive_only: category?.more_expensive_only,
    use_average: category?.use_average,
    enable_times: category?.enable_times,
  };

  const initialComplements = category?.complements.map((complement) => ({
    value: complement.id,
    label: complement.name,
  }));

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements, category.id]);

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Máximo obrigatório'),
        minimum: Yup.string().required('Mínimo obrigatório'),
        question: Yup.string().required('Pergunta obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(
        `restaurants/product/complement-category/${category.id}`,
        {
          name: data.name,
          question: data.question,
          limit: data.limit,
          minimum: Number(data.minimum),
          single_choice: false,
          optional: data.optional,
          available: availableCategory,
          available_in_delivery: availableDeliveryCategory,
          additional: data.additional,
          complements: data.complements,
          more_expensive_only: data.more_expensive_only,
          use_average: data.use_average,
          enable_times: enableTimes,
          active_days: daysSelected.join(''),
          start_time: new Date(`2023-01-01 ${hourSelected.start}`),
          end_time: new Date(`2023-01-01 ${hourSelected.end}`),
        }
      );

      toast.success('Categoria atualizada com sucesso!');
      // const aleatoryId = v4();

      // setCount(aleatoryId);
      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      const newCategories = [...categories];

      newCategories.map((cat, idix) => {
        if (cat.id === category.id) {
          newCategories[idix] = response.data;
        }
      });

      setCategories(newCategories);

      if (setComplementsCategoriesFiltered) {
        setComplementsCategoriesFiltered((state) => {
          const newCategories = [...state];

          newCategories.map((cat, idix) => {
            if (cat.id === category.id) {
              newCategories[idix] = response.data;
            }
          });

          return [...newCategories];
        });
      }

      toggle();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao salvar categoria');
      }
    }
  }

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] = useState(
    category?.available_in_delivery
  );

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  const [activeTab, setActiveTab] = useState('1');

  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(null);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(null);
  const [csosnValue, setCsosnValue] = useState(null);
  const [cfopValue, setCfopValue] = useState(null);
  const [cofinsValue, setCofinsValue] = useState(null);
  const [pisValue, setPisValue] = useState(null);
  const [cestValue, setCestValue] = useState(null);
  const [icmsValorValue, setIcmsValorValue] = useState(null);
  const [baseCalculoValue, setBaseCalculoValue] = useState(null);
  const [aliquotaValue, setAliquotaValue] = useState(null);
  // regime normal
  const [cstValue, setCstValue] = useState(null);
  const [pisAliquotaValue, setPisAliquotaValue] = useState(null);
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(null);
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(null);
  const [cbenefValue, setCbenefValue] = useState(null);
  const [ncm, setNcm] = useState(null);

  const [csosnSelected, setCsosnSelected] = useState(null);
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setIcmsValorValue(group.icms_valor);
    setAliquotaValue(group.aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };

      if (user.regime_tributario === '1') {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.ncm = ncm || data.ncm;

        if (csosnSelected === '00') {
          parsedData.aliquota = aliquotaValue || data.aliquota;
          parsedData.base_calculo =
            baseCalculoValue?.value || data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      } else {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;
        parsedData.icms_valor = icmsValorValue || data.icms_valor;
        parsedData.ncm = ncm || data.ncm;

        parsedData.cst = cstValue?.value || data.cst;
        parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
        parsedData.cofins_aliquota =
          cofinsAliquotaValue || data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
        parsedData.cbenef = cbenefValue || data.cbenef;

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      }

      await api.put(
        `restaurants/product/complement-category/fiscal/${category.id}`,
        parsedData
      );
      toast.success('Informações fiscais atualizadas');
      getCategories();
    } catch (error) {
      toast.error('Falha ao salvar dados fiscais');
      console.log(error);
    }
  }

  //English Menu
  const [isEnglishMenuModalOpened, setIsEnglishMenuModalOpened] = useState(false)
  function toggleEnglishMenuModal() {
    setIsEnglishMenuModalOpened(!isEnglishMenuModalOpened)
  }

  const englishInitialData = {
    en_name: category?.translations?.en?.name,
    en_question: category?.translations?.en?.question,
  }

  async function handleSubmitEnglishMenu(data) {
    if (!category) {
      return;
    }

    let translations = null;

    if (data.en_name || data.en_question) {
      translations = {
        en: {
          name: data.en_name,
          question: data.en_question,
        },
      };
    }

    try {
      const response = await api.put(`restaurants/product/complement-category/${category.id}`, {
        translations,
      });

      category.translations = response.data.translations;

      toast.success('Categoria atualizada com sucesso!');

      toggleEnglishMenuModal();
    } catch (error) {
      toast.error('Falha ao atualizar categoria');
      console.log(error);
    }
  }

  return (
    <Container>
      <ReactModal
        onRequestClose={toggleEnglishMenuModal}
        isOpen={isEnglishMenuModalOpened}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <StyledForm
            initialData={englishInitialData}
            ref={formRef}
            onSubmit={handleSubmitEnglishMenu}
          >
            <Row >
              <span style={{ fontWeight: 'bold' }}> <img src={englishFlag} alt="Bandeira dos Estados unidos" style={{ width: 20, height: 20 }} /> Cardápio em inglês</span>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col size="md-6">
                <Input
                  label="Nome"
                  name="en_name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={category?.is_multistore_child}
                />
              </Col>
              <Col size="md-6">
                <Input
                  label="Pergunta"
                  name="en_question"
                  type="text"
                  placeholder="Pergunta da categoria"
                  disabled={category?.is_multistore_child}
                />
              </Col>
            </Row>

            <ButtonsArea>
              <PersonalOutlineButton
                color="transparent"
                fontColor="#FF2C3A"
                borderColor="#FF2C3A"
                message="Voltar"
                Icon={FaArrowLeft}
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleEnglishMenuModal();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </InnerArea>
      </ReactModal>
      <Row>
        <Col md="12" style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: 18 }}>Editar Grupo de complementos</p>
          {user.enable_translations && (
            <EnglishMenuButton onClick={toggleEnglishMenuModal} />
          )}
        </Col>
      </Row>

      <div>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                >
                  <NavLinkSpan>Categoria</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  <NavLinkSpan>Fiscal</NavLinkSpan>
                </NavLink>
              </NavItem>
            </Nav>
          </NavDivTeste>
        </NavDiv>
      </div>

      <InnerArea browserZoomLevel={browserZoomLevel}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <Row>
                <Col sm="12" lg="6">
                  <Input
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="Nome da categoria"
                    disabled={category.is_multistore_child}
                  />
                </Col>
                <Col sm="12" lg="3">
                  <Input
                    label="Mínimo"
                    name="minimum"
                    type="number"
                    placeholder="1"
                    disabled={category.is_multistore_child}
                  />
                </Col>
                <Col sm="12" lg="3">
                  <Input
                    label="Máximo"
                    name="limit"
                    type="number"
                    placeholder="1"
                    disabled={category.is_multistore_child}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Input
                    label="Pergunta"
                    name="question"
                    type="text"
                    placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                    disabled={category.is_multistore_child}
                  />
                </Col>
              </Row>
              <Row>
                <Select
                  name="complements"
                  isMulti
                  options={complements}
                  label="Associar complementos à categoria"
                  defaultValue={initialComplements}
                  disabled={category.is_multistore_child}
                />
              </Row>
              <Row>
                {!user.only_delivery && (
                  <Col md="3">
                    {/* <CheckboxInput
                  defaultChecked
                  label="Disponível no presencial"
                  name="available"
                /> */}

                    <AreaSwitcher title="PDV">
                      <Switch
                        onChange={handleChangeAvailableCategory}
                        draggable
                        checked={availableCategory}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={27}
                        width={44}
                        handleDiameter={16}
                        activeBoxShadow="0 0 2px 3px #111"
                        boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                        onColor="#0CCAB4"
                        offColor="#666666"
                      />
                      <span>PDV</span>
                    </AreaSwitcher>
                  </Col>
                )}

                {user?.delivery_info?.is_delivery_allowed && (
                  <Col md="3">
                    <AreaSwitcher title="Delivery">
                      <Switch
                        onChange={handleChangeAvailableDeliveryCategory}
                        draggable
                        checked={availableDeliveryCategory}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={27}
                        width={44}
                        handleDiameter={16}
                        activeBoxShadow="0 0 2px 3px #111"
                        boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                        onColor="#0CCAB4"
                        offColor="#666666"
                      />
                      <span>Delivery</span>
                    </AreaSwitcher>
                  </Col>
                )}
              </Row>
              <Row className="mt-2">
                <Col md="4">
                  <CheckboxInput
                    defaultChecked={category?.additional}
                    label="Adicional"
                    name="additional"
                    disabled={category.is_multistore_child}
                  />
                </Col>

                <Col md="4">
                  <CheckboxInput
                    defaultCheckeda={category?.optional}
                    label="Opcional"
                    name="optional"
                    disabled={category.is_multistore_child}
                  />
                </Col>
                {/* 
                <Col md="3">
                  <CheckboxInput
                    defaultChecked={category?.single_choice}
                    label="Escolha única"
                    name="single_choice"
                    disabled={category.is_multistore_child}
                  />
                </Col> */}
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <CheckboxInput
                    label="Cobrar apenas o mais caro"
                    name="more_expensive_only"
                    onChange={() =>
                      setMoreExpensiveChecked(!moreExpensiveChecked)
                    }
                    disabled={useAverageChecked || category.is_multistore_child}
                  />
                </Col>

                <Col sm="12" md="4">
                  <CheckboxInput
                    label="Cobrar a média do preço"
                    name="use_average"
                    onChange={() => setUseAverageChecked(!useAverageChecked)}
                    disabled={
                      moreExpensiveChecked || category.is_multistore_child
                    }
                  />
                </Col>

                <Col sm="12" md="4">
                  <CheckboxInput
                    label="Horário personalizado"
                    name="enable_times"
                    onChange={() => setEnableTimes(!enableTimes)}
                    value={enableTimes}
                  />
                </Col>
                {/* <Col sm="12" lg="6">
                <CheckboxInfo>
                  <h4> Legenda</h4>
                  <div>
                    <span>Disponível</span>
                    <p>
                      Se esta categoria está disponível no restaurante no momento
                    </p>
                  </div>
                  <div>
                    <span>Disponível no presencial</span>
                    <p>
                      Se esta categoria está disponível para os clientes
                      presenciais
                    </p>
                  </div>
                  <div>
                    <span>Disponível no delivery</span>
                    <p>
                      Se esta categoria está disponível no cardápio do delivery
                    </p>
                  </div>
                  <div>
                    <span>Opcional</span>
                    <p>O cliente poderá escolher ou não algo nesta categoria</p>
                  </div>
                  <div>
                    <span>Escolha única</span>
                    <p>
                      O cliente poderá escolher somente uma opção nesta categoria
                    </p>
                  </div>
                  <div>
                    <span>Adicional</span>
                    <p>
                      Se marcado, os complementos terão preço adicional. Caso
                      contrário, serão gratúitos
                    </p>
                  </div>
                  <div>
                    <span>Cobra apenas complemento mais caro</span>
                    <p>
                      Se marcado, apenas o complemento mais caro da categoria será
                      cobrado.
                    </p>
                  </div>
                  <div>
                    <span>Cobra a média do preço dos complementos</span>
                    <p>
                      Se marcado, será feita cobrança calculando a média de preço
                      dos complementos.
                    </p>
                  </div>
                </CheckboxInfo>
              </Col> */}
              </Row>

              {enableTimes && (
                <PersonalizedHoursContainer>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Dias:</span>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Button
                        type="button"
                        title="Dom"
                        buttonColor="#2ec9b7"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                        }}
                        onClick={() => {
                          handleChangeDay(0);
                        }}
                      />
                      <Button
                        type="button"
                        title="Seg"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(1);
                        }}
                      />
                      <Button
                        type="button"
                        title="Ter"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(2);
                        }}
                      />
                      <Button
                        type="button"
                        title="Qua"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(3);
                        }}
                      />
                      <Button
                        type="button"
                        title="Qui"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(4);
                        }}
                      />
                      <Button
                        type="button"
                        title="Sex"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(5);
                        }}
                      />
                      <Button
                        type="button"
                        title="Sáb"
                        containerStyles={{
                          width: 60,
                          height: 30,
                          opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                        }}
                        buttonColor="#2ec9b7"
                        onClick={() => {
                          handleChangeDay(6);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Horários:</span>
                    <div
                      style={{
                        display: 'flex',
                        gap: 5,
                        alignItems: 'center',
                      }}
                    >
                      <span>das</span>
                      <DefaultInput
                        placeholder="__:__"
                        mask="99:99"
                        maskChar="0"
                        style={{
                          width: '74px',
                          padding: '0px 10px',
                          height: '30px',
                        }}
                        value={hourSelected.start}
                        onChange={(e) => {
                          const value = e.target.value;
                          setHourSelected((state) => {
                            return {
                              start: handleTimeInput(value),
                              end: state.end,
                            };
                          });
                        }}
                      />
                      <span>às</span>
                      <DefaultInput
                        placeholder="__:__"
                        mask="99:99"
                        maskChar="0"
                        style={{
                          width: '74px',
                          padding: '0px 10px',
                          height: '30px',
                        }}
                        value={hourSelected.end}
                        onChange={(e) => {
                          const value = e.target.value;
                          setHourSelected((state) => {
                            return {
                              start: state.start,
                              end: handleTimeInput(value),
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </PersonalizedHoursContainer>
              )}

              <ButtonsArea>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggle();
                  }}
                />

                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                  style={{
                    marginRight: 10,
                  }}
                />
              </ButtonsArea>
            </Form>
          </TabPane>

          <TabPane tabId="2">
            <Form onSubmit={handleSubmitFiscal}>
              <InnerArea browserZoomLevel={browserZoomLevel}>
                {user.has_nfce && (
                  <p>
                    Área destinada a aplicar informações fiscais em massa em
                    todos os complementos desse grupo. Ao Salvar, as informações
                    fiscais serão aplicadas a todos os complementos desse grupo,
                    e essa ação não poderá ser desfeita. Portanto, é importante
                    tomar cuidado.
                  </p>
                )}
                {user.has_nfce && (
                  <Row>
                    <Col sm="12">
                      <Select
                        name="fiscal_group_id"
                        options={fiscalGroups?.map((group) => {
                          return {
                            label: group.title,
                            value: group.id,
                          };
                        })}
                        label="Grupo Fiscal"
                        onChange={(e) => updateFiscalValues(e.value)}
                        value={fiscalGroupDefault}
                      />
                    </Col>
                  </Row>
                )}

                {!user.has_nfce && (
                  <FiscalMessage>
                    <p>Seu plano atual não contempla a função de fiscal!</p>
                    <span>
                      Entre em contato com nosso time de suporte e faça a
                      ativação.
                    </span>
                  </FiscalMessage>
                )}
                {user.has_nfce &&
                  (user.regime_tributario === '1' ? (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask
                            mask="99999999"
                            onChange={(e) => {
                              setNcm(e.target.value);
                            }}
                            value={ncm}
                          >
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>
                        <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.cofins_options}
                            label="Cofins"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.cofins_options}
                            label="Pis"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '00' ||
                        aliquotaValue ||
                        baseCalculoValue) && (
                          <div>
                            <Row style={{ paddingTop: 20 }}>
                              <Col sm="12" lg="6">
                                <Input
                                  label="Alíquota (Entre 0 e 100)"
                                  name="aliquota"
                                  type="text"
                                  value={aliquotaValue}
                                  onChange={(e) => {
                                    setAliquotaValue(e.target.value);
                                  }}
                                />
                              </Col>

                              <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                                <Select
                                  name="base_calculo"
                                  options={base_calculo_options}
                                  label="Base Cálculo"
                                  value={baseCalculoValue}
                                  onChange={(e) => {
                                    setBaseCalculoValue(
                                      base_calculo_options.find(
                                        (x) => x.value === e.value
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="4">
                          <InputMask
                            mask="99999999"
                            onChange={(e) => {
                              setNcm(e.target.value);
                            }}
                            value={ncm}
                          >
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>

                        <Col sm="12" lg="4">
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>

                        <Col sm="12" lg="4">
                          <Input
                            label="Alíquota ICMS (%)"
                            name="icms_aliquota"
                            type="text"
                            value={aliquotaValue}
                            onChange={(e) => {
                              setAliquotaValue(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <Select
                            name="cst"
                            options={NFeInfo.icms_situacao_tributaria}
                            label="CST"
                            onChange={(e) => {
                              setCstValue(
                                NFeInfo.icms_situacao_tributaria.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cstValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="PIS"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota PIS (%)"
                            name="pis_aliquota"
                            type="text"
                            onChange={(e) => {
                              setPisAliquotaValue(e.target.value);
                            }}
                            value={pisAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="COFINS"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota COFINS (%)"
                            name="cofins_aliquota"
                            type="text"
                            onChange={(e) => {
                              setCofinsAliquotaValue(e.target.value);
                            }}
                            value={cofinsAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_modalidade_base_calculo"
                            options={nfeInfo.icms_modalidade_base_calculo}
                            label="Modalidade Base Calculo"
                            onChange={(e) => {
                              setIcmsModalidadeBCValue(
                                NFeInfo.icms_modalidade_base_calculo.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={icmsModalidadeBCValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Código de Benefícios Fiscais (cBenef)"
                            name="cbenef"
                            type="text"
                            onChange={(e) => {
                              setCbenefValue(e.target.value);
                            }}
                            value={cbenefValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="12">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>
                        </Row>
                      )}

                      {(cstValue?.value === '00' || icmsValorValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="12">
                            <Input
                              label="ICMS Valor"
                              name="icms_valor"
                              type="text"
                              onChange={(e) => {
                                setIcmsValorValue(e.target.value);
                              }}
                              value={icmsValorValue}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}
              </InnerArea>
              <ButtonsArea>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggle();
                  }}
                />

                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                  style={{
                    marginRight: 10,
                  }}
                />
              </ButtonsArea>
            </Form>
          </TabPane>
        </TabContent>
      </InnerArea>
    </Container>
  );
}

export default EditComplementCategory;
