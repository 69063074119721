import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { Line, Bar } from 'react-chartjs-2';
import { Form } from '@unform/web';
import {
  format,
  startOfDay,
  endOfDay,
  endOfMonth,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
  subDays,
  differenceInDays,
} from 'date-fns';

import { toast } from 'react-hot-toast';
import { SiAirtable } from 'react-icons/si';
import { FaMotorcycle, FaQuestionCircle } from 'react-icons/fa';
import { TiDocument } from 'react-icons/ti';

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Progress,
  Table,
  Row,
  Col,
  Collapse,
} from 'reactstrap';

import {
  chartExample2,
  chartExample5,
  chartExample7,
  chartExample8,
} from 'variables/charts.js';
import { MdArrowDropDown } from 'react-icons/md';
import RadarChart from '~/components/Reports/RadarChart';
import ReportChartPiePrincipal from '~/components/Reports/ReportChartPiePrincipal';
import api from '~/services/api';
import formatMonthYear from '~/services/formatMonthYear';

import {
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  ButtonsSelectDate,
  ErrorContainer,
  DataPickerArea,
  DatePickerStyled,
} from './styles';
import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { FullHourCalendar } from 'ui-kit-takeat';

const Dashboard = () => {
  // Data/Hora
  const { user } = useAuth();
  const formRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const chartExample8 = {
    data: {
      labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      datasets: [
        {
          label: 'Data',
          fill: true,
          backgroundColor: '#ff8a76',
          hoverBackgroundColor: ' #ff8a76',
          borderColor: '#ff8a76',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          stack: '1',
          data: [80, 100, 70, 80, 120, 80, 130],
        },
        {
          label: 'Data',
          fill: true,
          backgroundColor: 'red',
          hoverBackgroundColor: ' #ff8a76',
          borderColor: '#ff8a76',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          stack: '1',
          data: [80, 100, 70, 80, 120, 80, 130],
        },
        {
          label: 'Data',
          fill: true,
          backgroundColor: '#2782f0',
          hoverBackgroundColor: ' #2782f0',
          borderColor: '#2782f0',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          stack: '2',
          data: [60, 110, 90, 70, 90, 100],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: '#9e9e9e',
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            gridLines: {
              drawBorder: false,
              color: 'rgba(29,140,248,0.1)',
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#9e9e9e',
            },
          },
        ],
      },
    },
  };
  const [rangeOn, setRangeOn] = useState(true);

  const [monthYearDate, setMonthYearDate] = useState(null);

  const defaultInicial = useMemo(() => {
    return format(
      startOfMonth(subHours(new Date(), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const phrase = `Nesse mês`;

  const [inicialDate, setInicialDate] = useState(
    format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [finalDate, setFinalDate] = useState(
    format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [hour4Week, setHour4Week] = useState('Nas 4 últimas semanas');

  const [bigChartData, setbigChartData] = useState('data1');
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  const colorsTeste = require('nice-color-palettes');

  const colors = [
    '#22aa99',
    '#289976',
    '#329262',
    '#5574a6',
    '#1F8A70',
    '#00796B',
    '#287D7D',
    '#2a778d',
    '#009688',
    '#22aa90',
    '#289977',
    '#329261',
    '#5574a7',
    '#1F8A71',
    '#00796C',
    '#287D7E',
    '#2a778e',
    '#009684',
    '#226666',
    '#669999',
    '#407F7F',
    '#0D4D4D',
    '#003333',
    '#27556C',
    '#6E91A1',
    '#477286',
    '#113C51',
    '#032536',
    '#68b684',
    '#a6e1fa',
    '#75dddd',
    '#84c7d0',
    '#04151f',
    '#183a37',
    '#0c7489',
    '#119da4',
    '#13505b',
  ];

  const [card, setCard] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  const [chartRevenueAll, setChartRevenueAll] = useState([]);
  const [chartHours, setChartHours] = useState([]);
  const [chartCashflow, setChartCashflow] = useState(false);
  const [chartCashflowCategory, setChartCashflowCategory] = useState(false);
  const [chartDailyWeek, setChartDailyWeek] = useState([]);
  const [chartRating, setChartRating] = useState([]);
  const [chartMethods, setChartMethods] = useState([]);
  const [topClients, setTopClients] = useState([]);
  const [labels, setLabels] = useState([]);
  const [total, setTotal] = useState([]);
  const [local, setLocal] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [ifood, setIfood] = useState([]);

  // index
  const [chartProductsIndex, setChartProductsIndex] = useState(0);
  const [chartRatingIndex, setChartRatingIndex] = useState(0);

  const [totalBox, setTotalBox] = useState(0);

  const getCards = useCallback(async ({ start_date, end_date }) => {
    try {
      const response = await api.get('restaurants/reports/general-cards', {
        params: {
          start_date,
          end_date,
        },
      });
      setCard(response.data);

      setTotalBox(response.data?.total_payments_tax || 0);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getTopProducts = useCallback(async ({ start_date, end_date }) => {
    try {
      if (start_date === null && end_date === null) {
        start_date = `${format(
          addHours(startOfMonth(new Date()), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`;
        end_date = `${format(
          addHours(new Date(), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`;
      }
      const response = await api.get('restaurants/reports/top-products', {
        params: {
          start_date,
          end_date,
        },
      });
      setTopProducts([
        response.data.products.byTotal,
        response.data.products.byAmount,
      ]);

      // Categories
      const filtered = response.data.categories.byAmount.slice(0, 10);
      const labels = filtered.map((data) => data.name);
      const total = filtered.map((data) => data.total);

      setChartCategories({ labels, total });
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const setNewChartData = (values) => {
    setChartRevenueAll(getChartPrincipal(labels, values));
  };

  const getRevenue = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/revenue');

      const handleTotal = response.data.total;
      const handleLocal = response.data.local;
      const handleDelivery = response.data.delivery;
      const handleIfood = response.data.ifood;
      // Todos
      setLabels(handleTotal.map((total) => total.label));
      setTotal(handleTotal.map((total) => total.value));
      const all = getChartPrincipal(
        handleTotal.map((total) => total.label),
        handleTotal.map((total) => total.value)
      );

      setLocal(handleLocal.map((total) => total.value));

      setDelivery(handleDelivery.map((total) => total.value));

      setIfood(handleIfood.map((total) => total.value));

      setChartRevenueAll(all);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getHours = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/orders-hour');

      const labels = response.data.map((data) => data.label);
      const values = response.data.map((data) => data.average);

      const hours = getChart5(labels, values, 'Arrecadação Média (R$)');
      setChartHours(hours);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCashFlowChart = useCallback(async ({ start_date, end_date }) => {
    try {
      const response = await api.get('restaurants/reports/cash-flow-year');

      const handle = response.data.filter(
        (data) => data.payments > 0 || data.cashflow > 0
      );
      const labels = handle.map((data) => data.label);
      const values1 = handle.map((data) => data.cashflow);
      const values2 = handle.map((data) => data.payments);
      const chart = getChart8(labels, values2, values1, 'Entrada', 'Saída');
      setChartCashflow(chart);

      // Gráfico de categorias
      if (start_date === null && end_date === null) {
        start_date = `${format(
          startOfMonth(addHours(new Date(), 3)),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`;
        end_date = `${format(
          addHours(new Date(), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`;
      }
      const responseCategory = await api.get(
        'restaurants/reports/cash-flow-category',
        {
          params: {
            start_date,
            end_date,
          },
        }
      );

      const chartCategory = getChart8Category(
        responseCategory.data?.labels,
        responseCategory.data?.datas
      );
      setChartCashflowCategory(chartCategory);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getDailyWeek = useCallback(async ({ start_date, end_date }) => {
    try {
      const response = await api.get(
        'restaurants/reports/payments-daily-week',
        {
          params: {
            start_date,
            end_date,
          },
        }
      );

      const labels = response.data.week.map((data) => data.label);
      const values = response.data.week.map((data) => data.value);

      const chart = getChart7(labels, values, 'Arrecadação Média (R$)');
      setChartDailyWeek(chart);

      // Metodos de Pagamento
      const toChart = [];
      const responseFiltered = response.data.methods.slice(0, 10);

      responseFiltered.forEach(function (value, index) {
        toChart.push({
          id: value.label,
          label: value.label,
          value: value.value,
          color: colors[index],
        });
      });
      setChartMethods(toChart);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getRating = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/rating-complete');

      const dataPastWeeks = response.data.pastWeeks; // .filter(data => data.value > 0);
      const dataYear = response.data.year; // .filter(data => data.value > 0);
      const dataDaysOfWeek = response.data.daysOfWeek; // .filter(data => data.value > 0);

      // Todos
      const labels = dataPastWeeks.map((total) => total.label);
      const values = dataPastWeeks.map((total) => total.value);
      const pastWeeks = getChart5(
        labels,
        values,
        'Média de Avaliação',
        'yellow'
      );

      const labelsYear = dataYear.map((total) => total.label);
      const valuesYear = dataYear.map((total) => total.value);
      const year = getChart5(
        labelsYear,
        valuesYear,
        'Média de Avaliação',
        'yellow'
      );

      const labelsWeek = dataDaysOfWeek.map((total) => total.label);
      const valuesWeek = dataDaysOfWeek.map((total) => total.value);
      const daysOfWeek = getChart5(
        labelsWeek,
        valuesWeek,
        'Média de Avaliação',
        'yellow'
      );

      setChartRating([pastWeeks, year, daysOfWeek]);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getTopClientsAndWaiter = useCallback(
    async ({ start_date, end_date }) => {
      try {
        if (start_date === null && end_date === null) {
          start_date = `${format(
            startOfMonth(addHours(new Date(), 3)),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`;
          end_date = `${format(
            addHours(new Date(), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`;
        }
        const response = await api.get('restaurants/reports/buyers', {
          params: {
            start_date,
            end_date,
          },
        });

        const buyersOrderedBySum = response.data.sort((a, b) => {
          return a.sum - b.sum;
        });
        const top5Clients = buyersOrderedBySum.reverse().slice(0, 10);
        setTopClients(top5Clients);
      } catch (err) {
        toast.error('Erro ao carregar informações');
      }
    },
    []
  );

  // Funções pra montar gráfico
  function getChartPrincipal(labels, values) {
    const chartExample2 = {
      data: (canvas) => {
        const ctx = canvas.getContext('2d');

        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
        gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
        gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); // blue colors

        return {
          labels,
          datasets: [
            {
              label: 'R$',
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: '#1f8ef1',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: '#1f8ef1',
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: '#1f8ef1',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: values,
            },
          ],
        };
      },
    };

    return chartExample2;
  }

  function getChart5(labels, values, label, color) {
    const chartExample5 = {
      data: (canvas) => {
        const ctx = canvas.getContext('2d');
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        if (color === 'yellow') {
          gradientStroke.addColorStop(1, 'rgba(255, 255, 64,0.4)');
          gradientStroke.addColorStop(0.8, 'rgba(255, 255, 64,0.2)');
          gradientStroke.addColorStop(0, 'rgba(255, 255, 64,0)'); // purple colors
        } else {
          gradientStroke.addColorStop(1, 'rgba(72,72,176,0.4)');
          gradientStroke.addColorStop(0.8, 'rgba(72,72,176,0.2)');
          gradientStroke.addColorStop(0, 'rgba(119,52,169,0)'); // purple colors
        }

        return {
          labels,
          datasets: [
            {
              label,
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: color === 'yellow' ? '#cccc00' : '#ba54f5',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: color === 'yellow' ? '#cccc00' : '#be55ed',
              pointBorderColor:
                color === 'yellow'
                  ? 'rgba(204, 204, 0, 0)'
                  : 'rgba(255,255,255,0)',
              pointHoverBackgroundColor:
                color === 'yellow' ? '#cccc00' : '#be55ed',
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: values,
            },
          ],
        };
      },
    };
    return chartExample5;
  }

  function getChart7(labels, values, label) {
    const chartExample7 = {
      data: (canvas) => {
        const ctx = canvas.getContext('2d');
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

        gradientStroke.addColorStop(1, 'rgba(253,93,147,0.8)');
        gradientStroke.addColorStop(0, 'rgba(253,93,147,0)'); // blue colors
        return {
          labels,
          datasets: [
            {
              label,
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: '#ff5991',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: values,
            },
          ],
        };
      },
    };

    return chartExample7;
  }

  function getChart8(labels, values1, values2, label1, label2) {
    return {
      labels,
      datasets: [
        {
          label: label1,
          fill: true,
          backgroundColor: '#28a745',
          hoverBackgroundColor: '#28a745',
          borderColor: '#28a745',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: values1,
        },
        {
          label: label2,
          fill: true,
          backgroundColor: '#dc3545',
          hoverBackgroundColor: '#dc3545',
          borderColor: '#dc3545',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: values2,
        },
      ],
    };
  }

  function getChart8Category(labels, datas) {
    const datasets = [];
    datas.forEach(function (data, index) {
      if (data.name !== 'Pagamentos') {
        datasets.push({
          label: data.name,
          fill: true,
          backgroundColor:
            data.name === 'Pagamentos' ? 'green' : colorsTeste[index][0],
          hoverBackgroundColor:
            data.name === 'Pagamentos' ? 'green' : colorsTeste[index][0],
          borderColor:
            data.name === 'Pagamentos' ? 'green' : colorsTeste[index][0],
          borderWidth: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          stack: data.stack,
          data: data.data,
        });
      }
    });

    return {
      labels,
      datasets,
    };
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsMainPage'}`
      );

      const { can_read } = response.data.ReportsMainPage;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    // document.body.classList.toggle("white-content");
    getCards({ start_date: null, end_date: null });
  }, [getCards]);

  useEffect(() => {
    getTopProducts({ start_date: null, end_date: null });
  }, [getTopProducts]);

  useEffect(() => {
    getRevenue();
  }, [getRevenue]);

  useEffect(() => {
    getHours();
  }, [getHours]);
  useEffect(() => {
    getDailyWeek({ start_date: null, end_date: null });
  }, [getDailyWeek]);
  useEffect(() => {
    getRating();
  }, [getRating]);
  useEffect(() => {
    getCashFlowChart({ start_date: null, end_date: null });
  }, [getCashFlowChart]);
  useEffect(() => {
    getTopClientsAndWaiter({ start_date: null, end_date: null });
  }, [getTopClientsAndWaiter]);

  function getFilters({ start_date, end_date }) {
    getCards({ start_date, end_date });
    getTopClientsAndWaiter({ start_date, end_date });
    getTopProducts({ start_date, end_date });
    getCashFlowChart({ start_date, end_date });
    getDailyWeek({ start_date, end_date });

    const inicialFormat = format(new Date(inicialDate), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalDate), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <>
      <div
        className="content"
        style={{ padding: 20, height: 'calc(100vh - 48px)', overflowY: 'auto' }}
      >
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle tag="h2">Faturamento Mensal</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: bigChartData === 'data1',
                        })}
                        onClick={() => {
                          setBgChartData('data1');
                          setNewChartData(total);
                        }}
                      >
                        <span
                          style={{
                            color: bigChartData === 'data1' && 'white',
                          }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Total
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: bigChartData === 'data2',
                        })}
                        onClick={() => {
                          setBgChartData('data2');
                          setNewChartData(local);
                        }}
                      >
                        <span
                          style={{
                            color: bigChartData === 'data2' && 'white',
                          }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Presencial
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: bigChartData === 'data3',
                        })}
                        onClick={() => {
                          setBgChartData('data3');
                          setNewChartData(delivery);
                        }}
                      >
                        <span
                          style={{
                            color: bigChartData === 'data3' && 'white',
                          }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Delivery
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                      {user.has_ifood && (
                        <Button
                          color="info"
                          id="3"
                          size="sm"
                          tag="label"
                          className={classNames('btn-simple', {
                            active: bigChartData === 'data4',
                          })}
                          onClick={() => {
                            setBgChartData('data4');
                            setNewChartData(ifood);
                          }}
                        >
                          <span
                            style={{
                              color: bigChartData === 'data4' && 'white',
                            }}
                            className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                          >
                            iFood
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                      )}
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartRevenueAll && chartRevenueAll.data && (
                    <Line
                      data={chartRevenueAll ? chartRevenueAll.data : []}
                      options={chartExample5.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', gap: 10 }}>
              <FullHourCalendar
                value={{
                  start: new Date(inicialDate),
                  end: new Date(finalDate),
                }}
                onDateSelected={(e) => setInicialDate(e.start)}
              />
              <FullHourCalendar
                isEnd
                value={{
                  start: new Date(inicialDate),
                  end: new Date(finalDate),
                }}
                onDateSelected={(e) => {
                  const days = differenceInDays(e.end, new Date(inicialDate));
                  if (days > 31) {
                    setRangeOn(false);
                  } else {
                    setRangeOn(true);
                    setFinalDate(e.end);
                    getFilters({ start_date: inicialDate, end_date: e.end });
                  }
                }}
                onError={(e) => toast.error(e)}
                maxRange={31}
              />
            </div>
            <p style={{ color: 'red', opacity: rangeOn ? '0' : '1' }}>
              Favor selecionar o intervalo máximo de 31 dias entre as datas.
            </p>
          </div>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-warning">
                      <i className="tim-icons">
                        <SiAirtable />
                      </i>
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Ticket Médio Mesas</p>
                      <CardTitle tag="h3">
                        R$
                        {(card?.ticket_local || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-danger">
                      <i className="tim-icons">
                        <FaMotorcycle />
                      </i>
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Ticket Médio Delivery</p>
                      <CardTitle tag="h3">
                        R$
                        {(card?.ticket_delivery || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="4">
                    <div className="info-icon text-center icon-success">
                      <i className="tim-icons icon-money-coins" />
                    </div>
                  </Col>
                  <Col xs="8">
                    <div className="numbers">
                      <p className="card-category">Total de Vendas</p>
                      <CardTitle tag="h3">
                        R$
                        {(card?.total_payments || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-info">
                      <i className="tim-icons icon-single-02" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total de Clientes</p>
                      <CardTitle tag="h3">{card?.clients}</CardTitle>
                      <br />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-primary">
                      <i className="tim-icons">
                        <TiDocument />
                      </i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Comandas Abertas</p>
                      <CardTitle tag="h3">{card?.sessions}</CardTitle>
                      <br />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="3">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div
                      className="info-icon text-center"
                      style={{ background: '#ffc107' }}
                    >
                      <i className="tim-icons icon-shape-star" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Média de Avaliações</p>
                      <CardTitle tag="h3">
                        {(card?.rating_average || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                        })}
                      </CardTitle>
                      <br />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {user?.delivery_info?.is_delivery_allowed && (
            <>
              <Col lg="3" md="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col xs="1">
                        <div className="info-icon text-center icon-success">
                          <i className="tim-icons icon-money-coins" />
                        </div>
                      </Col>
                      <Col xs="11">
                        <div className="numbers">
                          <p className="card-category">
                            Delivery - Total de vendas (Produtos)
                          </p>
                          <CardTitle tag="h3">
                            R${' '}
                            {(
                              card?.delivery_payments_without_tax || 0
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}
                          </CardTitle>
                          <p className="card-category" style={{ fontSize: 12 }}>
                            taxas de entrega:{' '}
                            <strong>
                              R${' '}
                              {(card?.delivery_tax || 0).toLocaleString(
                                'pt-BR',
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}{' '}
                            </strong>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}

          <Col lg="5">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Arrecadação Média por Hora</CardTitle>
                <h4 className="card-category">Últimos 30 dias</h4>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartHours && chartHours.data && (
                    <Line
                      data={chartHours.data}
                      options={chartExample2.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="7">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Fluxo de Caixa Mensal</CardTitle>
                <h4 className="card-category">
                  Acompanhe as entradas e saídas de seu estabelecimento.
                </h4>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartCashflow && (
                    <Bar
                      data={chartCashflow}
                      options={chartExample8?.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Lançamentos por Centro de Custo</CardTitle>
                <h4 className="card-category">
                  Acompanhe a evolução do seu centro de custo por categoria.
                </h4>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartCashflowCategory && (
                    <Bar
                      data={chartCashflowCategory}
                      options={chartExample8?.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Formas de Pagamento Mais Usadas</CardTitle>
                <h4 className="card-category">
                  As {chartMethods.length} Mais Usadas {hour4Week}
                </h4>
              </CardHeader>
              <CardBody>
                <Col xs="12">
                  <div style={{ height: 250, padding: 10 }}>
                    <ReportChartPiePrincipal
                      radius={0.5}
                      data={chartMethods}
                      colors={colors}
                    />
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">Categorias Mais Vendidas</CardTitle>
                <h4 className="card-category">
                  As {chartCategories?.labels?.length} mais vendidas{' '}
                  {hourPhrase}
                </h4>
              </CardHeader>
              <CardBody>
                <div
                  className="chart-area"
                  style={{ height: 250, padding: 10 }}
                >
                  <RadarChart
                    data={{
                      labels: chartCategories && chartCategories.labels,
                      datasets: [
                        {
                          label: 'Categoria (R$)',
                          data: chartCategories && chartCategories.total,
                          backgroundColor: '#007C7750',
                          borderColor: '#007C77',
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="5">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">
                  Média Diária de Vendas por Dia da Semana
                </CardTitle>
                <h4 className="card-category">Média referente {hour4Week}.</h4>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartDailyWeek && chartDailyWeek.data && (
                    <Bar
                      data={chartDailyWeek.data}
                      options={chartExample7.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="7">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <CardTitle tag="h4">Média de Avaliações</CardTitle>
                    <h4 className="card-category">
                      Acompanhe a evolução das avaliações de seus clientes!
                    </h4>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: chartRatingIndex === 0,
                        })}
                        onClick={() => {
                          setChartRatingIndex(0);
                        }}
                      >
                        <span
                          style={{ color: chartRatingIndex === 0 && 'white' }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Semanal
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: chartRatingIndex === 1,
                        })}
                        onClick={() => {
                          setChartRatingIndex(1);
                        }}
                      >
                        <span
                          style={{ color: chartRatingIndex === 1 && 'white' }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Mensal
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: chartRatingIndex === 2,
                        })}
                        onClick={() => {
                          setChartRatingIndex(2);
                          console.log(chartRating);
                        }}
                      >
                        <span
                          style={{ color: chartRatingIndex === 2 && 'white' }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Diário
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {chartRating && chartRating.data && (
                    <Line
                      data={
                        chartRating[chartRatingIndex]
                          ? chartRating[chartRatingIndex].data
                          : []
                      }
                      options={chartExample2.options}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <Row>
              <Card className="card-tasks" style={{ height: 100 }}>
                <CardHeader>
                  <h6 className="title d-inline">A Fazer (2)</h6>
                  <p className="card-category d-inline">Hoje</p>
                </CardHeader>
                <CardBody>
                  <p>Em breve</p>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="12">
                      <CardTitle tag="h4">
                        Top {topClients.length} Clientes do Último Mês
                      </CardTitle>
                      <h4 className="card-category">{hourPhrase}</h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th>Cliente</th>
                        <th className="text-center">Visitas</th>
                        <th className="text-right">Consumido</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topClients.map((client) => (
                        <tr>
                          <td>{client.buyer.phone}</td>
                          <td className="text-center">{client.count}</td>
                          <td className="text-right" style={{ color: 'green' }}>
                            R$
                            {parseFloat(client.sum).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Row>
          </Col>
          <Col lg="7">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <CardTitle tag="h4">
                      Top {topProducts[0] ? topProducts[0].length : '20'}{' '}
                      Produtos Mais Vendidos
                    </CardTitle>
                    <h4 className="card-category">{hourPhrase}</h4>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: chartProductsIndex === 0,
                        })}
                        onClick={() => {
                          setChartProductsIndex(0);
                        }}
                      >
                        <span
                          style={{
                            color: chartProductsIndex === 0 && 'white',
                          }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Arrecadação
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames('btn-simple', {
                          active: chartProductsIndex === 1,
                        })}
                        onClick={() => {
                          setChartProductsIndex(1);
                        }}
                      >
                        <span
                          style={{
                            color: chartProductsIndex === 1 && 'white',
                          }}
                          className="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        >
                          Quantidade
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" />
                      <th>Produto</th>
                      <th>Quantidade</th>
                      <th>Porcentagem</th>
                      <th className="text-right">Arrecadação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(topProducts[chartProductsIndex] || []).map((product) => (
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img alt="..." src={product?.image || null} />
                          </div>
                        </td>
                        <td>{product.name}</td>
                        <td>{product.amount}</td>
                        <td className="text-center">
                          <div className="progress-container progress-sm">
                            <Progress multi>
                              <span className="progress-value">
                                {chartProductsIndex === 0
                                  ? product.percent_total
                                  : product.percent_amount}
                                %
                              </span>
                              <Progress
                                bar
                                max="100"
                                value={
                                  chartProductsIndex === 0
                                    ? product.percent_total
                                    : product.percent_amount
                                }
                              />
                            </Progress>
                          </div>
                        </td>
                        <td className="text-right">
                          R$
                          {product.total.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
