import React, { useState } from 'react';
import { Container, Modal, ModalBody } from 'reactstrap';
import {
  OrderNumber,
  Time,
  PhoneNumber,
  DeleteAndEditActions,
  CardColumn,
  CardColumnItem,
} from '../styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import EyeModalOrders from '~/components/EyeModalOrders';
import { useAuth } from '~/context/AuthContext';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye } from 'react-icons/fa';
import { format } from 'date-fns';
import { ContentBody, TableNumber } from './styles';

export default function OrderCanceledDelivery({ item }) {
  const [modal, setModal] = useState(false);

  const { user } = useAuth();
  const toggle = () => setModal(!modal);

  const userName = item?.buyer
    ? formatPhone(
        item?.buyer?.phone ? item?.buyer.phone : item?.buyer?.ifood_phone
      ) || item?.buyer.name
    : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.phone
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    if (table?.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table?.table_number}`;
  }

  return (
    <ContentBody>
      <CardColumn>
        <CardColumnItem style={{ marginTop: 5, marginLeft: 2 }} onStart>
          <TableNumber>
            {item?.table?.table_type === 'command'
              ? `Comanda  ${item?.table.table_number} / Mesa ${
                  item?.basket?.command_table_number || 'não informada'
                } `
              : searchTableName(item?.table)}
          </TableNumber>
        </CardColumnItem>

        {item?.basket?.schedule && (
          <CardColumnItem style={{ marginLeft: 2 }} onStart>
            <PhoneNumber>
              <strong>{item?.basket?.schedule}</strong>
            </PhoneNumber>
          </CardColumnItem>
        )}
        {item?.table.table_type === 'delivery' && item?.buyer?.name && (
          <CardColumnItem style={{ marginLeft: 2 }} onStart>
            <PhoneNumber>{item.buyer.name}</PhoneNumber>
          </CardColumnItem>
        )}
        {item?.table.table_type === 'delivery' ? (
          <CardColumnItem style={{ marginLeft: 2 }} onStart>
            <PhoneNumber>{userNameDelivery}</PhoneNumber>
          </CardColumnItem>
        ) : (
          <CardColumnItem style={{ marginLeft: 2 }} onStart>
            <PhoneNumber>{userName}</PhoneNumber>
          </CardColumnItem>
        )}
      </CardColumn>

      <CardColumn grow>
        <CardColumnItem>
          <span>Horário de início:&nbsp;&nbsp;</span>
          {format(new Date(item?.basket?.start_time), 'dd/MM/yy - HH:mm')}
        </CardColumnItem>
        <CardColumnItem>
          <span>Horário de cancelamento:&nbsp;&nbsp;</span>
          {format(new Date(item?.basket?.canceled_at), 'dd/MM/yy - HH:mm')}
        </CardColumnItem>
      </CardColumn>

      <CardColumn onEnd>
        <DeleteAndEditActions>
          <OrderButton icon={<FaEye />} color="#FFA814" onClick={toggle} />
        </DeleteAndEditActions>
        <CardColumnItem>
          <OrderNumber></OrderNumber>
        </CardColumnItem>
        <CardColumnItem>
          <OrderNumber>#{item?.attendance_password}</OrderNumber>
        </CardColumnItem>
      </CardColumn>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>
    </ContentBody>
  );
}
