import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  Modal,
  Table
} from 'reactstrap';


export const Container = styled.div`
  padding: 0 20px 20px;
  height: calc(100vh - 136px);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 7px;
  padding: 20px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
`;

export const MethodsTable = styled(Table)`
  width: 100%;
  height: calc(100% - 192px);
  min-width: 400px;
  border-collapse: separate;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: center;

    th:first-child {
      text-align: left;
    }
  }

  tbody {
    td {
      border-bottom: 1px solid #eee;
      text-align: center;
      width: ${(props) => (props.twoShifts ? '16.6%' : '25%')};

      input {
        width: 60px;
        border: 0px;
        border-bottom: 1px solid #33333330;
        transition: all 1s;
        text-align: center;

        :focus {
          border-bottom: 1px solid red;
        }
      }
    }
    td:first-child {
      text-align: left;
    }

    tr:last-child {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`

export const CarouselStyled = styled(Carousel)`
  width: 100%;

  img {
    width: 500px;
  }
`;

export const CarouselIndicatorsStyled = styled(CarouselIndicators)`
  margin-bottom: 80px;
  button {
    background-color: #33333370 !important;
  }
`;

export const CarouselControlStyled = styled(CarouselControl)`
  button {
    fill: #2ec9b7 !important;
    color: #2ec9b7 !important;
  }
`;

