import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Form } from '@unform/web';
import { Row, Col, Modal, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import {
  Wrapper,
  Header,
  Card,
  Content,
  ButtonCollapse,
  NumberFormatStyled,
  DataPickerAreaItem,
} from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import NfeAvulsaItem from '../../../components/NfesEntry/NfeAvulsaItem';
import { Button, Checkbox } from 'ui-kit-takeat';
import NfeAvulsaVolume from '../../../components/NfesEntry/NfeAvulsaVolume';
import { MdExpandMore } from 'react-icons/md';

import getValidationErrors from '~/services/getValidationErrors';
import * as Yup from 'yup';

import nfeInfo from '~/services/NFeInfo';
import InputCurrency from '~/components/Form/InputCurrency';
import toast from 'react-hot-toast';
import history from '~/services/history';
import BackButton from '~/components/Buttons/BackButton';
import InputMask from 'react-input-mask';

export default function NotaAvulsa() {
  const { user } = useAuth();

  const [loadingNfe, setLoadingNfe] = useState(false);
  const [mensagemSefaz, setMensagemSefaz] = useState('');
  const [erros, setErros] = useState([]);

  const formRef = useRef(null);
  const formRefDev = useRef(null);

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const icms_s_t_devolucao =
    user.regime_tributario === '1'
      ? nfeInfo.csosn.map((icms) => ({
          value: icms.csosn,
          label: `${icms.csosn} - ${icms.description}`,
        }))
      : nfeInfo.cst.map((icms) => ({
          value: icms.cst,
          label: icms.label,
        }));

  const [modalItem, setModalItem] = useState(false);
  function toggleItem() {
    setModalItem(!modalItem);
  }

  const [modalVolume, setModalVolume] = useState(false);
  function toggleVolume() {
    setModalVolume(!modalVolume);
  }

  const localDestinoOptions = [
    { label: '1 – Operação interna', value: '1' },
    { label: '2 – Operação interestadual', value: '2' },
    { label: '3 – Operação com exterior', value: '3' },
  ];

  const finalidadeOptions = [
    { label: '1 – Normal', value: '1' },
    { label: '2 – Complementar', value: '2' },
    { label: '3 – Nota de ajuste', value: '3' },
    { label: '4 – Devolução', value: '4' },
  ];

  const tipoOptions = [
    { label: '0 – Nota Fiscal de Entrada', value: '0' },
    { label: '1 – Nota Fiscal de Saída', value: '1' },
  ];

  const consumidorOptions = [
    { label: '0 – Normal', value: '0' },
    { label: '1 – Consumidor final', value: '1' },
  ];

  const modalidadeOptions = [
    { label: '0 – Por conta do emitente', value: '0' },
    { label: '1 – Por conta do destinatário', value: '1' },
    { label: '2 – Por conta de terceiros', value: '2' },
    { label: '9 – Sem frete', value: '3' },
  ];

  const presencaOptions = [
    {
      label:
        '0 – Não se aplica (por exemplo, para a Nota Fiscal complementar ou de ajuste)',
      value: '0',
    },
    { label: '1 – Operação presencial', value: '1' },
    { label: '2 – Operação não presencial, pela Internet', value: '2' },
    { label: '3 – Operação não presencial, Teleatendimento', value: '3' },
    { label: '4 – NFC-e em operação com entrega em domicílio', value: '4' },
    { label: '9 – Operação não presencial, outros', value: '9' },
  ];

  const indicadorOptions = [
    {
      label: '1 – Contribuinte ICMS (informar a IE do destinatário)',
      value: '1',
    },
    {
      label:
        '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS',
      value: '2',
    },
    {
      label:
        '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS',
      value: '9',
    },
  ];

  const [docSelected, setDocSelected] = useState('cnpj');

  const [data, setData] = useState(
    localStorage.getItem('@gddashboard:formNotaAvulsa')
      ? JSON.parse(localStorage.getItem('@gddashboard:formNotaAvulsa'))
      : {
          cnpj_emitente: user?.cnpj || '',
        }
  );

  const [itens, setItens] = useState(
    localStorage.getItem('@gddashboard:itensNotaAvulsa')
      ? JSON.parse(localStorage.getItem('@gddashboard:itensNotaAvulsa'))
      : []
  );

  const [hasNotaRef, setHasNotaRef] = useState(
    data?.notas_referenciadas?.length > 0 ? true : false
  );
  const [notaRef, setNotaRef] = useState(
    (data?.notas_referenciadas?.length > 0 &&
      data?.notas_referenciadas[0]?.chave_nfe) ||
      null
  );

  const [volumes, setVolumes] = useState(
    localStorage.getItem('@gddashboard:volumesNotaAvulsa')
      ? JSON.parse(localStorage.getItem('@gddashboard:volumesNotaAvulsa'))
      : []
  );

  // useEffect(() => {
  //   console.log(itens);
  // }, [itens]);

  const [nextId, setNextId] = useState(itens.length + 1);
  const [nextVolumeId, setNextVolumeId] = useState(volumes.length + 1);

  const [isOpenDadosNota, setIsOpenDadosNota] = useState(false);
  const [isOpenDadosEmitente, setIsOpenDadosEmitente] = useState(false);
  const [isOpenDadosDestinatario, setIsOpenDadosDestinatario] = useState(false);
  const [isOpenFornecedor, setIsOpenFornecedor] = useState(false);
  const [isOpenVolume, setIsOpenVolume] = useState(volumes.length !== 0);
  const [isOpenItens, setIsOpenItens] = useState(true);

  const [isProcessing, setIsProcessing] = useState(
    data._is_processing || false
  );

  const handleDevolucao = useCallback(async () => {
    console.log(data);

    try {      
      setLoadingNfe(true);
      setMensagemSefaz('');
      setErros([]);

      if (itens.length === 0) {
        toast.error('A nota tem que conter pelo menos um item');
        setIsOpenItens(true);
        setLoadingNfe(false);
        return;
      }

      if (formRefDev.current) {
        formRefDev.current.setErrors({});
      }

      let schemaValidator = {
        natureza_operacao: Yup.string().required('Obrigatório'),
        tipo_documento: Yup.string().required('Obrigatório'),
        finalidade_emissao: Yup.string().required('Obrigatório'),
        local_destino: Yup.string().required('Obrigatório'),
        consumidor_final: Yup.string().required('Obrigatório'),
        presenca_comprador: Yup.string().required('Obrigatório'),
        modalidade_frete: Yup.string().required('Obrigatório'),

        // nome_emitente: Yup.string().required('Obrigatório'),
        cnpj_emitente: Yup.string().required('Obrigatório'),
        inscricao_estadual_emitente: Yup.string().required('Obrigatório'),
        // logradouro_emitente: Yup.string().required('Obrigatório'),
        // numero_emitente: Yup.string().required('Obrigatório'),
        // municipio_emitente: Yup.string().required('Obrigatório'),
        // uf_emitente: Yup.string().required('Obrigatório'),
        // bairro_emitente: Yup.string().required('Obrigatório'),
        // cep_emitente: Yup.string().required('Obrigatório'),

        nome_destinatario: Yup.string().required('Obrigatório'),
        // cnpj_destinatario: Yup.string().required('Obrigatório'),
        logradouro_destinatario: Yup.string().required('Obrigatório'),
        numero_destinatario: Yup.string().required('Obrigatório'),
        indicador_inscricao_estadual_destinatario:
          Yup.string().required('Obrigatório'),
        municipio_destinatario: Yup.string().required('Obrigatório'),
        uf_destinatario: Yup.string().required('Obrigatório'),
        bairro_destinatario: Yup.string().required('Obrigatório'),
        cep_destinatario: Yup.string().required('Obrigatório'),
      };

      if(docSelected === 'cnpj'){
        schemaValidator.cnpj_destinatario = Yup.string().required('Obrigatório');
      }else{
        schemaValidator.cpf_destinatario = Yup.string().required('Obrigatório');
      }

      // if(data.icms_situacao_tributaria === '00'){
      //   schemaValidator.icms_aliquota = Yup.string().required('Obrigatório');
      //   schemaValidator.icms_base_calculo = Yup.string().required('Obrigatório');
      //   schemaValidator.icms_modalidade_base_calculo = Yup.string().required('Obrigatório');
      // }

      const schema = Yup.object().shape(schemaValidator);

      // const thisItens = itens.map(dt => dt.dataValues);
      data.itens = itens;

      // const thisVolumes = volumes.map(dt => dt.dataValues);
      if (volumes.length > 0) {
        data.volumes = volumes;
      }

      if (hasNotaRef) {
        data.notas_referenciadas = [{ chave_nfe: notaRef }];
      } else {
        data.notas_referenciadas = [];
      }

      localStorage.setItem('@gddashboard:formNotaAvulsa', JSON.stringify(data));
      console.log('dataaa', data);
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post(`/restaurants/new-nfe-avulsa`, data);

      if (response.data.ref) {
        data._ref = response.data.ref;
        localStorage.setItem(
          '@gddashboard:formNotaAvulsa',
          JSON.stringify(data)
        );
      }

      if (response.data.status === 'erro_autorizacao') {
        setMensagemSefaz(response.data.mensagem);
      } else if (response.data.status === 'autorizado') {
        window.open(
          response.data.url,
          'janela',
          'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
        );
        // getNfces();
        // toggle();

        setMensagemSefaz('');
        setErros([]);
        setItens([]);
        setVolumes([]);
        setIsProcessing(false);
        setData({ cnpj_emitente: user?.cnpj || '' });
        localStorage.setItem(
          '@gddashboard:itensNotaAvulsa',
          JSON.stringify([])
        );
        localStorage.setItem(
          '@gddashboard:volumesNotaAvulsa',
          JSON.stringify([])
        );
        localStorage.setItem(
          '@gddashboard:formNotaAvulsa',
          JSON.stringify({ cnpj_emitente: user?.cnpj || '' })
        );
        history.push('/operation/fiscal/issued');
      } else {
        setMensagemSefaz(response.data?.mensagem || '');
        setErros(response?.data?.erros || []);
      }

      if (response.data.status === 'processando_autorizacao') {
        setIsProcessing(true);
        data._is_processing = true;
        localStorage.setItem(
          '@gddashboard:formNotaAvulsa',
          JSON.stringify(data)
        );
      }

      setLoadingNfe(false);
    } catch (err) {
      console.log(err);
      setLoadingNfe(false);
      if (err instanceof Yup.ValidationError) {
        setIsOpenDadosEmitente(true);
        setIsOpenDadosDestinatario(true);
        const errors = getValidationErrors(err);
        if (formRefDev.current) {
          formRefDev.current.setErrors(errors);
        }
      }
    }
  }, [itens, volumes, data]);

  const handleUpdate = useCallback(async () => {
    try {
      setLoadingNfe(true);

      const response = await api.post(`/restaurants/new-nfe-avulsa`, {
        _ref: data._ref,
        _update_processing: true,
      });

      if (response.data.status === 'erro_autorizacao') {
        setMensagemSefaz(
          response.data.mensagem || response.data.mensagem_sefaz
        );
        setErros(response.data);
      } else if (response.data.status === 'autorizado') {
        window.open(
          response.data.url,
          'janela',
          'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
        );

        setMensagemSefaz('');
        setErros([]);
        setItens([]);
        setVolumes([]);
        setIsProcessing(false);
        setData({ cnpj_emitente: user?.cnpj || '' });
        localStorage.setItem(
          '@gddashboard:itensNotaAvulsa',
          JSON.stringify([])
        );
        localStorage.setItem(
          '@gddashboard:volumesNotaAvulsa',
          JSON.stringify([])
        );
        localStorage.setItem(
          '@gddashboard:formNotaAvulsa',
          JSON.stringify({ cnpj_emitente: user?.cnpj || '' })
        );
        history.push('/operation/fiscal/issued');
      } else {
        setMensagemSefaz(
          response.data?.mensagem || response.data.mensagem_sefaz || ''
        );
        setErros(response.data);
      }

      setLoadingNfe(false);
    } catch (err) {
      console.log(err);
      setLoadingNfe(false);
      setErros(err?.response?.data);
    }
  }, [data]);

  let dataItens = {};

  const validateItem = useCallback(
    async (data) => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        //o dataItens sao os itens que usa o InputCurrency com formato
        //o que ta no "data" sao os outros valores que usam o Input
        data.valor_bruto = dataItens.valor_bruto || null;
        data.valor_frete = dataItens.valor_frete || null;
        data.valor_desconto = dataItens.valor_desconto || null;
        data.valor_unitario_comercial =
          dataItens.valor_unitario_comercial || null;
        data.quantidade_comercial = dataItens.quantidade_comercial || null;
        data.valor_unitario_tributavel =
          dataItens.valor_unitario_tributavel || null;
        data.quantidade_tributavel = dataItens.quantidade_tributavel || null;
        data.icms_base_calculo = dataItens.icms_base_calculo || null;
        data.icms_aliquota = dataItens.icms_aliquota || null;
        data.icms_valor = dataItens.icms_valor || null;
        data.pis_aliquota_porcentual =
          dataItens.pis_aliquota_porcentual || null;
        data.pis_base_calculo = dataItens.pis_base_calculo || null;
        data.pis_valor = dataItens.pis_valor || null;
        data.cofins_aliquota_porcentual =
          dataItens.cofins_aliquota_porcentual || null;
        data.cofins_base_calculo = dataItens.cofins_base_calculo || null;
        data.cofins_valor = dataItens.cofins_valor || null;

        console.log(data);

        const schemaValidator = {
          descricao: Yup.string().required('Obrigatório'),
          codigo_ncm: Yup.string().required('Obrigatório'),
          cfop: Yup.string().required('Obrigatório'),
          codigo_produto: Yup.string().required('Obrigatório'),
          quantidade_comercial: Yup.string().required('Obrigatório'),
          quantidade_tributavel: Yup.string().required('Obrigatório'),
          icms_situacao_tributaria: Yup.string().required('Obrigatório'),
          valor_unitario_comercial: Yup.string().required('Obrigatório'),
          unidade_comercial: Yup.string().required('Obrigatório'),
          valor_unitario_tributavel: Yup.string().required('Obrigatório'),
          unidade_tributavel: Yup.string().required('Obrigatório'),
          valor_bruto: Yup.string().required('Obrigatório'),
          icms_origem: Yup.string().required('Obrigatório'),
          icms_situacao_tributaria: Yup.string().required('Obrigatório'),
          pis_situacao_tributaria: Yup.string().required('Obrigatório'),
          cofins_situacao_tributaria: Yup.string().required('Obrigatório'),
        };

        if (data.icms_situacao_tributaria === '00') {
          schemaValidator.icms_aliquota = Yup.string().required('Obrigatório');
          schemaValidator.icms_base_calculo =
            Yup.string().required('Obrigatório');
          schemaValidator.icms_modalidade_base_calculo =
            Yup.string().required('Obrigatório');
        }

        const schema = Yup.object().shape(schemaValidator);

        await schema.validate(data, {
          abortEarly: false,
        });

        data.numero_item = nextId;
        const newItens = [...itens, data];

        setItens(newItens);

        localStorage.setItem(
          '@gddashboard:itensNotaAvulsa',
          JSON.stringify(newItens)
        );

        setNextId(nextId + 1);
        toggleItem();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef.current) {
            formRef.current.setErrors(errors);
          }
        }
      }
    },
    [itens, setItens, toggleItem, dataItens]
  );

  const validateVolume = useCallback(
    async (data) => {
      try {
        console.log(data);

        data._numero_volume = nextVolumeId;
        const newItens = [...volumes, data];

        setVolumes(newItens);

        localStorage.setItem(
          '@gddashboard:volumesNotaAvulsa',
          JSON.stringify(newItens)
        );

        setNextVolumeId(nextVolumeId + 1);
        toggleVolume();
      } catch (error) {
        console.log(error);
      }
    },
    [volumes, setVolumes, toggleVolume]
  );

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  function decimalFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount.toString().replace('R$ ', '')}`;
  }

  function percentFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
    }).format(value / 100);

    return `${amount}%`;
  }

  const [cnpjDest, setCnpjDest] = useState('');

  const getClientInfo = useCallback(async () => {
    try {
      const cnpj = cnpjDest
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      const response = await api.get(`restaurants/nfe-client/${cnpj}`);

      if (response.data) {
        // setFormData({
        //   ...formData,
        //   nome_destinatario: response.data.nome,
        //   logradouro_destinatario: response.data.logradouro,
        //   numero_destinatario: response.data.numero,
        //   bairro_destinatario: response.data.bairro,
        //   municipio_destinatario: response.data.municipio,
        //   uf_destinatario: response.data.uf,
        //   indicador_inscricao_estadual_destinatario:
        //     response.data.indicador_inscricao_estadual || '9',
        //   inscricao_estadual_destinatario: response.data.inscricao_estadual,
        //   local_destino: response.data.local_destino
        //     ? parseInt(response.data.local_destino)
        //     : 1,
        //   email: response.data.email,
        // });
        formRefDev.current.setFieldValue(
          'nome_destinatario',
          response.data.nome || ''
        );
        formRefDev.current.setFieldValue(
          'logradouro_destinatario',
          response.data.logradouro || ''
        );
        formRefDev.current.setFieldValue(
          'numero_destinatario',
          response.data.numero || ''
        );
        formRefDev.current.setFieldValue(
          'bairro_destinatario',
          response.data.bairro || ''
        );
        formRefDev.current.setFieldValue(
          'municipio_destinatario',
          response.data.municipio || ''
        );
        formRefDev.current.setFieldValue(
          'uf_destinatario',
          response.data.uf || ''
        );
        console.log('>>><', data);
        const thisData = {
          ...data,
          nome_destinatario: response.data.nome || '',
          logradouro_destinatario: response.data.logradouro || '',
          numero_destinatario: response.data.numero || '',
          bairro_destinatario: response.data.bairro || '',
          municipio_destinatario: response.data.municipio || '',
          uf_destinatario: response.data.uf || '',
        };
        console.log('>>>', thisData);
        setData(thisData);
      }
    } catch (error) {
      console.log(error);
    }
  }, [cnpjDest]);

  useEffect(() => {
    if (cnpjDest.length === 18 && cnpjDest[17] !== '_') {
      getClientInfo();
    }
  }, [cnpjDest]);

  return (
    <Wrapper>
      <Header>
        <h4>Emissão de Nota Avulsa</h4>
        <BackButton onClick={() => history.push('/operation/fiscal/issued')} />
      </Header>

      <Content>
        <Card>
          <Form initialData={data} ref={formRefDev} onSubmit={handleDevolucao}>
            {/* Cabeçalho */}
            {/* Dados do Emitente */}
            <ButtonCollapse
              onClick={() => setIsOpenDadosNota(!isOpenDadosNota)}
              style={{ fontSize: 18 }}
            >
              Dados da Nota
            </ButtonCollapse>
            <div>
              <Row>
                <Col md="8">
                  <Input
                    name="natureza_operacao"
                    label="Natureza da Operação"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.natureza_operacao = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Select
                    name="tipo_documento"
                    options={tipoOptions}
                    label="Tipo Documento"
                    defaultValue={
                      data.tipo_documento &&
                      tipoOptions.find(
                        (tipo) => tipo.value === data.tipo_documento
                      )
                    }
                    onChange={(e) => {
                      let thisData = data;
                      thisData.tipo_documento = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Select
                    name="finalidade_emissao"
                    options={finalidadeOptions}
                    label="Finalidade Emissão"
                    defaultValue={
                      data.finalidade_emissao &&
                      finalidadeOptions.find(
                        (tipo) => tipo.value === data.finalidade_emissao
                      )
                    }
                    onChange={(e) => {
                      let thisData = data;
                      thisData.finalidade_emissao = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="6">
                  <Select
                    name="local_destino"
                    options={localDestinoOptions}
                    defaultValue={
                      data.local_destino &&
                      localDestinoOptions.find(
                        (tipo) => tipo.value === data.local_destino
                      )
                    }
                    label="Local Destino"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.local_destino = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Select
                    name="consumidor_final"
                    options={consumidorOptions}
                    defaultValue={
                      data.consumidor_final &&
                      consumidorOptions.find(
                        (tipo) => tipo.value === data.consumidor_final
                      )
                    }
                    label="Consumidor Final"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.consumidor_final = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Select
                    name="presenca_comprador"
                    options={presencaOptions}
                    defaultValue={
                      data.presenca_comprador &&
                      presencaOptions.find(
                        (tipo) => tipo.value === data.presenca_comprador
                      )
                    }
                    label="Presença Comprador"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.presenca_comprador = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Select
                    name="modalidade_frete"
                    options={modalidadeOptions}
                    defaultValue={
                      data.modalidade_frete &&
                      modalidadeOptions.find(
                        (tipo) => tipo.value === data.modalidade_frete
                      )
                    }
                    label="Modalidade Frete"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.modalidade_frete = e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 6 }}>
                <Col md="12">
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      // label="Referenciar nota"
                      name="has_nota_ref"
                      checked={hasNotaRef}
                      onClick={() => setHasNotaRef(!hasNotaRef)}
                      style={{ fontSize: 10 }}
                    />
                    <label style={{ marginTop: 6 }}>Referenciar Nota</label>
                  </div>
                </Col>
              </Row>

              {hasNotaRef && (
                <Row>
                  <Col md="12">
                    <Input
                      name="nota_ref"
                      type="text"
                      onChange={(e) => {
                        setNotaRef(e.target.value);
                      }}
                      placeholder="Chave da Nota"
                      value={notaRef}
                    />
                  </Col>
                </Row>
              )}

              {/* Observações da Nota */}
              <Row>
                <Col md="12">
                  <Input
                    name="informacoes_adicionais_contribuinte"
                    label="Observações da Nota"
                    type="text"
                    fontSize={{ fontSize: 12 }}
                    onChange={(e) => {
                      let thisData = data;
                      thisData.informacoes_adicionais_contribuinte =
                        e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>
            </div>

            {/* Dados do Emitente */}
            <ButtonCollapse
              onClick={() => setIsOpenDadosEmitente(!isOpenDadosEmitente)}
              style={{ marginTop: 20, fontSize: 18 }}
            >
              <MdExpandMore size={20} title="Mostrar mais" />
              Dados do Emitente
            </ButtonCollapse>

            <Collapse isOpen={isOpenDadosEmitente}>
              <Row>
                <Col md="6">
                  <Input
                    name="cnpj_emitente"
                    label="CNPJ"
                    type="text"
                    defaultValue={data.cnpj_emitente}
                    onChange={(e) => {
                      let thisData = data;
                      thisData.cnpj_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>

                <Col md="6">
                  <Input
                    name="inscricao_estadual_emitente"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.inscricao_estadual_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>
              <>
                {/* <Row>
                <Col md="6">
                  <Input
                    name="nome_emitente"
                    label="Razão Social"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.nome_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="cnpj_emitente"
                    label="CNPJ"
                    type="text"
                    defaultValue={data.cnpj_emitente}
                    onChange={e => {
                      let thisData = data;
                      thisData.cnpj_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="inscricao_estadual_emitente"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.inscricao_estadual_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="7">
                  <Input
                    name="logradouro_emitente"
                    label="Logradouro"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.logradouro_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="numero_emitente"
                    label="Nº"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.numero_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="complemento_emitente"
                    label="Complemento"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.complemento_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Input
                    name="municipio_emitente"
                    label="Município"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.municipio_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="uf_emitente"
                    label="UF"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.uf_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Input
                    name="bairro_emitente"
                    label="Bairro"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.bairro_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="cep_emitente"
                    label="CEP"
                    type="text"
                    onChange={e => {
                      let thisData = data;
                      thisData.cep_emitente = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row> */}
              </>
            </Collapse>

            {/* Dados do Destinatário */}
            <ButtonCollapse
              onClick={() =>
                setIsOpenDadosDestinatario(!isOpenDadosDestinatario)
              }
              style={{ marginTop: 15, fontSize: 18 }}
            >
              <MdExpandMore size={20} title="Mostrar mais" />
              Dados do Destinatário
            </ButtonCollapse>

            <Collapse isOpen={isOpenDadosDestinatario}>
              <Row>
                <Col md="4">
                  <Input
                    name="nome_destinatario"
                    label="Razão Social"
                    type="text"
                    // value={data.nome_destinatario}
                    onChange={(e) => {
                      let thisData = data;
                      thisData.nome_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>

                <Col md="2">
                  <div style={{ marginTop: 13 }}>
                    <Select
                      label="Documento:"
                      name="doc"
                      options={[{ value: 'cnpj', label: 'CNPJ' }, { value: 'cpf', label: 'CPF' }]}
                      defaultValue={
                        { value: 'cnpj', label: 'CNPJ' }
                      }
                      onChange={(e) => {
                        setDocSelected(e.value);
                        let thisData = data;
                        if(thisData.cpf_destinatario && e.value === 'cnpj'){
                          thisData.cnpj_destinatario = thisData.cpf_destinatario;
                          thisData.cpf_destinatario = null;
                        }

                        if(thisData.cnpj_destinatario && e.value === 'cpf'){
                          thisData.cpf_destinatario = thisData.cnpj_destinatario;
                          thisData.cnpj_destinatario = null;
                        }

                        setData(thisData);
                      }}
                    />
                  </div>
                </Col>

                {docSelected === 'cpf' ? (
                  <Col md="3">
                    <InputMask 
                      mask="999.999.999-99" 
                      onChange={e => {
                        let thisData = data;
                        thisData.cpf_destinatario = e.target.value;
                        thisData.cnpj_destinatario = null;
                        setData(thisData);
                      }}
                    >
                      <Input
                        label="CPF:"
                        name="cpf_destinatario"
                        type="text"
                        placeholder="xxx.xxx.xxx-xx"

                      />
                    </InputMask>
                  </Col>
                ) : (
                  <Col md="3">
                    <InputMask 
                      mask="99.999.999/9999-99"
                      onChange={e => {
                        let thisData = data;
                        thisData.cnpj_destinatario = e.target.value;
                        thisData.cpf_destinatario = null;
                        setData(thisData);
                        setCnpjDest(e.target.value);
                      }}
                    >
                      <Input
                        label="CNPJ:"
                        name="cnpj_destinatario"
                        type="text"
                        placeholder="xx.xxx.xxx/xxxx-xx"
                      />
                    </InputMask>
                  </Col>
                )}

                {/* <Col md="3">
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    onChange={(e) => {
                      let thisData = data;
                      thisData.cnpj_destinatario = e.target.value;
                      setData(thisData);
                      setCnpjDest(e.target.value);
                    }}
                  >
                    <Input
                      name="cnpj_destinatario"
                      label="CNPJ"
                      type="text"
                      // onChange={e => {
                      //   let thisData = data;
                      //   thisData.cnpj_destinatario = e.target.value;
                      //   setData(thisData);
                      // }}
                    />
                  </InputMask>
                </Col> */}

                <Col md="3">
                  <Input
                    name="inscricao_estadual_destinatario"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.inscricao_estadual_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Input
                    name="logradouro_destinatario"
                    label="Logradouro"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.logradouro_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="numero_destinatario"
                    label="Nº"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.numero_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="complemento_destinatario"
                    label="Complemento"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.complemento_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Select
                    name="indicador_inscricao_estadual_destinatario"
                    options={indicadorOptions}
                    defaultValue={
                      data.indicador_inscricao_estadual_destinatario &&
                      indicadorOptions.find(
                        (tipo) =>
                          tipo.value ===
                          data.indicador_inscricao_estadual_destinatario
                      )
                    }
                    label="Indicador Inscrição Estadual"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.indicador_inscricao_estadual_destinatario =
                        e.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Input
                    name="municipio_destinatario"
                    label="Município"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.municipio_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="uf_destinatario"
                    label="UF"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.uf_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Input
                    name="bairro_destinatario"
                    label="Bairro"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.bairro_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="cep_destinatario"
                    label="CEP"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.cep_destinatario = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>
            </Collapse>

            {/* Fornecedor */}
            <ButtonCollapse
              onClick={() => setIsOpenFornecedor(!isOpenFornecedor)}
              style={{ marginTop: 15, fontSize: 18 }}
            >
              <MdExpandMore size={20} title="Mostrar mais" />
              Fornecedor
            </ButtonCollapse>

            <Collapse isOpen={isOpenFornecedor}>
              <Row>
                <Col md="6">
                  <Input
                    name="nome_transportador"
                    label="Razão Social"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.nome_transportador = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="cnpj_transportador"
                    label="CNPJ"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.cnpj_transportador = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="3">
                  <Input
                    name="inscricao_estadual_transportador"
                    label="Inscrição Estadual"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.inscricao_estadual_transportador =
                        e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Input
                    name="endereco_transportador"
                    label="Endereço"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.endereco_transportador = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="4">
                  <Input
                    name="municipio_transportador"
                    label="Município"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.municipio_transportador = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
                <Col md="2">
                  <Input
                    name="uf_transportador"
                    label="UF"
                    type="text"
                    onChange={(e) => {
                      let thisData = data;
                      thisData.uf_transportador = e.target.value;
                      setData(thisData);
                    }}
                  />
                </Col>
              </Row>
            </Collapse>

            {/* Volumes */}
            <ButtonCollapse
              onClick={() => setIsOpenVolume(!isOpenVolume)}
              style={{ marginTop: 15, fontSize: 18 }}
            >
              <MdExpandMore size={20} title="Mostrar mais" />
              Volumes
            </ButtonCollapse>

            <Collapse isOpen={isOpenVolume}>
              <Row>
                <Col>
                  <button
                    name="add_volume"
                    onClick={(e) => {
                      // setVolumes([...volumes, {numero_item: nextVolumeId }]);
                      // setNextVolumeId(nextVolumeId + 1);
                      toggleVolume();
                    }}
                    type="button"
                    style={{
                      borderRadius: '25px',
                      height: 30,
                      border: 'none',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      background: '#2AB7A6',
                      color: 'white',
                      textAlign: 'center',
                      marginTop: 5,
                    }}
                  >
                    {' '}
                    + Adicionar volume
                  </button>
                </Col>
              </Row>

              {volumes.map((item, key) => (
                <NfeAvulsaVolume
                  number={key}
                  item={item}
                  itens={volumes}
                  setItens={setVolumes}
                  nextVolumeId={nextVolumeId}
                  setNextVolumeId={setNextVolumeId}
                />
              ))}
            </Collapse>

            {/* Itens */}
            <ButtonCollapse
              onClick={() => setIsOpenItens(!isOpenItens)}
              style={{ marginTop: 15, fontSize: 18 }}
            >
              <MdExpandMore size={20} title="Mostrar mais" />
              Itens
            </ButtonCollapse>

            <Collapse isOpen={isOpenItens}>
              <Row>
                <Col>
                  <button
                    name="add_item"
                    onClick={(e) => {
                      // setItens([...itens, {numero_item: nextId }]);
                      // setNextId(nextId + 1);
                      toggleItem();
                    }}
                    type="button"
                    style={{
                      borderRadius: '25px',
                      height: 30,
                      border: 'none',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      background: '#2AB7A6',
                      color: 'white',
                      textAlign: 'center',
                      marginTop: 5,
                    }}
                  >
                    {' '}
                    + Adicionar item
                  </button>
                </Col>
              </Row>

              {itens.map((item, key) => (
                <NfeAvulsaItem
                  key={key + 1}
                  number={key}
                  item={item}
                  itens={itens}
                  setItens={setItens}
                  nextId={nextId}
                  setNextId={setNextId}
                  decimalFormatter={decimalFormatter}
                  percentFormatter={percentFormatter}
                  currencyFormatter={currencyFormatter}
                  icms_s_t_devolucao={icms_s_t_devolucao}
                  icms_origem_options={icms_origem_options}
                  user={user}
                />
              ))}
            </Collapse>
          </Form>

          <Row style={{ marginTop: 15 }}>
            <Col md="8">
              {mensagemSefaz !== '' && (
                <div style={{ marginTop: 10 }}>
                  <p style={{ color: 'red' }}>
                    <strong>Mensagem SEFAZ:</strong> {mensagemSefaz}
                  </p>
                </div>
              )}

              {erros.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  {erros.map((erro) => (
                    <p style={{ color: 'red' }}>- {erro.mensagem}</p>
                  ))}
                </div>
              )}

              {data._ref && (
                <div>
                  <p>Referência: {data._ref}</p>
                </div>
              )}
            </Col>
            <Col
              md="4"
              style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <Button
                type="button"
                onClick={handleDevolucao}
                disabled={loadingNfe}
                buttonColor="#229688"
                title={loadingNfe ? 'Aguarde...' : 'Emitir Nota'}
              />

              {isProcessing && (
                <Button
                  type="button"
                  onClick={handleUpdate}
                  disabled={loadingNfe}
                  buttonColor="#2EC9B7"
                  title={loadingNfe ? 'Aguarde...' : 'Atualizar status'}
                  style={{ marginRight: 10 }}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Content>

      <Modal isOpen={modalItem} toggle={toggleItem} size="xl">
        <Form onSubmit={validateItem} ref={formRef}>
          <ModalBody>
            Item {nextId}
            <Row>
              <Col md="2">
                <Input
                  name="codigo_produto"
                  label="Código do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="descricao"
                  label="Descrição / Nome do Produto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="codigo_ncm"
                  label="NCM"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cfop"
                  label="CFOP"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>
            {/* QTD comercial | Valor unit. Comercial | UN comercial | QTD tributável | Valor unit. Tributável | UN tributável */}
            <Row>
              <Col md="2">
                <InputCurrency
                  name="quantidade_comercial"
                  label="Quantidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  format={decimalFormatter}
                  onValueChange={(e) => {
                    dataItens.quantidade_comercial =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_comercial"
                  label="Valor Unit. Comercial"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.valor_unitario_comercial =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_comercial"
                  label="Unidade Comercial"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="quantidade_tributavel"
                  label="Quantidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  format={decimalFormatter}
                  onValueChange={(e) => {
                    dataItens.quantidade_tributavel =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_unitario_tributavel"
                  label="Valor Unit. Tributável"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.valor_unitario_tributavel =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <Input
                  name="unidade_tributavel"
                  label="Unidade Tributável"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>
            {/* Valor bruto | Valor Desconto | Valor Frete |Origem Mercadoria | CEST */}
            <Row>
              <Col md="2">
                <InputCurrency
                  prefix="R$"
                  label="Valor Bruto"
                  placeholder="R$"
                  format={currencyFormatter}
                  name="valor_bruto"
                  fontSize={{ fontSize: 12 }}
                  onValueChange={(e) => {
                    dataItens.valor_bruto =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_desconto"
                  label="Valor Desconto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.valor_desconto =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="valor_frete"
                  label="Valor Frete"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  prefix="R$"
                  placeholder="R$"
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.valor_frete =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
              {/* 
              <Col md="2">
                <Input
                  name="cest"
                  label="CEST"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col> */}

              <Col md="2">
                <Input
                  name="codigo_beneficio_fiscal"
                  label="cBenef"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Select
                  name="icms_situacao_tributaria"
                  options={icms_s_t_devolucao}
                  label={`ICMS Situação Tributária (${
                    user.regime_tributario === '1' ? 'CSOSN' : 'CST'
                  })`}
                />
              </Col>
            </Row>
            {/* ICMS */}
            <Row>
              <Col md="3">
                <Select
                  name="icms_origem"
                  options={icms_origem_options}
                  label="ICMS de Origem"
                />
              </Col>

              <Col md="3">
                <Select
                  name="icms_modalidade_base_calculo"
                  options={nfeInfo.icms_modalidade_base_calculo}
                  label="ICMS Mod. B. Calc."
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_base_calculo"
                  label="ICMS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.icms_base_calculo =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_aliquota"
                  label="ICMS Alíquota"
                  type="text"
                  placeholder="%"
                  suffix="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItens.icms_aliquota =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="2">
                <InputCurrency
                  name="icms_valor"
                  label="ICMS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.icms_valor =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
            {/* PIS */}
            <Row>
              <Col md="3">
                <Select
                  name="pis_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="PIS Situação Trib."
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_base_calculo"
                  label="PIS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.pis_base_calculo =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_aliquota_porcentual"
                  label="PIS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItens.pis_aliquota_porcentual =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="pis_valor"
                  label="PIS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.pis_valor =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
            {/* COFINS */}
            <Row>
              <Col md="3">
                <Select
                  name="cofins_situacao_tributaria"
                  options={nfeInfo.pis_situacao_tributaria}
                  label="COFINS Situação Trib."
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_base_calculo"
                  label="COFINS Base Cálculo"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.cofins_base_calculo =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_aliquota_porcentual"
                  label="COFINS Alíquota"
                  type="text"
                  placeholder="%"
                  fontSize={{ fontSize: 12 }}
                  format={percentFormatter}
                  onValueChange={(e) => {
                    dataItens.cofins_aliquota_porcentual =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  name="cofins_valor"
                  label="COFINS Valor"
                  type="text"
                  prefix="R$"
                  placeholder="R$"
                  fontSize={{ fontSize: 12 }}
                  format={currencyFormatter}
                  onValueChange={(e) => {
                    dataItens.cofins_valor =
                      e.floatValue > 0 ? e.floatValue / 100 : null;
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              id="close_modal"
              type="button"
              onClick={toggleItem}
              style={{ background: '#dc3545' }}
              title="Cancelar"
            />
            <Button
              type="submit"
              onClick={() => {}}
              buttonColor="#2ec9b7"
              title="Adicionar Item"
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalVolume} toggle={toggleVolume} size="lg">
        <Form onSubmit={validateVolume} ref={formRef}>
          <ModalBody>
            Volume {nextVolumeId}
            <Row>
              <Col md="4">
                <Input
                  name="quantidade"
                  label="Quantidade"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
              <Col md="4">
                <Input
                  name="especie"
                  label="Espécie"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Input
                  name="marca"
                  label="Marca"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  name="numero"
                  label="Número"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                />
              </Col>

              <Col md="4">
                <Input
                  name="peso_liquido"
                  label="Peso Líquido"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                  step={0.001}
                />
              </Col>

              <Col md="4">
                <Input
                  name="peso_bruto"
                  label="Peso Bruto"
                  type="number"
                  fontSize={{ fontSize: 12 }}
                  step={0.001}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              id="close_modal"
              type="button"
              onClick={toggleVolume}
              style={{ background: '#dc3545' }}
              title="Cancelar"
            />
            <Button
              type="submit"
              onClick={() => {}}
              buttonColor="#2ec9b7"
              title="Adicionar Volume"
            />
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}
