import React, { useState } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Users from './Users';
import Tables from './Tables';
import Header from './Header';
import StoneDevices from './StoneDevices';
import Waiter from './Waiter';
import { Content } from './styles';

function RegistersPage() {
  return (
    <>
      <Header />

      <Content>
        <Switch>
          <Route path="/register/tables" component={Tables} />
          <Route path="/register/users" component={Users} />
          <Route path="/register/waiters" component={Waiter} />
          <Route path="/register/stone-devices" component={StoneDevices} />
        </Switch>
      </Content>
    </>
  );
}

export default RegistersPage;
