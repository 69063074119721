/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import Switch from 'react-switch';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import { ButtonsArea, InnerArea, AreaSwitcher, Container, StyledForm } from './styles';

import api from '~/services/api';
import englishFlag from "~/assets/img/englishFlag.png"
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useAuth } from '~/context/AuthContext';
import { complement } from 'polished';
import { PersonalizedHoursContainer } from '../../Edit/styles';
import { Button, DefaultInput } from 'ui-kit-takeat';
import { format } from 'date-fns';
import EnglishMenuButton from '~/components/Buttons/EnglishMenuButton';
import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
import { FaArrowLeft } from 'react-icons/fa';
import ReactModal from 'react-modal';

function EditComplementCategoryInsideProduct({
  toggle,
  complementCategory,
  setComplementsCategories,
  complementCategories,
  complementCategoriesProduct,
  setComplementCategoriesInsideProduct,
}) {

  const { user } = useAuth();
  const [complements, setComplements] = useState([]);
  const formRef = useRef(null);
  const initialData = {
    name: complementCategory?.name,
    question: complementCategory?.question,
    minimum: complementCategory?.minimum || 1,
    limit: complementCategory?.limit,
    available: complementCategory?.available,
    optional: complementCategory?.optional,
    single_choice: complementCategory?.single_choice,
    additional: complementCategory?.additional,
    more_expensive_only: complementCategory?.more_expensive_only,
    use_average: complementCategory?.use_average,
    enable_times: complementCategory?.enable_times,
  };
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const initialComplements = complementCategory?.complements?.map(
    (complement) => ({
      value: complement.id,
      label: complement.name,
    })
  );

  const [enableTimes, setEnableTimes] = useState(
    complementCategory?.enable_times
  );
  const [daysSelected, setDaysSelected] = useState(
    complementCategory?.active_days.split('')
  );
  const [hourSelected, setHourSelected] = useState({
    start: format(new Date(complementCategory?.start_time), 'HH:mm'),
    end: format(new Date(complementCategory?.end_time), 'HH:mm'),
  });

  const handleChangeDay = (idx) => {
    const updatedDays = [...daysSelected];
    updatedDays[idx] = updatedDays[idx] === 'f' ? 't' : 'f';
    setDaysSelected(updatedDays);
  };

  const handleTimeInput = (time) => {
    let validTime = '';
    const array = time.split('');

    array.forEach((element, i) => {
      if (i === 0 && element > 2) {
        validTime += '2';
      } else if (i === 1 && element > 3 && array[0] > 1) {
        validTime += '3';
      } else if (i === 3 && element > 5) {
        validTime += '5';
      } else {
        validTime += element;
      }
      return;
    });

    return validTime;
  };

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements, complementCategory.id]);

  const [availableCategory, setAvailableCategory] = useState(
    complementCategory?.available
  );

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] = useState(
    complementCategory?.available_in_delivery
  );

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Máximo obrigatório'),
        minimum: Yup.string().required('Mínimo obrigatório'),
        question: Yup.string().required('Pergunta obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(
        `restaurants/product/complement-category/${complementCategory.id}`,
        {
          name: data.name,
          question: data.question,
          limit: data.limit,
          minimum: Number(data.minimum),
          single_choice: false,
          optional: data.optional,
          available: availableCategory,
          available_in_delivery: availableDeliveryCategory,
          additional: data.additional,
          complements: data.complements,
          more_expensive_only: data.more_expensive_only,
          use_average: data.use_average,
          enable_times: enableTimes,
          active_days: daysSelected.join(''),
          start_time: new Date(`2023/01/01 ${hourSelected.start}:00`),
          end_time: new Date(`2023/01/01 ${hourSelected.end}:00`),
        }
      );

      const newCategories = [...complementCategories];

      const categoryFiltered = newCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryFiltered !== -1) {
        newCategories[categoryFiltered] = response.data;

        setComplementsCategories(newCategories);
      }

      const newComplementsCategories = [...complementCategoriesProduct];

      const categoryComplementsFiltered = newComplementsCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryComplementsFiltered !== -1) {
        newComplementsCategories[categoryComplementsFiltered] = response.data;
        setComplementCategoriesInsideProduct(newComplementsCategories);
      }

      toast.success('Categoria atualizada com sucesso!');

      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toggle();
    } catch (error) {
      console.log(error.message);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao salvar categoria');
      }
    }
  }

  const [moreExpensiveChecked, setMoreExpensiveChecked] = useState(
    complementCategory?.more_expensive_only
  );
  const [useAverageChecked, setUseAverageChecked] = useState(
    complementCategory?.use_average
  );
  //English Menu
  const [isEnglishMenuModalOpened, setIsEnglishMenuModalOpened] = useState(false)
  function toggleEnglishMenuModal() {
    setIsEnglishMenuModalOpened(!isEnglishMenuModalOpened)
  }

  const englishInitialData = {
    en_name: complementCategory?.translations?.en?.name,
    en_question: complementCategory?.translations?.en?.question,
  }

  async function handleSubmitEnglishMenu(data) {
    if (!complementCategory) {
      return;
    }

    let translations = null;

    if (data.en_name || data.en_question) {
      translations = {
        en: {
          name: data.en_name,
          question: data.en_question,
        },
      };
    }

    try {
      const response = await api.put(
        `restaurants/product/complement-category/${complementCategory.id}`,
        {
          translations,
        }
      );

      complementCategory.translations = response.data.translations;

      const newCategories = [...complementCategories];

      const categoryFiltered = newCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryFiltered !== -1) {
        newCategories[categoryFiltered].translations = response.data.translations;

        setComplementsCategories(newCategories);
      }

      const newComplementsCategories = [...complementCategoriesProduct];

      const categoryComplementsFiltered = newComplementsCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryComplementsFiltered !== -1) {
        newComplementsCategories[categoryComplementsFiltered].translations =
          response.data.translations;

        setComplementCategoriesInsideProduct(newComplementsCategories);
      }

      toast.success('Categoria atualizada com sucesso!');
      toggleEnglishMenuModal()
    } catch (error) {
      toast.error('Falha ao atualizar categoria');
      console.log(error);
    }
  }

  return (
    <Container>

      <ReactModal
        onRequestClose={toggleEnglishMenuModal}
        isOpen={isEnglishMenuModalOpened}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <InnerArea browserZoomLevel={browserZoomLevel}>
          <StyledForm
            initialData={englishInitialData}
            ref={formRef}
            onSubmit={handleSubmitEnglishMenu}
          >
            <Row >
              <span style={{ fontWeight: 'bold' }}> <img src={englishFlag} alt="Bandeira dos Estados unidos" style={{ width: 20, height: 20 }} /> Cardápio em inglês</span>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col size="md-6">
                <Input
                  label="Nome"
                  name="en_name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={complementCategory.is_multistore_child}
                />
              </Col>

              <Col size="md-6">
                <Input
                  label="Nome"
                  name="en_question"
                  type="text"
                  placeholder="Pergunta da categoria"
                  disabled={complementCategory.is_multistore_child}
                />
              </Col>
            </Row>

            <ButtonsArea>
              <PersonalOutlineButton
                color="transparent"
                fontColor="#FF2C3A"
                borderColor="#FF2C3A"
                message="Voltar"
                Icon={FaArrowLeft}
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggleEnglishMenuModal();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </InnerArea>
      </ReactModal>
      <InnerArea browserZoomLevel={browserZoomLevel}>

        <Row>
          <Col md="12" style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: 18 }}>Editar Grupo de complementos</p>
            {user?.enable_translations && (
              <EnglishMenuButton onClick={toggleEnglishMenuModal} />
            )}
          </Col>
        </Row>
        <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
          <Row>
            <Col sm="12" lg="6">
              <Input
                label="Nome"
                name="name"
                type="text"
                placeholder="Nome da categoria"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Mínimo"
                name="minimum"
                type="number"
                placeholder="1"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Máximo"
                name="limit"
                type="number"
                placeholder="1"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Input
                label="Pergunta"
                name="question"
                type="text"
                placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
          </Row>
          <Row>
            <Select
              name="complements"
              isMulti
              options={complements}
              label="Associar complementos à categoria"
              defaultValue={initialComplements}
              disabled={complementCategory.is_multistore_child}
            />
          </Row>
          <Row>
            {!user.only_delivery && (
              <Col md="3">
                {/* <CheckboxInput
                defaultChecked
                label="Disponível no presencial"
                name="available"
              /> */}

                <AreaSwitcher title="Disponível">
                  <Switch
                    onChange={handleChangeAvailableCategory}
                    draggable
                    checked={availableCategory}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <span>PDV</span>
                </AreaSwitcher>
              </Col>
            )}

            {user?.delivery_info?.is_delivery_allowed && (
              <Col md="3">
                <AreaSwitcher title="Disponível no Delivery">
                  <Switch
                    onChange={handleChangeAvailableDeliveryCategory}
                    draggable
                    checked={availableDeliveryCategory}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                  <span>Delivery</span>
                </AreaSwitcher>
              </Col>
            )}
          </Row>
          <Row className="mt-2">
            <Col md="4">
              <CheckboxInput
                defaultChecked={complementCategory?.additional}
                label="Adicional"
                name="additional"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>

            <Col md="4">
              <CheckboxInput
                defaultChecked={complementCategory?.optional}
                label="Opcional"
                name="optional"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
            {/* 
            <Col md="3">
              <CheckboxInput
                defaultChecked={complementCategory?.single_choice}
                label="Escolha única"
                name="single_choice"
                disabled={complementCategory.is_multistore_child}
              />
            </Col> */}
          </Row>
          <Row>
            <Col sm="12" md="4">
              <CheckboxInput
                label="Cobrar apenas o mais caro"
                name="more_expensive_only"
                onChange={() => setMoreExpensiveChecked(!moreExpensiveChecked)}
                disabled={
                  useAverageChecked || complementCategory.is_multistore_child
                }
              />
            </Col>

            <Col sm="12" md="4">
              <CheckboxInput
                label="Cobrar a média do preço"
                name="use_average"
                onChange={() => setUseAverageChecked(!useAverageChecked)}
                disabled={
                  moreExpensiveChecked || complementCategory.is_multistore_child
                }
              />
            </Col>

            <Col sm="12" md="4">
              <CheckboxInput
                label="Horário personalizado"
                name="enable_times"
                onChange={() => setEnableTimes(!enableTimes)}
                value={enableTimes}
              />
            </Col>
          </Row>

          {enableTimes && (
            <PersonalizedHoursContainer>
              <div>
                <span style={{ fontWeight: 'bold' }}>Dias:</span>
                <div style={{ display: 'flex', gap: 5 }}>
                  <Button
                    type="button"
                    title="Dom"
                    buttonColor="#2ec9b7"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[0] === 'f' ? 0.5 : 1,
                    }}
                    onClick={() => {
                      handleChangeDay(0);
                    }}
                  />
                  <Button
                    type="button"
                    title="Seg"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[1] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(1);
                    }}
                  />
                  <Button
                    type="button"
                    title="Ter"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[2] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(2);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qua"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[3] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(3);
                    }}
                  />
                  <Button
                    type="button"
                    title="Qui"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[4] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(4);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sex"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[5] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(5);
                    }}
                  />
                  <Button
                    type="button"
                    title="Sáb"
                    containerStyles={{
                      width: 60,
                      height: 30,
                      opacity: daysSelected[6] === 'f' ? 0.5 : 1,
                    }}
                    buttonColor="#2ec9b7"
                    onClick={() => {
                      handleChangeDay(6);
                    }}
                  />
                </div>
              </div>
              <div>
                <span style={{ fontWeight: 'bold' }}>Horários:</span>
                <div
                  style={{
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center',
                  }}
                >
                  <span>das</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '74px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    value={hourSelected.start}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: handleTimeInput(value),
                          end: state.end,
                        };
                      });
                    }}
                  />
                  <span>às</span>
                  <DefaultInput
                    placeholder="__:__"
                    mask="99:99"
                    maskChar="0"
                    style={{
                      width: '74px',
                      padding: '0px 10px',
                      height: '30px',
                    }}
                    value={hourSelected.end}
                    onChange={(e) => {
                      const value = e.target.value;
                      setHourSelected((state) => {
                        return {
                          start: state.start,
                          end: handleTimeInput(value),
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </PersonalizedHoursContainer>
          )}

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              style={{
                marginLeft: 0,
              }}
              onClick={() => {
                toggle();
              }}
            />

            <PersonalButton
              color="#0CCAB4"
              message="Salvar"
              type="submit"
              style={{
                marginRight: 10,
              }}
            />
          </ButtonsArea>
        </Form>
      </InnerArea>
    </Container>
  );
}

export default EditComplementCategoryInsideProduct;
