import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 377px;

  border-radius: 5px;
  background: #fff;
  border: 2px solid #d9d9d9;
  position: relative;
`;

export const Orders = styled.div`
  height: 300px;

  background: #fff;

  overflow-y: auto;
  padding-bottom: 48px;
`;

export const Header = styled.div`
  background: #d9d9d9;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`;

export const BuyerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: 2px solid #d9d9d9;
  background: #f4f4f4;

  ${(props) =>
    props.isProductLate &&
    css`
      background: #ffa814;
    `}
`;

export const OrdersInfo = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: 1px solid #d9d9d9;
`;

export const ConfirmButton = styled.button`
  width: 100%;
  height: 48px;
  background: #01afff;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 5px 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;

  ${(props) =>
    props.status === 'doing' &&
    css`
      background: #0ccab4;
    `}

  ${(props) =>
    props.status === 'canceled' &&
    css`
      background: #fe1933;
    `}

  ${(props) =>
    props.status === 'alter' &&
    css`
      background: #ccbbed;
    `}
`;

export const ExpandButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  position: absolute;
  bottom: 48px;
  border-radius: 0px 0px 5px 5px;
  border: none;
  color: #3d3d3d;
  font-size: 14px;
  font-weight: bold;
`;
