import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 10px 10px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const NoImage = styled.div`
  width: 80px;
  height: 80px;
  background: #33333330;
  border-radius: 8px;

  @media (max-width: 1025px){
      width: 50px;
  height: 50px;
  }
`;

export const SetSliceSizeButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #ff2c3a;
  font-size: 14px;

  margin-bottom: 10px;
  margin-left: 40px;
  float: left;
  font-weight: bold;
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 10px 5px 10px 0;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.available &&
    css`
      img {
        opacity: 0.7;
      }
    `}

    @media (max-width: 1025px){
      width: 50px;
  height: 50px;
  }
`;

export const ComplementsTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;

  border-radius: 8px;
  padding: 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const ComplementsItemHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  div {
    p {
      height: 32px;
      display: flex;
      align-items: center;
    }
  }
`;

export const ComplementsItemBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCollapse = styled.div`
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`;

export const ComplementItemHeaderTitle = styled.p`
  font-weight: bold;
`;
