import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AreaSeparator, Container, ModalFiscalDiff } from './styles';
import { Cart } from './Cart';
import { Payments } from './Payments';
import { Button } from 'ui-kit-takeat';
import { Fiscal } from './Fiscal';
import { useAuth } from '~/context/AuthContext';
import toast from 'react-hot-toast';
import api from '~/services/api';
import { useCart } from '~/context/OrderingSystem/Cart';
import { useMenu } from '~/context/OrderingSystem/Menu';
import { v4 } from 'uuid';
import { usePos } from '~/context/PosContext';
import { ModalDiscount } from '../ModalDiscount';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Select from '~/components/Form/SelectInput';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { Form } from '@unform/web';

import PrintedComponent from './PrintedComponent';

export const BalconyPaymentArea = ({
  client,
  clientId,
  getSessions,
  commandTableNumber,
  session,
  setResponseNfce,
  setModalErrorNF,
}) => {
  const { user, restaurantDiscountObs, setData } = useAuth();

  const [isTablePrintable, setIsTablePrintable] = useState(
    user?.print_balcony_default
  );

  const containerRef = useRef(null);
  const { scrollHeight, scrollTop, clientHeight } = containerRef.current || {};
  const showShadow = scrollHeight - scrollTop - clientHeight > 0;
  const [scrollAmount, setScrollAmount] = useState(0);

  const [tableId, setTableId] = useState(null);
  const [makingOrder, setMakingOrder] = useState(false);

  const [metodo, setMetodo] = useState('Outros');
  const { cart, addCartToOrder, closeCart } = useCart();
  const { getMenu } = useMenu();

  const [isProcessing, setIsProcessing] = useState(false);
  const [sendUpdateProcessing, setSendUpdateProcessing] = useState(false);

  const {
    stoneTransactions,
    cancelStoneTransactionsBalcony,
    createStoneTransactionsBalcony,
  } = usePos();

  const checkStonePending =
    user.has_stone_pdv &&
    stoneTransactions.some((transaction) => transaction.status === 'pending');

  const [modalDiscount, setModalDiscount] = useState(false);
  const [modalCancelPos, setModalCancelPos] = useState(false);
  function toggleCancelPos(payment_id) {
    setPaymentIdToCancelStone(payment_id);
    setModalCancelPos(!modalCancelPos);
  }

  const [hasMoneyMethod, setHasMoneyMethod] = useState(false);

  const [paymentIdToCancelStone, setPaymentIdToCancelStone] = useState(null);

  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discountObservation, setDiscountObservation] = useState(false);
  const [discountObs, setDiscountObs] = useState(null);
  const [paymentOption, setPaymentOption] = useState([]);
  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [leftInput, setLeftInput] = useState(() => {
    const totalProduct = cart.reduce((TotalAccumulator, order) => {
      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(complement.price) *
                  (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }

          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              category.complements
                .map((item) => item)
                .reduce((acum, curr) => acum + curr.price * curr.amount, 0) /
              amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator + complement.price * complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        return (
          TotalAccumulator +
          (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount
        );
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0);

    return totalProduct.toFixed(2);
  }, []);

  const [value, setValue] = useState(leftInput);
  const [porcent, setPorcent] = useState(0);
  const [paymentsSession, setPaymentsSession] = useState([]);
  const [paymentsBill, setPaymentsBill] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [clubInfo, setClubInfo] = useState({ phone: client?.phone, date: '' });
  const [clientCashback, setClientCashback] = useState({
    date: null,
    value: 0,
  });
  const [adminPassword, setAdminPassword] = useState(null);

  const [loadingNfce, setLoadingNfce] = useState(false);
  const [formData, setFormData] = useState({
    type: 'nfce',
    doc: 'cpf',
  });
  const [typeSelected, setTypeSelected] = useState('nfce');
  const [ieSelected, setIeSelected] = useState('9');
  const [localDestinoSelected, setLocalDestinoSelected] = useState(1);
  const [ufSelected, setUfSelected] = useState(null);

  const totalProduct = cart.reduce((TotalAccumulator, order) => {
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.more_expensive_only) {
          let totalComplements = 0;
          category.complements.forEach((complement) => {
            if (
              totalComplements <
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0)
            ) {
              totalComplements =
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0);
            }
          });

          return categoryAccumulator + totalComplements;
        }

        if (category.use_average) {
          const amountAverage = category.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0
          );

          if (amountAverage === 0) {
            return 0;
          }

          const totalPriceAverage =
            category.complements.reduce(
              (acum, curr) => acum + curr.price * curr.amount,
              0
            ) / amountAverage;

          return totalPriceAverage + categoryAccumulator;
        }

        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return complementAccumulator + complement.price * complement.amount;
          },
          0
        );
        return categoryAccumulator + totalComplements;
      },
      0
    );

    if (order.weight_price) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.weight_price)) *
        order.amount
      );
    }

    if (order.weight) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price * order.weight)) *
        order.amount
      );
    }
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [favoriteMethods, setFavoriteMethods] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const resetBalcony = () => {
    closeCart();
    setClubInfo({ phone: '', date: '' });
    setClientCashback({ date: null, value: 0 });
    setPaymentsSession([]);
    setDiscount(null);
    setDiscountPercent(null);
    setDiscountObs(null);
    setDiscountObservation(null);
    setFormData({
      type: 'nfce',
      doc: 'cpf',
    });
  };

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('restaurants/payment-methods');

    const methods = response.data.payment_methods.filter(
      (method) => method.available
    );

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
      method: data.method,
    }));

    setAllPaymentMethods(parsedCompleteData);

    const favoriteMethodsSplice = methods.splice(0, 8);

    const parsedDataFav = favoriteMethodsSplice.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setFavoriteMethods(parsedDataFav);

    const parsedData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const getPaymentsSession = useCallback(async () => {
    let total = 0;

    paymentsSession.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });
    setPaid(total.toFixed(2).toString());

    const localLeft = totalProduct - total;

    setLeft(parseFloat(localLeft.toFixed(2)));

    const porcentPaid = (total / totalProduct) * 100;

    setPorcent(parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsSession, totalProduct]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const newPayments = useCallback(async () => {
    try {
      if (paymentOption.id && paymentOption.keyword === 'prazo') {
        toggleModalInsertCreditRegisterPayment();
      } else if (paymentOption.id) {
        const newPayment = {
          payment_value:
            paymentOption.id === 238 ? clientCashback.value : Number(value),
          payment_method_id: paymentOption.id,
          id: v4(),
          keyword: paymentOption.keyword,
        };

        if (left <= 0) {
          setValue(0)
        } else {
          setValue((left - Number(value)).toFixed(2));
        }

        setPaymentsSession([...paymentsSession, newPayment]);

        if (
          user.has_stone_pdv &&
          ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
            allPaymentMethods.find(
              (pay) => pay.id === newPayment.payment_method_id
            )?.method || false
          ) &&
          parseFloat(newPayment.payment_value) > 0
        ) {
          handleStoneTransactions({
            payment_value: newPayment.payment_value,
            payment_balcony_id: newPayment.id,
            method: allPaymentMethods.find(
              (pay) => pay.id === newPayment.payment_method_id
            )?.method,
          });
        }

        setClientCashback((state) => {
          return { ...state, value: 0 };
        });
        toast.success('Pagamento inserido com sucesso!');
      }
    } catch (error) {
      console.log('Error: ', error);
      toast.error(error.response.data.message || 'Selecione o método de pagamento.');
    }
  }, [paymentsSession, paymentOption, left, totalProduct]);

  const storeNfce = useCallback(
    async (data) => {
      setLoadingNfce(true);
      try {
        let response;

        if (typeSelected === 'nfce') {
          const dataSent = { key: data.session.key || session?.session_key };

          if (data.email !== null && data.email !== '') {
            dataSent.email = data.email;
          }

          if (data.cpf !== '' && data.cpf !== null) {
            dataSent.cpf = data.cpf;
          }

          if (data.cnpj !== '' && data.cnpj !== null) {
            dataSent.cnpj = data.cnpj;
          }

          if (data.informacoes_adicionais_contribuinte) {
            dataSent.informacoes_adicionais_contribuinte =
              data.informacoes_adicionais_contribuinte;
          }

          response = await api.post('restaurants/new-nfce', dataSent);
        } else {
          const parsedData = {
            key: data.session.key || session.session_key,
            cnpj_destinatario: data.cnpj,
            cpf: data.cpf,
            nome_destinatario: data.nome_destinatario,
            logradouro_destinatario: data.logradouro_destinatario,
            numero_destinatario: data.numero_destinatario,
            bairro_destinatario: data.bairro_destinatario,
            municipio_destinatario: data.municipio_destinatario,
            uf_destinatario: ufSelected,
            cep: data.cep,
            indicador_inscricao_estadual_destinatario: ieSelected,
            local_destino: localDestinoSelected,
            informacoes_adicionais_contribuinte:
              data.informacoes_adicionais_contribuinte,
          };

          if (data.inscricao_estadual_destinatario !== '') {
            parsedData.inscricao_estadual_destinatario =
              data.inscricao_estadual_destinatario;
          }

          if (data.email !== null && data.email !== '') {
            parsedData.email = data.email;
          }

          if (sendUpdateProcessing) {
            parsedData.updateProcessing = true;
          }

          response = await api.post('restaurants/new-nfe', parsedData);
          getSessions();
          // document.getElementById('update_button').click();
        }

        if (response.data.status_sefaz === '100') {
          if (data.type === 'nfce') {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );
          } else {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );
          }
        }
        setResponseNfce(response.data);

        setSendUpdateProcessing(false);

        if (response.data.status === 'processando_autorizacao') {
          setIsProcessing(true);
        } else {
          setIsProcessing(false);
        }

        resetBalcony();

        setLoadingNfce(false);
        if (response.data.status_sefaz !== '100') {
          setModalErrorNF(true);
        }
      } catch (error) {
        setLoadingNfce(false);
        console.log('error', error);
        toast.error(
          'Não foi possível finalizar e emitir a Nota Fiscal! Tente novamente'
        );
      }
    },
    [session, formData, typeSelected, sendUpdateProcessing]
  );

  const getTableId = async () => {
    const { data } = await api.get('/restaurants/tables?is_balcony=true');

    if (data.length > 0) {
      setTableId(data[0].id);
    } else {
      const response = await api.post('restaurants/tables/multiple', {
        start: -1,
        end: -1,
        table_type: 'balcony',
      });

      setTableId(response.data[0].id);
    }
  };

  async function makeOrder(withNfce) {
    if (cart.length <= 0) {
      toast.error('Adicione produtos ao carrinho para finalizar o pedido');
      return;
    }

    if (checkStonePending) {
      toast.error(
        'Aguarde a confirmação de todos os pagamentos com a máquina de cartão'
      );
      return;
    }

    withNfce ? setLoadingNfce(true) : setMakingOrder(true);

    const res = await addCartToOrder(
      tableId,
      clientId || null,
      'in_person',
      null,
      null,
      null,
      paymentsSession,
      isTablePrintable,
      commandTableNumber,
      getMenu,
      parseFloat(discount?.replace(',', '.')),
      discountObservation ? `${discountObs} - ${discountObservation}` : discountObs,
      user.user_id,
      clubInfo.phone,
      null,
      adminPassword
    );

    if (!res) {
      setMakingOrder(false);
      setLoadingNfce(false);
      return;
    }

    setMakingOrder(false);
    if (clubInfo.phone?.length > 0) {
      if (res?.data.cashbackAdded) {
        toast.success('Cliente pontuado!');
      } else {
        toast.error('Não foi possível pontuar o cliente');
      }
    }
    if (withNfce && res) {
      storeNfce({ ...formData, session: res.data.tableBill });
    } else {
      resetBalcony();
    }

    if (res.data.tableBill.id) {
      if (user.print_balcony_default && !user.print_bills_on_web) {
        await api.post('restaurants/printers/printer-queue', {
          table_session_id: res.data.tableBill.id,
          close_after_print: false
        });
      } else if (user.is_printed_on_web, user.print_bills_on_web) {
        await getBillsSession(res.data.tableBill.id);

        const tableSess = await searchTableSession(res.data.tableBill.id);

        setAllPayments(tableSess?.payments);
        setPrinterSession(tableSess);

        setOpenPrintable(true)
      }
    }

    // handleUpdate();
  }

  const searchTableSession = async (sessionId) => {
    try {
      const response = await api.get(`/restaurants/sessions/search`, {
        params: {
          type: 'session',
          id: sessionId,
        },
      })
      console.log('searchresponse', response.data)
      return response.data[0];
    } catch (error) {
      console.log('error', error);
    }
  }

  async function setMetodoAndPaymentOption(payment) {
    if (
      payment.id === 238 &&
      parseFloat(clientCashback.value) < parseFloat(user.minimo)
    ) {
      toast.error('Valor do resgate abaixo do mínimo do clube');
      return;
    }

    if (
      payment?.keyword === 'prazo' &&
      paymentsSession.length > 0 &&
      user.credit_register_with_nfce
    ) {
      toast.error(
        'O método Prazo só pode ser escolhido caso não tenham outros métodos inseridos'
      );
      return;
    }
    setMetodo(payment.label);
    setPaymentOption({
      id: payment.id,
      label: payment.label,
      keyword: payment.keyword,
    });
  }

  useEffect(() => {
    const paymentMethodFiltered = paymentsSession?.filter(
      (payment) => payment.payment_method_id === 161
    );
    if (paymentMethodFiltered?.length > 0) {
      setHasMoneyMethod(true);
    } else {
      setHasMoneyMethod(false);
    }
  }, [paymentsSession]);

  useEffect(() => {
    getPaymentsSession();
  }, [getPaymentsSession]);

  useEffect(() => {
    const payments_bill = session?.payments;

    setPaymentsBill(payments_bill);
  }, [session]);

  useEffect(() => {
    newPayments();
  }, [paymentOption]);

  useEffect(() => {
    if (discount) {
      setValue((left - parseFloat(discount.replace(',', '.'))).toFixed(2));
    } else {
      if (left <= 0) {
        setValue(0);
      } else {
        setValue(left);
      }
    }

    setMetodo('Outros');
  }, [left, discount]);

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    getTableId();
  }, []);

  // Diferença entre o valor da comanda e os pagamentos
  const [totalDifference, setTotalDifference] = useState(0);
  const [showModalDifference, setShowModalDifference] = useState(false);

  useEffect(() => {
    const paid = paymentsSession.reduce((total, payment) => {
      return total + parseFloat(payment.payment_value);
    }, 0);

    const diff = parseFloat((totalProduct - discount - paid).toFixed(2));
    setTotalDifference(diff);
  }, [paymentsSession, totalProduct, discount]);

  const [loadingPos, setLoadingPos] = useState(false);

  const handleStoneTransactions = useCallback(
    async ({ payment_value, method, payment_balcony_id }) => {
      setLoadingPos(true);
      try {
        await createStoneTransactionsBalcony({
          payment_value,
          method,
          payment_balcony_id,
        });
      } catch (err) { }
      setLoadingPos(false);
    },
    []
  );

  const handleCancelStoneTransactions = useCallback(async () => {
    setLoadingPos(true);
    try {
      await cancelStoneTransactionsBalcony({
        payment_balcony_id: paymentIdToCancelStone,
      });
      toggleCancelPos();
    } catch (err) { }
    setLoadingPos(false);
  }, [paymentIdToCancelStone]);

  //register credit
  const [modalRegisterCreditPayment, setModalRegisterCreditPayment] =
    useState(false);
  const [creditRegisterOptions, setCreditRegisterOptions] = useState();

  function toggleModalInsertCreditRegisterPayment() {
    setModalRegisterCreditPayment(!modalRegisterCreditPayment);
  }

  const getCreditRegisters = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/credit-register?details=true'
      );

      const creditRegisterOptions = response.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          total_limit: item.total_limit,
          balance: item.balance,
        };
      });

      setCreditRegisterOptions(creditRegisterOptions);
    } catch (error) {
      toast.error('Erro ao baixar contas a prazo');
    }
  }, []);

  useEffect(() => {
    if (modalRegisterCreditPayment) {
      getCreditRegisters();
    }
  }, [getCreditRegisters, modalRegisterCreditPayment]);

  const submitRegisterCreditPayment = useCallback(
    async (data) => {
      try {
        if (paymentOption.id) {
          const newPayment = {
            payment_value:
              paymentOption.id === 238 ? clientCashback.value : Number(value),
            payment_method_id: paymentOption.id,
            id: v4(),
            credit_register_id: data.credit_register_id,
            keyword: paymentOption.keyword,
          };

          if (left <= 0) {
            setValue((totalProduct - Number(value)).toFixed(2));
          } else {
            setValue((left - Number(value)).toFixed(2));
          }

          const response = await api.post(
            '/restaurants/credit-register/verify',
            {
              value: Number(value),
              credit_register_id: data.credit_register_id,
            }
          );

          setPaymentsSession([...paymentsSession, newPayment]);
          setClientCashback((state) => {
            return { ...state, value: 0 };
          });
          toast.success('Pagamento inserido com sucesso!');
          setModalRegisterCreditPayment(false);
        }
      } catch (error) {
        if (!error.response?.ok) {
          if (error.response.data.errorType === 'credit_register_expired') {
            toast.error('Conta a prazo com tempo expirado.');
          } else if (
            error.response.data.errorType === 'credit_register_limit_reached'
          ) {
            toast.error('Limite insuficiente nesta conta a prazo.');
          } else {
            toast.error(error.response.data.message || 'Selecione o método de pagamento.');
          }
        }
      }
    },
    [paymentsSession, paymentOption, left, totalProduct]
  );

  const [creditRegisterSelected, setCreditRegisterSelected] = useState(false);

  function handleSetCreditRegisterSelected(e) {
    const userFiltered = creditRegisterOptions.find(
      (item) => Number(item.value) === Number(e)
    );

    setCreditRegisterSelected(userFiltered);
  }
  const hasCreditRegisterPayment = paymentsSession.some((p) => {
    return p?.keyword === 'prazo' || p?.payment_method?.keyword === 'prazo';
  });

  function showNfceFooter() {
    if (user.credit_register_with_nfce) {
      return !hasCreditRegisterPayment ? true : false;
    } else {
      return true;
    }
  }

  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionBills] = useState();
  const [productBills, setProductBills] = useState();
  const [payments, setAllPayments] = useState();
  const [count, setCount] = useState('');
  const [printerCommandTableNumber, setPrinterCommandTableNumber] = useState();
  const [printerSession, setPrinterSession] = useState();
  const [openPrintable, setOpenPrintable] = useState(false);

  const getBillsSession = async (sessId) => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${sessId}`
    );

    setPrintBills(response.data.print_bills);
    setSessionBills(response.data.session);
    setProductBills(response.data.products);

    const baskets = [];

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setPrinterCommandTableNumber(sorted[0]);
  };

  return (
    <Container
      ref={containerRef}
      onScroll={(e) => {
        setScrollAmount(e.target.scrollTop);
      }}
    >
      <Cart />
      <AreaSeparator />
      <Payments
        clubInfo={clubInfo}
        setClubInfo={setClubInfo}
        clientCashback={clientCashback}
        setClientCashback={setClientCashback}
        paymentsSession={paymentsSession}
        setPaymentsSession={setPaymentsSession}
        setMetodoAndPaymentOption={setMetodoAndPaymentOption}
        left={left}
        setLeft={setLeft}
        discount={discount}
        discountPercent={discountPercent}
        value={value}
        setValue={setValue}
        paid={paid}
        totalProduct={totalProduct}
        hasMoneyMethod={hasMoneyMethod}
        setModalDiscount={setModalDiscount}
        loadingPos={loadingPos}
        handleStoneTransactions={handleStoneTransactions}
        toggleCancelPos={toggleCancelPos}
        modalCancelPos={modalCancelPos}
        setModalCancelPos={setModalCancelPos}
        handleCancelStoneTransactions={handleCancelStoneTransactions}
        allPaymentMethods={allPaymentMethods}
        favoriteMethods={favoriteMethods}
        paymentMethods={paymentMethods}
        checkStonePending={checkStonePending}
      />
      <AreaSeparator />
      {user.has_nfce && (
        <Fiscal
          formData={formData}
          setFormData={setFormData}
          makeOrder={makeOrder}
          setTypeSelected={setTypeSelected}
          totalDifference={totalDifference}
          setShowModalDifference={setShowModalDifference}
        />
      )}
      <AreaSeparator />
      <footer
        style={{
          boxShadow: !showShadow && 'none',
        }}
      >
        <Button
          title={user.has_nfce ? 'Pedir e emitir NF depois' : 'Pedir'}
          inverted
          buttonColor="#2EC9B7"
          onClick={() => makeOrder(false)}
          isLoading={loadingNfce || makingOrder}
          disabled={loadingNfce || makingOrder}
        />
        {user.has_nfce && (
          <Button
            title="Pedir e emitir NFCe"
            buttonColor="#2EC9B7"
            onClick={() => {
              if (totalDifference !== 0) {
                if (checkStonePending) {
                  toast.error(
                    'Aguarde a confirmação de todos os pagamentos com a máquina de cartão'
                  );
                  return;
                }
                setShowModalDifference(true);
                return;
              }
              makeOrder(true);
            }}
            isLoading={loadingNfce || makingOrder}
            disabled={loadingNfce || makingOrder}
          />
        )}
      </footer>

      <ModalFiscalDiff
        isOpen={showModalDifference}
        toggle={() => setShowModalDifference(false)}
      >
        <ModalBody>
          <h2 style={{ color: 'red' }}>
            O total dos pagamentos é {totalDifference > 0 ? 'menor' : 'maior'}{' '}
            do que o valor da comanda.
          </h2>
          <p>
            Você deseja emitir a nota fiscal e finalizar a comanda com um
            {totalDifference > 0 ? ' desconto' : ' troco'} de R$
            {Math.abs(totalDifference).toFixed(2)}?{' '}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button title="Não" onClick={() => setShowModalDifference(false)} />
          <Button
            title="Sim"
            buttonColor="#2ec9b7"
            disabled={!showModalDifference}
            onClick={() => {
              setShowModalDifference(false);
              makeOrder(true);
            }}
          />
        </ModalFooter>
      </ModalFiscalDiff>

      <ModalDiscount
        isOpen={modalDiscount}
        setIsOpen={setModalDiscount}
        total={totalProduct}
        discountObsOptions={discountObsOptions}
        onApplyDiscount={(discount, discountMotive, adminPassword, discountObservation) => {
          setDiscount(discount);
          setDiscountPercent(((discount / totalProduct) * 100).toFixed(2));
          setDiscountObs(discountMotive);
          setAdminPassword(adminPassword);
          setDiscountObservation(discountObservation)
        }}
      />

      <Modal
        isOpen={modalRegisterCreditPayment}
        toggle={toggleModalInsertCreditRegisterPayment}
      >
        <Form onSubmit={submitRegisterCreditPayment}>
          <ModalBody>
            <p style={{ fontWeight: 'bold' }}>Fiado</p>
            <Select
              options={creditRegisterOptions}
              name="credit_register_id"
              label="Selecione a conta a prazo"
              placeholder="Selecione ..."
              onChange={(e) => handleSetCreditRegisterSelected(e.value)}
            />
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              flexjustifyContent: 'space-between',
            }}
          >
            {creditRegisterSelected &&
              creditRegisterSelected.total_limit > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p
                    style={{
                      color:
                        Number(creditRegisterSelected.total_limit) ===
                          Math.abs(Number(creditRegisterSelected.balance))
                          ? '#FF2C3A'
                          : '#3BD2C1',
                    }}
                  >
                    Limite disponível: R${' '}
                    {creditRegisterSelected &&
                      (
                        creditRegisterSelected.total_limit -
                        Math.abs(creditRegisterSelected.balance)
                      ).toFixed(2)}
                  </p>

                  {Number(creditRegisterSelected.total_limit) ===
                    Math.abs(Number(creditRegisterSelected.balance)) && (
                      <p style={{ color: '#FF2C3A', fontWeight: 'bold' }}>
                        LIMITE INSUFICIENTE
                      </p>
                    )}
                </div>
              )}
            <PersonalButton
              type="submit"
              message="Inserir pagamento"
              color="#2ec9b7"
            />
          </ModalFooter>
        </Form>
      </Modal>
      {openPrintable && (
        <PrintedComponent
          item={printerSession}
          restaurant={user}
          count={count}
          paymentData={payments}
          printBills={printBills}
          sessionBills={sessionBills}
          productBills={productBills}
          commandTableNumber={printerCommandTableNumber}
          setOpenPrintable={setOpenPrintable}
        />
      )}
    </Container>
  );
};