import { endOfMonth, format, startOfMonth } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { FaAward, FaInfoCircle, FaTrophy, FaUtensils } from 'react-icons/fa';
import { ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { toast } from 'react-hot-toast';
import api from '~/services/api';

import {
  Container,
  IconContainer,
  ModalHeaderH2,
  ModalHeaderSubtitle,
  ModalTable,
  ModalWaiter,
  ServiceTaxTable,
  TableHeader,
} from './styles';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { PageTitle } from '~/components/PageTitle';
import {
  CollapseTable,
  DownloadButton,
  FullCalendar,
  FullHourCalendar,
  OrderButton,
  Tabs,
  Tooltip,
} from 'ui-kit-takeat';
import { generateSheet } from '~/services/SheetGenerate/generateTaxReportSheet';
import FileSaver from 'file-saver';
import { generateWaiterProductsSheet } from '~/services/SheetGenerate/generateWaiterProductsSheet';
import { useAuth } from '~/context/AuthContext';

export const ServiceTax = () => {
  const now = new Date();
  const { user } = useAuth();
  const [waiters, setWaiters] = useState([]);
  const [waitersParsed, setWaitersParsed] = useState(null);
  const [waitersLoading, setWaitersLoading] = useState(false);

  const [sessions, setSessions] = useState([]);
  const [sessionsParsed, setSessionsParsed] = useState(null);
  const [sessionsLoading, setSessionsLoading] = useState(false);

  const [startDate, setStartDate] = useState(startOfMonth(now));
  const [endDate, setEndDate] = useState(endOfMonth(now));

  const [tabSelected, setTabSelected] = useState(0);
  const [permission, setPermission] = useState();
  const [modalWaiter, setModalWaiter] = useState(false);
  const [detailedWaiter, setDetailedWaiter] = useState(null);
  const [earningTooltip, setEarningTooltip] = useState(false);
  const [taxTooltip, setTaxTooltip] = useState(false);
  const [taxPrevTooltip, setTaxPrevTooltip] = useState(false);
  const [totalSumTooltip, setTotalSumTooltip] = useState(false);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsServiceTax'}`
      );

      const { can_read } = response.data.ReportsServiceTax;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const formatPrice = (value) => {
    return value?.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getSessions = async () => {
    setSessionsLoading(true);
    const response = await api.get(
      `/restaurants/reports/taxes?start_date=${startDate}&end_date=${endDate}`
    );

    setSessions(response.data);
    setSessionsLoading(false);
  };

  const getWaiters = async () => {
    setWaitersLoading(true);
    const response = await api.get(
      `/restaurants/reports/waiters-tax?start_date=${startDate}&end_date=${endDate}`
    );

    setWaiters(response.data);
    setWaitersLoading(false);
  };

  const getDetailedWaiter = async (waiterId) => {
    const response = await api.get(
      `/restaurants/reports/waiter-detailed/${waiterId}?start_date=${startDate}&end_date=${endDate}`
    );

    setDetailedWaiter(response.data);
  };

  const getModalIcon = (id) => {
    if (id === 0) {
      return (
        <IconContainer color="#FFA814">
          <FaTrophy size={20} />
        </IconContainer>
      );
    }
    if (id === 1) {
      return (
        <IconContainer color="#C0C0C0">
          <FaAward size={20} />
        </IconContainer>
      );
    }
    if (id === 2) {
      return (
        <IconContainer color="#C9956F">
          <FaAward size={20} />
        </IconContainer>
      );
    }
    return (
      <IconContainer>
        <FaUtensils />
      </IconContainer>
    );
  };

  useEffect(() => {
    getWaiters();
    getSessions();
  }, [startDate, endDate]);

  const handleDownload = async () => {
    try {
      const sheet = await generateSheet(waiters);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Relatório de Taxas.xlsx`);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const handleDownloadDetailed = async () => {
    try {
      const sheet = await generateWaiterProductsSheet(detailedWaiter);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Relatório de Garçom.xlsx`);
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const handleWaitersData = () => {
    return waiters.map((waiter) => {
      return {
        waiter: waiter.waiter_name,
        total: `R$${formatPrice(parseFloat(waiter.sum))}`,
        taxes: `R$${formatPrice(parseFloat(waiter.tax))} `,
        preview_tax: `R$${formatPrice(
          parseFloat(waiter.sum) * (Number(user.service_tax) / 100)
        )}`,
        total_with_tax: `R$${formatPrice(
          parseFloat(waiter.sum) + parseFloat(waiter.tax)
        )}`,
        productsSold: waiter.waiter_id && (
          <OrderButton
            title="Ver todos"
            type="button"
            color="#FFA814"
            inverted
            icon={<FaTrophy />}
            onClick={(e) => {
              getDetailedWaiter(waiter.waiter_id);
              setModalWaiter(true);
              e.stopPropagation();
            }}
          />
        ),
        subitems:
          waiter.bills?.length > 0
            ? waiter.bills.map((bill) => {
                const totalPriceFormatted = bill?.total_price
                  ?.substring(2)
                  .replace(',', '.');
                const taxFormatted = bill?.tax?.substring(2).replace(',', '.');
                return {
                  id: bill.id,
                  tax: bill.total_price,
                  total: bill.tax,
                  preview_tax: `R$${formatPrice(
                    parseFloat(totalPriceFormatted) *
                      (Number(user.service_tax) / 100)
                  )}`,
                  total_with_tax: `R$${formatPrice(
                    parseFloat(totalPriceFormatted) + parseFloat(taxFormatted)
                  )}`,
                  aux: '',
                };
              })
            : null,
      };
    });
  };

  const handleSessionsData = () => {
    return sessions.index.map((day) => {
      return {
        day: day,
        total: `R$${formatPrice(parseFloat(sessions.days[day].total))}`,
        taxes: `R$${formatPrice(parseFloat(sessions.days[day].taxes))}`,
        preview_tax: `R$${formatPrice(
          parseFloat(sessions.days[day].total) *
            (Number(user.service_tax) / 100)
        )}`,
        total_with_tax: `R$${formatPrice(
          parseFloat(sessions.days[day].total) +
            parseFloat(sessions.days[day].taxes)
        )}`,
        subitems:
          sessions.days[day].sessions?.length > 0
            ? sessions.days[day].sessions.map((session) => {
                return {
                  id: `${format(new Date(session.created_at), 'HH:mm')} - #${
                    session.id
                  }`,
                  total: `R$${formatPrice(parseFloat(session.earning))}`,
                  tax: `R$${formatPrice(parseFloat(session.taxPayed))}`,
                  preview_tax: `R$${formatPrice(
                    parseFloat(session.earning) *
                      (Number(user.service_tax) / 100)
                  )}`,
                  total_with_tax: `R$${formatPrice(
                    parseFloat(session.earning) + parseFloat(session.taxPayed)
                  )}`,
                };
              })
            : null,
      };
    });
  };

  useEffect(() => {
    if (waiters) {
      setWaitersParsed(handleWaitersData());
    }
  }, [waiters]);

  useEffect(() => {
    if (sessions.index) {
      setSessionsParsed(handleSessionsData());
    }
  }, [sessions]);

  return permission ? (
    <Container>
      <PageTitle>Taxas de Serviço</PageTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <h2>
          Relatório de taxas de serviço recebidas pelo restaurante. Filtro
          referente a data de criação da comanda.
        </h2>

        <DownloadButton
          onClick={() => {
            handleDownload();
          }}
          fixed="open"
          text="Baixar"
          buttonWidth={55}
          marginRight={8}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          tabs={['Por pessoa', 'Por dia']}
          selected={tabSelected}
          onChangeTab={(e) => setTabSelected(e)}
        />

        <div style={{ display: 'flex', gap: 10, marginBottom: 5 }}>
          <FullHourCalendar
            value={{ start: startDate, end: endDate }}
            onDateSelected={(e) => {
              setStartDate(e.start);
              setEndDate(e.end);
            }}
            maxRange={90}
            onError={(e) => toast.error(e)}
            hideFields={['this_year']}
          />
          <FullHourCalendar
            isEnd
            value={{ start: startDate, end: endDate }}
            onDateSelected={(e) => {
              setStartDate(e.start);
              setEndDate(e.end);
            }}
            maxRange={90}
            onError={(e) => toast.error(e)}
            hideFields={['this_year']}
          />
        </div>
      </div>
      <ServiceTaxTable firstTab={tabSelected === 0}>
        <TableHeader>
          <span>Período</span>
          <span style={{ position: 'relative' }}>
            Valor consumido{' '}
            {tabSelected === 0 && (
              <FaInfoCircle
                onMouseEnter={() => setEarningTooltip(true)}
                onMouseLeave={() => setEarningTooltip(false)}
              />
            )}
            <Tooltip
              show={earningTooltip}
              content={
                <p style={{ textAlign: 'justify' }}>
                  Os valores são divididos proporcionalmente entre os garçons
                  com base no total pago em cada comanda.
                </p>
              }
              containerStyles={{ top: '30px', left: '-73px', width: '288px' }}
            />
          </span>
          <span style={{ position: 'relative' }}>
            Taxa recebida{' '}
            {tabSelected === 0 && (
              <FaInfoCircle
                onMouseEnter={() => setTaxTooltip(true)}
                onMouseLeave={() => setTaxTooltip(false)}
              />
            )}
            <Tooltip
              show={taxTooltip}
              content={
                <p style={{ textAlign: 'justify' }}>
                  É calculada a quantidade da taxa que foi paga na comanda e
                  distribuida proporcionalmente entre os garçons que atenderam
                  nessa mesa.
                </p>
              }
              containerStyles={{
                top: '30px',
                right: '0px',
                width: '350px',
                left: 'unset',
              }}
            />
          </span>
          <span style={{ position: 'relative' }}>
            Taxa prevista{' '}
            {tabSelected === 0 && (
              <FaInfoCircle
                onMouseEnter={() => setTaxPrevTooltip(true)}
                onMouseLeave={() => setTaxPrevTooltip(false)}
              />
            )}
            <Tooltip
              show={taxPrevTooltip}
              content={
                <p style={{ textAlign: 'justify' }}>
                  É calculada a quantidade da taxa que se espera ser paga na
                  comanda e distribuida proporcionalmente entre os garçons que
                  atenderam nessa mesa.
                </p>
              }
              containerStyles={{
                top: '30px',
                right: '0px',
                width: '350px',
                left: 'unset',
              }}
            />
          </span>
          <span style={{ position: 'relative' }}>
            Soma dos pagamentos{' '}
            {tabSelected === 0 && (
              <FaInfoCircle
                onMouseEnter={() => setTotalSumTooltip(true)}
                onMouseLeave={() => setTotalSumTooltip(false)}
              />
            )}
            <Tooltip
              show={totalSumTooltip}
              content={
                <p style={{ textAlign: 'justify' }}>
                  É calculada a soma do valor consumido + taxa recebida
                </p>
              }
              containerStyles={{
                top: '30px',
                right: '0px',
                width: '350px',
                left: 'unset',
              }}
            />
          </span>
          {tabSelected === 0 && <span>Prod. mais vendidos</span>}
        </TableHeader>

        {waitersLoading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <CollapseTable
            header={
              tabSelected === 0
                ? [
                    `${format(startDate, 'dd/MM/yy')} a ${format(
                      endDate,
                      'dd/MM/yy'
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          )
                        : sessions.total
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.tax),
                            0
                          )
                        : sessions.taxes
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          ) *
                            (Number(user.service_tax) / 100)
                        : sessions.total * (Number(user.service_tax) / 100)
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          ) +
                            waiters.reduce(
                              (acc, cur) => acc + parseFloat(cur.tax),
                              0
                            )
                        : sessions.total + sessions.taxes
                    )}`,
                    '',
                  ]
                : [
                    `${format(startDate, 'dd/MM/yy')} a ${format(
                      endDate,
                      'dd/MM/yy'
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          )
                        : sessions.total
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.tax),
                            0
                          )
                        : sessions.taxes
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          ) *
                            (Number(user.service_tax) / 100)
                        : sessions.total * (Number(user.service_tax) / 100)
                    )}`,
                    `R$${formatPrice(
                      tabSelected === 0
                        ? waiters.reduce(
                            (acc, cur) => acc + parseFloat(cur.sum),
                            0
                          ) +
                            waiters.reduce(
                              (acc, cur) => acc + parseFloat(cur.tax),
                              0
                            )
                        : sessions.total + sessions.taxes
                    )}`,
                  ]
            }
            data={
              tabSelected === 0
                ? waitersParsed
                  ? waitersParsed
                  : []
                : sessionsParsed
                ? sessionsParsed
                : []
            }
          />
        )}
      </ServiceTaxTable>
      <ModalWaiter
        isOpen={modalWaiter}
        toggle={() => setModalWaiter(!modalWaiter)}
      >
        <ModalHeader>
          <ModalHeaderH2
            style={{
              color: '#4D4D4C',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}
          >
            Produtos mais vendidos
          </ModalHeaderH2>
        </ModalHeader>
        <ModalBody>
          <ModalHeaderSubtitle>
            <span
              style={{
                color: '#4D4D4C',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
              }}
            >
              Exibindo do garçom: {detailedWaiter?.waiter.name}
            </span>
            <DownloadButton
              text="Baixar"
              fixed="open"
              buttonWidth={55}
              onClick={handleDownloadDetailed}
            />
          </ModalHeaderSubtitle>
          <ModalTable>
            <thead>
              <tr>
                <th>Qtd. de produtos</th>
                <th>Produto</th>
                <th>Preço médio do item</th>
                <th>Total vendido</th>
              </tr>
            </thead>
            <tbody>
              {detailedWaiter?.products.map((item, idx) => {
                return (
                  <tr key={item.product_id}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      {getModalIcon(idx)}
                      {item.amount}
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 10,
                        }}
                      >
                        {item.product_name}
                      </div>
                    </td>
                    <td>R${formatPrice(item.avg)}</td>
                    <td>R${formatPrice(item.total)}</td>
                  </tr>
                );
              })}
            </tbody>
          </ModalTable>
        </ModalBody>
      </ModalWaiter>
    </Container>
  ) : (
    <PermissionErrorContainer />
  );
};
