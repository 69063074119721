import React, { useState, useRef } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';
import EyeBillsIndividual from '~/components/Tables/CloseOrder/Eye/EyeBillsIndividual';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
} from '../styles';
import { Print, DisplayNoneImprimir, CardColumn, CardRow } from './styles';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import { OrderButton, OrderStatusTag } from 'ui-kit-takeat';
import { FaEye, FaPrint } from 'react-icons/fa';

export default function CloseIndividual({
  item,
  handlePrintItem,
  individualBills,
}) {
  const { user } = useAuth();
  const componentRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const userName = item.bill.buyer
    ? formatPhone(item.bill.buyer.phone)
    : item.bill.waiter.name;

  async function closeClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        individual_bill_id: item.bill.id,
      });
      handlePrintItem({
        session_key: item.session_key,
        bill_id: item.bill.id,
      });
    } catch (error) {
      toast.error('Erro ao fechar comanda.');
    }
  }

  return (
    <Pedido>
      <ContentBody>
        <CardRow>
          <TableNumber>
            Mesa{' '}
            {item.table?.table_number === -4 ? 'POS' : item.table?.table_number}
          </TableNumber>
          <OrderNumber>#{item.bill.id}</OrderNumber>
        </CardRow>
        <CardRow>
          <PhoneNumber>{userName}</PhoneNumber>

          <Time>{formatTime(item.bill.updatedAt)}</Time>
        </CardRow>
        <CardRow>
          <div>
            <OrderStatusTag
              text="Fechamento individual"
              color="#FFA814"
              width="185px"
            />
          </div>
          <Actions>
            {user.is_printed_on_web || user.print_bills_on_web ? (
              <ReactToPrint
                trigger={() => (
                  <OrderButton
                    title="Fechar"
                    icon={<FaPrint />}
                    color="#2EC9B7"
                  />
                )}
                content={() => componentRef.current}
                onAfterPrint={() =>
                  handlePrintItem({
                    session_key: item.session_key,
                    bill_id: item.bill.id,
                  })
                }
              />
            ) : (
              <OrderButton
                title="Fechar"
                icon={<FaPrint />}
                color="#2EC9B7"
                onClick={closeClick}
              />
            )}

            <OrderButton icon={<FaEye />} color="#ffa814" onClick={toggle} />
          </Actions>
        </CardRow>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsIndividual item={item} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsIndividual
          item={item}
          restaurant={user}
          ref={componentRef}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
