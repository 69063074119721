import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  Container,
  Header,
  StyledLink,
  WaitersTable,
  Delete,
  Actions,
  ScrollArea,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';
import CreatePos from './Create';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function StoneDevices() {
  const [devices, setDevices] = useState([]);
  const [permission, setPermission] = useState();

  const [isModalRegisterOpen, setIsModalRegisterOpen] = useState(false);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterWaiters'}`
      );

      const { can_read } = response.data.RegisterWaiters;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [, setTotalWaiters] = useState(0);

  function toggleModalRegister() {
    setIsModalRegisterOpen(!isModalRegisterOpen);
  }

  const getDevices = useCallback(async () => {
    const response = await api.get('/restaurants/stone/devices');

    setDevices(response.data);
  }, []);

  useEffect(() => {
    try {
      getDevices();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getDevices]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esse POS?')) {
        await api.delete(`/restaurants/stone/devices/${id}`);

        getDevices();

        toast.success('POS deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar POS');
    }
  }

  function getType(type) {
    switch (type) {
      case 'gm':
        return 'Garçom';
      case 'auto':
        return 'Autoatendimento';
      default:
        return '-';
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <RegisterButton onClick={toggleModalRegister}>Adicionar</RegisterButton>
      </Header>

      <Modal isOpen={isModalRegisterOpen} toggle={toggleModalRegister}>
        <ModalHeader>Cadastrar Garçom</ModalHeader>
        <ModalBody>
          <CreatePos
            toggleModalRegisterWaiter={toggleModalRegister}
            getWaiters={getDevices}
          />
        </ModalBody>
      </Modal>

      <WaitersTable borderless>
        <thead>
          <tr>
            <th>Nº Serial</th>
            <th>Tipo</th>
            <th>Garçom</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <tr key={device.id}>
              <td>{device.serial_number}</td>
              <td>{getType(device.type)}</td>
              <td>{device.waiter?.name || '-'}</td>
              <td>
                <Actions>
                  <Delete onClick={() => handleDelete(device.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </WaitersTable>
    </Container>
  );
}

export default StoneDevices;
