import React, { useCallback, useState, useEffect } from 'react';
import { Button, DefaultInput, Hyperlink } from 'ui-kit-takeat';
import {
  ClientArea,
  ClubArea,
  Container,
  DiscountApplied,
  OptionButton,
  PaymentsMade,
  PaymentsOptions,
  Value,
  ValuesArea,
} from './styles';
import { FaPercent } from 'react-icons/fa';
import { PaymentRow } from './PaymentRow';
import { CurrencyInput } from '~/components/CurrencyInput';
import { useCart } from '~/context/OrderingSystem/Cart';
import formatValue from '~/utils/formatValue';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { useAuth } from '~/context/AuthContext';
import { useMenu } from '~/context/OrderingSystem/Menu';
import { usePos } from '~/context/PosContext';
import { format } from 'date-fns';
import { v4 } from 'uuid';
import apiClube from '~/services/apiClube';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

export const Payments = ({
  clubInfo,
  setClubInfo,
  clientCashback,
  setClientCashback,
  paymentsSession,
  setPaymentsSession,
  setMetodoAndPaymentOption,
  left,
  setLeft,
  discount,
  discountPercent,
  value,
  setValue,
  paid,
  totalProduct,
  hasMoneyMethod,
  setModalDiscount,
  handleStoneTransactions,
  loadingPos,
  toggleCancelPos,
  modalCancelPos,
  setModalCancelPos,
  handleCancelStoneTransactions,
  allPaymentMethods,
  favoriteMethods,
  paymentMethods,
  checkStonePending,
}) => {
  const { user, setData } = useAuth();
  const { cart } = useCart();
  const {
    stoneTransactions,
    cancelStoneTransactionsBalcony,
    createStoneTransactionsBalcony,
    cancelAllStoneTransactions,
  } = usePos();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const [metodo, setMetodo] = useState('Outros');

  const [loadingCashback, setLoadingCashback] = useState(false);

  const totalToShow =
    totalProduct - (parseFloat(discount?.replace(',', '.')) || 0);

  const methodsCheck = [
    'online_ifood',
    'credit_card_auto',
    'clube',
    'pix_auto',
  ];

  const deletePayments = useCallback(
    async (id, skipConfirmation = false) => {
      if (skipConfirmation || window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        try {
          const payments_bill = paymentsSession.filter(
            (payment) => payment.id !== id
          );

          const payments_bill_find = paymentsSession.filter(
            (payment) => payment.id === id
          );

          setValue(Number(left) + Number(payments_bill_find[0].payment_value));

          if (discount) {
            setLeft(
              Math.abs(
                Number(left) +
                  Number(payments_bill_find[0].payment_value) -
                  Number(discount)
              ).toFixed(2)
            );
          } else {
            setLeft(
              Math.abs(
                Number(left) + Number(payments_bill_find[0].payment_value)
              ).toFixed(2)
            );
          }

          setPaymentsSession(payments_bill);

          if (
            stoneTransactions.find(
              (trans) =>
                trans.payment_balcony_id === id && trans.status !== 'canceled'
            )
          ) {
            cancelStoneTransactionsBalcony({ payment_balcony_id: id });
          }

          toast.success('Pagamento removido com sucesso!');
        } catch (error) {
          if (
            error.response.data.errorType === 'credit_register_already_finished'
          ) {
            toast.error(
              'Essa conta a prazo já foi quitada, não pode ser removida.'
            );
          } else {
            toast.error('Erro ao deletar pagamento');
          }
        }
      }
    },
    [paymentsSession, setPaymentsSession, left, stoneTransactions]
  );

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);

      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({
            ...user,
            club_login: login_club.data.token,
            minimo: login_club.data.user?.settings[0]?.minimo,
          })
        );

        setData((state) => {
          return {
            ...state,
            user: {
              ...user,
              club_login: login_club.data.token,
              minimo: login_club.data.user?.settings[0]?.minimo,
            },
          };
        });
      }
      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });

      setClientCashback(response.data);
    } catch (err) {
      setClientCashback((state) => {
        return { ...state, value: 0 };
      });
      if (err.response?.data?.errorType === 'default_error') {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
      console.log('getCashback error: ', err);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  function getLeftName() {
    if (discount) {
      if (left > 0 && left > discount) {
        return 'Restante';
      } else if (left > 0 && left < discount && hasMoneyMethod) {
        return 'Troco';
      } else if (left > 0 && left < discount && !hasMoneyMethod) {
        return 'Extra';
      } else if (left < 0 && hasMoneyMethod) {
        return 'Troco';
      } else if (left < 0 && !hasMoneyMethod) {
        return 'Extra';
      } else {
        return 'Restante';
      }
    } else if (left < 0 && !hasMoneyMethod) {
      return 'Extra';
    } else if (left < 0) {
      return 'Troco';
    } else {
      return 'Restante';
    }
  }

  useEffect(() => {
    if (user.has_clube && clubInfo.phone?.length === 15) {
      getCashback();
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      format(new Date(clientCashback.date), 'dd/MM/yyyy') !== clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  const handleCancelPendingPos = () => {
    if (window.confirm('Tem certeza que deseja cancelar os pagamentos pendentes?')) {
      cancelAllStoneTransactions()
        .then(() => {
          const stoneTransactionPendingId = stoneTransactions.find((transaction) => transaction.status === 'pending').payment_balcony_id;
          deletePayments(stoneTransactionPendingId, true);
        })
        .catch(() => {
          console.log('Erro ao cancelar pagamentos pendentes');
        });
    }
  }

  return (
    <Container>
      <h3>Pagamento</h3>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          title="Aplicar desconto"
          buttonColor="#2EC9B7"
          icon={<FaPercent />}
          onClick={() => {
            if (cart.length <= 0) {
              toast.error(
                'Adicione produtos ao carrinho para aplicar desconto'
              );
              return;
            }
            setModalDiscount(true);
          }}
        />
        {discount && (
          <DiscountApplied>
            <p>Desconto aplicado:</p>
            <p style={{ fontWeight: 500 }}>
              {formatValue(discount)} ({discountPercent}%)
            </p>
          </DiscountApplied>
        )}
      </div>

      <ClientArea>
        {loadingCashback && (
          <Spinner
            style={{
              position: 'absolute',
              right: 30,
              top: 40,
              width: 20,
              height: 20,
            }}
          />
        )}
        <DefaultInput
          title="Celular"
          mask="(99) 99999-9999"
          disabled={loadingCashback}
          titleStyles={{ color: '#666666' }}
          style={{ height: 38, padding: '5px 10px' }}
          value={clubInfo.phone}
          onChange={(e) => {
            const newPhone = e.target.value;
            setClubInfo((state) => {
              return { ...state, phone: newPhone };
            });
          }}
        />
        {user.has_clube && clientCashback.date !== null && (
          <ClubArea>
            <h3>Takeat Clube</h3>
            <div className="buttons-div">
              <DefaultInput
                title="Data de nascimento"
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                titleStyles={{ color: '#666666' }}
                style={{ height: 38, padding: '5px 10px' }}
                value={clubInfo.date}
                onChange={(e) => {
                  const newDate = e.target.value;
                  setClubInfo((state) => {
                    return { ...state, date: newDate };
                  });
                }}
              />
              <Button
                title={`Resgatar ${formatValue(clientCashback.value)}`}
                disabled={
                  format(
                    new Date(clientCashback?.date || null),
                    'dd/MM/yyyy'
                  ) !== clubInfo?.date || parseFloat(clientCashback?.value) <= 0
                }
                buttonColor="#2EC9B7"
                onClick={(e) => {
                  e.preventDefault();
                  if (cart.length <= 0) {
                    toast.error(
                      'Adicione produtos ao carrinho para adicionar pagamento'
                    );
                    return;
                  }
                  if (
                    paymentsSession.find((p) => p.payment_method_id === 238)
                  ) {
                    toast.error('Este telefone já realizou o resgate');
                  } else if (parseFloat(clientCashback.value) > 0) {
                    setMetodoAndPaymentOption({
                      label: 'Resgate Clube',
                      id: 238,
                    });
                  }
                }}
              />
            </div>
          </ClubArea>
        )}
      </ClientArea>

      <ValuesArea>
        <Value>
          <span>Total</span>
          <span>
            <b>
              {formatValue(
                totalProduct - (parseFloat(discount?.replace(',', '.')) || 0)
              )}
            </b>
          </span>
        </Value>
        <Value>
          <span>{getLeftName()}</span>
          <span>
            <b>
              {formatValue(
                left > 0
                  ? Math.abs(
                      left - (parseFloat(discount?.replace(',', '.')) || 0)
                    ).toFixed(2)
                  : (
                      Math.abs(Number(left)) +
                      (parseFloat(discount?.replace(',', '.')) || 0)
                    ).toFixed(2)
              )}
            </b>
          </span>
        </Value>
        <CurrencyInput
          id="value"
          value={value * 100}
          onChange={(e) => {
            setValue(e.floatValue ? e.floatValue / 100 : 0)
            }
          }
        />
      </ValuesArea>

      <PaymentsOptions>
        {favoriteMethods
          .filter(
            (method) =>
              method.available && !methodsCheck.includes(method.keyword)
          )
          .map((method) => {
            return (
              <OptionButton
                disabled={
                  paymentsSession?.filter((pay) => pay?.keyword === 'prazo')
                    .length > 0 && user.credit_register_with_nfce
                }
                onClick={() => {
                  if (cart.length <= 0) {
                    toast.error(
                      'Adicione produtos ao carrinho para adicionar pagamento'
                    );
                    return;
                  }
                  if (
                    user.has_stone_pdv &&
                    ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                      allPaymentMethods.find((pay) => pay.id === method.id)
                        ?.method || false
                    ) &&
                    checkStonePending
                  ) {
                    toast.error(
                      'Pagamento pendente na maquininha, aguarde a confirmação ou cancele o pagamento'
                    );
                    return;
                  }
                  setMetodoAndPaymentOption(method);
                }}
              >
                <span>{method.label}</span>
              </OptionButton>
            );
          })}

        {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
          <Dropdown
            // disabled={checkStonePending}
            isOpen={dropdownOpen}
            size="sm"
            toggle={toggleDrop}
          >
            <DropdownToggle
              caret
              style={{
                height: '40px',
                margin: 0,
                background: '#ff2c3a',
                width: '100%',
              }}
            >
              {metodo}
            </DropdownToggle>
            <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
              {paymentMethods.map((payment) =>
                payment.available &&
                payment.keyword !== 'clube' &&
                payment.keyword !== 'online_ifood' &&
                payment.keyword !== 'credit_card_auto' &&
                payment.keyword !== 'pix_auto' ? (
                  <div key={payment.id}>
                    <DropdownItem
                      disabled={
                        paymentsSession.filter(
                          (pay) => pay?.keyword === 'prazo'
                        ).length > 0 && user.credit_register_with_nfce
                      }
                      onClick={() => {
                        if (cart.length <= 0) {
                          toast.error(
                            'Adicione produtos ao carrinho para adicionar pagamento'
                          );
                          return;
                        }
                        if (
                          user.has_stone_pdv &&
                          ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(
                            allPaymentMethods.find(
                              (pay) => pay.id === payment.id
                            )?.method || false
                          ) &&
                          checkStonePending
                        ) {
                          toast.error(
                            'Pagamento pendente na maquininha, aguarde a confirmação ou cancele o pagamento'
                          );
                          return;
                        }
                        setMetodoAndPaymentOption(payment);
                      }}
                    >
                      {payment.label}
                    </DropdownItem>
                  </div>
                ) : (
                  <></>
                )
              )}
            </DropdownMenu>
          </Dropdown>
        )}
      </PaymentsOptions>

      <PaymentsMade>
        <thead>
          <th>Método de pgto</th>
          <th>Valor</th>
          <th style={{ textAlign: 'right' }}>Ação</th>
        </thead>
        <tbody>
          {paymentsSession?.length > 0 ? (
            paymentsSession.map((payment) => {
              const method =
                payment.payment_method_id === 238
                  ? { label: 'Resgate Clube', id: 238 }
                  : allPaymentMethods.find(
                      (method) =>
                        method.available &&
                        method.id === payment.payment_method_id
                    );

              return (
                <PaymentRow
                  key={payment.id}
                  deletePayments={deletePayments}
                  payment={payment}
                  method={method}
                  stoneTransactions={stoneTransactions}
                  allPaymentMethods={allPaymentMethods}
                  handleStoneTransactions={handleStoneTransactions}
                  loadingPos={loadingPos}
                  toggleCancelPos={toggleCancelPos}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>Nenhum pagamento adicionado</td>
            </tr>
          )}
        </tbody>
        {user.has_stone_pdv && user.stone_device_id && checkStonePending && (
          <Hyperlink
            color="#2EC9B7"
            fontSize={12}
            textDecoration="underline"
            onClick={handleCancelPendingPos}
          >
            Cancelar Pagamentos Pendentes POS
          </Hyperlink>
        )}
      </PaymentsMade>

      <ValuesArea>
        <Value>
          <span>
            <b>Total pago</b>
          </span>
          <span>{formatValue(paid)}</span>
        </Value>
        <Value style={{ alignItems: 'flex-end' }}>
          <span>
            <b>Pago</b>
          </span>
          <span>
            {(totalToShow <= 0
              ? '0'
              : paid / totalToShow > 1
              ? '100'
              : (paid / totalToShow) * 100
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            %
          </span>
        </Value>
      </ValuesArea>

      <Modal isOpen={modalCancelPos} toggle={toggleCancelPos}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="12">Deseja cancelar esse pagamento na POS?</Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ marginLeft: 'auto' }}>
          <Button
            title="Sim, cancelar"
            buttonColor="#2ec9b7"
            disabled={loadingPos}
            onClick={(e) => handleCancelStoneTransactions()}
          />
        </ModalFooter>
      </Modal>
    </Container>
  );
};