import React, { useState, useRef } from 'react';

import { addMinutes, isBefore } from 'date-fns';

import { AutoSizer, List, Table, Column } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once

import { AiOutlineEye } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';

import { EmptyDiv, StatusTag, SeeButton } from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import { FaEnvelope } from 'react-icons/fa6';

function getStatusName(status) {
  switch (status) {
    case 'autorizado':
      return 'Autorizada';
    case 'canceled':
      return 'Cancelada';
    default:
      return '';
  }
}

function rowStyle({ index }) {
  if (index === -1) {
    return {
      background: '#fff',
    };
  }

  return {
    background: '#fff',
    borderBottom: '1px solid rgba(34,42,66,.2)',
  };
}

function header(name, align) {
  return () => {
    const s = align ? { textAlign: align, width: '100%' } : {};

    return (
      <div
        style={{
          display: 'inline-block',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontSize: '12px',
          color: 'rgba(34,42,66,.7)',
          ...s,
        }}
      >
        {name}
      </div>
    );
  };
}

export default function NfcesTable({
  nfces,
  onClickDetail,
  onClickPrint,
  onClickEmail,
  onClickCancel,
  showCancelDate,
  onClickCartaCorrecao
}) {
  const tableRef = useRef(null);

  function ActionButtons({ nfce }) {
    const amount = nfce.type === 'nfce' ? 30 : 1440;

    const expireDate = addMinutes(new Date(nfce.created_at), amount);
    const isCancelAllowed = isBefore(new Date(), expireDate);

    const isCanceled = nfce.status === 'canceled';

    return (
      <div>
        {!isCanceled && (
          <>
            <SeeButton
              onClick={() => onClickPrint && onClickPrint(nfce)}
              style={{ background: '#0D4D4D' }}
            >
              <FaFileInvoiceDollar size={14} style={{ marginRight: 3 }} />
              Espelho da Nota
            </SeeButton>

            <SeeButton
              onClick={() => onClickEmail && onClickEmail(nfce)}
              style={{ background: '#113C51' }}
            >
              <BiMailSend size={14} style={{ marginRight: 3 }} />
              Enviar por E-mail
            </SeeButton>
          </>
        )}

        <SeeButton
          onClick={() => onClickDetail && onClickDetail(nfce)}
          style={{
            justifyContent: isCanceled ? 'center' : 'initial',
          }}
        >
          <AiOutlineEye size={14} style={{ marginRight: 3 }} />
          Detalhes
        </SeeButton>

        {!isCanceled && isCancelAllowed && (
          <SeeButton
            onClick={() => onClickCancel && onClickCancel(nfce)}
            style={{ background: '#dc3545' }}
          >
            <TiCancel size={14} style={{ marginRight: 3 }} />
            Cancelar
          </SeeButton>
        )}

        {(nfce.type === 'nfe' || nfce.type === 'nfe-avulsa') && !isCanceled &&
          <SeeButton
            onClick={() => onClickCartaCorrecao && onClickCartaCorrecao(nfce)}
            style={{ background: '#F5B800' }}
          >
            <FaEnvelope size={14} style={{ marginRight: 3 }} />
            Carta de Correção
          </SeeButton>
        }
      </div>
    );
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        if (tableRef.current) {
          tableRef.current.recomputeGridSize();
        }

        const proportion = showCancelDate ? 0.2 : 0.25;

        return (
          <Table
            ref={tableRef}
            rowStyle={rowStyle}
            disableHeader={false}
            height={800}
            width={width}
            headerHeight={40}
            rowHeight={145}
            overscanRowCount={10}
            rowGetter={({ index }) => nfces[index]}
            rowCount={nfces.length}
            noRowsRenderer={() => {
              return (
                <EmptyDiv>
                  Não há nenhuma Nota Fiscal com os filtros selecionados.
                </EmptyDiv>
              );
            }}
          >
            <Column
              width={(width - 370) * proportion}
              disableSort
              label="Ref."
              headerRenderer={header('Ref.')}
              dataKey="ref"
              cellRenderer={({ cellData }) => `#${cellData}`}
            />

            <Column
              width={50}
              disableSort
              label="Tipo"
              headerRenderer={header('Tipo')}
              dataKey="type"
              cellRenderer={({ cellData: type }) =>
                type === 'nfce' ? 'NFCe' : 'NFe'
              }
            />

            <Column
              width={(width - 370) * proportion}
              disableSort
              label="Status"
              headerRenderer={header('Status', 'center')}
              dataKey="status"
              cellRenderer={({ cellData: status }) => {
                return (
                  <StatusTag
                    color={status === 'autorizado' ? 'green' : '#dc3545'}
                  >
                    <span>{getStatusName(status)}</span>
                  </StatusTag>
                );
              }}
            />

            <Column
              width={60}
              disableSort
              label="Série"
              headerRenderer={header('Série')}
              dataKey="serie"
            />

            <Column
              width={80}
              disableSort
              label="Número"
              headerRenderer={header('Número')}
              dataKey="numero"
            />

            <Column
              width={(width - 370) * proportion}
              disableSort
              label="Criada em"
              headerRenderer={header('Criada em')}
              dataKey="created_at"
              cellRenderer={({ cellData: dt }) => {
                return (
                  <div style={{ whiteSpace: 'normal' }}>
                    {formatCompleteDate(dt)}
                  </div>
                );
              }}
            />
            {showCancelDate && (
              <Column
                width={(width - 370) * proportion}
                disableSort
                label="Cancelada em"
                headerRenderer={header('Cancelada em')}
                dataKey="deleted_at"
                cellRenderer={({ cellData: dt }) => {
                  return (
                    <div style={{ whiteSpace: 'normal' }}>
                      {formatCompleteDate(dt)}
                    </div>
                  );
                }}
              />
            )}

            <Column
              width={(width - 370) * proportion}
              disableSort
              label="Valor"
              headerRenderer={header('Valor')}
              dataKey="total_price"
              cellRenderer={({ cellData: total_price }) =>
                parseFloat(total_price).toFixed(2)
              }
            />

            <Column
              width={180}
              disableSort
              label="Ações"
              headerRenderer={header('Ações', 'center')}
              dataKey="status"
              cellRenderer={({ rowIndex }) => {
                return <ActionButtons nfce={nfces[rowIndex]} />;
              }}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
}