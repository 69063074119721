import React, { useCallback, useEffect, useState } from 'react';
import { FaAngleRight, FaClock, FaPen, FaUserCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Badge, Modal, Nav, NavItem, NavLink } from 'reactstrap';
import ReactModal from 'react-modal';
import api from '~/services/api';
import OrdersCanceled from './OrdersCanceled';
import OrdersFinished from './OrdersFinished';
import OrdersNew from './OrdersNew';
import OrderCard from './OrderCard';
import {
  Card,
  Container,
  Header,
  NavLinkSpan,
  HeaderTitle,
  CanceledCard,
  AlteredCard,
  BadgeCount,
} from './styles';
import EditKitchen from './EditKitchen';

import { useAuth } from '~/context/AuthContext';
import OrderItem from './OrderCard/OrderItem';
import formatTime from '~/services/formatTime';
import { BuyerInfo, ConfirmButton, Orders } from './OrderCard/styles';

ReactModal.setAppElement('#root');

const priorities = {
  pending: 0,
  doing: 1,
  finished: 2,
};

function Kitchen() {
  const { websocketManager } = useAuth();
  const location = useLocation();
  const { item } = location.state;

  const [isModalCancelOpened, setIsModalCancelOpened] = useState(false);
  const [isModalAlterOpened, setIsModalAlterOpened] = useState(false);

  const [itemCanceledState, setItemCanceledState] = useState({});
  const toggleIsModalCancelOpened = useCallback(
    (item) => {
      setIsModalCancelOpened(!isModalCancelOpened);
      setItemCanceledState(item);
    },
    [isModalCancelOpened]
  );

  const toggleIsModalAlterOpened = useCallback(
    (item) => {
      setIsModalAlterOpened(!isModalAlterOpened);
      setItemCanceledState(item);
    },
    [isModalAlterOpened]
  );

  const [kdsState, setKdsState] = useState({});

  const getKds = useCallback(async () => {
    const response = await api.get(`/restaurants/kds/${item.id}`);

    const kdsFind = response.data;

    setKdsState(kdsFind);
  }, [item]);

  useEffect(() => {
    try {
      getKds();
    } catch (error) {
      console.log(error.message);
    }
  }, [getKds]);
  const [activeTab, setActiveTab] = useState('1');

  const [baskets, setBaskets] = useState([]);

  const [modalEditKitchen, setModalEditKitchen] = useState(false);

  function toggleModalEditKitchen() {
    setModalEditKitchen(!modalEditKitchen);
  }

  const verifyOrdernation = useCallback((baskets_compare) => {
    baskets_compare.sort((a, b) => {
      if (a.status === b.status) {
        return (
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
        );
      }

      return priorities[b.status] - priorities[a.status];
    });

    return baskets_compare;
  }, []);

  const getBaskets = useCallback(async () => {
    const response = await api.get(`/restaurants/kds/baskets/${item?.id}`);

    const basketOrdened = verifyOrdernation(response.data);

    setBaskets(basketOrdened);
  }, [item, verifyOrdernation]);

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(5, (data) => {
        if (data.type === 'kds:new-orders' || data.type === 'new-transfer') {
          getBaskets();
        } else if (data.type === 'kds:basket-status') {
          const kds_baskets = data.item.updated_baskets;

          const kds_ids = kds_baskets.map((d) => d.kds_id);

          if (kds_ids.includes(item.id)) {
            const kds_basket_ids = kds_baskets.map((d) => d.id);

            setBaskets((state) => {
              const newBaskets = [...state];

              let baskets_has_been_updated = false;

              for (const basket of newBaskets) {
                const updated_basket = kds_baskets.find((kb) => kb.id === basket.id);

                if (updated_basket) {
                  const { order_ids } = data.item;

                  // Atualizando os itens checados
                  if (updated_basket.status === 'finished') {
                    for (const ko of basket.kds_orders) {
                      ko.checked = true;
                    }
                  } else if (order_ids) {
                    for (const ko of basket.kds_orders) {
                      if (order_ids.includes(ko.order_id)) {
                        ko.checked = true;
                      }
                    }
                  }

                  basket.status = updated_basket.status;

                  baskets_has_been_updated = true;
                }
              }

              if (baskets_has_been_updated) {
                return verifyOrdernation(newBaskets);
              }

              return state;
            });
          }
        } else if (data.type === 'kds:order-check') {
          const kdsData = data.item;

          const { kds_order_id, checked } = data.item;
          const kds_baskets = data.item.updated_baskets;

          const kds_ids = kds_baskets.map((d) => d.kds_id);

          if (kds_ids.includes(item.id)) {
            setBaskets((state) => {
              const newBaskets = [...state];

              let baskets_has_been_updated = false;

              for (const basket of newBaskets) {
                const updated_basket = kds_baskets.find((kb) => kb.id === basket.id);

                if (updated_basket) {
                  const order_idx = basket.kds_orders.findIndex(
                    (item) => item.id === kds_order_id
                  );

                  if (order_idx > -1) {
                    basket.kds_orders[order_idx].checked = checked;
                  }

                  basket.status = updated_basket.status;

                  baskets_has_been_updated = true;
                }
              }

              if (baskets_has_been_updated) {
                return verifyOrdernation(newBaskets);
              }

              return state;
            });
          }
        } else if (data.type === 'kds:cancel-basket') {
          const kdsBaskets = data.item.kds_baskets;

          const kdsBasketFind = kdsBaskets.find(
            (basket) => basket.kds_id === item.id
          );

          if (kdsBasketFind) {
            toggleIsModalCancelOpened(kdsBasketFind);
          }
        } else if (data.type === 'kds:cancel-order-item') {
          const kdsBaskets = data.item.kds_baskets;

          const kdsBasketFind = kdsBaskets.find(
            (basket) => basket.kds_id === item.id
          );

          if (kdsBasketFind) {
            toggleIsModalCancelOpened(kdsBasketFind);
          }
        } else if (data.type === 'kds:cancel-basket') {
          const kdsBaskets = data.item.kds_baskets;

          const kdsBasketFind = kdsBaskets.find(
            (basket) => basket.kds_id === item.id
          );

          if (kdsBasketFind) {
            toggleIsModalCancelOpened(kdsBasketFind);
          }
        } else if (data.type === 'kds:cancel-order-amount') {
          const kdsBaskets = data.item.kds_baskets;

          const kdsBasketFind = kdsBaskets.find(
            (basket) => basket.kds_id === item.id
          );

          if (kdsBasketFind) {
            toggleIsModalCancelOpened(kdsBasketFind);
          }
        } else if (data.type === 'kds:basket-alter') {
          const kdsBaskets = data.item.kds_baskets;

          const kdsBasketFiltered = kdsBaskets.filter(
            (basket) => basket.kds_id === item.id
          );

          if (kdsBasketFiltered.length === 1) {
            toggleIsModalAlterOpened(kdsBasketFiltered[0]);
          } else if (kdsBasketFiltered.length > 1) {
            const first = kdsBasketFiltered[0];

            first.kds_orders = kdsBasketFiltered.map((b) => b.kds_orders).flat();

            toggleIsModalAlterOpened(first);
          }
        }
      });

      return () => {
        websocketManager.addMessageCallback(5, (data) => { });
      };
    }
  }, [
    websocketManager,
    getBaskets,
    baskets,
    kdsState,
    item,
    toggleIsModalCancelOpened,
    verifyOrdernation,
  ]);

  useEffect(() => {
    getBaskets();
  }, [getBaskets]);



  return (
    <Container>
      <Modal isOpen={isModalCancelOpened}>
        <CanceledCard>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <span
              style={{ fontWeight: 'bold', color: '#ffffff', fontSize: 16 }}
            >{`Mesa ${itemCanceledState?.table?.table_number}`}</span>

            <Badge pill className="bg-light">
              <span
                style={{ fontWeight: 'bold', color: '#FE1933', fontSize: 16 }}
              >
                Cancelado
              </span>
            </Badge>
            <span style={{ color: '#fff' }}>
              #{itemCanceledState?.basket_key}
            </span>
          </div>
          <BuyerInfo isProductLate={false}>
            <span>
              <FaUserCircle />{' '}
              <span>
                {itemCanceledState?.buyer
                  ? itemCanceledState?.buyer?.name
                  : itemCanceledState?.waiter?.name}
              </span>
            </span>
            <span>
              <FaClock />{' '}
              <span>
                {itemCanceledState &&
                  itemCanceledState.createdAt &&
                  formatTime(itemCanceledState?.createdAt)}
              </span>
            </span>
          </BuyerInfo>
          <Orders>
            {itemCanceledState &&
              itemCanceledState?.kds_orders &&
              itemCanceledState?.kds_orders.length > 0 &&
              itemCanceledState?.kds_orders
                .filter((kds_order) => kds_order.canceled_at)
                .map((product, index) => (
                  <OrderItem
                    isLate={false}
                    product={product}
                    index={index}
                    key={product.id}
                  />
                ))}
          </Orders>

          <ConfirmButton
            type="button"
            status="canceled"
            onClick={() => {
              setIsModalCancelOpened(false);
              getBaskets();
            }}
          >
            Confirmar
          </ConfirmButton>
        </CanceledCard>
      </Modal>

      <Modal isOpen={isModalAlterOpened}>
        <AlteredCard>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
            }}
          >
            <span style={{ fontWeight: 'bold', color: '#ffffff', fontSize: 16 }}>
              Delivery
            </span>

            <Badge pill className="bg-light">
              <span
                style={{ fontWeight: 'bold', color: '#ccbbed', fontSize: 16 }}
              >
                Pedido Alterado
              </span>
            </Badge>
            <span style={{ color: '#fff' }}>
              #{itemCanceledState?.attendance_password}
            </span>
          </div>
          <BuyerInfo isProductLate={false}>
            <span>
              <FaUserCircle />{' '}
              <span>
                {itemCanceledState?.buyer
                  ? itemCanceledState?.buyer?.name
                  : itemCanceledState?.waiter?.name}
              </span>
            </span>
            <span>
              <FaClock />{' '}
              <span>
                {itemCanceledState &&
                  itemCanceledState.createdAt &&
                  formatTime(itemCanceledState?.createdAt)}
              </span>
            </span>
          </BuyerInfo>
          <Orders>
            {itemCanceledState &&
              itemCanceledState?.kds_orders &&
              itemCanceledState?.kds_orders.length > 0 &&
              itemCanceledState?.kds_orders
                .map((product, index) => (
                  <OrderItem
                    isLate={false}
                    product={product}
                    index={index}
                    key={product.id}
                  />
                ))}
          </Orders>

          <ConfirmButton
            type="button"
            status="alter"
            onClick={() => {
              setIsModalAlterOpened(false);
              getBaskets();
            }}
          >
            Confirmar
          </ConfirmButton>
        </AlteredCard>
      </Modal>

      <ReactModal
        onRequestClose={toggleModalEditKitchen}
        isOpen={modalEditKitchen}
        className="modal-content-takeat-without-margin"
        closeTimeoutMS={500}
      >
        <EditKitchen
          toggleModalEditKitchen={toggleModalEditKitchen}
          kdsState={kdsState}
          item={item}
        />
      </ReactModal>
      <Header>
        <HeaderTitle>
          KDS <FaAngleRight /> {kdsState?.name}{' '}
        </HeaderTitle>
        <Badge
          pill
          className="bg-secondary"
          style={{ cursor: 'pointer', marginLeft: 8 }}
          onClick={toggleModalEditKitchen}
        >
          <FaPen color="#fff" />
          <span style={{ color: '#fff', marginLeft: 4 }}>Editar</span>
        </Badge>
      </Header>

      <Nav tabs style={{ marginLeft: '2%' }}>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => setActiveTab('1')}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            <NavLinkSpan>Pedidos</NavLinkSpan>{' '}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => {
              setActiveTab('2');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            <NavLinkSpan style={{ display: 'flex' }}>
              Pedidos finalizados
              <span className="badge bg-secondary" style={{ marginLeft: 8 }}>
                {baskets.filter((item) => item.status === 'finished').length}
              </span>
            </NavLinkSpan>{' '}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => {
              setActiveTab('3');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            <NavLinkSpan style={{ display: 'flex' }}>
              Pedidos cancelados
              <span className="badge bg-secondary" style={{ marginLeft: 8 }}>
                {baskets.filter((item) => item.status === 'canceled').length}
              </span>
            </NavLinkSpan>{' '}
          </NavLink>
        </NavItem>
      </Nav>
      <Card>
        {activeTab === '1' && (
          <OrdersNew
            baskets={baskets}
            setBaskets={setBaskets}
            kdsViewMode={kdsState?.view_mode}
          />
        )}
        {activeTab === '2' && (
          <OrdersFinished baskets={baskets} kdsViewMode={kdsState?.view_mode} />
        )}
        {activeTab === '3' && (
          <OrdersCanceled baskets={baskets} kdsViewMode={kdsState?.view_mode} />
        )}
      </Card>
    </Container>
  );
}

export default Kitchen;
