import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  Container,
  Header,
  StyledLink,
  WaitersTable,
  Delete,
  Actions,
  ScrollArea,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';
import CreateWaiter from './Create';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Waiter() {
  const [waiters, setWaiters] = useState([]);
  const [isModalRegisterWaiterOpen, setIsModalRegisterWaiterOpen] =
    useState(false);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterWaiters'}`
      );

      const { can_read } = response.data.RegisterWaiters;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [, setTotalWaiters] = useState(0);

  function toggleModalRegisterWaiter() {
    setIsModalRegisterWaiterOpen(!isModalRegisterWaiterOpen);
  }

  const getWaiters = useCallback(async () => {
    const response = await api.get('/restaurants/waiters');

    setWaiters(response.data);
    setTotalWaiters(response.data.count);
  }, []);

  useEffect(() => {
    try {
      getWaiters();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getWaiters]);

  async function handleDelete(id) {
    try {
      if (
        window.confirm('Tem certeza que deseja deletar o usuário deste garçom?')
      ) {
        await api.delete(`/restaurants/waiters/${id}`);

        getWaiters();

        toast.success('Usuário de garçom deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar usuário de garçom');
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <p>Gerencie os garçons de seu estabelecimento!</p>
          <p>
            Link de acesso ao aplicativo do garçom:{' '}
            <a
              href="https://gm.takeat.app"
              style={{
                color: '#0a6edf',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
              target="blank"
            >
              https://gm.takeat.app
            </a>
          </p>
        </div>

        <RegisterButton onClick={toggleModalRegisterWaiter}>
          Adicionar
        </RegisterButton>
      </Header>

      <Modal
        isOpen={isModalRegisterWaiterOpen}
        toggle={toggleModalRegisterWaiter}
      >
        <ModalHeader>Cadastrar Garçom</ModalHeader>
        <ModalBody>
          <CreateWaiter
            toggleModalRegisterWaiter={toggleModalRegisterWaiter}
            getWaiters={getWaiters}
          />
        </ModalBody>
      </Modal>

      <WaitersTable borderless>
        <thead>
          <tr>
            <th>Número</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {waiters.map((waiter) => (
            <tr key={waiter.id}>
              <td>{waiter.id}</td>
              <td>{waiter.name}</td>
              <td>{waiter.email}</td>
              <td>
                <Actions>
                  <StyledLink
                    to={{
                      pathname: 'waiters/edit',
                      state: {
                        waiter,
                      },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete onClick={() => handleDelete(waiter.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </WaitersTable>
    </Container>
  );
}

export default Waiter;
