import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { FaChair } from 'react-icons/fa';
import { CheckboxContainer } from './styles';

function CheckboxInput({ name, label, disabled, value, spanStyle, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, inputRef, registerField]);

  if (disabled && spanStyle) {
    spanStyle.color = 'gray';
  }

  return (
    <CheckboxContainer>
      <span />
      <input
        type="checkbox"
        id={fieldName}
        ref={inputRef}
        value={value}
        checked={value}
        defaultChecked={defaultValue}
        disabled={disabled}
        {...rest}
      />

      {disabled ? (
        <label
          style={
            spanStyle
              ? spanStyle
              : { color: 'gray', marginTop: 5, fontWeight: 'normal' }
          }
          htmlFor={fieldName}
        >
          {label}
        </label>
      ) : (
        <label
          style={
            spanStyle
              ? spanStyle
              : { marginTop: 5, marginBottom: 5, fontWeight: 'normal' }
          }
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      {/* <label htmlFor={fieldName}>{label}</label> */}
      {error && <span>{error}</span>}
    </CheckboxContainer>
  );
}

export default CheckboxInput;
