/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { toast } from 'react-hot-toast';

import {
  format,
  endOfDay,
  startOfMonth,
  addHours,
} from 'date-fns';

import { Row } from 'reactstrap';

import {
  FullCalendar, Tabs,
} from 'ui-kit-takeat';

import {
  Wrapper,
  Header,
  Card,
  Content,
  ScrollArea,
  ButtonsDiv,
} from './styles';

import api from '~/services/api';

import Nfes from '~/components/Nfes';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

export default function NFCeHistory() {
  const zip = require('jszip')();

  const [activeTab, setActiveTab] = useState(0);

  const [searchDate, setSearchDate] = useState({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
  });

  const [nfes, setNfes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState([]);
  const [cashFlows, setCashFlows] = useState([]);

  const updateNfe = useCallback(async () => {
    await api.get('/restaurants/nfe-received/pull');
  }, []);

  const getNfes = useCallback(async () => {
    try {
      const response = await api.get('restaurants/nfe-received', {
        params: {
          start_date: `${format(
            addHours(new Date(searchDate.start), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(searchDate.end), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          pendente: true,
        },
      });

      setNfes(response.data);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [searchDate]);

  const getAmount = (filter = []) => {
    return nfes.filter((nfce) => filter.includes(nfce.manifestacao_destinatario) || filter.includes(nfce.status)).length;
  };

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/inputs?units=true');

      const resp = response.data.map((inp) => ({
        value: inp.id,
        label: `${inp.name} (${inp.unidade})`,
        name: inp.name,
        name_sefaz: inp.name_sefaz,
        unidade: inp.unidade,
        quantidade: inp.quantidade,
        history_unit: inp.history_unit,
        unitary_price: inp.unitary_price,
        cfop: inp.cfop,
        cash_flow_category_subcategory_id:
          inp.cash_flow_category_subcategory_id,
      }));

      setInputs(resp);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  const getCashFlows = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });
      setCashFlows(categoriesSelect);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  useEffect(() => {
    getNfes();
    getInputs();
    getCashFlows();
    setLoading(false);
  }, [getNfes]);

  async function handleUpdate() {
    setLoading(true);
    updateNfe();
    getNfes();
    setLoading(false);
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalManifestNF'}`
      );

      const { can_read } = response.data.FiscalManifestNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <p>Aceite ou recuse NFes emitidas para seu estabelecimento</p>

        <ButtonsDiv>
          <FullCalendar value={searchDate} onDateSelected={setSearchDate} />
        </ButtonsDiv>
      </Header>

      <Tabs
        tabs={['Pendentes', 'Recusadas']}
        counter={[getAmount([null, 'ciencia', 'created']), getAmount(['desconhecimento'])]}
        selected={activeTab}
        onChangeTab={setActiveTab}
      />

      <Card firstTab={activeTab === 0}>
        <Content>

          <Row />

          <ScrollArea>
            <Nfes
              nfes={nfes.filter(nfe => activeTab === 0 ? 
                ([null, 'ciencia'].includes(nfe.manifestacao_destinatario) || nfe.status === 'created') : 
                (['desconhecimento'].includes(nfe.manifestacao_destinatario)))}
              loading={loading}
              inputs={inputs}
              getInputs={getInputs}
              handleUpdate={handleUpdate}
              cashFlows={cashFlows}
              getNfes={getNfes}
            />
          </ScrollArea>
        </Content>
      </Card>
    </Wrapper>
  );
}
