import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { Container, FormArea } from './styles';
import Checkbox from '~/components/Form/Checkbox';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';

function ChangeAcceptRestaurantTax({ session, getMinimalSessions }) {
  const [hasTaxState, setHasTaxState] = useState(!session?.has_tax);

  const handleSubmit = useCallback(
    async (data) => {
      setHasTaxState(data);
      const response = await api.put('restaurants/update-session-tax', {
        session_key: session?.key,
        has_tax: hasTaxState,
      });

      getMinimalSessions();

    },
    [session, hasTaxState, getMinimalSessions]
  );

  return (
    <Container>
      {/* <Form onSubmit={handleSubmit}> */}
      <FormArea>
        <div>
          <input
            type="checkbox"
            name="has_tax"
            checked={hasTaxState}
            onChange={() => {
              handleSubmit(!hasTaxState);
            }}
          />
          <h6>
            <strong>Retirar taxa de serviço</strong>
          </h6>
        </div>
        {/* <button type="submit">Salvar</button> */}
      </FormArea>
      {/* </Form> */}
    </Container>
  );
}

export default ChangeAcceptRestaurantTax;
