import styled from 'styled-components';

export const ItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid lightgrey;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OtherContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;
