import React, { useState } from 'react';

import { Modal } from 'reactstrap';

import NotificationBadge, { Effect } from 'react-notification-badge';

import {
  Container,
  ProductPrice,
  ProductName,
  ProductImage,
  ProductInfo,
} from './styles';
import ImgPlaceholder from '~/assets/img/ImgPlaceholder.png';
import { useCart } from '~/context/OrderingSystem/Cart';

import Product from '~/pages/OrderingSystem/Product';

function OrderingSystemProduct({
  product,
  isTableDelivery,
  setAdded,
  toggleProductArea,
}) {
  const [modalProductIsOpen, setModalProductIsOpen] = useState(false);
  const [itemProduct, setItemProduct] = useState();
  // const [count, setCount] = useState('');
  const { addToCart, countProducts, productsHasCounted } = useCart();

  function toggleModalProduct() {
    setModalProductIsOpen(!modalProductIsOpen);
  }

  // function openProductModal(product) {
  //   if (isTableDelivery) {
  //     setItemProduct({
  //       ...product,
  //       price:
  //         product.delivery_price_promotion ||
  //         product.delivery_price ||
  //         product.price_promotion ||
  //         product.price,
  //     });
  //   } else {
  //     setItemProduct({
  //       ...product,
  //       price: product.price_promotion || product.price,
  //     });
  //   }

  //   toggleModalProduct(!modalProductIsOpen);
  // }

  function closeProductModal() {
    setModalProductIsOpen(false);
  }

  // const [productSelected, setProductSelected] = useState([]);

  // function selectProduct(id) {
  //   if (!productSelected.includes(id)) {
  //     setProductSelected([id]);
  //   } else {
  //     const newArray = productSelected.filter((item) => item !== id);

  //     setProductSelected(newArray);
  //   }
  // }

  // const [productsHasCounted, setProductsHasCounted] = useState([]);

  // function countProducts(product) {
  //   const productFind = productsHasCounted.findIndex(
  //     (item) => item.id === product.id
  //   );

  //   if (productFind !== -1) {
  //     productsHasCounted[productFind].amount += 1;
  //   } else {
  //     setProductsHasCounted((state) => {
  //       return [...state, product];
  //     });
  //   }
  // }

  function handleSelectProduct(product) {
    if (product?.complement_categories.length > 0 || product?.use_weight) {
      // openProductModal(product);

      if (isTableDelivery) {
        setItemProduct({
          ...product,
          price:
            product?.delivery_price_promotion ||
            product?.delivery_price ||
            product?.price_promotion ||
            product?.price,
        });
      } else {
        setItemProduct({
          ...product,
          price: product?.price_promotion || product?.price,
        });
      }

      toggleProductArea(
        isTableDelivery
          ? {
            ...product,
            price:
              product?.delivery_price_promotion ||
              product?.delivery_price ||
              product?.price_promotion ||
              product?.price,
          }
          : {
            ...product,
            price: product?.price_promotion || product?.price,
          }
      );
    } else {
      countProducts({ id: product.id, amount: 1 });
      const productFormatted = isTableDelivery
        ? {
          ...product,
          price:
            product.delivery_price_promotion ||
            product.delivery_price ||
            product.price_promotion ||
            product.price,
        }
        : {
          ...product,
          price: product.price_promotion || product.price,
        };
      if (setAdded) {
        setAdded((state) => {
          const exists = state.find(
            (s) => s.product.id === productFormatted.id
          );
          if (exists) {
            return state.map((s) => {
              if (s.product.id === productFormatted.id) {
                return { ...s, amount: s.amount + 1 };
              }
              return s;
            });
          }
          return [
            ...state,
            {
              product: productFormatted,
              amount: 1,
              observation: '',
              complementsCategory: [],
              isProductWeight: product?.use_weight,
              created_at: new Date().toDateString(),
            },
          ];
        });
      }
      addToCart({
        product: productFormatted,
        amount: 1,
        observation: '',
        complementsCategory: [],
        isProductWeight: product?.use_weight,
      });
    }
  }

  return (
    <>
      <Container
        onClick={() => {
          handleSelectProduct(product);

          // selectProduct(product.id);
        }}
      // isProductSelected={productSelected.includes(product.id)}
      >

        <ProductImage link={product?.image?.url_thumb || product?.image?.url}>
          <img src={product?.image?.url_thumb || product?.image?.url || ImgPlaceholder} alt={product?.name} />
        </ProductImage>

        {isTableDelivery ? (
          <ProductInfo>
            <ProductName>{product?.name}</ProductName>
            {product?.price_promotion || product?.delivery_price_promotion ? (
              <ProductPrice>
                R${' '}
                {product?.delivery_price_promotion ||
                  product?.delivery_price ||
                  product?.price_promotion}
              </ProductPrice>
            ) : (
              <ProductPrice>
                R$ {product?.delivery_price || product?.price}
              </ProductPrice>
            )}
          </ProductInfo>
        ) : (
          <ProductInfo>
            <ProductName>{product?.name}</ProductName>
            {product?.price_promotion ? (
              <ProductPrice>R$ {product?.price_promotion}</ProductPrice>
            ) : (
              <ProductPrice>R$ {product?.price}</ProductPrice>
            )}
          </ProductInfo>
        )}

        <NotificationBadge
          count={
            productsHasCounted
              ? productsHasCounted.find((pr) => pr.id === product.id)?.amount
              : 0
          }
          effect={Effect.SCALE}
          style={{
            width: '25px',
            height: '25px',
            borderRadius: '12px',
            position: 'absolute',
            top: '-190px',
            paddingTop: '5px',
          }}
        />
      </Container>
      <Modal isOpen={modalProductIsOpen} toggle={toggleModalProduct}>
        <Product
          product={itemProduct}
          closeProductModal={closeProductModal}
          isTableDelivery={isTableDelivery}
          countProducts={countProducts}
          setAdded={setAdded}
        />
      </Modal>
    </>
  );
}

export default OrderingSystemProduct;
