import React, { useEffect, useState } from 'react';
import {
  ProductDescription,
  BackButton,
  ComplementsAreaContainer,
  ProductName,
  ProductQuantity,
  SelectionArea,
} from './styles';
import { IoIosArrowBack } from 'react-icons/io';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { DefaultInput, IconInput } from 'ui-kit-takeat';
import { CategorySelection } from '../CategorySelection';
import { ComplementsSelection } from '../ComplementsSelection';
import { BsChat } from 'react-icons/bs';
import { usePreCart } from '~/context/PreCartContext';

export const ComplementsArea = ({ product, setIsSelectingComplements }) => {
  const { preCart, changeProductPreCart } = usePreCart();
  const [categoryShowing, setCategoryShowing] = useState(
    product.complement_categories.filter((category) => category.available)[0]
  );

  return (
    <ComplementsAreaContainer>
      <BackButton onClick={() => setIsSelectingComplements(false)}>
        <IoIosArrowBack />
        <span>Voltar ao cardápio</span>
      </BackButton>

      <ProductName>
        <h2>{product.name}</h2>
        <ProductQuantity>
          {preCart.isProductWeight ? (
            <>
              <DefaultInput
                title="Por peso (kg)"
                placeholder="Ex.: 0,800"
                containerStyles={{ maxWidth: 110 }}
                value={preCart.inputProductWeight?.replace('.', ',') || ''}
                onChange={(e) => {
                  if (!/^[0-9,]*$/.test(e.target.value)) return;
                  changeProductPreCart(
                    'inputProductWeight',
                    e.target.value.replace(',', '.')
                  );
                }}
                disabled={preCart.inputProductPrice?.length > 0}
              />
              <DefaultInput
                title="Por preço (R$)"
                placeholder="Ex.: 12,50"
                containerStyles={{ maxWidth: 120 }}
                value={preCart.inputProductPrice?.replace('.', ',') || ''}
                onChange={(e) => {
                  if (!/^[0-9,]*$/.test(e.target.value)) return;
                  changeProductPreCart(
                    'inputProductPrice',
                    e.target.value.replace(',', '.')
                  );
                }}
                disabled={preCart.inputProductWeight?.length > 0}
              />
            </>
          ) : (
            <>
              <FaMinus
                onClick={() => {
                  if (preCart.amount === 1) return;
                  changeProductPreCart('amount', preCart.amount - 1);
                }}
              />
              <DefaultInput
                style={{ padding: 5, height: 40 }}
                containerStyles={{ width: 42 }}
                inputStyle={{ textAlign: 'center', fontWeight: 600 }}
                value={preCart.amount}
                onChange={(e) => {
                  if (isNaN(e.target.value) || e.target.value <= 0) return;
                  changeProductPreCart('amount', Number(e.target.value));
                }}
              />
              <FaPlus
                onClick={() =>
                  changeProductPreCart('amount', preCart.amount + 1)
                }
              />
            </>
          )}
        </ProductQuantity>
      </ProductName>

      <ProductDescription>
        <p>{product.description}</p>
        <IconInput
          placeholder="Observações aqui"
          icon={<BsChat color="#FF2C3A" />}
          containerStyles={{ height: 40, width: '40%' }}
          inputStyles={{ fontSize: 12 }}
          value={preCart.observation}
          onChange={(e) => changeProductPreCart('observation', e.target.value)}
        />
      </ProductDescription>

      {product.complement_categories.length > 0 ? (
        <SelectionArea>
          <CategorySelection
            categories={product.complement_categories.filter(
              (category) => category.available
            )}
            categoryShowing={categoryShowing}
            setCategoryShowing={setCategoryShowing}
          />
          <ComplementsSelection category={categoryShowing} />
        </SelectionArea>
      ) : (
        <span style={{ fontSize: 16, color: '#666' }}>
          Este produto não possui complementos
        </span>
      )}
    </ComplementsAreaContainer>
  );
};
