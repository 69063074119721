import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { BiTransfer } from 'react-icons/bi';
import { format, addHours } from 'date-fns';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  Tooltip,
} from 'reactstrap';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { FaEye } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { AiOutlineScissor } from 'react-icons/ai';
import {
  Item,
  NamePrice,
  ItemName,
  PriceItem,
  Complements,
  ReopenItemButton,
  CancelButton,
  CancelButtonArea,
  ButtonArea,
  ItemArea,
  OrderTime,
  ItemInfo,
  ButtonDivide,
  ButtonCancel,
} from './styles';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import SelectTable from '~/components/Form/SelectTable';
import { useOrder } from '~/context/orderContext';
import DeleteItemOrdersBill from '../DeleteItemOrdersBill';
import Input from '~/components/Form/Input';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '~/services/getValidationErrors';
import Select from '~/components/Form/SelectInput';
import { Button } from 'ui-kit-takeat';

export default function EyeOrderBillItem({
  order,
  basketLength,
  length,
  changeValue,
  billLength,
  basket,
  bill,
  getTables,
  finalDate,
  inicialDate,
  setSessions,
  session,
  userName,
  toggleEyeOpen,
  tables,
  getSessions,
  getTablesAvailable,
  getTableSessions,
  handleUpdateBills,
  handleSearchInputTables,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  setIsDivideBillsAreaOpen,
}) {
  const [, setTableChanged] = useState(null);
  const { moveOrderToCanceled } = useOrder();
  const cancelForm = useRef(null);
  const { user } = useAuth();
  const [optionProductsAmount, setOptionProductsAmount] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [loadingCancelItem, setLoadingCancelItem] = useState(false);

  useEffect(() => {
    const optionProducts = [];

    for (let i = 1; i <= Number(order?.amount); i++) {
      optionProducts.push({ label: i, value: i });
    }
    setOptionProductsAmount(optionProducts);
  }, [order]);

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] =
    useState(false);

  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const [transferDataState, setTransferDataState] = useState();

  const [orderBasketId, setOrderBasketId] = useState();

  const hasKey = user ? user.has_order_cancel_password : false;

  const [fromState, setFromState] = useState();

  function handleOpenCancelOrderModal(order_basket_id, from) {
    setModalCancelOrderIsOpened(true);
    setOrderBasketId(order_basket_id);
    setFromState(from);
  }

  const toggleModalCancelOrder = useCallback(() => {
    setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
  }, [modalCancelOrderIsOpened]);

  const [modalTransfer, setModalTransfer] = useState(false);
  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  let basketCount = 0;
  bill.order_baskets.forEach((order) => {
    if (order.orders.length > 0) {
      order.orders.forEach((o) => {
        basketCount++;
      });
    }
  });

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleVerifyTransferPassword(data) {
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });
    setIsSubmiting(true);
    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }
  async function handleTransferTableBill(data) {
    setIsSubmiting(true);
    try {
      if (data.product_amount === Number(order?.amount)) {
        await api.put('restaurants/transfer-table-waiter-item', {
          key: session.key,
          table_id: data.new_table_id,
          waiter_id: bill.waiter.id,
          basket_id: basket.id,
          bill_id: bill.id,
          order_id: order.id,
        });
      } else {
        await api.put('/restaurants/transfer-table-waiter-item-amount', {
          table_id: data.new_table_id,
          order_id: order.id,
          transfer_amount: data.product_amount,
        });
      }

      await delay(1000);
      const response = await api.get('/restaurants/table-sessions', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      const tablesFiltered = response.data.filter(
        (item) => item.table.table_type !== 'delivery'
      );

      setSessions(tablesFiltered);
      setTableChanged(data.new_table_id);
      toggleTransfer();
      toggleEyeOpen();
      getSessions();
      setIsSubmiting(false);

      getTableSessions(session.id);
      getTablesAvailable();
      setAllTablesAvailable('');
      setInputValue('');
      setTablesFilteredSearch('');
      toast.success('Cliente transferido com sucesso!');
    } catch (error) {
      setIsSubmiting(false);
      if (
        error.response.data.errorType === 'waiter_insuficient_number_of_orders'
      ) {
        toast.error(
          'O número de pedidos na mesa só permite a tranferência parcial e não do total da quantidade de itens.'
        );
      } else {
        toast.error('Erro ao transferir cliente.');
      }

      console.log('error', error);
    }
  }

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  async function handleCancelItemModal(data) {
    setLoadingCancelItem(true);
    try {
      if (cancelForm?.current) {
        cancelForm.current.setErrors({});
      }

      const schema = Yup.object().shape({
        order_cancel_password: Yup.string().required('senha obrigatória'),
        cancel_reason: Yup.string().required('justicativa obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await api.put(`restaurants/orders/cancel/${orderBasketId}`, {
        order_cancel_password: data.order_cancel_password,
        cancel_reason: data.cancel_reason,
      });

      moveOrderToCanceled({ order_basket_id: orderBasketId, from: fromState });
      getTableSessions(session.id);
      setAllTablesAvailable('');
      setInputValue('');
      setTablesFilteredSearch('');
      toast.success('Pedido cancelado');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (cancelForm?.current) {
          cancelForm.current.setErrors(errors);
        }
      } else if (
        error?.response?.data?.err?.errorType ===
        'incorrect_order_cancel_password'
      ) {
        toast.error('senha incorreta');
      } else {
        toast.error('Erro ao cancelar pedido');

        console.log(error);
      }
    }
    setLoadingCancelItem(false);
  }

  async function handleCancelItem({ order_basket_id, from }) {
    if (hasKey) {
      handleOpenCancelOrderModal(order_basket_id, from);
    } else {
      try {
        if (window.confirm('Tem certeza que deseja cancelar este pedido?')) {
          await api.put(`restaurants/orders/cancel/${order_basket_id}`);
          moveOrderToCanceled({ order_basket_id, from });
          getTableSessions(session.id);
          setAllTablesAvailable('');
          setInputValue('');
          setTablesFilteredSearch('');
          toast.success('Pedido cancelado!');
        }
      } catch (err) {
        toast.error('Não foi possível atualizar o pedido! tente novamente');
        console.log(err);
      }
    }
  }

  const [modalDetails, setModalDetails] = useState(false);

  function toggleModalDetails() {
    setModalDetails(!modalDetails);
  }

  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTip() {
    setTooltipOpen(!tooltipOpen);
  }

  return (
    <Item key={order?.id} style={{ width: '100%' }}>
      {bill?.waiter ? (
        <ItemArea>
          <ItemInfo>
            {order?.use_weight ? (
              <strong>
                {changeValue(order?.weight)}kg {order?.product.name}
              </strong>
            ) : (
              <strong>
                {order?.amount}x {order?.product.name}
              </strong>
            )}
            {order?.details && (
              <OrderTime>
                <FaEye
                  style={{ cursor: 'pointer' }}
                  title="Detalhes"
                  onClick={toggleModalDetails}
                  color="darkOrange"
                />
              </OrderTime>
            )}
            <OrderTime>{formatTime(basket?.start_time)}</OrderTime>
          </ItemInfo>
          <ButtonArea>
            <PriceItem>R${order?.total_price} </PriceItem>

            <ButtonDivide
              style={{ marginRight: 2 }}
              onClick={() => {
                setIsDivideBillsAreaOpen(true);
              }}
            >
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={`arrow2-${order?.id}`}
                toggle={toggleTip}
              >
                Dividir comanda
              </Tooltip>
              <AiOutlineScissor
                color="#01afff"
                style={{ marginLeft: 2 }}
                size={17}
                id={`arrow2-${order?.id}`}
              />{' '}
              {/* Dividir */}
            </ButtonDivide>
            {(basketCount > 1 || order?.amount > 1) && (
              <ReopenItemButton
                onClick={toggleTransfer}
                style={{ marginRight: 8 }}
              >
                <BiTransfer size={17} color="#ffc210" />
              </ReopenItemButton>
            )}
            <CancelButtonArea>
              <CancelButton onClick={toggleDelete}>
                <FiX color="#fff" size={16} />
              </CancelButton>
            </CancelButtonArea>
          </ButtonArea>
        </ItemArea>
      ) : (
        <NamePrice>
          <ItemArea>
            <ItemInfo>
              {order?.use_weight ? (
                <strong>
                  {changeValue(order?.weight)}kg {order?.product.name}
                </strong>
              ) : (
                <strong>
                  {order?.amount}x {order?.product.name}
                </strong>
              )}
              {order?.details && (
                <OrderTime>
                  <FaEye
                    style={{ cursor: 'pointer' }}
                    title="Detalhes"
                    onClick={toggleModalDetails}
                    color="darkOrange"
                  />
                </OrderTime>
              )}

              <OrderTime>{formatTime(basket?.start_time)}</OrderTime>
            </ItemInfo>
          </ItemArea>

          <PriceItem>R${order?.total_price}</PriceItem>
          <CancelButtonArea>
            <ButtonDivide
              style={{ marginRight: 8 }}
              onClick={() => {
                setIsDivideBillsAreaOpen(true);
              }}
            >
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={`arrow2-${order?.id}`}
                toggle={toggleTip}
                style={{ background: '#01afff' }}
              >
                Dividir comanda
              </Tooltip>
              <AiOutlineScissor
                color="#01afff"
                style={{ marginLeft: 2 }}
                size={17}
                id={`arrow2-${order?.id}`}
              />

              {/* Dividir */}
            </ButtonDivide>
            <CancelButton onClick={toggleDelete}>
              <FiX color="#fff" size={21} />
            </CancelButton>
          </CancelButtonArea>
        </NamePrice>
      )}
      {order?.complement_categories.map((category) => (
        <React.Fragment key={category.id}>
          {category.order_complements.map((complement) => (
            <div key={complement.complement.id}>
              <NamePrice>
                <Complements>
                  - {complement.amount}x {complement.complement.name}{' '}
                </Complements>
                {/* <PriceComplement>
                      R${complement.complement.price}
                    </PriceComplement> */}
              </NamePrice>
            </div>
          ))}
        </React.Fragment>
      ))}

      <Modal
        isOpen={isModalTransferPasswordOpen}
        toggle={toggleModalTransferPassword}
      >
        <Form onSubmit={handleVerifyTransferPassword}>
          <ModalBody>
            <Input
              name="order_transfer_password"
              label="Senha de Transferência"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={toggleModalTransferPassword}
              title="Cancelar"
              type="button"
            />
            <Button
              type="submit"
              title="Transferir"
              buttonColor="#2EC9B7"
              onClick={() => {}}
              disabled={isSubmiting}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
        <Form onSubmit={handleTransferTable}>
          <ModalBody style={{ padding: 20 }}>
            <h3 style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }}>
              Transferência de Mesa
            </h3>
            <h4>
              {order?.use_weight
                ? `${changeValue(order?.weight)}kg ${order?.product.name}`
                : `${order?.amount}x ${order?.product.name}`}
            </h4>
            <p>Garçom: {userName}</p>
            <p>
              Mesa atual: <strong>{session.table?.table_number}</strong>
            </p>
            {tables?.length !== 0 ? (
              <>
                <Row>
                  <Col md="5">
                    <SelectTable
                      name="new_table_id"
                      label="Nova mesa:"
                      options={tables.filter(
                        (a) => a.value !== session.table?.id
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="5">
                    <Select
                      name="product_amount"
                      label="Quantidade a transferir"
                      options={optionProductsAmount}
                      defaultValue={{
                        label: order?.amount,
                        value: order?.amount,
                      }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <p style={{ color: 'red' }}>
                Não há nenhuma mesa disponível no momento.
              </p>
            )}
          </ModalBody>
          <ModalFooter style={{ padding: '0 20px 20px' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                toggleTransfer();
              }}
              title="Cancelar"
            />
            {tables.length !== 0 && (
              <Button
                type="submit"
                title="Transferir"
                onClick={() => {}}
                buttonColor="#2EC9B7"
                disabled={isSubmiting}
              />
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrdersBill
              item={basket}
              from="pending"
              session={session}
              setAllTablesAvailable={setAllTablesAvailable}
              setInputValue={setInputValue}
              setTablesFilteredSearch={setTablesFilteredSearch}
              handleCancelItem={handleCancelItem}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          {basket && basket.orders?.length > 1 && (
            <ButtonCancel
              onClick={async () => {
                setLoadingCancelItem(true);
                await handleCancelItem({
                  order_basket_id: basket.id,
                  from: 'pending',
                });
                setLoadingCancelItem(false);
              }}
              disabled={loadingCancelItem}
            >
              Cancelar todos de uma vez
            </ButtonCancel>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
          <ModalBody>
            <Input
              name="order_cancel_password"
              label="Senha de cancelamento"
              type="password"
            />
            <Input name="cancel_reason" label="Justificativa" />
          </ModalBody>
          <ModalFooter>
            <ButtonDefault
              message="Confirmar"
              type="submit"
              disabled={loadingCancelItem}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDetails} toggle={toggleModalDetails}>
        <ModalBody>
          <strong>{order.details}</strong>
        </ModalBody>
      </Modal>
    </Item>
  );
}
