import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import {
  Container,
  Table,
  Bill,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Complements,
  PriceItem,
  NamePrice,
  PriceComplement,
  AlreadyClosed,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
  ComplementItem,
  BillItem,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import { useAuth } from '~/context/AuthContext';

import api from '~/services/api';

export default function EyeBillsTableOperation({
  item,
  payments_bill,
  bills,
  count,
}) {
  const [pendingBills, setPendingBills] = useState([]);
  const [closedBills, setClosedBills] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [totalPaid, setTotalPaid] = useState();
  const { user } = useAuth();

  // useEffect(() => {
  //   // const totals = item?.payments
  //   //   .map((payment) => payment)
  //   //   .reduce((acum, curr) => acum + Number(curr.payment_value), 0);

  //   let total = 0;
  //   payments_bill.forEach((payment) => {
  //     total += parseFloat(payment.payment_value);
  //   });
  //   setTotalPaid(total);
  // }, [count, item, payments_bill]);

  useEffect(() => {
    const pending = bills?.filter((bill) => bill.status !== 'finished');
    setPendingBills(pending);

    const closed = bills?.filter((bill) => bill.status === 'finished');

    setClosedBills(closed);
  }, [bills]);

  useEffect(() => {
    try {
      api
        .get('/restaurants/payments', {
          params: {
            table_session_id: item.id,
          },
        })
        .then((res) => {
          setTotalPaid(res.data.total_value);
        });
      // setPayments(payments_bill);
      // let total = 0;
      // payments_bill.forEach((payment) => {
      //   total += parseFloat(payment.payment_value);
      // });
      // setTotalPaid(total);
    } catch (error) {
      toast.error('Erro ao carregar pagamentos');
      console.log(error);
    }
  }, [payments_bill, item, count]);

  const date = useMemo(() => formatCompleteDate(item.updatedAt), [item]);

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table?.table_type === 'command') {
      return `Comanda ${table?.table_number}`;
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    return `MESA ${table?.table_number}`;
  }

  return (
    <Container>

      <Table>
        <strong>{searchTableName(item.table)}</strong>
      </Table>
      <Bill>
        {item?.table.table_type !== 'command' && 'Comanda'} #{item.id}
      </Bill>
      <Bill>Solicitado {date}</Bill>
      {pendingBills?.map((bill) => {
        const userName = bill.buyer
          ? bill.buyer.phone || bill.buyer.name
          : bill.waiter.name;
        return (
          <React.Fragment key={bill.id}>
            <BillItem>
              <Phone>
                <strong>{userName}</strong>
              </Phone>
              <Price>
                <strong>R${bill.total_service_price}</strong>
              </Price>
            </BillItem>
            <Items>
              {bill.order_baskets.map((basket) => (
                <>
                  {basket.orders.map((order) => (
                    <Item key={order.id}>
                      <NamePrice>
                        <ItemName>
                          {order.use_weight ? (
                            <strong>
                              {changeValue(order.weight)}kg {order.product.name}
                            </strong>
                          ) : (
                            <strong>
                              {order.amount}x {order.product.name}
                            </strong>
                          )}
                        </ItemName>
                        <PriceItem>R${order.total_price}</PriceItem>
                      </NamePrice>
                      {order.complement_categories?.map((category) => (
                        <>
                          {category.order_complements.map((complement) => (
                            <ComplementItem>
                              <Complements>
                                - {complement.amount}x{' '}
                                {complement.complement.name}{' '}
                              </Complements>
                              <PriceComplement>
                                R${complement.complement.price}
                              </PriceComplement>
                            </ComplementItem>
                          ))}
                        </>
                      ))}
                    </Item>
                  ))}
                </>
              ))}
              {user?.has_service_tax &&
                !item?.table?.is_balcony &&
                item?.table?.table_type !== 'delivery' && (
                  <Item>
                    <NamePrice>
                      <ItemName>
                        Serviços ({user.service_tax}% - Opcional)
                      </ItemName>
                      <PriceItem>
                        R$
                        {(bill.total_service_price - bill.total_price).toFixed(
                          2
                        )}
                      </PriceItem>
                    </NamePrice>
                  </Item>
                )}
            </Items>
          </React.Fragment>
        );
      })}
      <AlreadyClosed>
        <Body>
          <h6>Comandas Individuais Já Fechadas</h6>
          {closedBills?.map((bill) => {
            const userName = bill.buyer
              ? bill.buyer.phone || bill.buyer.name
              : bill.waiter.name;
            return (
              <Item style={{ display: 'flex', flexDirection: 'row' }}>
                <Phone>
                  <strong>{userName}</strong>
                </Phone>
                <Price>
                  <strong>R${bill.total_service_price}</strong>
                </Price>
              </Item>
            );
          })}
        </Body>
      </AlreadyClosed>
      <TotalDiv>
        {item.old_total_price && (
          <>
            <TotalRow style={{ color: '#ADA399' }}>
              <TotalName>
                <strong>SUB TOTAL</strong>
              </TotalName>
              <TotalPrice>
                <strong>
                  R${parseFloat(item?.old_total_price).toFixed(2)}
                </strong>
              </TotalPrice>
            </TotalRow>
            <TotalRow style={{ color: '#EA3546' }}>
              <TotalName>
                <strong>DESCONTO</strong>
              </TotalName>
              <TotalPrice>
                <strong>
                  {item?.discount_percent}% / R$
                  {parseFloat(item?.discount_total).toFixed(2)}
                </strong>
              </TotalPrice>
            </TotalRow>
          </>
        )}
        <TotalRow style={{ color: '#57B269' }}>
          <TotalName>
            <strong>TOTAL</strong>
          </TotalName>
          {item?.is_delivery ? (
            <TotalPrice>
              <strong>R${parseFloat(item?.total_delivery_price).toFixed(2)}</strong>
            </TotalPrice>
          ) : (
            <TotalPrice>
              <strong>R${parseFloat(item?.total_service_price).toFixed(2)}</strong>
            </TotalPrice>
          )}
        </TotalRow>

        <TotalRow>
          <TotalName>
            <strong>PAGO</strong>
          </TotalName>
          <TotalPrice>
            <strong>R${totalPaid}</strong>
          </TotalPrice>
        </TotalRow>
        <TotalRow>
          <TotalName>
            {item?.is_delivery ? (
              <strong>
                {item?.total_delivery_price - totalPaid > 0
                  ? 'RESTANTE'
                  : 'TROCO'}
              </strong>
            ) : (
              <strong>
                {item?.total_service_price - totalPaid > 0
                  ? 'RESTANTE'
                  : 'TROCO'}
              </strong>
            )}
          </TotalName>
          <TotalPrice>
            {item?.is_delivery ? (
              <strong>
                R$
                {item?.total_delivery_price - totalPaid > 0
                  ? (item?.total_delivery_price - totalPaid).toFixed(2)
                  : ((item?.total_delivery_price - totalPaid) * -1).toFixed(2)}
              </strong>
            ) : (
              <strong>
                R$
                {item?.total_service_price - totalPaid > 0
                  ? (item?.total_service_price - totalPaid).toFixed(2)
                  : ((item?.total_service_price - totalPaid) * -1).toFixed(2)}
              </strong>
            )}
          </TotalPrice>
        </TotalRow>
      </TotalDiv>
    </Container>
  );
}
