import {
  Badge,
  ButtonsDiv,
  Container,
  HeaderButton,
  Logo,
  NotificationBell,
  PendingNotification,
  RestaurantInfo,
  RightButtonsDiv,
  WppModalText,
  WppReconnect,
  UserName,
  StatusButtonsArea,
  GdButton,
  DlButton,
  WdButton,
  DlCircle,
  StatusButton,
  StatusCircle
} from './styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import logo from '../../assets/img/logo_red.png';
import { Button, Hyperlink, ToggleButton, Tooltip } from 'ui-kit-takeat';
import {
  FaAngleDown,
  FaCalendarCheck,
  FaMotorcycle,
  FaPowerOff,
  FaQrcode,
  FaRegBell,
  FaUtensils,
  FaWhatsapp,
} from 'react-icons/fa';

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';
import { NotificationPannel } from '../NotificationPannel';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useUzapi } from '~/context/UzapiContext';
import { FiCopy } from 'react-icons/fi';
import ImgToPrint from '../ImgToPrint';
import { useTables } from '~/context/TablesContext';
import { toJpeg } from 'html-to-image';
import logoIcon from '../../assets/img/logoIconRed.png';
import { FaRepeat } from 'react-icons/fa6';
import { useWhatsgw } from '~/context/WhatsgwContext';

export const NewHeader = () => {
  const {
    changeRestaurantStatus,
    updateDeliveryStatus,
    updateWithdrawalStatus,
    notificationsOpen,
    setNotificationsOpen,
    generalNotifications,
    setGeneralNotifications,
    getUser,
    signOut
  } = useAuth();

  const user = getUser() || {};

  const [showRevertTooltip, setShowRevertTooltip] = useState(false);

  const { ifoodStatus, pendingBaskets } = useOrder();
  const basketCount =
    pendingBaskets.filter((basket) => basket.table.table_type === 'delivery')
      .length || 0;

  const {
    wppConnected,
    wppQrcode,
    showReconnect,
    setShowReconnect,
    loadingQrCode,
    getQrCodeWpp,
    logoutWpp,
  } = useUzapi();

  const {
    wppConnectedGw,
    wppQrcodeGw,
    showReconnectGw,
    setShowReconnectGw,
    loadingQrCodeGw,
    crateInstanceGw,
    logoutWppGw,
  } = useWhatsgw();

  const { tableMenu } = useTables();

  const [opened, setOpened] = useState(user.opened);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [deliveryOpened, setDeliveryOpened] = useState(
    user.delivery_info?.is_delivery_active
  );
  const [withdrawalOpened, setWithdrawalOpened] = useState(
    user.delivery_info?.is_withdrawal_active
  );
  const [ifoodOpen, setIfoodOpen] = useState(user.ifood_open);
  const [notiIds, setNotiIds] = useState([]);
  const [newsRead, setNewsRead] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:newsRead'))
  );
  const history = useHistory();
  const [codeCopyed, setCodeCopyed] = useState(false);
  const [isModalQrcodeOpened, setIsModalQrcodeOpened] = useState(false);

  useEffect(() => {
    if (user) {
      setOpened(user.opened);
      setDeliveryOpened(user.delivery_info.is_delivery_active);
      setWithdrawalOpened(user.delivery_info.is_withdrawal_active);
    }
  }, [user]);

  const inputRef = useRef(null);
  const inputDeliveryRef = useRef(null);
  const imgRef = useRef(null);

  const tableUrl = tableMenu
    ? `${tableMenu.table_code?.url}?q=${Date.now()}`
    : '';

  const toggleModalQrcode = () => {
    setIsModalQrcodeOpened(!isModalQrcodeOpened);
    setCodeCopyed(false);
  };

  function toggleDropDown() {
    setIsDropDownOpen(!isDropDownOpen);
  }

  function copyUrlCode() {
    const textoCopiado = inputRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  function copyDeliveryUrlCode() {
    const textoCopiado = inputDeliveryRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  async function handleCloseRestaurant() {
    if (user?.delivery_info?.is_delivery_allowed) {
      setIsDropDownOpen(true);
    } else {
      const response = await changeRestaurantStatus({
        opened: !opened,
      });

      setOpened(response.opened);
    }
  }

  async function handleRestaurantStatusChange() {
    const response = await changeRestaurantStatus({
      opened: !opened,
      is_delivery_active: !!deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_delivery_active, is_withdrawal_active } = response.delivery_info;

    setOpened(response.opened);
    setDeliveryOpened(is_delivery_active);
    setWithdrawalOpened(is_withdrawal_active);
  }

  async function handleDeliveryStatusChange() {
    const response = await updateDeliveryStatus({
      opened: !!opened,
      is_delivery_active: !deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  async function handleWithdrawalStatusChange() {
    const response = await updateWithdrawalStatus({
      is_withdrawal_active: !withdrawalOpened,
      opened: !!opened,
      is_delivery_active: !!deliveryOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  const downloadPng = useCallback(() => {
    if (imgRef.current === null) {
      return;
    }

    toJpeg(imgRef.current, { cacheBust: true, fontEmbedCSS: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'cardapio_digital_takeat.jpg';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [imgRef]);

  const getNotifications = async () => {
    const res = await api.get('/restaurants/notifications');

    setGeneralNotifications(res.data);
    const fixedIds = res.data.fixed.map((f) => f.id);
    const allIds = res.data.all.map((a) => a.id);
    setNotiIds(fixedIds.concat(allIds));
  };

  const checkNotificationsRead = () => {
    return notiIds.some((nid) => !newsRead?.includes(nid));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (!notificationsOpen) {
      setNewsRead(JSON.parse(localStorage.getItem('@gddashboard:newsRead')));
    }
  }, [notificationsOpen]);

  const [showWdButton, setShowWdButton] = useState(false)
  const [showGdButton, setShowGdButton] = useState(false)

  function handleGoToConfigPage() {
    history.push('/delivery/opening-hours')
  }

  const handleClickOutside = (event) => {
    if (wdButtonRef.current && !wdButtonRef.current.contains(event.target)) {
      setShowWdButton(false);
    }
  };
  const wdButtonRef = useRef(null);
  useEffect(() => {
    if (showWdButton) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showWdButton]);

  return (
    <Container>
      <ButtonsDiv>
        {!user.only_delivery && (
          <HeaderButton onClick={() => history.push('/operation')}>
            <FaUtensils size={20} />
            <span>Operação</span>
          </HeaderButton>
        )}
        {(user.delivery_info?.is_delivery_allowed ||
          user.delivery_info?.is_withdrawal_allowed) && (
            <HeaderButton onClick={() => history.push('/delivery/orders')}>
              <FaMotorcycle size={22} />
              <span>Delivery</span>
              {basketCount > 0 && <Badge>{basketCount}</Badge>}
            </HeaderButton>
          )}
        <HeaderButton onClick={toggleModalQrcode}>
          <FaQrcode size={18} />
          <span>Divulgar cardápio</span>
        </HeaderButton>
        {/* <HeaderButton onClick={() => history.push('/profile/tasks')}> */}
        {/*   <FaCalendarCheck size={20} /> */}
        {/*   <span>Tarefas</span> */}
        {/* </HeaderButton> */}


        {user.use_wpp && wppConnectedGw !== null && (
          <HeaderButton
            style={{
              width: 70,
            }}
            color={!wppConnectedGw ? '#ff2c3a' : '#2ec9b7'}
          >
            <div style={{ position: 'relative' }}>
              {/* <Hyperlink */}
              {/*   fontSize={14} */}
              {/*   icon={<FaWhatsapp size={22} />} */}
              {/*   color={!wppConnectedGw ? '#ff2c3a' : '#2ec9b7'} */}
              {/*   onClick={(e) => { */}
              {/*     setShowReconnectGw(!showReconnectGw); */}
              {/*     if (!showReconnectGw && !wppConnectedGw) { */}
              {/*       crateInstanceGw(); */}
              {/*     } */}
              {/*     e.stopPropagation(); */}
              {/*   }} */}
              {/* > */}
              {/*   {wppConnectedGw ? 'Conectado' : 'Desconectado'} */}
              {/* </Hyperlink> */}
              <div
                onClick={(e) => {
                  setShowReconnectGw(!showReconnectGw);
                  if (!showReconnectGw && !wppConnectedGw) {
                    crateInstanceGw();
                  }
                  e.stopPropagation();
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <FaWhatsapp size={22} color={!wppConnectedGw ? '#ff2c3a' : '#2ec9b7'} />
                <span style={{ color: !wppConnectedGw ? '#ff2c3a' : '#2ec9b7' }}>
                  {wppConnectedGw ? 'Conectado' : 'Desconectado'}
                </span>
              </div>
              {wppConnectedGw ? (
                <WppReconnect
                  show={showReconnectGw}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    isLoading={loadingQrCodeGw}
                    disabled={loadingQrCodeGw}
                    title="Desconectar"
                    onClick={() => logoutWppGw()}
                  />
                </WppReconnect>
              ) : (
                <WppReconnect
                  show={showReconnectGw}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    onClick={() => crateInstanceGw()}
                    isLoading={loadingQrCodeGw}
                    title={wppQrcodeGw ? 'Gerar novo QR Code' : 'Iniciar conexão'}
                    disabled={loadingQrCodeGw}
                  />
                  <WppModalText>
                    <h1>
                      Siga as instruções para ler o{' '}
                      <span style={{ color: '#2EC9B7' }}>QR Code</span> e efetivar
                      a conexão:
                    </h1>
                    <ol>
                      <li>
                        <p>
                          Abra seu WhatsApp e vá nos três pontinhos (no Android)
                          ou em “Configurações” (no iPhone);
                        </p>
                      </li>
                      <li>
                        <p>
                          Clique no botão <b>“Dispositivos Vinculados”</b> e leia
                          o QR Code. Caso apareça uma mensagem de erro, clique em{' '}
                          <b>”Gerar novo QR Code”</b> e tente novamente;
                        </p>
                      </li>
                      <li>
                        <p>
                          Realizada a conexão, constará no seu celular o
                          dispositivo com nome <b>"Windows"</b>.
                        </p>
                      </li>
                    </ol>
                  </WppModalText>

                  {wppQrcodeGw &&
                    (loadingQrCodeGw ? (
                      <div
                        style={{
                          width: '264px',
                          height: '264px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <img src={wppQrcodeGw} />
                    ))}
                </WppReconnect>
              )}
            </div>
          </HeaderButton>
        )}

        <NotificationBell
          onClick={(e) => {
            setNotificationsOpen(true);
            e.stopPropagation();
          }}
        >
          <FaRegBell />
          {checkNotificationsRead() && <PendingNotification />}
        </NotificationBell>
        <AnimatePresence>
          {notificationsOpen && (
            <NotificationPannel
              setNotificationsOpen={setNotificationsOpen}
              notifications={generalNotifications}
            />
          )}
        </AnimatePresence>
        <UserName>{user.fantasy_name}</UserName>
      </ButtonsDiv>

      <Logo
        src={window.innerWidth < 1095 ? logoIcon : logo}
        alt="logo"
        style={{ width: window.innerWidth < 1095 && '25px' }}
      />
      <RightButtonsDiv style={{ position: "relative" }}>
        <StatusButtonsArea>
          {user.only_pdv && (
            <StatusButton opened={opened}>
              <span>Garcom Digital</span>
              <ToggleButton
                onClick={handleRestaurantStatusChange}
                value={opened}
              />
            </StatusButton>
          )}

          {user.only_delivery && (
            <>
              <StatusButton opened={deliveryOpened} onClick={handleGoToConfigPage}>
                <span>Delivery</span>
                <StatusCircle
                  opened={deliveryOpened}
                >
                  {deliveryOpened ? "ON" : "OFF"}
                </StatusCircle>
              </StatusButton>
              <StatusButton opened={withdrawalOpened} onClick={handleGoToConfigPage}>
                <span>Retirada</span>
                <StatusCircle
                  opened={withdrawalOpened}
                >
                  {withdrawalOpened ? "ON" : "OFF"}
                </StatusCircle>
              </StatusButton>
            </>
          )}

          {!user.only_delivery && !user.only_pdv && (
            <>
              {
                user?.delivery_info?.is_delivery_allowed && (
                  <>
                    <StatusButton
                      onClick={handleGoToConfigPage}
                      opened={deliveryOpened}
                      onMouseEnter={() => setShowWdButton(true)}
                    >
                      <FaAngleDown color='#666666' style={{ transform: showWdButton && "rotateX(180deg)" }} />
                      <span>Delivery</span>
                      <StatusCircle
                        opened={deliveryOpened}
                      >
                        {deliveryOpened ? "ON" : "OFF"}

                      </StatusCircle>
                    </StatusButton>
                    {showWdButton && (
                      <StatusButton
                        opened={withdrawalOpened}
                        style={{ position: "absolute", top: 41, left: 0 }}
                        onClick={handleGoToConfigPage}
                        onMouseLeave={() => setShowWdButton(false)}
                        ref={wdButtonRef}
                      >
                        <FaAngleDown color='transparent' />
                        <span>Retirada</span>
                        <StatusCircle
                          opened={withdrawalOpened}
                        >
                          {withdrawalOpened ? "ON" : "OFF"}

                        </StatusCircle>
                      </StatusButton>
                    )}
                  </>
                )
              }

              <StatusButton opened={opened}
                onMouseEnter={() => { setShowWdButton(false); setShowGdButton(true) }}
                onMouseLeave={() => setShowGdButton(false)}
              >
                <span>Garcom Digital</span>
                <ToggleButton
                  onClick={handleRestaurantStatusChange}
                  value={opened}
                />
              </StatusButton>
              {showGdButton && (
                <StatusButton opened={opened} style={{ width: 253, position: "absolute", top: 41, right: 0 }}>
                  <span>{opened ? "Pedido via QR Code ativo" : "Pedido via QR Code inativo"}</span>
                  <StatusCircle opened={opened}>
                    {opened ? "ON" : "OFF"}
                  </StatusCircle>
                </StatusButton>
              )}
            </>
          )}
        </StatusButtonsArea>
        {user.is_pdv && (
          <Button
            title="Sair"
            inverted
            containerStyles={{ height: 40 }}
            onClick={signOut}
          />
        )}
      </RightButtonsDiv>

      <Modal isOpen={isModalQrcodeOpened} toggle={toggleModalQrcode}>
        <ModalHeader>
          <strong style={{ fontSize: 16 }}>Cardápio Digital</strong>
        </ModalHeader>
        <ModalBody>
          <p>
            Compartilhe seu Cardápio em suas redes sociais e Whatsapp clicando
            no ícone "Copiar” <FiCopy color="FF2C3A" /> . Salve a imagem ou
            copie o link. Aproveite!
          </p>

          <div
            style={{
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',

                alignItems: 'center',

                width: '100%',
              }}
            >
              <strong>Cardápio Digital (SOMENTE VISUALIZAÇÃO)</strong>

              <FiCopy
                color="FF2C3A"
                onClick={copyUrlCode}
                size={18}
                style={{ cursor: 'pointer', marginLeft: 8 }}
                title="Copiar link"
              />
            </div>
            {tableMenu && (
              <input
                id="url-qrcode"
                ref={inputRef}
                value={`https://cardapio.takeat.app/${user.nickname ? user.nickname : user.name}`}
                type="Text"
                style={{ border: 'none', width: '80%' }}
              />
            )}
          </div>

          {(user?.delivery_info?.is_delivery_allowed ||
            user?.delivery_info?.is_withdrawal_allowed) && (
              <div
                style={{
                  marginBottom: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',

                    width: '100%',
                  }}
                >
                  <strong>Cardápio Digital (PEDIDOS)</strong>
                  <FiCopy
                    color="FF2C3A"
                    onClick={copyDeliveryUrlCode}
                    size={18}
                    style={{ cursor: 'pointer', marginLeft: 8 }}
                    title="Copiar link"
                  />
                </div>

                <input
                  id="url-delivery"
                  ref={inputDeliveryRef}
                  value={`https://pedido.takeat.app/${user.nickname ? user.nickname : user.name}`}
                  type="Text"
                  style={{ border: 'none', width: '80%' }}
                />
              </div>
            )}

          {tableMenu && (
            <>
              <ImgToPrint
                ref={imgRef}
                imgSrc={tableUrl}
                tableMenu={tableMenu}
              />

              <Button
                width="100%"
                containerStyles={{ marginTop: 10 }}
                onClick={downloadPng}
                title="Baixar imagem"
              />
            </>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};