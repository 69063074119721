import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    margin: 0;
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ValuesArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;

  span {
    color: #666;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const PaymentsOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  max-width: 100%;
`;

export const OptionButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #666;

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  span {
    color: #666;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    border-color: #ff2c3a;
  }
`;

export const PaymentsMade = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  td {
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ClientArea = styled.div`
  position: relative;
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  background: #fff;

  h3 {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ClubArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #cdcdcd;
  padding-top: 10px;

  h3 {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .buttons-div {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
`;

export const DiscountApplied = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
`;
