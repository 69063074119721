import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import UserInfo from '~/components/Delivery/UserInfo';
import Cart from '~/components/OrderingSystem/Cart';

import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
} from './styles';
import { useCart } from '~/context/OrderingSystem/Cart';
import Menu from '~/pages/OrderingSystem/Menu';
import { useAuth } from '~/context/AuthContext';
import { Button } from 'ui-kit-takeat';
import { FaPlus } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { ModalConfirmation } from '~/components/ModalConfirmation';

function TableDeliveryButton({
  session,
  getMinimalSessions,
  type,
  getTablesAvailable,
  sessionId,
  handleSearchInputTables,
  setInputValue,
  setTablesFilteredSearch,
  setAllTablesAvailable,
  disable,
  isScheduling,
  ...rest
}) {
  const { closeCart, cart } = useCart();
  const [discountObs, setDiscountObs] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [deliveryTax, setDeliveryTax] = useState(null);
  const [adminPassword, setAdminPassword] = useState(null);
  const { user } = useAuth();

  const [modalAdvice, setModalAdvice] = useState(false);

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
  }

  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  function toggleModalOperation() {
    setDeliveryTax(null);
    if (cart.length > 0) {
      setModalAdvice(true);
    } else {
      setIsModalOperationOpened(!isModalOperationOpened);
      closeCart();
      setDeliveryTax(null);
    }
  }

  function confirmCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    toggleModalAdvice();
    closeCart();
    setDeliveryTax(null);
  }

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }
  const tableId = type !== 'available' ? session?.table?.id : session?.id;
  function toggleModalOperationWithoutCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    setDiscount(null);
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <div>
      <Button
        {...rest}
        title="Fazer pedido"
        icon={<FaPlus />}
        onClick={() => {
          setDeliveryTax(null);
          if (isScheduling) {
            if (user.is_order_scheduling_active) {
              setIsModalOperationOpened(true);
            } else {
              toast.error('Agendamento desativado no momento!');
            }

          } else if (
            !user.delivery_info?.is_delivery_active &&
            !user.delivery_info?.is_withdrawal_active

          ) {
            toast.error('Seu delivery está fechado no momento!');
          } else {
            setIsModalOperationOpened(true);
          }
        }}
        disabled={disable}
        containerStyles={{
          width: 'calc(100% - 10px)',
          height: 30,
          margin: '5px 5px 0',
        }}
        inverted
      />
      <ModalConfirmation
        title="Você possui itens no carrinho. Tem certeza que deseja sair sem fazer
            o pedido?"
        isOpen={modalAdvice}
        toggle={toggleModalAdvice}
        confirm={confirmCloseCart}
        confirmText={'Sim, sair e excluir o carrinho'}
        cancel={toggleModalAdvice}
        cancelText={'Irei fazer o pedido'}
      />

      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="takeat"
      >
        <ModalBody>
          <OperationArea browserZoomLevel={browserZoomLevel}>
            <OrderSystemArea browserZoomLevel={browserZoomLevel}>
              <Menu
                tableId={tableId}
                toggleModalOperation={toggleModalOperation}
                getTablesAvailable={getTablesAvailable}
                getSessions={getMinimalSessions}
                session={sessionId}
                handleSearchInputTables={handleSearchInputTables}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                setAllTablesAvailable={setAllTablesAvailable}
                isTableDelivery
              />
            </OrderSystemArea>
            <BillTableArea browserZoomLevel={browserZoomLevel}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Delivery </h2>{' '}
                <span
                  style={{ fontWeight: 'bold', fontSize: 20, paddingRight: 10 }}
                >
                  Carrinho
                </span>
              </div>
              <Cart
                isTableDelivery
                setDiscount={setDiscount}
                setDiscountObs={setDiscountObs}
                discount={discount}
                discountObs={discountObs}
                deliveryTax={deliveryTax}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                user={user}
                isBalcony={false}
              />
              <UserInfo
                toggleModalOperation={toggleModalOperationWithoutCloseCart}
                discount={discount}
                discountObs={discountObs}
                setDeliveryTax={setDeliveryTax}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                user={user}
              />
              {/* <ButtonsArea>
                <button>Fazer PEdido</button>
              </ButtonsArea> */}
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default TableDeliveryButton;