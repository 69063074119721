import React from 'react';
import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco ,StyleKanbanWarning} from './styles';
import OrderAndamentoDelivery from '../../Items/OrderAndamentoDelivery';

export default function CardOrdersAndamento({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleItemReady,
  handleUpdate,
  hasOrderActive,
  countOrders,
}) {
  const style_kanban_warning = {
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    height:"100%",
    fontWeight: 700,
    fontSize: "14px", 
    lineHeight: "21px",
  }
  return (
    <Bloco>
      <Header>
        <div>EM ANDAMENTO </div>
        <ItemsCount style={{ color: '#ffa814' }}>{countOrders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .map((item) => (
              <OrderAndamentoDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
              />
            ))
        )}
        {!hasOrderActive && (
        <StyleKanbanWarning>
          <p style={{color:"#666666"}}>Não há pedido</p>
          <p style={{color:"#666666"}}>no momento</p>
        </StyleKanbanWarning>
      )}

        
      </Content>
    </Bloco>
  );
}