import React, { useEffect, useState } from 'react';
import { StyledButton, Badge } from './styles';
import { FaCheck, FaCircle } from 'react-icons/fa';
import { usePreCart } from '~/context/PreCartContext';

export const CategoryButton = ({
  text,
  selected,
  required,
  onClick,
  category,
}) => {
  const { preCart } = usePreCart();
  const [requiredChecked, setRequiredChecked] = useState(false);


  useEffect(() => {
    if (required && preCart?.complementsCategory?.length > 0) {
      const categoryInCart = preCart.complementsCategory.find(
        (cat) => cat.id === category.id
      );
      if (categoryInCart) {
        const catAmount = categoryInCart.complements.reduce((acc, curr) => acc + curr.amount, 0);
        if (catAmount >= category.minimum) {
          setRequiredChecked(true);
        } else {
          setRequiredChecked(false);
        }
      } else {
        setRequiredChecked(false);
      }
    } else {
      setRequiredChecked(false);
    }
  }, [preCart])

  return (
    <StyledButton selected={selected} required={required} requiredChecked={requiredChecked} onClick={(e) => onClick(e)}>
      <span>{text}</span>
      {required && (
        <>
          {requiredChecked ? (
            <FaCheck size={8} color='#2EC9B7'/>
          ) : (
            <Badge>Obrigatório</Badge>
          )}
        </>
      )}
    </StyledButton>
  );
};