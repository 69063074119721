import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalBody, Collapse } from 'reactstrap';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { Container, Header, Card, HelpDiv } from './styles';

import image1_1 from '../../assets/img/help/1.1.png';
import image1_2 from '../../assets/img/help/1.2.png';
import image1_3 from '../../assets/img/help/1.3.png';
import image1_4 from '../../assets/img/help/1.4.png';
import image1_5 from '../../assets/img/help/1.5.png';

import image2_1 from '../../assets/img/help/2.1.png';
import image3_1 from '../../assets/img/help/3.1.jpg';
import image3_2 from '../../assets/img/help/3.2.jpg';
import image4_1 from '../../assets/img/help/4.1.jpg';
import image5_1 from '../../assets/img/help/5.1.jpg';
import image5_2 from '../../assets/img/help/5.2.jpg';
import image6_1 from '../../assets/img/help/6.1.jpg';
import image7_1 from '../../assets/img/help/7.1.jpg';
import image8_1 from '../../assets/img/help/8.1.jpg';
import image9_1 from '../../assets/img/help/9.1.png';
import image9_2 from '../../assets/img/help/9.2.png';
import image9_3 from '../../assets/img/help/9.3.png';

import image10_1 from '../../assets/img/help/10.1.png';
import image11_1 from '../../assets/img/help/11.1.png';
import image12_1 from '../../assets/img/help/12.1.png';
import image12_2 from '../../assets/img/help/12.2.png';

function Help() {
  const [arrayHelp, setArrayHelp] = useState([]);
  // let arrayHelp = [];

  const handleCollapse = (id) => {
    if (arrayHelp.includes(id)) {
      const newCollapse = arrayHelp.filter((c) => c !== id);
      setArrayHelp(newCollapse);
    } else {
      setArrayHelp((state) => [...state, id]);
    }
  };

  return (
    <Container>
      <Header>
        <h2>Ajuda</h2>
      </Header>
      {/* {groups.map((group) => (
        <FiscalGroupComponent group={group} user={user} setGroups={setGroups} />
      ))} */}
      <Card>
        <Row style={{ cursor: 'pointer' }} onClick={(e) => handleCollapse(1)}>
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(1) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                {' '}
                1 - Minha impressão automática não está saindo, o que faço?
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(1)}>
          <HelpDiv>
            <p style={{fontSize: 16}}><b>Primeiramente devemos lembrar que, para as impress&otilde;es autom&aacute;ticas funcionarem, o app de impress&atilde;o precisa estar aberto. Quando ele &eacute; aberto e voc&ecirc; clica no &ldquo;x&rdquo;, ele vai para segundo plano.</b></p>
            <br/>
            <p><b>Etapa 1 - Verifique se possui o app de impress&atilde;o</b></p>
            <p>Abaixo est&aacute; a imagem dele. Basta localizar(geralmente fica na &aacute;rea de trabalho). Caso n&atilde;o n&atilde;o localize o programa, entre em contato com o suporte e pe&ccedil;a para que seja instalado.</p>
            <div style={{ textAlign: 'center' }}>
              <img src={image1_1} alt="2.1" style={{ align: 'center' }} />
            </div>
            <p><b>Etapa 2 - Verifique se ele est&aacute; aberto</b></p>
            <p>Se j&aacute; localizou o app, clique na setinha no canto inferior direito, onde mostram os programas abertos em segundo plano, depois verifique se o encontra l&aacute;. Caso tenha no seu computador, mas n&atilde;o est&aacute; ali, significa que n&atilde;o est&aacute; aberto, fazendo com que n&atilde;o imprima os pedidos automaticamente. Nesse caso, &eacute; s&oacute; dar 2 cliques no &iacute;cone que localizou anteriormente para abri-lo.</p>
            <div style={{ textAlign: 'center' }}>
              <img src={image1_2} alt="2.1" style={{ align: 'center' }} />
            </div>
            <p><b>Etapa 3 - Verifique se est&aacute; logado</b></p>
            <p>Se a tela abaixo for mostrada ao abrir o programa, basta entrar com o mesmo login que utiliza no site. Se n&atilde;o abrir com essa tela, siga para a etapa 4.</p>
            <div style={{ textAlign: 'center' }}>
              <img src={image1_3} alt="2.1" style={{ align: 'center' }} />
            </div>
            <p><b>Etapa 4 - Verifique se as impressoras est&atilde;o configuradas</b></p>
            <p>Depois de logado, o programa precisa estar aberto dessa forma, mostrando suas impressoras. Nesse caso, as impress&otilde;es j&aacute; devem funcionar normalmente. Se n&atilde;o estiver dessa forma, verifique a etapa 5.</p>
            <div style={{ textAlign: 'center' }}>
              <img src={image1_4} alt="2.1" style={{ align: 'center' }} />
            </div>
            <p><b>Etapa 5 - Impressoras n&atilde;o cadastradas</b></p>
            <p>Se estiver assim, sem impressoras exibidas, significa que elas n&atilde;o foram configuradas no seu app de impress&atilde;o. Nesse caso, solicite o suporte.</p>
            <div style={{ textAlign: 'center' }}>
              <img src={image1_5} alt="2.1" style={{ align: 'center' }} />
            </div>
            <br/>
            <p>Se realizou todas as etapas e suas impress&otilde;es ainda n&atilde;o funcionaram, contate o suporte e solicite ajuda.</p>
          </HelpDiv>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(2)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(2) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                2 - Não consigo lançar nem receber pedidos, o que aconteceu?
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(2)}>
          <div style={{ padding: 15 }}>
            <p>
              Primeiramente, você deve verificar o erro que está ocorrendo
              quando há a tentativa de lançamento de produto ou recebimento de
              pedido enquanto o restaurante está fechado. Nesse caso, será
              exibida uma notificação informando sobre isso, e o restaurante
              deve ser aberto para que seja possível realizar a operação.
            </p>
            <div style={{ textAlign: 'center' }}>
              <img src={image2_1} alt="2.1" style={{ align: 'center' }} />
            </div>

            <p>
              Ao clicar no botão identificado pela seta 1 na imagem acima, será
              exibido um painel com os módulos de venda disponíveis para o seu
              restaurante. Você pode clicar no módulo desejado para ativá-lo e
              começar a receber pedidos na modalidade escolhida.
            </p>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(3)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(3) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                3 - Qual meu link de pedidos? Ou de visualização do cardápio?
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(3)}>
          <div style={{ padding: 15 }}>
            <p>
              O link para pedidos de delivery está localizado dentro do próprio
              painel de delivery, logo acima das colunas de etapas do pedido.
              Você pode usar o botão ao lado do link para copiá-lo
              instantaneamente e colá-lo em uma conversa com um cliente ou em
              qualquer outro lugar que desejar, conforme mostra a imagem a
              seguir.
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image3_1}
                alt="3.1"
                style={{ align: 'center', width: '90%' }}
              />
            </div>

            <p>
              Clicando na opção “Divulgar Cardápio”, você terá acesso aos seus links de visualização e pedidos. 
              Lembrando que um é apenas para que o cardápio seja visualizado, não podendo realizar pedidos por ele.
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image3_2}
                alt="3.2"
                style={{ align: 'center', width: '90%' }}
              />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(4)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(4) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>4 - Não consigo emitir cupom/nota fiscal</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(4)}>
          <div style={{ padding: 15 }}>
            <p>
              Quando ocorre uma tentativa de emissão fiscal e a SEFAZ
              (Secretaria da Fazenda) rejeita, o sistema retorna com o erro
              informado. A partir dessa informação, é necessário fazer as
              adequações necessárias de acordo com o erro. Comumente, erros
              relacionados às informações fiscais dos produtos são recebidos.
              Portanto, as informações fiscais dos produtos mencionados no erro
              devem ser atualizadas de acordo com os padrões contábeis
              necessários.
            </p>

            <p>
              Dentro do produto (em CARDÁPIO &gt; PRODUTOS &gt; EDITAR PRODUTO),
              é possível para o lojista escolher entre os Grupos Fiscais, que
              fornecem as informações fiscais basicamente completas. É
              necessário apenas inserir manualmente o NCM (Nomenclatura Comum do
              Mercosul), que deve ser buscado com a ajuda de um contador ou nos
              resultados adequados encontrados no próprio Google.
            </p>
            <div style={{ textAlign: 'center', width: '94%' }}>
              <img
                src={image4_1}
                alt="4.1"
                style={{ align: 'center', width: '94%' }}
              />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(5)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(5) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                5 - Meu produto não aparece no cardápio pro cliente
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(5)}>
          <div style={{ padding: 15 }}>
            <p>
              Quando um produto está cadastrado no seu sistema, mas não aparece
              para o cliente, a única coisa que pode estar causando isso são as
              configurações da categoria ou a disponibilidade do produto.
            </p>

            <p>
              Você deve começar verificando as configurações e a disponibilidade
              da categoria. Verifique se a categoria está habilitada para o dia
              da semana em questão e se está dentro do horário definido nas
              configurações. Verifique também se a função de uso exclusivo (que
              faz a categoria aparecer apenas no painel do lojista e do garçom)
              está habilitada. Se o problema não for solucionado e compreendido
              com as configurações da categoria, verifique a disponibilidade do
              produto.
            </p>
            <div style={{ textAlign: 'center', width: '95%' }}>
              <img
                src={image5_1}
                alt="5.1"
                style={{ align: 'center', width: '95%' }}
              />
            </div>
            <p>
              A verificação da disponibilidade do produto é feita conforme
              mostrado na imagem a seguir. Observe e clique na chave caso deseje
              alterar o status atual do produto para a modalidade de venda
              desejada.
            </p>
            <div style={{ textAlign: 'center', width: '95%' }}>
              <img
                src={image5_2}
                alt="5.2"
                style={{ align: 'center', width: '95%' }}
              />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(6)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(6) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                6 - Meu cliente não consegue pedir no Delivery, dá erro ao fazer
                pedido
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(6)}>
          <div style={{ padding: 15 }}>
            <ol>
              <li>
                O lojista deve confirmar se cadastrou os endereços/regiões em
                que faz entrega. Caso não haja nada cadastrado, o cliente não
                conseguirá fazer os pedidos. Esse cadastro deverá ser feito
                dentro de DELIVERY > ENDEREÇOS.{' '}
                <a
                  href="https://www.youtube.com/watch?v=4kQVE0b8sd8&list=PLzE6n2kKmJw5OH3q5Sz29ygIRP9MAhj85"
                  target="_blank"
                >
                  Clique aqui
                </a>{' '}
                para assistir ao vídeo que ensina a fazer o cadastro.
              </li>

              <div style={{ textAlign: 'center', width: '90%' }}>
                <img
                  src={image6_1}
                  alt="6.1"
                  style={{ align: 'center', width: '90%' }}
                />
              </div>

              <li>
                Nem o cliente nem o lojista podem inserir letras no campo de
                NÚMERO do endereço de entrega. Caso ocorra, um erro será gerado
                ao fazer o pedido.
              </li>
              <li>
                Se o cliente abriu o cardápio antes da loja estar aberta, ele
                deve sair do cardápio e entrar novamente. Assim, a página será
                recarregada e o pedido poderá ser feito, conforme mencionado na
                resposta anterior.
              </li>
            </ol>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(7)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(7) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>7 - Meu estoque não está abatendo, há algum erro?</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(7)}>
          <div style={{ padding: 15 }}>
            <p>
              O estoque tem uma rotina de atualização automática às 3h da
              madrugada. No entanto, caso necessite que a atualização ocorra no
              momento em que estiver visualizando o estoque, você pode usar o
              botão "ATUALIZAR" em destaque na imagem a seguir.
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image7_1}
                alt="7.1"
                style={{ align: 'center', width: '90%' }}
              />
            </div>

            <p>
              Se, mesmo assim, as deduções não estiverem ocorrendo, você deve
              verificar se a ficha técnica dos produtos, dos quais você está
              querendo ver a dedução no estoque, estão devidamente preenchidas.
              Caso precise de auxílio no preenchimento das fichas técnicas,
              confira o vídeo a seguir:{' '}
              <a
                href="https://www.youtube.com/watch?v=wJ8OMgaITDg&list=PLzE6n2kKmJw7i09LQiq2CsU14vwUfpN0z"
                target="_blank"
              >
                Estoque e Insumos
              </a>
              .
            </p>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(8)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(8) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>8 - Não consigo cancelar ou transferir itens</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(8)}>
          <div style={{ padding: 15 }}>
            <p>
              Existe uma senha administrativa do sistema que todo usuário com
              acesso administrativo consegue visualizar no campo dentro do menu
              "MEU RESTAURANTE". Essa senha pode ser alterada e salva conforme a
              preferência de um usuário administrativo, e será usada para os
              processos de cancelamento e descontos.
            </p>
            <p>
              A senha de transferência também deve ser definida no seu
              respectivo campo, localizado dentro do mesmo menu. Você pode
              encontrar a imagem ilustrativa a seguir:
            </p>
            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image8_1}
                alt="8.1"
                style={{ align: 'center', width: '90%' }}
              />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(9)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(9) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>9 - Como utilizar mais de um caixa? </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(9)}>
          <div style={{ padding: 15 }}>
            <p>
              Agora é possível ativar a função de caixa por usuário. Essa função
              permite que todo usuário que possuir acesso ao caixa possa abrir
              um caixa. Para verificar/cadastrar os usuários, basta ir em{' '}
              <strong>Cadastros > Gerenciar usuários</strong>.
            </p>

            <p>
              <strong>
                <em>Obs:</em> Apenas usuário com nível administrativo pode
                ativar essa funcionalidade.
              </strong>
            </p>

            <p>
              Para ativar essa função, acesse <em>menu > meu restaurante</em> e
              identifique “Vincular o caixa ao usuário”.
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image9_1}
                alt="9.1"
                style={{ align: 'center', width: '90%' }}
              />
            </div>

            <p>
              Ao marcar a caixa, você deve escolher quem será responsável por
              receber os pagamentos online:
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image9_2}
                alt="9.2"
                style={{ align: 'center', width: '90%' }}
              />
            </div>

            <p>
              Pronto! Agora os usuários operacionais conseguirão abrir caixas
              independentes, e o usuário administrador poderá acompanhar todos
              caixas abertos no restaurante e também individualmente.
            </p>

            <div style={{ textAlign: 'center', width: '90%' }}>
              <img
                src={image9_3}
                alt="9.3"
                style={{ align: 'center', width: '90%' }}
              />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(10)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(10) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                10 - Quero cancelar, reabrir ou reativar uma comanda, como faço?
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(10)}>
          <div style={{ padding: 15 }}>
            <p>
              No sistema, não é possível de fato excluir uma comanda. O que você pode fazer, é apagar os itens dela e os pagamentos informados, deixando a comanda zerada. 
              Depois disso, basta fechá-la, fazendo com que não interfira em relatórios, valores e nada no sistema.
            </p>
            <p>
              Para localizar uma comanda, vá na aba de comandas pelo seguinte caminho:
            </p>
            <p>
              Menu &#62; Operação &#62; Comandas
            </p>

            <p>
              Nessa aba, você pode ver as comandas abertas, fechadas, finalizadas e pesquisar pelo número dela, escolhendo o método de busca e informando o número. 
              Também é possível filtrar, excedo na coluna de pesquisa, o período que deseja procurar as comandas. 
              O filtro fica no canto superior direito.
            </p>

            <p>
              Comandas finalizadas podem ser reativadas e comandas fechadas podem ser reabertas. 
              Lembre-se: para reativar uma comanda, você precisa de uma senha administrativa. 
              E se ela for reativada, voltará a ser uma comanda fechada.
            </p>

            <div style={{ textAlign: 'center' }}>
              <img src={image10_1} alt="10.1" style={{ align: 'center' }} />
            </div>
          </div>
        </Collapse>


        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(11)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(11) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                11 - O que é a senha administrativa e como faço uma?
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(11)}>
          <div style={{ padding: 15 }}>
            <p>
              A senha administrativa é definida pelos administradores da loja. 
              Com ela, o sistema passará a solicitar a senha em alguns momentos, como: cancelar um pedido, dar desconto ou reativar uma mesa. 
              Caso você seja um administrador e não tenha ou não se lembre dela, pode alterar pelo seguinte caminho:
            </p>

            <p>
              Menu &#62; Perfil &#62; Editar meu negócio 
            </p>

            <p>
              No campo de senha administrativa, defina sua senha.
              Vale lembrar que é necessário salvar as alterações no fim da página
            </p>

            <div style={{ textAlign: 'center' }}>
              <img src={image11_1} alt="11.1" style={{ align: 'center' }} />
            </div>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(12)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(12) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                12 - Está dando erro de NCM ao emitir nota
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(12)}>
          <div style={{ padding: 15 }}>
            <p>
              Se na hora de emitir a nota fiscal aparece um erro como esse:
            </p>

            <div style={{ textAlign: 'center' }}>
              <img src={image12_1} alt="12.1" style={{ align: 'center' }} />
            </div>

            <p>
              O que você precisa fazer, é preencher os dados fiscais dos seus produtos. 
              Esse erro indica que os dados fiscais não estão preenchidos corretamente, fazendo com que não seja possível emitir a nota.
            </p>

            <p>
              Para resolver, nesse caso, você verá um campo abaixo desse erro, da seguinte forma:
            </p>


            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <img src={image12_2} alt="12.2" style={{ align: 'center' }} />
            </div>

            <p>
              Basta informar o NCM do produto, o grupo fiscal, clicar no botão de confirmar para salvar os dados e depois conseguirá emitir a nota clicando em “Finalizar e Emitir Nota Fiscal”
            </p>
          </div>
        </Collapse>

        <div
          style={{
            borderBottom: '1px solid #E1E3DE',
            width: '97%',
            marginTop: 10,
          }}
        />

        <Row
          style={{ cursor: 'pointer', marginTop: 15 }}
          onClick={(e) => handleCollapse(13)}
        >
          <Col>
            <h4 style={{ marginBottom: 0 }}>
              {arrayHelp.includes(13) ? (
                <MdExpandLess
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <MdExpandMore
                  size={20}
                  title="Mostrar Resposta"
                  style={{ marginRight: 5 }}
                />
              )}
              <strong>
                13 - Quero o acesso ao treinamentos, vídeos e plantão tira dúvidas
              </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(13)}>
          <div style={{ padding: 15 }}>
            <p>
              <a href="https://www.takeat.app/treinamentos-exclusivos-clientes" target="_blank" style={{color: 'blue'}}>Ao clicar aqui</a>, 
              você pode consultar nossa <b>agenda de treinamentos</b> dos assuntos que quiser, e se inscrever para a data que achar melhor.
              Após a inscrição, você receberá o link para entrar na reunião no email que tiver informado no formulário.
            </p>

            <p>
              Nós temos também uma <b>série de vídeos</b> que ensinam diversas funções dentro do sistema. <a href="https:/linktr.ee/videoaulastakeat" target="_blank" style={{color: 'blue'}}>Ao clicar aqui</a>, você pode acessar e escolher o vídeo que deseja.
            </p>

            <p>
              E temos todas as segundas e quartas, das 15h às 17h e nas terças e quintas, das 9h às 11h, um <b>plantão ao vivo</b> do suporte para tirar dúvidas acerca do sistema. Aproveitem para participar, <a href="https://meet.google.com/adz-ummu-brb" target="_blank" style={{color: 'blue'}}>clicando aqui.</a>
            </p>
          </div>
        </Collapse>
      </Card>
    </Container>
  );
}

export default Help;
