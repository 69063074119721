
import React, { useRef, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Container, CategoryList, CategoryThumbnailLink } from './styles';

import { useAuth } from '~/context/AuthContext';
import { useEffect } from 'react';

import { FaPhone, FaUser, FaUserPlus } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import { FaPencil } from 'react-icons/fa6';
import { Modal } from 'reactstrap';
import Input from '~/components/Form/Input';

import { Form } from '@unform/web';
import InputMask from 'react-input-mask';
import { Button, Hyperlink } from 'ui-kit-takeat';
import { ModalGrid } from '../OrderingSystem/Cart/styles';
import api from '~/services/api';

function BuyersScrollNav({ buyers, createBuyer, newBuyer }) {

  const { setBuyerSelectedByWaiter, buyerCreatedByWaiter, buyerSelectedByWaiter } = useAuth()
  const [selectedItem, setSelectedItem] = useState()

  function handleSelectBuyer(name, id) {

    if (name[0] === '+') {
      if (buyerCreatedByWaiter) {
        toast.error('Você já criou um usuário, faça o pedido para o mesmo, antes de criar outro.')
      } else {
        createBuyer(name)
      }

    } else {
      setBuyerSelectedByWaiter({
        name: name,
        id: id
      })
    }
  }


  function handleSelectItem(id) {
    if (id !== -33801) {
      setSelectedItem(id);
    }
  }

  function handleSelectUser() {
    setSelectedItem(newBuyer);
    setBuyerSelectedByWaiter('')
  }

  useEffect(() => { newBuyer ? handleSelectUser() : setSelectedItem(-33800) }, [newBuyer])

  useEffect(() => {
    if (!buyerSelectedByWaiter) {
      setSelectedItem(-33800)
    }
  }, [buyerSelectedByWaiter]);

  const formEditRef = useRef(null);
  const [isModalEditBuyerOpened, setIsModalEditBuyerOpened] = useState(false);

  function toggleModalEditBuyer() {
    setIsModalEditBuyerOpened(!isModalEditBuyerOpened);
    setIsAddClient(false);
    setClientInfo(null);
    setCpfClient('');
    setCepClient('');
    setBirthdayClient('');

    if(!isModalEditBuyerOpened && buyerSelected?.phone && buyerSelected.phone[14] && buyerSelected.phone[14] !== '_'){
      getClient();
      // setIsAddClient(true);
    }
  }

  const [isAddClient, setIsAddClient] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [clientPhone, setClientPhone] = useState('');
  const [cpfClient, setCpfClient] = useState('');
  const [cepClient, setCepClient] = useState('');
  const [birthdayClient, setBirthdayClient] = useState('');
  const [buyerSelected, setBuyerSelected] = useState(null);

  async function editClient(data) {
    try {
      if(clientInfo.hasClient){
        await api.put(
          `restaurants/clients/${clientInfo.client.id}`,
          data
        );
        
        toggleModalEditBuyer();
        toast.success('Cliente atualizado com sucesso!');
        return;
      }else{
        await api.post(
          `restaurants/clients`,
          data
        );
        
        toggleModalEditBuyer();
        toast.success('Cliente cadastrado com sucesso!');
        return;
      }
    } catch (err) {
      console.log(err);
      toast.error('Não foi possível atualizar o cadastro! Tente novamente');
    }
  }

  async function getClient() {
    try {
      const res = await api.post('/restaurants/get-clients', {
        phone: buyerSelected?.phone
      });

      setClientInfo(res.data);

      const client = res.data.client;
      if(client){
        setCpfClient(client.cpf);
        setCepClient(client.cep);
        setBirthdayClient(client.birthday);
        setIsAddClient(true);
      }
    } catch (error) {
      console.log('aaaa', error);
      toast.error('Erro ao consultar cliente');
    }
  }

  useEffect(() => {
    const buyer = buyers.find(buy => buy.id === selectedItem);
    if(buyer){
      setBuyerSelected(buyer);
    }
  }, [selectedItem]);

  return (
    <Container>
      <ScrollContainer className="scroll-container">
        {buyers && buyers.map(buyer => (
          <CategoryList 
            onClick={() => { 
              handleSelectBuyer(buyer.name ? buyer.name : buyer.phone, buyer.id); 
              handleSelectItem(buyer.id); 
              (selectedItem === buyer.id && selectedItem !== -33800) 
                && buyerSelected?.phone 
                && buyerSelected.phone[14] 
                && buyerSelected.phone[14] !== '_'
                && toggleModalEditBuyer() 
            }}
            key={buyer.id}
          >
            <CategoryThumbnailLink
              alan={selectedItem === buyer.id ? "selected" : ""} buttonAdd={buyer?.name[0] === '+'}
            ><span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{buyer?.name[0] === '+' ? <FaUserPlus color='#3BD2C1' /> : buyer?.name ? buyer?.name[0].toUpperCase() : <FaUser />}</span>
            </CategoryThumbnailLink>
            <p>{buyer?.name || buyer?.phone.substring(11, 15)} 
              {selectedItem === buyer.id 
                && selectedItem !== -33800 
                //só pode editar o cliente se tiver telefone
                && buyerSelected?.phone 
                && buyerSelected.phone[14] 
                && buyerSelected.phone[14] !== '_' 
                && <FaPencil size={11} style={{color: '#666666', marginLeft: 2}}/>
              }
            </p>
          </CategoryList>
        ))}
      </ScrollContainer>

      <Modal isOpen={isModalEditBuyerOpened} toggle={toggleModalEditBuyer}>
        <Form onSubmit={editClient} ref={formEditRef} initialData={clientInfo?.client || null}>
          <div style={{ display: 'grid', gap: '5px', padding: '20px' }}>
            <div style={{fontSize: 16, fontWeight: 700}}>
              Editar cliente
            </div>
            <ModalGrid>
              <Input name="name" label="Nome do cliente *" value={buyerSelected?.name || ''} disabled={true}/>
              <Input name="phone" label="Celular" value={buyerSelected?.phone || ''} disabled={true}/>
            </ModalGrid>

            {!isAddClient && buyerSelected?.phone && buyerSelected.phone[14] && buyerSelected.phone[14] !== '_' &&
              <div style={{marginLeft: 'auto'}}>
                <Hyperlink fontSize="14px" textDecoration="underline" onClick={() => { 
                  getClient();
                  setIsAddClient(true);
                }}>
                  Cadastro completo
                </Hyperlink>
              </div>
            }

            {isAddClient &&
              <ModalGrid>
                <Input name="email" label="E-mail" placeholder="(opcional)"/>

                <InputMask mask="999.999.999-99" maskChar="" value={cpfClient} onChange={e => setCpfClient(e.target.value)}>
                  <Input name="cpf" label="CPF" placeholder="(opcional)" />
                </InputMask>

                <InputMask mask="99/99/9999" maskChar="" value={birthdayClient} onChange={e => setBirthdayClient(e.target.value)}>
                  <Input name="birthday" label="Data de Nascimento" placeholder="(opcional)" />
                </InputMask>

                <InputMask mask="99.999-999" value={cepClient} onChange={e => setCepClient(e.target.value)}>
                  <Input name="cep" label="CEP" type="text" placeholder="(opcional)" />
                </InputMask>

                <Input name="street" label="Rua" placeholder="(opcional)" />

                <Input name="bairro" label="Bairro" placeholder="(opcional)" />

                <Input name="number" label="Número" placeholder="(opcional)"/>

                <Input name="complement" label="Complemento" placeholder="(opcional)"/>
              </ModalGrid>
            }
            <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
              <Button
                type="button"
                onClick={toggleModalEditBuyer}
                title="Cancelar"
                buttonColor="#ff2c3a"
              />
              <Button type="submit" title="Salvar" buttonColor="#2ec9b7" />
            </div>
          </div>
        </Form>
      </Modal>
    </Container>
  );
}

export default BuyersScrollNav;
