import styled, { css } from "styled-components";
import { motion } from 'framer-motion';

export const Content = styled.button`
  border: none;
  font-size: 14px;
  display: flex;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const OperationArea = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  position: relative;

  flex-wrap: wrap;
`;
export const OrderSystemArea = styled.div`
  width: 58%;
  height: 80vh;
  max-height: 800px;
  overflow-y: hidden;
  border-right: 1px solid #33333320;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const BillTableArea = styled.div`
  width: 40%;
  height: 80vh;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 80px;

  h2 {
    padding-left: 8px;
  }

  @media (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const ButtonsArea = styled.div`
  z-index: 999;
  padding-top: 16px;
  height: 60px;
  border-top: 1px solid #33333330;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  background-color: #fff;
`;

export const AlterButton = styled.button`
  border: none;
  padding: 10px 20px;
  background: ${(props) =>
		props.disabled ? "#D9D9D9" : props.confirm ? "#3BD2C1" : "#FF2C3A"};
  border-radius: 5px;

  ${(props) => props.disabled && "pointer-events: none"};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0;
  color: #ffffff;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const CloseButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #dc3545;

  display: flex;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const CashierButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  color: white;
  font-size: 12px;

  border: none;
  background: #28a745;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ReopenButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;
  color: #000;

  border: none;
  background: #ffc107;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #17a2b8;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const TablePayment = styled.div`
  border-top: 1px solid #ffffff40;
  height: 40%;
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 24px;

  /* background-color: #fff;
  color: #dc3545; */

  p {
    font-size: 12px;

    & + p {
      margin-top: -10px;
    }
  }
`;

export const ProductsCard = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
`;

export const CardHeader = styled.div`
  background-color: #f4f4f4;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardHeaderPhone = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    width: 100%;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #4d4d4c;
  }

  svg {
    color: #ff1f2b;
  }
`;

export const CardHeaderData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    color: #6c6c6c;
  }
`;

export const CardHeaderRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;

  input[type='radio'] {
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    border: 2px solid #6c6c6c;
    border-radius: 12px;
    outline: none;
    background-color: inherit;
    &:checked {
      border-color: #FF2C3A;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px

  }
  span {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 700;
    color: #6c6c6c;
  }
`;

export const Fill = styled.span`
  bottom: 1px;
  position: relative;
  left: -14px;
  box-sizing: border-box;
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background: #FF2C3A;
`;

export const CreateUserAddressButton = styled.button`
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
`;

export const UserInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  width: 100%;
  height: auto;
  padding: 10px;
  cursor: pointer;

  border-radius: 7px;
  filter: ${(props) =>
		props.selected
			? "drop-shadow(0 0 4px rgba(250, 0, 0, 0.7));"
			: "drop-shadow(0 0 4px rgba(0, 0, 0, 0.1))"};
  transition: filter 0.3s;

  :hover {
    filter: ${(props) =>
		!props.children[0].props.disabled &&
      "drop-shadow(0 0 4px rgba(250, 0, 0, 0.7))"};
  }

  svg {
    :hover {
      transition: color 0.5s;
      color: red;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const CardProduct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  gap: 10px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4c;
    ${(props) => props.canceled && "text-decoration: line-through"};
  }

  span:first-child {
    margin-right: auto;
  }
`;

export const CardComplement = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-start;
  align-items: center;
  margin: 0 103px 0 30px;
  gap: 10px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #4d4d4c;
    ${(props) => props.canceled && "text-decoration: line-through"};
  }

  span:first-child {
    margin-right: auto;
  }
`;

export const ButtonCancel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-width: 93px;
`;

export const XButton = styled.button`
  width: 30px;
  height: 30px;
  background: #ff2c3a;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmDiv = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4d4d4c;
  min-width: 93px;
  display: flex;
  justify-content: flex-end;

  position: relative;

  span {
    position: absolute;
    top: -24px;
    left: -14px;
    width: 124px;
  }
`;

export const ConfirmationButton = styled.button`
  width: 44px;
  height: 30px;
  background: ${(props) => (props.confirm ? "#3BD2C1" : "#ff2c3a")};
  border-radius: 5px;
  border: none;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
`;

export const CanceledButton = styled.div`
  border: none;
  pointer-events: none;
  background: #d9d9d9;
  border-radius: 5px;
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
`;

export const AddedItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AmountArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;

export const SvgContainer = styled.div`
  color: #ff2c3a;
  border: 2px solid #ff2c3a;
  border-radius: 15px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const DataPickerArea = styled.div`
  display: flex;
  height: 335px;
  justify-content: space-between;

  @media (max-width: 1025px) {
    flex-direction: column;
    height: 600px;
  }

  .react-datepicker {
    border: none;
    position: absolute;
    top: -27px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month {
    width: 305px;
    margin: 0;

    @media (max-width: 1025px) {
      width: 305px;
    }

    @media (min-width: 1026px) and (max-width: 1441px) {
      width: 214px;
    }

    @media (min-width: 1442px) and (max-width: 1681px) {
      width: 283px;
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    background: #f4f4f4;
    color: #979797;
    border-radius: 3px;
  }

  .react-datepicker__navigation {
    opacity: 2;
    width: 50px;
    height: 50px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ff2c3a;
  }

  .react-datepicker__navigation--next {
    right: 0px;
  }

  .react-datepicker__day-names {
    background-color: #ffffff;

    div {
      width: 41px;
      margin: 0;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #666666;
    }

    div:first-child,
    div:last-child {
      color: #979797;
    }
  }

  .react-datepicker__day {
    width: 41px;
    margin: 0;

    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }

  .react-datepicker__day--selected {
    background-color: #ff2c3a;
    color: #ffffff;
    border-radius: 20px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
  }

  .react-datepicker__current-month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #ff2c3a;

    height: 44px;
  }

  .react-datepicker__header {
    background: #f4f4f4;
    border: none;
    border-radius: 0px;
    text-transform: capitalize;
    width: 305px;

    @media (max-width: 1025px) {
      width: 305px;
    }

    @media (min-width: 1026px) and (max-width: 1441px) {
      width: 214px;
    }

    @media (min-width: 1442px) and (max-width: 1681px) {
      width: 283px;
    }
  }

  .react-datepicker__input-container {
    width: fit-content;
  }
  .react-datepicker-wrapper {
    width: fit-content;
  }
  input {
    background: transparent;
    border: none;
    width: 185px;
    text-transform: capitalize;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }
`;

export const HourDiv = styled.div`
  width: 288px;

  @media (max-width: 1025px) {
    width: 305px;
    height: 290px;
  }

  @media (min-width: 1026px) and (max-width: 1281px) {
    width: 148px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    width: 200px;
  }

  @media (min-width: 1442px) and (max-width: 1681px) {
    width: 211px;
  }
`;

export const HourTitle = styled.div`
  background: #f4f4f4;
  color: #ff2c3a;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #ff2c3a;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
`;

export const HoursAvailable = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
  max-height: 250px;

  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 1025px) {
    grid-template-columns: auto auto auto;
  }

  @media (min-width: 1026px) and (max-width: 1281px) {
    grid-template-columns: auto auto;
    font-size: 12px;
  }

  @media (min-width: 1282px) and (max-width: 1441px) {
    grid-template-columns: auto auto;
  }

  @media (min-width: 1442px) and (max-width: 1681px) {
    grid-template-columns: auto auto auto;
    font-size: 12px;
  }
`;

export const HourCheckbox = styled(motion.div)`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 18px;

  div {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) =>
      props.disabled ? '#979797' : props.selected ? '#FF1C32' : '#ffffff'};
    border: 1px solid ${(props) => (props.selected ? '#FF1C32' : '#979797')};
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Time = styled.span`
  color: #33333390;
  text-align: left;
`;