import React, { useEffect, useRef, useState } from 'react';
import {
  Item,
  IconDiv,
  SectionDiv,
  ModalContainer,
  ModalHeader,
  SubitemsDiv,
  Subitem,
  ThreadLine,
  SecondLine,
} from './styles';
import { FaCircle } from 'react-icons/fa';
import clubWhite from '../../assets/img/clubIcon.png';
import clubRed from '../../assets/img/clubIconRed.png';
import api from '~/services/api';


export const SideBarItem = ({
  hasDropdown = true,
  isOpen,
  setIsOpen,
  item,
  scrollValue,
}) => {
  const sectionRef = useRef(null);
  const ArrowDown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
    >
      <path
        d="M4.75595 4.56504C5.08135 4.25267 5.08135 3.74538 4.75595 3.43301L1.42392 0.234346C1.18443 0.00444216 0.827801 -0.0630293 0.515423 0.0619187C0.203045 0.186867 -3.28852e-07 0.476746 -3.14652e-07 0.80161L0.00260327 7.19894C0.00260329 7.52131 0.205649 7.81369 0.518027 7.93863C0.830405 8.06358 1.18704 7.99361 1.42653 7.76621L4.75856 4.56754L4.75595 4.56504Z"
        fill="#FF2C3A"
      />
    </svg>
  );


  const [columnsArray, setColumnsArray] = useState([]);
  const [itemHovered, setItemHovered] = useState(false);
  const [clubToken, setClubToken] = useState(
    localStorage.getItem('@clube:token') || null
  );
  const [clubUser, setClubUser] = useState(
    JSON.parse(localStorage.getItem('@clube:user') || null)
  );

  const loginClub = async () => {
    const response = await api.get('/restaurants/club');

    if (response.data.token) {
      setClubToken(response.data.token);
      localStorage.setItem('@clube:token', response.data.token);
    }

    if (response.data.user) {
      setClubUser(response.data.user);
    }

    if (response.data.token && response.data.user) {
      window.open(
        `https://dashboard.takeat.club/reports?token=${response.data.token}`,
        '_blank'
      );
    }
  };

  const clubIcon = itemHovered ? (
    <img src={clubRed} style={{ width: 18 }} />
  ) : (
    <img src={clubWhite} style={{ width: 18 }} />
  );

  const generateColumns = () => {
    const columns = [];

    let currentColumn = 0;
    let currentIndex = 0;

    if (item.subitems) {
      item.subitems.forEach((subitem) => {
        if (subitem.condition === false) return;
        currentColumn += 40;
        if (columns[currentIndex] === undefined) {
          columns[currentIndex] = [subitem];
        } else {
          columns[currentIndex].push(subitem);
        }

        if (subitem.subitems) {
          subitem.subitems.forEach((subsubitem) => {
            if (subsubitem.condition === false) return;
            currentColumn += 40;
            if (currentColumn > 160) {
              currentColumn = 40;
              currentIndex++;
            }
            if (columns[currentIndex] === undefined) {
              columns[currentIndex] = [{ ...subsubitem, isSubSub: true }];
            } else {
              columns[currentIndex].push({ ...subsubitem, isSubSub: true });
            }
          });
        }

        if (currentColumn >= 160) {
          currentColumn = 0;
          currentIndex++;
        }
      });
    }

    setColumnsArray(columns);
  };

  useEffect(() => {
    if (setIsOpen) {
      window.addEventListener('click', () => {
        setIsOpen('');
      });
    }

    generateColumns();

    return () => {
      window.removeEventListener('click', () => {
        setIsOpen('');
      });
    };
  }, []);

  return (
    <>
      <Item
        key={item.section}
        initial={{ height: 0, scaleX: 1, opacity: 0 }}
        animate={{ height: 48, scaleX: 1, opacity: 1 }}
        exit={{ height: 0, scaleX: 1, opacity: 0 }}
        transition={{ duration: 0.2 }}
        onClick={(e) => {
          if (item.external) {
            window.open(item.external, item.externalRef);
          }
          if (item.subitems && item.subitems?.length > 0) {
            e.stopPropagation();
            setIsOpen(isOpen ? '' : item.section);
          }

        }}
        to={
          !item.subitems || item.subitems?.length <= 0 ? item.path || '#' : '#'
        }
        onMouseEnter={() => setItemHovered(true)}
        onMouseLeave={() => setItemHovered(false)}
        hovered={itemHovered}
      >
        <IconDiv hovered={itemHovered}>
          {item.icon === 'club' ? clubIcon : item.icon}
        </IconDiv>
        <SectionDiv ref={sectionRef} open={isOpen} hovered={itemHovered}>
          <span>{item.section}</span>
          {hasDropdown && ArrowDown}
        </SectionDiv>
        <ModalContainer
          open={isOpen}
          topDist={sectionRef.current?.offsetTop - scrollValue}
        >
          <ModalHeader>{item.section}</ModalHeader>
          <div style={{ display: 'flex' }}>
            {columnsArray.map((col, colIdx) => {
              const lastCol = colIdx === columnsArray.length - 1;
              return (
                <SubitemsDiv>
                  {col?.map((subitem, idx) => {
                    if (subitem.condition === false) return;
                    const firstSubsubPath =
                      subitem.subitems?.length > 0
                        ? item.path + subitem.subitems[0].path
                        : null;

                    return (
                      <Subitem
                        key={idx}
                        to={
                          subitem.external
                            ? '#'
                            : firstSubsubPath
                              ? firstSubsubPath
                              : subitem.overridePath ||
                              item.path + subitem.path ||
                              '#'
                        }
                        onClick={() => {
                          if (subitem.external) {
                            window.open(subitem.external, subitem.externalRef);
                          }
                        }}
                        isSubSub={subitem.isSubSub}
                      >
                        {subitem.isSubSub && <ThreadLine isFirst={idx === 0} />}
                        {(subitem.subitems?.length > 0 || subitem.isSubSub) && (
                          <FaCircle />
                        )}
                        {subitem.section}
                        {idx === 3 &&
                          !lastCol &&
                          (subitem.isSubSub ||
                            subitem.subitems?.length > 0) && <SecondLine />}
                      </Subitem>
                    );
                  })}
                </SubitemsDiv>
              );
            })}
          </div>
        </ModalContainer>
      </Item>

    </>
  );
};
