import React from 'react';

import { Spinner, Button, Row, Col } from 'reactstrap';

import ReactToPrint from 'react-to-print';

import { useAuth } from '~/context/AuthContext';
import { useClube } from '~/context/ClubeContext';
import { toast } from 'react-hot-toast';
import PersonalButton from '../Buttons/PersonalButton';
import PersonalOutlineButton from '../Buttons/PersonalOutlineButton';

export default function CashierFooterNF({
  session,
  getBillsSession,
  handlePrintItem,
  printClick,
  componentRef,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  loadingNfce,
  diferenceValueFunction,
  payments,
  isProcessing,
  setSendUpdateProcessing
}) {
  const { user } = useAuth();
  const { handlePontuarSession, sessionsAwarded } = useClube();

  const hasCreditRegisterPayment = payments.some((p) => {
    return p?.keyword === 'prazo' || p?.payment_method?.keyword === 'prazo';
  });


  function showNfceFooter() {
    if (user.credit_register_with_nfce) {
      return !hasCreditRegisterPayment ? true : false
    } else {
      return true
    }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <Row lg="8">
          <Col md="12">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-start',
                marginLeft: -7
              }}
            >
              {user.has_nfce && showNfceFooter() ? (
                !session.nfce_allowed && (
                  <>
                    <PersonalButton
                      type="submit"
                      id="button_finalizar"
                      message={loadingNfce ? (
                        'Finalizando, aguarde...'
                      ) : (
                        'Finalizar e Emitir Nota Fiscal'
                      )}
                      color="#2EC9B7"
                    />

                    {isProcessing &&
                      <PersonalButton
                        type="submit"
                        color="#229688"
                        id="atualizar_status"
                        onClick={() => setSendUpdateProcessing(true)}
                        message={
                          loadingNfce ? 'Aguarde...' : 'Atualizar status'
                        }
                        disabled={loadingNfce}
                      />
                    }

                    {session?.table?.table_type === 'delivery' && (
                      <PersonalOutlineButton
                        onClick={() => diferenceValueFunction()}
                        color="#fff"
                        fontColor="#2EC9B7"
                        borderColor="#2EC9B7"
                        disabled={loadingNfce}
                        id="button_finalizar"
                        message={loadingNfce
                          ? 'Finalizando, aguarde ...'
                          : 'Fechar comanda'}
                      />


                    )}
                  </>
                )
              ) : (
                <>
                  {showNfceFooter() && (
                    <PersonalButton
                      onClick={() => {
                        diferenceValueFunction();
                      }}
                      color="#2EC9B7"

                      disabled={loadingNfce}
                      id="button_finalizar"
                      message="Finalizar"
                    />
                  )}

                  {isProcessing &&
                    <PersonalButton
                      type="submit"
                      color="#229688"
                      id="atualizar_status"
                      onClick={() => setSendUpdateProcessing(true)}
                      message={
                        loadingNfce ? 'Aguarde...' : 'Atualizar status'
                      }
                      disabled={loadingNfce}
                    />
                  }
                </>
              )}

              {user.is_printed_on_web || user.print_bills_on_web ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    await getBillsSession();
                  }}
                  trigger={
                    !loadingNfce
                      ? () => (
                        <PersonalOutlineButton
                          disabled={!session?.key}
                          type="button"
                          id="button_fechar"
                          message="Fechar comanda"
                          color="#fff"
                          fontColor="#2EC9B7"
                          borderColor="#2EC9B7"
                        />)
                      : () => (
                        <PersonalOutlineButton
                          // style={{ marginTop: 20, background: '#2EC9B7' }}
                          id="button_fechar"
                          disabled={true}
                          fontColor="#2EC9B7"
                          color="#fff"
                          message="Fechar comanda"
                          borderColor="#2EC9B7"
                        >
                          {/* <Spinner
                            style={{ width: '1em', height: '1em' }}
                            color="light"
                          /> */}
                        </PersonalOutlineButton>
                      )
                  }
                  content={() => componentRef.current}
                  onAfterPrint={() => {
                    handlePrintItem({
                      session_key: session.key,
                    });
                    setAllTablesAvailable('');
                    setInputValue('');
                    setTablesFilteredSearch('');
                    if (
                      !sessionsAwarded.includes(session.id) &&
                      user.has_clube
                    ) {
                      handlePontuarSession({
                        session_key: session.key || session.session_key,
                      });
                    } else {
                      toast.error('Sessão já pontuada!');
                    }
                  }}
                />
              ) : (
                <>
                  {!loadingNfce ? (
                    <PersonalOutlineButton
                      disabled={!session?.key}
                      id="button_fechar"
                      message="Fechar comanda"
                      color="#fff"
                      fontColor="#2EC9B7"
                      borderColor="#2EC9B7"
                      onClick={printClick}
                    />
                  ) : (
                    <Button
                      style={{ marginTop: 20, background: '#2EC9B7' }}
                      id="button_fechar"
                    >
                      <Spinner
                        style={{ width: '1em', height: '1em' }}
                        color="light"
                      />
                    </Button>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}