import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';

export const Container = styled.div`
  background: #fff;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f8f8f8;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const DataPickerArea = styled.div`
  display: flex;
  height: 360px;
  justify-content: space-between;

  .react-datepicker {
    border: none;
    position: absolute;
    top: -27px;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month {
    width: 305px;
    margin: 0;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    background: #f4f4f4;
    color: #979797;
    border-radius: 3px;
  }

  .react-datepicker__navigation {
    opacity: 2;
    width: 50px;
    height: 50px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ff2c3a;
  }

  .react-datepicker__navigation--next {
    right: 0px;
  }

  .react-datepicker__day-names {
    background-color: #ffffff;

    div {
      width: 41px;
      margin: 0;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #666666;
    }

    div:first-child,
    div:last-child {
      color: #979797;
    }
  }

  .react-datepicker__day {
    width: 41px;
    margin: 0;

    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }

  .react-datepicker__day--selected {
    background-color: #ff2c3a;
    color: #ffffff;
    border-radius: 20px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
  }

  .react-datepicker__current-month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #ff2c3a;

    height: 44px;
  }

  .react-datepicker__header {
    background: #f4f4f4;
    border: none;
    border-radius: 0px;
    text-transform: capitalize;
    width: 305px;
  }

  .react-datepicker__input-container {
    width: fit-content;
  }
  .react-datepicker-wrapper {
    width: fit-content;
  }
  input {
    background: transparent;
    border: none;
    width: 185px;
    text-transform: capitalize;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }
`;

export const HourTitle = styled.div`
  background: #f4f4f4;
  color: #ff2c3a;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #ff2c3a;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
`;

export const HeaderAction = styled.div`
  padding-bottom: 14px;
  background: #fff;

  input {
    width: 340px;
    height: 48px;
    color: #fb4a20;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #fb4a20cc;
    margin-top: 40px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

    &::placeholder {
      text-align: center;
      color: #fb4a20cc;
      margin-top: 40px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  background: #fff;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #fb4a20;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  border-radius: 7px;
`;

export const MenuItem = styled.li`
  list-style: none;
  width: 100%;
`;

export const ItemListHeader = styled.div`
  color: #333;

  p {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  height: 48px;
  width: 100%;
  padding-right: 20px;
  position: absolute;
  bottom: 0;

  button {
    height: 48px;
    width: 100%;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px 7px 0 0;
    border: none;
  }
`;

export const RouterDomLink = styled(Link)`
  text-decoration: none;
  color: ${shade(0.1, '#333333')};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const ButtonDeletePhone = styled.button`
  width: 90px;
  border: 1px solid #fb4a20;
  background: #fb4a20;
  border-radius: 7px;
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
`;

export const UserDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-top: 20px;

  & + div {
    margin-top: 20px;
    border-top: 1px solid #33333320;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
    margin-right: 15px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 490px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: #fb4a20;
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
    border: 1px solid #fb4a20;
    border-radius: 14px;
  }
`;

export const MenuItemQuantity = styled.div`
  display: flex;
  width: 150px;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  p {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fb4a20;
  }
`;

export const MakingOrder = styled.div`
  width: 500px;
  height: 500px;
  background: #fb4a20;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4d4d4c;
`;

export const HoursAvailable = styled.div`
  max-height: 270px;
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
`;

export const HourCheckbox = styled(motion.div)`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 18px;

  div {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${(props) =>
      props.disabled ? '#979797' : props.selected ? '#FF1C32' : '#ffffff'};
    border: 1px solid ${(props) => (props.selected ? '#FF1C32' : '#979797')};
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
