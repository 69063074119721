/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { FaAngleDown, FaEllipsisV } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from 'reactstrap';
import ReactModal from 'react-modal';

import { Form } from '@unform/web';

import api from '~/services/api';
import beforeBg from '~/assets/img/line-circle.svg';

import ComplementCategoryComplements from './ComplementCategoryComplements';
import EditComplementCategoryInsideProduct from './EditComplementCategoryInsideProduct';

import {
  Container,
  SetSliceSizeButton,
  TdStyled,
  ButtonLinnk,
  Content,
  ButtonsArea,
  DropdownDiv,
  ButtonArea,
} from './styles';
import Select from '~/components/Form/SelectInput';
import RegisterButton from '~/components/Buttons/RegisterButton';
import PersonalButton from '~/components/Buttons/PersonalButton';

import CreateComplementInsideProduct from './ComplementCategoryComplements/CreateComplementInsideProduct';
import { useAuth } from '~/context/AuthContext';

function ProductsComplementsCategory({
  complementCategory,
  complementCategoriesProduct,
  complementCategories,
  index,
  isOpen,
  getCategories,
  menewProducts,
  product,
  categories,
  setCategories,
  category,
  setComplementsCategories,
  setComplementCategoriesInsideProduct,
  fiscalGroups,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { user } = useAuth();
  const [modalEditCategory, setModalEditCategory] = useState(false);

  const canCreateComplement =
    !user?.price_table || user?.price_table?.create_complements;
  const canCreateComplementCategory =
    !user?.price_table || user?.price_table?.create_complement_categories;

  const [modalEditCategoryData, setModalEditCategoryData] = useState();
  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function handleChangeModalEditCategory(category) {
    setModalEditCategoryData(category);
    setModalEditCategory(!modalEditCategory);
  }

  async function handleDeleteCategory(product_id, complement_category_id) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja deletar esta categoria de Complemento desse produto?'
        )
      ) {
        const category_complements_filtered =
          complementCategoriesProduct.filter(
            (cat) => cat.id !== complement_category_id
          );

        const category_complements_filtered_ids =
          category_complements_filtered.map((item) => item.id);

        setComplementCategoriesInsideProduct(category_complements_filtered);

        await api.put('restaurants/product-complements', {
          product_id: product.id,
          complement_categories: category_complements_filtered_ids,
        });

        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  const [sliceSize, setSliceSize] = useState(4);
  const [angleChanged, setAngleChange] = useState(false);

  function handleSetSliceSize() {
    setSliceSize(sliceSize + 5);
    setAngleChange(true);
  }

  const [createNewComplementArea, setCreateNewComplementArea] = useState(false);

  const [modalCreateComplement, setModalCreateComplament] = useState(false);

  function toggleModalCrerateComplement() {
    setModalCreateComplament(!modalCreateComplement);
    setCreateNewComplementArea(false);
  }

  const [complementGroupState, setcomplementGroupState] = useState();
  const [complements, setComplements] = useState([]);

  const initialComplements = complementCategory?.complements?.filter(item => item.available_multistore).map(
    (complement) => ({
      value: complement.id,
      label: `${complement.name} (R$${complement.price})`,
    })
  );

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.filter(item => item.available_multistore).map((complement) => ({
      value: complement.id,
      label: `${complement.name} (R$${complement.price})`,
    }));

    setComplements(data);
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const response = await api.put(
          `restaurants/product/complement-category/${complementCategory.id}`,
          {
            complements: data.complements,
          }
        );

        const newCategories = [...complementCategories];

        const categoryFiltered = newCategories.findIndex(
          (cat) => cat.id === complementCategory.id
        );

        if (categoryFiltered !== -1) {
          newCategories[categoryFiltered] = response.data;

          setComplementsCategories(newCategories);
        }

        const newComplementsCategories = [...complementCategoriesProduct];

        const categoryComplementsFiltered = newComplementsCategories.findIndex(
          (cat) => cat.id === complementCategory.id
        );

        if (categoryComplementsFiltered !== -1) {
          newComplementsCategories[categoryComplementsFiltered] = response.data;
          setComplementCategoriesInsideProduct(newComplementsCategories);
        }

        toggleModalCrerateComplement();
        toast.success('Grupo atualizado com sucesso.');
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
      } catch (error) {
        console.log(error.message);
      }
    },

    [
      complementCategory,
      toggleModalCrerateComplement,
      complementCategories,
      complementCategoriesProduct,
      setComplementCategoriesInsideProduct,
      setComplementsCategories,
    ]
  );

  return (
    <>
      <ReactModal
        onRequestClose={toggleModalEditCategory}
        isOpen={modalEditCategory}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <EditComplementCategoryInsideProduct
          complementCategory={modalEditCategoryData}
          toggle={toggleModalEditCategory}
          categories={categories}
          setCategories={setCategories}
          product={product}
          category={category}
          setComplementsCategories={setComplementsCategories}
          complementCategories={complementCategories}
          complementCategoriesProduct={complementCategoriesProduct}
          setComplementCategoriesInsideProduct={
            setComplementCategoriesInsideProduct
          }
        />
      </ReactModal>

      <ReactModal
        onRequestClose={toggleModalCrerateComplement}
        isOpen={modalCreateComplement}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <Content>
          {!createNewComplementArea && (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
              onSubmit={handleSubmit}
            >
              <div>
                <h3>Complementos</h3>

                <Select
                  label="Associe complementos já existente"
                  name="complements"
                  isMulti
                  defaultValue={initialComplements}
                  options={complements}
                  disabled={category.is_multistore_child || false}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <RegisterButton
                    text="... ou crie um novo Complemento"
                    disabled={createNewComplementArea}
                    onClick={() => setCreateNewComplementArea(true)}
                  />
                </div>
              </div>

              <ButtonsArea>
                <PersonalButton
                  color="#FF2C3A"
                  message="Cancelar"
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    toggleModalCrerateComplement();
                  }}
                />
                <PersonalButton
                  color="#0CCAB4"
                  message="Salvar"
                  type="submit"
                />
              </ButtonsArea>
            </Form>
          )}
          {createNewComplementArea && (
            <div style={{ marginTop: 20 }}>
              <CreateComplementInsideProduct
                toggleModalCrerateComplement={toggleModalCrerateComplement}
                complementsCategories={complementCategories}
                setComplementsCategories={setComplementsCategories}
                category={complementCategory}
                setComplementCategoriesInsideProduct={
                  setComplementCategoriesInsideProduct
                }
                complementCategoriesInsideProduct={complementCategoriesProduct}
                fiscalGroups={fiscalGroups}
              />
            </div>
          )}
        </Content>
      </ReactModal>

      <Container isOpen={isOpen}>
        <TdStyled
          style={{
            borderTop: 'none',
            display: 'flex',
            alignItems: 'flex-end',
          }}
          backgroundImage={beforeBg}
        >
          <ButtonArea>
            <strong style={{ fontSize: 18 }}>{complementCategory?.name}</strong>{' '}
            <ButtonLinnk
              onClick={() => {
                toggleModalCrerateComplement();
                setcomplementGroupState(complementCategory);
                getComplements();
              }}
              disabled={!canCreateComplement}
            >
              + Adicionar complemento
            </ButtonLinnk>
          </ButtonArea>
        </TdStyled>
        {!user.only_delivery && (
          <td style={{ borderTop: 'none' }}>
            <p style={{ fontWeight: 'bold' }}>PDV</p>
          </td>
        )}

        {user.delivery_info.is_delivery_allowed && (
          <td style={{ borderTop: 'none' }}>
            <p style={{ fontWeight: 'bold' }}>Delivery</p>
          </td>
        )}

        <td
          style={{ borderTop: 'none' }}
          colSpan={
            user.delivery_info.is_delivery_allowed && !user.only_delivery
              ? 2
              : 1
          }
        >
          <p>
            {complementCategory?.optional ? (
              <Badge style={{ width: '88%' }}>Opcional</Badge>
            ) : (
              <Badge style={{ width: '88%' }}>Obrigatório</Badge>
            )}
          </p>
        </td>
        <td style={{ borderTop: 'none' }}>
          <p style={{ fontWeight: 'bold' }}>Limite</p>
        </td>
        {!user.only_delivery && <td style={{ borderTop: 'none' }}></td>}

        {user.only_delivery && <td style={{ borderTop: 'none' }}></td>}

        <td style={{ textAlign: 'right', borderTop: 'none' }}></td>

        <td style={{ textAlign: 'right', borderTop: 'none' }}>
          <DropdownDiv>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              direction={
                index + 1 === complementCategoriesProduct?.length
                  ? 'up'
                  : 'down'
              }
            >
              {!complementCategory.is_ifood && (
                <DropdownToggle tag="span">
                  {' '}
                  <FaEllipsisV
                    style={{ marginLeft: 20 }}
                    title="Mostrar Opções"
                    cursor="pointer"
                  />
                </DropdownToggle>
              )}
              <DropdownMenu flip>
                <DropdownItem
                  disabled={!canCreateComplementCategory}
                  onClick={() => {
                    handleChangeModalEditCategory(complementCategory);
                  }}
                >
                  Editar Item
                </DropdownItem>
                {!complementCategory.is_multistore_child && (
                  <DropdownItem
                    disabled={!canCreateComplementCategory}
                    onClick={() =>
                      handleDeleteCategory(product?.id, complementCategory?.id)
                    }
                  >
                    Excluir Item
                  </DropdownItem>
                )}
                {/* <DropdownItem>Duplicar Item</DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          </DropdownDiv>
        </td>
      </Container>

      {complementCategory?.complements &&
        complementCategory?.complements.length > 0 &&
        complementCategory?.complements
          .slice(0, sliceSize)
          .filter(item => item.available_multistore)
          .map((complement, index) => (
            <ComplementCategoryComplements
              complement={complement}
              index={index}
              complements={complementCategory?.complements}
              complementCategories={complementCategories}
              setComplementsCategories={setComplementsCategories}
              isOpen={isOpen}
              getCategories={getCategories}
              menewProducts={menewProducts}
              complementCategory={complementCategory}
              categories={categories}
              setCategories={setCategories}
              product={product}
              category={category}
              setComplementCategoriesInsideProduct={
                setComplementCategoriesInsideProduct
              }
              complementCategoriesInsideProduct={complementCategoriesProduct}
              fiscalGroups={fiscalGroups}
            />
          ))}

      {!isOpen && sliceSize < complementCategory?.complements.length && (
        <div>
          {' '}
          <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
            <FaAngleDown
              style={{
                transition: 'all 0.2s',
                transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
              }}
            />{' '}
            Ver mais complementos
          </SetSliceSizeButton>
        </div>
      )}
    </>
  );
}

export default ProductsComplementsCategory;
