import React, { useState, useEffect } from 'react';
import { Form } from '@unform/web';

import { DefaultInput, DefaultSelect, InformationDiv, RadioGroup, Tooltip } from 'ui-kit-takeat';

import { FaInfoCircle } from 'react-icons/fa';
import { FaArrowsRotate } from 'react-icons/fa6';
import { AverageArea, Container, ConvertArea, ConvertCsosnToCst, ConvertInputDiv, ConvertItemArea, ConvertUnityArea, GroupDiv } from './styles';
import { IoWarningOutline } from "react-icons/io5";
import NFeInfo from '~/services/NFeInfo';

export default function NfeManifestItem({
  item,
  inputs = [],
  cashFlows = [],
  cfop_options,
  user
}) {
  DefaultSelect.defaultProps = {
    ...DefaultSelect.defaultProps,
    labelStyles: {
      fontSize: '14px'
    },
    containerStyles: {
      fontSize: 14
    }
  }

  function removeAcentos(str){
    let txt = str || '';
    txt = txt.toLowerCase();
    txt = txt.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return txt;
  }

  const cfop_filter_options = [
    { default: 5403, value: 1403, label: '1403 - Revenda' },
    { default: 5405, value: 1403, label: '1403 - Revenda' },
    {
      default: 6403,
      value: 2403,
      label:
        '2403 - Compra para comercialização em operação com mercadoria sujeita ao regime de substituição tributária',
    },
    { default: 5102, value: 1102, label: '1102 - Revenda' },
    {
      default: 6102,
      value: 2102,
      label: '2102 - Revenda originado de outro estado',
    },
    {
      default: 6108,
      value: 2102,
      label: '2102 - Revenda originado de outro estado',
    },
  ];

  function searchStrings(array, search){
    const splited = search.split(' ');
    let lastFiltered = null;
    let filtered = null;

    if(splited.length > 0){
      filtered = array.filter(arr => removeAcentos(arr.name).includes(removeAcentos(splited[0])));
    }

    if(splited.length > 1){
      filtered = array.filter(arr => removeAcentos(arr.name).includes(removeAcentos(splited[0])));
      lastFiltered = filtered;

      if(filtered.length === 0){
        filtered = lastFiltered;
      }

      if(filtered.length > 1){
        filtered = filtered.filter(arr => removeAcentos(arr.name).includes(removeAcentos(splited[1])));
        if(filtered.length === 0){
          filtered = lastFiltered;
        }
      }
    }

    if(splited.length > 2){
      filtered = array.filter(arr => removeAcentos(arr?.name).includes(removeAcentos(splited[0])));
      lastFiltered = filtered;

      if(filtered.length > 1){
        filtered = filtered.filter(arr => removeAcentos(arr?.name).includes(removeAcentos(splited[1])));

        if(filtered.length === 0){
          filtered = lastFiltered;
        }

        if(filtered.length > 1){
          filtered = filtered.filter(arr => removeAcentos(arr?.name).includes(removeAcentos(splited[2])));

          if(filtered.length === 0){
            filtered = lastFiltered;
          }
        }else if(filtered.length === 0){
          filtered = lastFiltered;
        }
      }else if(filtered.length === 0){
        filtered = lastFiltered;
      }
    }

    if(filtered?.length > 0){
      filtered = [filtered[0]];
    }

    return filtered[0];
  }

  const cst_options = NFeInfo.cst.filter(cs => cs.cst !== '720').map(cst => {
    return { label: cst.label, value: cst.cst }
  });

  const [input, setInput] = useState(inputs.find(
    (inp) => inp.name_sefaz === item.descricao
  )
  || searchStrings(inputs, item.descricao) 
  || inputs[0]);
  
  const [cfop, setCfop] = useState(
    (input.cfop && input.cfop !== '') ?
    (cfop_options.find(opt => opt.value.toString() === input.cfop) || cfop_options[0]) :
    cfop_options[0]
  );
  
  const [cashFlow, setCashFlow] = useState(
    (input.cash_flow_category_subcategory_id && input.cash_flow_category_subcategory_id !== null) ?
    (cashFlows.find(opt => opt.value === input.cash_flow_category_subcategory_id) || cashFlows[0]) :
    cashFlows[0]
  );

  const [inputName, setInputName] = useState(item.input_name);
  const [inputUnidade, setInputUnidade] = useState({ value: 'UN', label: 'UN'});
  const [inputUnitaryPrice, setInputUnitaryPrice] = useState(item.input_unitary_price);

  const [convertTax, setConvertTax] = useState(1);
  const [unidadeTooltip, setUnidadeTooltip] = useState(false);
  const [unitaryPriceTooltip, setUnitaryPriceTooltip] = useState(false);
  const [notaTooltip, setNotaTooltip] = useState(false);
  const [inputTooltip, setInputTooltip] = useState(false);

  const [isAverage, setIsAverage] = useState(false);

  const [unidadeOptions, setUnidadeOptions] = useState([
    { value: 'UN', label: 'UN'},
    { value: 'KG', label: 'KG'},
    { value: 'L', label: 'L'},
    { value: 'M', label: 'M'},
    { value: 'G', label: 'G'},
    { value: 'ML', label: 'ML'},
    { value: 'PCT', label: 'PCT'},
    { value: 'BDJ', label: 'BDJ'},
    { value: 'CX', label: 'CX'},
    { value: 'Xícara', label: 'Xícara'},
  ]);

  useEffect(() => {
    if(input.cfop && input.cfop !== ''){
      const thisCfop = cfop_options.find(opt => opt.value.toString() === input.cfop);
      if(thisCfop){
        setCfop(thisCfop);
        item.cfop = thisCfop.value;
      }
    }

    item.input_id = input.value;
    item.input_name = input.value !== null ? input.name : item.descricao;
    // item.input_unidade = input.value !== null ? input.unidade : item.unidade_comercial;
    setInputName(item.input_name);

    const foundUnidade = unidadeOptions.find(opt => opt.value === input.unidade?.toUpperCase());
    if(foundUnidade){
      setInputUnidade(foundUnidade);

      //removendo outras unidade que podem ter sido adicionadas no else abaixo
      if(unidadeOptions[0].label !== 'UN'){
        unidadeOptions.shift();
      }

      item.input_unidade = foundUnidade.value;

    } else if (input.value !== null) {
      //caso nao exista a unidade do insumo selecionado nas options, insere nas opcoes
      const newUnidade = { value: input.unidade?.toUpperCase(), label: input.unidade?.toUpperCase() };
      setInputUnidade(newUnidade);
      setUnidadeOptions([newUnidade, ...unidadeOptions]);

      item.input_unidade = newUnidade.value;
    } else if (input.value === null) {
      //removendo outras unidade que podem ter sido adicionadas no else acima
      if(unidadeOptions[0].label !== 'UN'){
        unidadeOptions.shift();
      }

      setInputUnidade(unidadeOptions[0]);
      item.input_unidade = unidadeOptions[0].value;
    }

    // setInputUnidade(item.input_unidade);

    if(!item.input_id){
      setIsAverage(false);
      item.is_average = false;
    }

    if(isAverage){
      const thisPrice = item.input_quantidade !== 0 ? 
        parseFloat(item.valor_bruto) / item.input_quantidade : 
        parseFloat(item.valor_unitario_comercial);
      
      const unitary = 
        ((thisPrice * parseFloat(item.input_quantidade)) + (parseFloat(input.unitary_price) * parseFloat(input.quantidade))) 
        / (parseFloat(item.input_quantidade) + parseFloat(input.quantidade));

      item.input_unitary_price = unitary;

      setInputUnitaryPrice(unitary);
    }else{
      item.input_unitary_price = item.input_quantidade !== 0 ? 
        parseFloat(item.valor_bruto) / item.input_quantidade : 
        parseFloat(item.valor_unitario_comercial);

      setInputUnitaryPrice(item.input_unitary_price);
    }

    if(input.cash_flow_category_subcategory_id){
      const thisCash = cashFlows.find(opt => opt.value === input.cash_flow_category_subcategory_id);
      if(thisCash){
        setCashFlow(thisCash);
        item.cash_flow_id = thisCash.value;
        item.cash_flow_name = thisCash.label;
        item.cash_flow_disabled = true;
      }else{
        item.cash_flow_disabled = false;
      }
    }
  }, [input]);

  useEffect(() => {
    if(isAverage){
      const thisPrice = item.input_quantidade !== 0 ? 
        parseFloat(item.valor_bruto) / item.input_quantidade : 
        parseFloat(item.valor_unitario_comercial);
            
      // const unitary = (thisPrice + parseFloat(input.unitary_price)) / 2;

      const unitary = 
        ((thisPrice * parseFloat(item.input_quantidade)) + (parseFloat(input.unitary_price) * parseFloat(input.quantidade))) 
        / (parseFloat(item.input_quantidade) + parseFloat(input.quantidade));

      item.input_unitary_price = unitary;
      setInputUnitaryPrice(unitary);
    }else{
      item.input_unitary_price = item.input_quantidade !== 0 ? 
        parseFloat(item.valor_bruto) / item.input_quantidade : 
        parseFloat(item.valor_unitario_comercial);

      setInputUnitaryPrice(item.input_unitary_price);
    }

    setIsAverage(isAverage);
  }, [isAverage]);

  return (
    <Container>
      <Form
        initialData={item}
      >
        <div style={{width: '100%', backgroundColor: '#F4F4F4', marginBottom: 20}}>
          <h4>Item <strong>#{item.numero_item}</strong></h4>
        </div>

        <div style={{display: 'flex', gap: '10px'}}>
          <DefaultInput
            name="codigo_produto"
            title="Cód. Forn."
            type="text"
            value={item.codigo_produto}
            disabled
          />

          <DefaultInput
            name="descricao"
            title="Produto"
            type="text"
            defaultValue={item.descricao}
            disabled={true}
          />

          <DefaultInput 
            name="codigo_ncm" 
            title="NCM" 
            type="text" 
            value={item.codigo_ncm}
            disabled
          />

          <DefaultSelect
            label="CFOP"
            name="cfop"
            options={cfop_options}
            value={cfop}
            onChange={(e) => {
              setCfop(e);
              item.cfop = e.value;
            }}
          />
        </div>
            
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', marginTop: 15}}>
          <DefaultSelect
            label="Centro de Custo"
            name="centro"
            id={`select_select_${item.numero_item}_1`}
            options={cashFlows}
            value={cashFlow}
            disabled={input.cash_flow_category_subcategory_id}
            onChange={(e) => {
              setCashFlow(e);
              item.cash_flow_name = e.label;
              item.cash_flow_id = e.value;
            }}
            containerStyles={{gridColumn: '1 / span 2'}}
          />

          <DefaultInput
            name="valor_unitario_comercial"
            title="Custo unitário"
            type="text"
            value={`R$ ${item.valor_unitario_comercial ? parseFloat(item.valor_unitario_comercial).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : '-'}`}
            disabled
            containerStyles={{gridColumn: '3'}}
            />

          <DefaultInput
            name="valor_bruto"
            title="Valor total"
            type="text"
            value={`R$ ${item.valor_bruto ? parseFloat(item.valor_bruto).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) : '-'}`}
            disabled
            containerStyles={{gridColumn: '4'}}
          />
        </div>
        
        {/* <div style={{
          display: 'grid', 
          gridTemplateColumns: '1fr 1fr 1fr 1fr', 
          gap: '10px', 
          marginTop: 15
        }}>
          
        </div> */}
        {user.regime_tributario !== '1' && item.icms_situacao_tributaria && item.icms_situacao_tributaria.length === 3 &&
          <ConvertCsosnToCst>
            <DefaultInput
              name="csosn_entry"
              title="CSOSN"
              type="text"
              value={`${item.icms_situacao_tributaria}`}
              disabled
              containerStyles={{gridColumn: '3'}}
            />

            <div style={{ marginBottom: 10, color: '#979797' }}>
              <FaArrowsRotate size={17}/>
            </div>

            <DefaultSelect
              label="CST"
              name="cst_entry"
              options={cst_options}
              onChange={(e) => {
                item.cst = e.value;
                console.log(item);
              }}
              containerStyles={{gridColumn: '1 / span 2'}}
            />

            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              minWidth: '200px',
            }}>
              <div style={{ fontSize: 20, color: '#FFA814' }}>
                <IoWarningOutline size={20}/>
              </div>
              <div>Atenção, é necessário converter o CSOSN para CST</div>
            </div>
          </ConvertCsosnToCst>
        }

        {user.regime_tributario !== '1' && item.icms_situacao_tributaria && item.icms_situacao_tributaria.length !== 3 &&
          <ConvertCsosnToCst>
            <DefaultInput
              name="csosn_entry"
              title="CST que veio na nota"
              type="text"
              value={`${item.icms_situacao_tributaria}`}
              disabled
              containerStyles={{gridColumn: '3'}}
            />

            <div style={{ marginBottom: 10, color: '#979797' }}>
              <FaArrowsRotate size={17}/>
            </div>

            <DefaultSelect
              label="CST que vai ser realizada a entrada"
              name="cst_entry"
              options={cst_options}
              defaultValue={cst_options.find(opt => opt.value === item.icms_situacao_tributaria) || { label: item.icms_situacao_tributaria, value: item.icms_situacao_tributaria }}
              onChange={(e) => {
                item.cst = e.value;
                console.log(item);
              }}
              containerStyles={{gridColumn: '1 / span 2'}}
            />

            {/* <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              minWidth: '200px',
            }}>
              <div style={{ fontSize: 20, color: '#FFA814' }}>
                <IoWarningOutline size={20}/>
              </div>
              <div>Atenção, é necessário converter o CSOSN para CST</div>
            </div> */}
          </ConvertCsosnToCst>
        }

        <ConvertInputDiv>
          <ConvertArea>
            {/* ITEM NA NOTA */}
            <ConvertItemArea>
              <div style={{position: 'relative'}}>
                <p>
                  ITEM NA NOTA 
                  <FaInfoCircle
                    onMouseEnter={() => setNotaTooltip(true)}
                    onMouseLeave={() => setNotaTooltip(false)}
                    style={{marginLeft: 5}}
                  />
                </p>

                <Tooltip
                  show={notaTooltip}
                  content={
                    <p style={{ textAlign: 'justify', marginBottom: 0, fontWeight: 400 }}>
                      Informações do item vindo da nota de entrada
                    </p>
                    // Como vai ficar o insumo selecionado quando aceitar a nota
                  }
                  containerStyles={{
                    top: '30px',
                    right: 'unset',
                    width: '330px',
                    left: '0px',
                  }}
                />
              </div>

              <div style={{ width: '100%' }}>
                <DefaultInput 
                  name="descricao_item"
                  title="Nome"
                  disabled={true}
                  defaultValue={item.descricao}
                />
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '20px',
                alignSelf: 'stretch',
                width: '100%'
              }}>
                <DefaultInput 
                  name="quantidade_item"
                  title="Quantidade"
                  value={item.quantidade_comercial?.replace('.',',')}
                  disabled={true}
                />

                <DefaultInput 
                  name="unidade_item"
                  title="Unidade"
                  disabled={true}
                  value={item.unidade_comercial}
                />
              </div>

              <div style={{ width: '100%' }}>
                <DefaultInput 
                  name="custo_item"
                  title="Custo unitário"
                  disabled={true}
                  value={`R$ ${item.valor_unitario_comercial ? parseFloat(item.valor_unitario_comercial).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) : '-'}`}
                />
              </div>

              <ConvertItemArea style={{ gap: 0 }}>
                <p style={{ marginBottom: 0 }}>Resumo de entrada:</p>
                <p style={{fontSize: 15, fontWeight: 400, marginTop: 0}}>
                  <b>{item?.input_quantidade?.toString()?.replace('.',',')} {item.input_unidade}</b> do {item.input_id === null && 'novo'} insumo <b>{item.input_name}</b> custando 
                    <b>
                    {` R$ ${inputUnitaryPrice ? parseFloat(inputUnitaryPrice).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) : item.valor_unitario_comercial}`}
                    </b> cada
                </p>
              </ConvertItemArea>
            </ConvertItemArea>
            
            {/* Conversao de unidade */}
            <ConvertUnityArea>
              <p>Converter unidade</p>
              <DefaultInput
                name="unidade_convert"
                type="number"
                value={convertTax}
                disabled={input.value === null && !user.allow_inputs}
                onChange={(e) => {
                  setConvertTax(e.target.value);
                  item.convert_tax = e.target.value;

                  const quantidade = item.convert_tax !== 0 ? parseFloat(item.quantidade_comercial) * (item.convert_tax || 1) : 0;
                  item.input_quantidade = parseFloat(quantidade.toFixed(4));

                  if(isAverage){
                    const thisPrice = quantidade !== 0 ? 
                      parseFloat(item.valor_bruto) / quantidade : 
                      parseFloat(item.valor_unitario_comercial);

                    // const unitary = (thisPrice + parseFloat(input.unitary_price)) / 2;

                    const unitary = 
                      ((thisPrice * parseFloat(item.input_quantidade)) + (parseFloat(input.unitary_price) * parseFloat(input.quantidade))) 
                      / (parseFloat(item.input_quantidade) + parseFloat(input.quantidade));

                    item.input_unitary_price = unitary;
                    setInputUnitaryPrice(item.input_unitary_price);
                  }else{
                    item.input_unitary_price = quantidade !== 0 ? 
                      parseFloat(item.valor_bruto) / quantidade : 
                      parseFloat(item.valor_unitario_comercial);

                    setInputUnitaryPrice(item.input_unitary_price);
                  }
                }}
              />
            </ConvertUnityArea>

            {/* ITEM NO ESTOQUE */}
            <ConvertItemArea>
              <div style={{position: 'relative'}}>
                <p>
                  ITEM NO ESTOQUE
                  <FaInfoCircle
                    onMouseEnter={() => setInputTooltip(true)}
                    onMouseLeave={() => setInputTooltip(false)}
                    style={{marginLeft: 5}}
                  />
                </p>

                <Tooltip
                  show={inputTooltip}
                  content={
                    <p style={{ textAlign: 'justify', marginBottom: 0, fontWeight: 400 }}>
                      {item.input_id ? 
                        'Como vai ficar o insumo selecionado ao aceitar a nota' : 
                        'Como vai ficar o novo insumo criado ao aceitar a nota'
                      }
                    </p>
                  }
                  containerStyles={{
                    top: '30px',
                    right: 'unset',
                    width: '330px',
                    left: '0px',
                  }}
                />
              </div>

              <div style={{ 
                display: 'flex',
                alignItems: 'flex-start',
                gap: '20px',
                alignSelf: 'stretch',
                width: '100%'
              }}>
                <DefaultSelect
                  label="Relacionar Insumo"
                  name="inputs"
                  id={`input_select_${item.numero_item}`}
                  options={inputs}
                  value={input}
                  onChange={(e) => {
                    setInput(e);
                  }}
                />

                {input.value === null &&
                  <DefaultInput 
                    name="descricao_input"
                    title="Nome"
                    disabled={(input && input.value !== null) || (input.value === null && !user.allow_inputs)}
                    value={inputName}
                    onChange={e => {
                      setInputName(e.target.value);
                      item.input_name = e.target.value;
                    }}
                    placeholder="Nome do novo insumo"
                  />
                }

              </div>

              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '20px',
                alignSelf: 'stretch',
                width: '100%'
              }}>
                <DefaultInput 
                  name="quantidade_input"
                  title="Quantidade"
                  value={convertTax !== 0 ? parseFloat(item.quantidade_comercial) * (convertTax || 1) : ''}
                  type="number"
                  disabled={input.value === null && !user.allow_inputs}
                  onChange={e => {
                    const convert = e.target.value !== '' ? 
                      (e.target.value || 1) / parseFloat(item.quantidade_comercial) 
                      : 0;

                    setConvertTax(convert);

                    const quantidade = convert !== 0 ? parseFloat(item.quantidade_comercial) * (convert || 1) : 0;
                    item.input_quantidade = quantidade;

                    if(isAverage){
                      const thisPrice = quantidade !== 0 ? 
                        parseFloat(item.valor_bruto) / quantidade : 
                        parseFloat(item.valor_unitario_comercial);
                      
                      // const unitary = (thisPrice + parseFloat(input.unitary_price)) / 2;

                      const unitary = 
                        ((thisPrice * parseFloat(item.input_quantidade)) + (parseFloat(input.unitary_price) * parseFloat(input.quantidade))) 
                        / (parseFloat(item.input_quantidade) + parseFloat(input.quantidade));
                
                      item.input_unitary_price = unitary;

                      setInputUnitaryPrice(item.input_unitary_price);
                    }else{
                      item.input_unitary_price = quantidade !== 0 ? 
                        parseFloat(item.valor_bruto) / quantidade : 
                        parseFloat(item.valor_unitario_comercial);

                      setInputUnitaryPrice(item.input_unitary_price);
                    }
                  }}
                />

                <div style={{position: 'relative', width: '100%'}}>
                  {/* <DefaultInput 
                    name="unidade_input"
                    title={
                      <span>
                        Unidade

                        <FaInfoCircle
                          onMouseEnter={() => setUnidadeTooltip(true)}
                          onMouseLeave={() => setUnidadeTooltip(false)}
                          style={{position: 'absolute', top: 0, left: 65}}
                        />
                      </span>
                    }
                    value={inputUnidade}
                    onChange={e => {
                      setInputUnidade(e.target.value);
                      item.input_unidade = e.target.value;
                    }}
                  /> */}

                  <DefaultSelect
                    label={
                      <span>
                        Unidade

                        <FaInfoCircle
                          onMouseEnter={() => setUnidadeTooltip(true)}
                          onMouseLeave={() => setUnidadeTooltip(false)}
                          style={{position: 'absolute', top: 0, left: 65}}
                        />
                      </span>
                    }
                    name="inputs"
                    id={`unidade_select_${item.numero_item}`}
                    options={unidadeOptions}
                    value={inputUnidade}
                    disabled={input.value === null && !user.allow_inputs}
                    onChange={e => {
                      setInputUnidade(e);
                      item.input_unidade = e.value;
                    }}
                  />

                  <Tooltip
                    show={unidadeTooltip}
                    content={
                      <p style={{ textAlign: 'justify', marginBottom: 0, fontWeight: 400 }}>
                        Essa unidade será atribuida ao insumo
                      </p>
                    }
                    containerStyles={{
                      top: '30px',
                      right: '0px',
                      width: '330px',
                      left: 'unset',
                    }}
                  />
                </div>
              </div>

              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '20px',
                alignSelf: 'stretch',
                width: '100%'
              }}>
                <div style={{position: 'relative', width: '100%'}}>
                  <DefaultInput 
                    name="custo_input"
                    title={item.input_id ? 
                      <span>
                        Novo custo unitário

                        <FaInfoCircle
                          onMouseEnter={() => setUnitaryPriceTooltip(true)}
                          onMouseLeave={() => setUnitaryPriceTooltip(false)}
                          style={{marginLeft: 5}}
                        />
                      </span> : 
                      "Custo unitário"
                    }
                    disabled={true}
                    value={`R$ ${inputUnitaryPrice ? parseFloat(inputUnitaryPrice).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) : item.valor_unitario_comercial}`}
                  />

                  <Tooltip
                    show={unitaryPriceTooltip}
                    content={
                      isAverage ? 
                      <>
                        <p style={{marginBottom: 0}}>Na nota</p>
                        <p style={{ textAlign: 'justify', marginBottom: 0, fontWeight: 400 }}>
                          Custo unitário: {`R$ ${item.valor_bruto ? parseFloat(item.valor_bruto).toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) : '-'}`} / {item.input_quantidade?.toString()?.replace('.',',')} = {`R$ ${(parseFloat(item.valor_bruto) / item.input_quantidade) ? parseFloat((parseFloat(item.valor_bruto) / item.input_quantidade)).toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) : item.valor_unitario_comercial}`}
                        </p>
                        <p style={{marginBottom: 0, fontWeight: 400}}>
                          Quantidade: {item.input_quantidade?.toString()?.replace('.',',')}
                        </p>

                        <p style={{marginTop: 10, marginBottom: 0}}>
                          Insumo
                        </p>
                        <p style={{marginBottom: 0, fontWeight: 400}}>
                          Custo unitário: {`R$ ${input.unitary_price ? parseFloat(input.unitary_price).toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) : '-'}`}
                        </p>
                        <p style={{marginBottom: 0, fontWeight: 400}}>
                          Quantidade: {input.quantidade?.replace('.',',')}
                        </p>

                        <p style={{marginTop: 10, marginBottom: 0}}>
                          Média 
                        </p>
                        <p style={{fontWeight: 400}}> 
                          ({`R$ ${(parseFloat(item.valor_bruto) / item.input_quantidade) ? parseFloat((parseFloat(item.valor_bruto) / item.input_quantidade)).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) : item.valor_unitario_comercial}`} * {item.input_quantidade?.toString()?.replace('.',',')}) + ({`R$ ${input.unitary_price ? parseFloat(input.unitary_price).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) : '-'}`} * {input.quantidade?.replace('.',',')}) / ({item.input_quantidade?.toString()?.replace('.',',')} + {input.quantidade?.replace('.',',')}) = <b>{`R$ ${inputUnitaryPrice ? parseFloat(inputUnitaryPrice).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) : item.valor_unitario_comercial}`}</b>
                        </p>
                      </> :
                      <p style={{ textAlign: 'justify', marginBottom: 0, fontWeight: 400 }}>
                        {`R$ ${item.valor_bruto ? parseFloat(item.valor_bruto).toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : '-'}`} / {item.input_quantidade} = <b>{`R$ ${inputUnitaryPrice ? parseFloat(inputUnitaryPrice).toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : item.valor_unitario_comercial}`}</b>
                      </p> 
                    }
                    containerStyles={{
                      top: '30px',
                      right: '0px',
                      width: '330px',
                      left: 'unset',
                    }}
                  />
                </div>

                {input.value !== null &&
                  <DefaultInput 
                    name="custo_input"
                    title="Custo unitário atual"
                    disabled={true}
                    value={`R$ ${input.unitary_price ? parseFloat(input.unitary_price).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) : '-'}`}
                  />
                }
              </div>

              {input.value !== null &&
                <AverageArea>
                  <p>Valor custo unitário: </p>
                  <GroupDiv>
                    <RadioGroup 
                      options={item.input_id ? 
                        [{ label: "Novo", value: false }, { label: "Média", value: true }] :
                        [{ label: "Novo", value: false }]
                      }
                      selected={isAverage}
                      horizontal={true}
                      onClick={e => {
                        setIsAverage(e);
                        item.is_average = e;
                      }}
                      customColor="#FF2C3A"
                      containerStyles={{width: 19}}
                    />
                  </GroupDiv>
                </AverageArea>
              }

              {input.value === null && user.allow_inputs && 
                <InformationDiv
                  text='Será criado um insumo com a conversão acima.'
                  containerStyles={{alignContent: 'center', height: '50px', width: '100%' }}
                />
              }

              {input.value === null && !user.allow_inputs && 
                <InformationDiv
                  text='Você não pode criar insumos. Contate o franqueador se necessário.'
                  containerStyles={{alignContent: 'center', height: '50px', width: '100%' }}
                  type="error"
                />
              }
            </ConvertItemArea>
          </ConvertArea>
          
          {/* {!item.input_id &&
            <ConvertArea>
              <ConvertItemArea style={{ gap: 0 }}>
                <p style={{ marginBottom: 0 }}>Resumo de entrada:</p>
                <p style={{fontSize: 15, fontWeight: 400, marginTop: 0}}>
                  <b>{item?.input_quantidade?.toString()?.replace('.',',')} {item.input_unidade}</b> do {item.input_id === null && 'novo'} insumo <b>{item.input_name}</b> custando 
                    <b>
                    {` R$ ${inputUnitaryPrice ? parseFloat(inputUnitaryPrice).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) : item.valor_unitario_comercial}`}
                    </b> cada
                </p>
              </ConvertItemArea>
            </ConvertArea>
          } */}
        </ConvertInputDiv>
      </Form>
    </Container>
  );
}