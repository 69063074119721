import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 48px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #fff;
`;

export const Logo = styled.img`
  width: 140px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1300px) {
    position: relative;
    left: 0;
    transform: translateX(0%);
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const HeaderButton = styled.button`
  position: relative;
  display: flex;
  width: 50px;
  height: 40px;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #f4f4f4;
  color: #ff2c3a;
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${({ color }) => color ? color : '#ff2c3a'};
  }

  span {
    color: #ff2c3a;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 7px;
  }
`;

export const RightButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const UserName = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #666;
  width: 200px;;
`;

export const NotificationBell = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  svg {
    width: 18px;
    height: 21px;
    flex-shrink: 0;
    color: #ff2c3a;
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;

  margin-top: -5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const WppButton = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #33333320;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: transparent;
  margin: 5px 10px 0 0;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => (props.connected ? 'green' : '#ff403d')};
    text-align: center;
  }

  svg {
    color: ${(props) => (props.connected ? 'green' : '#ff403d')};
  }
`;

export const WppReconnect = styled.div`
  z-index: 1200;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 30px;
  right: -8px;
  width: 390px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 12px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
`;

export const WppModal = styled(Modal)`
  .modal-content {
    width: 600px;
    border-radius: 10px;
  }

  img {
    border-radius: 10px;
  }

  h1 {
    margin: 20px 0;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  p {
    width: 505px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;

export const ReconnectButton = styled.button`
  width: 233px;
  height: 48px;
  line-height: 48px;
  background: #ff2c3a;
  border-radius: 5px;
  border: none;
  margin: 80px auto 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const WppModalText = styled.div`
  ol {
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  h1 {
    color: #3d3d3d;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 700;
    margin: 0 0 15px;
  }

  p {
    color: #3d3d3d;
    font-size: 14px;
    font-family: Poppins;
    margin: 0;
  }
`;

export const PendingNotification = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background-color: #ffa814;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -8px;
  top: -4px;
  width: 19px;
  height: 19px;
  font-size: 12px;
  border-radius: 9px;
  background-color: orange;
  font-weight: 600;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const StatusButtonsArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;`

export const StatusButton = styled.button`
  width: 182px;
  height: 40px;
  background-color: ${props => props.opened ? "#ECFFFD" : "#FFF0F2"}; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  border-radius: 5px;
  margin-left: 4px;
  z-index: 10000000;
  span{
    color: #666666;
    font-size: 14px;     
    font-weight: 700;
  }
`
export const StatusCircle = styled.div`
  width: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 50%;
  background-color: ${props => props.opened ? "#2EC9B7" : "#FF2B3A"}; 
  font-size: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`