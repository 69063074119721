/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { CellMeasurerCache } from 'react-virtualized';

import { Card, Modal, ModalBody, Spinner, Collapse } from 'reactstrap';
import { FaRedo, FaSearch } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import api from '~/services/api';
import ProductCategoryHeaderIfood from '~/components/Products/ProductCategoryHeaderIfood';
import CategoryItemIfood from '~/components/Products/CategoryItemIfood';
import EditCategory from './Edit';

import {
  Container,
  LoadingContainer,
  InputSearchArea,
  SelectStyled,
  ButtonIfood,
  CategoryName,
  ContainerComplementsCategories,
  CategoryHeader,
} from './styles';
import Store from './Store';
import { useIfood } from '~/context/iFoodContext';
import CreateComplementCategory from '../ComplementsCategories/Create';
import CreateComplement from '../Complements/Create';
import AssociateComplement from '../ComplementsCategories/AssociateComplement';

import Complements from '../Complements';
import { Tabs } from 'ui-kit-takeat';

function ProductCategory({ menewProducts, user, fiscalGroups }) {
  const { ifoodMenus, ifoodRestaurants, ifoodProducts, getIfoodMenus } =
    useIfood();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');

  const [modalEditCategory, setModalEditCategory] = useState(false);

  const [modalEditCategoryData, setModalEditCategoryData] = useState();

  const cache = new CellMeasurerCache({
    fixedHeight: false,
    defaultWidth: 800,
    fixedWidth: true,
  });

  const categorySelect = categories.map((cat) => {
    return {
      label: cat.name,
      value: cat.name,
    };
  });

  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function handleChangeModalEditCategory(category) {
    setModalEditCategoryData(category);
    setModalEditCategory(!modalEditCategory);
  }

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/restaurants/products', {
      params: {
        ifood: true,
      },
    });

    setCategories(response.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  function handleSelectSearch(name) {
    setSelectedValue({ value: name, label: name });
    const categoriesFind = categories.filter((cat) => {
      if (
        cat.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .startsWith(
            name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      ) {
        return { ...cat, products: cat.products };
      }
      return '';
    });

    setCategoriesFiltered(() => {
      return categoriesFind;
    });

    setShowFiX(true);
  }

  async function handleSearchInputProduct(name) {
    if (name.length >= 3) {
      try {
        const productFind = categories.map((cat) =>
          cat.products.map((prod) => {
            if (
              prod.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  name
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            ) {
              return { ...cat, products: [prod] };
            }
            return '';
          })
        );

        const arrayReady = productFind
          .map((item) => item.filter((cat) => cat !== ''))
          .filter((arr) => arr.length > 0);

        const newArray = arrayReady.flatMap((item) => item);

        //
        const output = newArray.reduce(function (o, cur) {
          // Get the index of the key-value pair.
          const occurs = o.reduce(function (n, item, i) {
            return item.name === cur.name ? i : n;
          }, -1);

          // If the name is found,
          if (occurs >= 0) {
            // append the current value to its list of values.
            o[occurs].products = o[occurs].products.concat(cur.products);

            // Otherwise,
          } else {
            // add the current item to o (but make sure the value is an array).
            const obj = {
              name: cur.name,
              ...cur,
            };
            o = o.concat([obj]);
          }

          return o;
        }, []);

        setCategoriesFiltered(output);
      } catch (error) {
        toast.error('errro ao filtrar');
      }
    }

    if (name.length === 0) {
      setCategoriesFiltered({ products: [] });
    }
  }

  async function handleDeleteCategory(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta Categoria?')) {
        await api.delete(`/restaurants/product-category/${id}`);

        const categoriesFiltered = categories.filter(
          (category) => category.id !== id
        );

        setCategories(categoriesFiltered);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  const [modalCreateCategory, setModalCreateCatgory] = useState(false);
  function toggleModalCreateCategory() {
    setModalCreateCatgory(!modalCreateCategory);
  }
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const [modalNewComplementCategory, setModalNewComplementCategory] =
    useState();
  function toggleNewComplementCategory() {
    setModalNewComplementCategory(!modalNewComplementCategory);
  }

  const [sliceSize, setSliceSize] = useState(4);
  const [angleChanged, setAngleChange] = useState(false);

  function handleSetSliceSize() {
    setSliceSize(sliceSize + 5);
    setAngleChange(true);
  }

  const [showFiX, setShowFiX] = useState(false);

  // Complement category
  const [complementsCategories, setComplementsCategories] = useState([]);
  const [count, setCount] = useState('');
  const [complementsCategoriesFiltered, setComplementsCategoriesFiltered] =
    useState([]);
  const complementCategorySelect = complementsCategories.map((cat) => {
    return {
      label: cat.name,
      value: cat.name,
    };
  });

  const [selectedCategoryComplementValue, setSelectedCategroyComplementValue] =
    useState('');
  const [modal, setModal] = useState(false);
  const [modalAssociateCategory, setModalAssociateCategory] = useState();
  const [modalCreateComplements, setModalCreateComplements] = useState();

  const [modalNewComplement, setModalNewComplement] = useState();
  const selectComplementRef = useRef(null);
  function toggle() {
    setModal(!modal);
  }

  function toggleNewComplement() {
    setModalNewComplement(!modalNewComplement);
  }

  function AssociateComplements(category) {
    setModalAssociateCategory(category);
    toggle();
  }

  function createComplements(category) {
    setModalCreateComplements(category);
    toggleNewComplement();
  }

  const getComplementsCategories = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/product/complement-category',
        {
          params: {
            ifood: true,
          },
        }
      );

      setComplementsCategories(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  async function handleChangeComplementCategory(value, event, idx) {
    try {
      const id = Number(idx);
      const response = await api.put(
        `/restaurants/product/complement-category/${id}`,
        {
          available: value,
        }
      );

      const newCategories = [...complementsCategories];

      newCategories.map((cat, idix) => {
        if (cat.id === id) {
          newCategories[idix].available = response.data.available;
        }
      });

      setComplementsCategories(newCategories);

      toast.success('Categoria alterado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
    } catch (error) {
      toast.error('Erro ao ativar/ desativar categoria');
    }
  }

  function handleSelectSearchComplementCategory(name) {
    setSelectedCategroyComplementValue({ value: name, label: name });
    const categoriesFind = complementsCategories.filter((cat) => {
      if (
        cat.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .startsWith(
            name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      ) {
        return { ...cat, products: cat.products };
      }
      return '';
    });

    setComplementsCategoriesFiltered(() => {
      return categoriesFind;
    });

    setShowComplementFiX(true);
  }

  async function handleSearchInputComplement(name) {
    if (name.length >= 3) {
      try {
        const productFind = complementsCategories.map((cat) =>
          cat.complements.map((comp) => {
            if (
              comp.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  name
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            ) {
              return { ...cat, complements: [comp] };
            }
            return '';
          })
        );

        const arrayReady = productFind
          .map((item) => item.filter((cat) => cat !== ''))
          .filter((arr) => arr.length > 0);

        const newArray = arrayReady.flatMap((item) => item);

        //
        const output = newArray.reduce(function (o, cur) {
          // Get the index of the key-value pair.
          const occurs = o.reduce(function (n, item, i) {
            return item.name === cur.name ? i : n;
          }, -1);

          // If the name is found,
          if (occurs >= 0) {
            // append the current value to its list of values.
            o[occurs].complements = o[occurs].complements.concat(
              cur.complements
            );

            // Otherwise,
          } else {
            // add the current item to o (but make sure the value is an array).
            const obj = {
              name: cur.name,
              ...cur,
            };
            o = o.concat([obj]);
          }

          return o;
        }, []);

        setComplementsCategoriesFiltered(output);
      } catch (error) {
        console.log(error.message);
      }
    }

    if (name.length === 0) {
      setComplementsCategoriesFiltered({ complements: [] });
    }
  }

  const [showComplementFiX, setShowComplementFiX] = useState(false);
  const inputComplementRef = useRef(null);
  useEffect(() => {
    try {
      getComplementsCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplementsCategories]);

  const [collapse, setCollapse] = useState([]);

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const [sliceSizeComplementsCategoires, setSliceSizeComplementsCategoires] =
    useState(5);
  const [
    angleChangedgetComplementsCategories,
    setAngleChangegetComplementsCategories,
  ] = useState(false);

  function handleSetSliceSizegetComplementsCategories() {
    setSliceSizeComplementsCategoires(sliceSizeComplementsCategoires + 5);
    setAngleChangegetComplementsCategories(true);
  }

  return isLoading ? (
    <LoadingContainer>
      <Spinner />
      <div>Carregando Cardápio...</div>
    </LoadingContainer>
  ) : (
    <Container>
      <Modal
        isOpen={modalCreateCategory}
        toggle={toggleModalCreateCategory}
        size="md"
      >
        <ModalBody>
          <Store
            toggle={toggleModalCreateCategory}
            getCategories={getCategories}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalEditCategory}
        toggle={toggleModalEditCategory}
        size="md"
      >
        <ModalBody>
          <EditCategory
            category={modalEditCategoryData}
            toggle={toggleModalEditCategory}
            getCategories={getCategories}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalNewComplementCategory}
        toggle={toggleNewComplementCategory}
        size="lg"
      >
        <ModalBody>
          <CreateComplementCategory
            setComplementsCategories={setComplementsCategories}
            toggle={toggleNewComplementCategory}
            complementCategories={complementsCategories}
          />
        </ModalBody>
      </Modal>
      <ProductCategoryHeaderIfood />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {activeTab === 0 ? (
          <InputSearchArea>
            <div style={{ position: 'relative' }}>
              <SelectStyled
                placeholder="Todas as categorias"
                onChange={(e) => handleSelectSearch(e.value)}
                options={categorySelect}
                ref={selectRef}
                value={selectedValue}
              />
              <span
                style={{
                  position: 'absolute',
                  right: 40,
                  display: showFiX ? 'block' : 'none',
                }}
              >
                {' '}
                <FiX
                  color="#333"
                  title="Limpar seleção"
                  cursor="pointer"
                  onClick={(e) => {
                    setCategoriesFiltered([]);
                    inputRef.current.value = '';
                    setSelectedValue('');
                    setShowFiX(false);
                  }}
                />
              </span>
            </div>

            <span>
              <FaSearch color="FF2C3A" />
            </span>

            <input
              placeholder="Buscar produto"
              onChange={(e) => handleSearchInputProduct(e.target.value)}
              style={{ marginLeft: 10, height: 38, paddingLeft: 38 }}
              ref={inputRef}
            />
            <button
              type="button"
              onClick={(e) => {
                setCategoriesFiltered([]);
                inputRef.current.value = '';
                setSelectedValue('');
              }}
              title="Mostrar todas"
            >
              <FiX color="#f7c3ca" />
            </button>
          </InputSearchArea>
        ) : (
          <InputSearchArea>
            <div style={{ position: 'relative' }}>
              <SelectStyled
                placeholder="Todas as cat. de complementos"
                onChange={(e) => handleSelectSearchComplementCategory(e.value)}
                options={complementCategorySelect}
                ref={selectComplementRef}
                value={selectedCategoryComplementValue}
              />

              <span
                style={{
                  position: 'absolute',
                  right: 40,
                  display: showComplementFiX ? 'block' : 'none',
                }}
              >
                {' '}
                <FiX
                  color="#333"
                  title="Limpar seleção"
                  cursor="pointer"
                  onClick={(e) => {
                    setComplementsCategoriesFiltered([]);
                    inputComplementRef.current.value = '';
                    setSelectedCategroyComplementValue('');
                    setShowComplementFiX(false);
                  }}
                />
              </span>
            </div>
            <span>
              <FaSearch color="FF2C3A" />
            </span>
            <input
              placeholder="Buscar complemento"
              onChange={(e) => handleSearchInputComplement(e.target.value)}
              style={{ marginLeft: 10, height: 38, paddingLeft: 38 }}
              ref={inputComplementRef}
            />
            <button
              type="button"
              onClick={(e) => {
                setComplementsCategoriesFiltered([]);
                inputComplementRef.current.value = '';
                setSelectedCategroyComplementValue('');
              }}
              title="Mostrar todas"
            >
              <FiX color="#f7c3ca" />
            </button>
          </InputSearchArea>
        )}

        <div>
          {user?.has_ifood && (
            <ButtonIfood
              type="button"
              onClick={() => {
                getIfoodMenus();
              }}
            >
              <FaRedo color="#ff2c3a" />
              Atualizar produtos/complementos iFood
            </ButtonIfood>
          )}
        </div>
      </div>

      <Tabs
        containerStyles={{ zIndex: 1 }}
        tabs={['Produtos', 'Complementos']}
        onChangeTab={setActiveTab}
        selected={activeTab}
      />

      <Card
        style={{
          paddingBottom: '20px',
          boxShadow: '1px 0px 5px 1px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          borderTopLeftRadius: activeTab === 0 ? '0px' : '8px',
          transition: 'border-radius 0.2s',
        }}
      >
        {activeTab === 0 && (
          <>
            {categoriesFiltered.length > 0
              ? categoriesFiltered.map((cat, idx) => (
                  <CategoryItemIfood
                    category={cat}
                    getCategories={getCategories}
                    user={user}
                    handleChangeModalEditCategory={
                      handleChangeModalEditCategory
                    }
                    handleDeleteCategory={handleDeleteCategory}
                    menewProducts={menewProducts}
                    categories={categories}
                    cache={cache}
                    setCategoriesFiltered={setCategoriesFiltered}
                    setCategories={setCategories}
                    idx={idx}
                    setComplementsCategories={setComplementsCategories}
                    complementCategories={complementsCategories}
                    fiscalGroups={fiscalGroups}
                    ifoodMenus={ifoodMenus}
                    ifoodRestaurants={ifoodRestaurants}
                    ifoodProducts={ifoodProducts}
                  />
                ))
              : categories.map((cat, idx) => (
                  <CategoryItemIfood
                    category={cat}
                    getCategories={getCategories}
                    user={user}
                    handleChangeModalEditCategory={
                      handleChangeModalEditCategory
                    }
                    handleDeleteCategory={handleDeleteCategory}
                    menewProducts={menewProducts}
                    categories={categories}
                    cache={cache}
                    setCategoriesFiltered={setCategoriesFiltered}
                    setCategories={setCategories}
                    idx={idx}
                    setComplementsCategories={setComplementsCategories}
                    complementCategories={complementsCategories}
                    fiscalGroups={fiscalGroups}
                    ifoodMenus={ifoodMenus}
                    ifoodRestaurants={ifoodRestaurants}
                    ifoodProducts={ifoodProducts}
                  />
                ))}
          </>
        )}

        {activeTab === 1 && (
          // <ComplementsCategories
          //   setProductsCategories={setCategories}
          //   productsCategories={categories}
          // /
          <>
            <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalBody>
                <AssociateComplement
                  category={modalAssociateCategory}
                  toggle={toggle}
                  getCategories={getCategories}
                  setCount={setCount}
                  productsCategories={categories}
                  setProductsCategories={setCategories}
                  categories={categories}
                  setComplementsCategories={setComplementsCategories}
                  complementsCategories={complementsCategories}
                />
              </ModalBody>
            </Modal>
            <Modal
              isOpen={modalNewComplement}
              toggle={toggleNewComplement}
              size="lg"
            >
              <ModalBody>
                <CreateComplement
                  category={modalCreateComplements}
                  getCategories={getCategories}
                  toggle={toggleNewComplement}
                  setCount={setCount}
                  setComplementsCategories={setComplementsCategories}
                  complementsCategories={complementsCategories}
                />
              </ModalBody>
            </Modal>
            {complementsCategoriesFiltered &&
            complementsCategoriesFiltered.length > 0
              ? complementsCategoriesFiltered.map((category, index) => (
                  <ContainerComplementsCategories>
                    <CategoryHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CategoryName>{category.name}</CategoryName>
                      </div>
                    </CategoryHeader>
                    <Collapse isOpen={!collapse.includes(category.id)}>
                      <Complements
                        category={category}
                        count={count}
                        setCount={setCount}
                        getCategories={getComplementsCategories}
                        menewProducts={menewProducts}
                        allCategories={complementsCategories}
                        setProductsCategories={setCategories}
                        productsCategories={categories}
                        setCategories={setComplementsCategories}
                        fiscalGroups={fiscalGroups}
                        ifoodMenus={ifoodMenus}
                        ifoodRestaurants={ifoodRestaurants}
                        ifoodProducts={ifoodProducts}
                      />
                    </Collapse>
                  </ContainerComplementsCategories>
                ))
              : complementsCategories.map((category, index) => (
                  <ContainerComplementsCategories>
                    <CategoryHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CategoryName>{category.name}</CategoryName>
                      </div>
                    </CategoryHeader>
                    <Collapse isOpen={!collapse.includes(category.id)}>
                      <Complements
                        category={category}
                        count={count}
                        setCount={setCount}
                        getCategories={getComplementsCategories}
                        menewProducts={menewProducts}
                        allCategories={complementsCategories}
                        setProductsCategories={setCategories}
                        productsCategories={categories}
                        setCategories={setComplementsCategories}
                        fiscalGroups={fiscalGroups}
                        ifoodMenus={ifoodMenus}
                        ifoodRestaurants={ifoodRestaurants}
                        ifoodProducts={ifoodProducts}
                      />
                    </Collapse>
                  </ContainerComplementsCategories>
                ))}
            {/* {sliceSizeComplementsCategoires < complementsCategories.length && (
              <div>
                {' '}
                <SetSliceSizeButton
                  type="button"
                  onClick={handleSetSliceSizegetComplementsCategories}
                >
                  <FaAngleDown
                    style={{
                      transition: 'all 0.2s',
                      transform: !angleChangedgetComplementsCategories
                        ? 'rotate(-90deg)'
                        : 'rotate(0)',
                    }}
                  />{' '}
                  Ver mais categorias
                </SetSliceSizeButton>
              </div>
            )} */}
          </>
        )}
        {/* 
        {categoriesFiltered &&
          categoriesFiltered.length < 1 &&
          sliceSize < categories.length &&
          activeTab === '1' && (
            <div>
              {' '}
              <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
                <FaAngleDown
                  style={{
                    transition: 'all 0.2s',
                    transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
                  }}
                />{' '}
                Ver mais categorias
              </SetSliceSizeButton>
            </div>
          )} */}
      </Card>

      {/* {categories &&
        categories.length > 0 &&
        categories.map((category, index) => (
          <CategoryItem
            key={category.id}
            category={category}
            getCategories={getCategories}
            user={user}
            handleChangeModalEditCategory={handleChangeModalEditCategory}
            handleChangeDeliveryCategory={handleChangeDeliveryCategory}
            handleChangeExclusiveCategory={handleChangeExclusiveCategory}
            handleChangeCategory={handleChangeCategory}
            handleDeleteCategory={handleDeleteCategory}
            index={index}
            menewProducts={menewProducts}
            categories={categories}
          />
        ))} */}
    </Container>
  );
}

export default ProductCategory;
