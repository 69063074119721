import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import { Input } from '@material-ui/core';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import { Row, Col, Collapse } from 'reactstrap';
import { toast } from 'react-hot-toast';
import {
  Card,
  Container,
  Header,
  BoxTotal,
  BoxOthers,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  StyledLink,
  ErrorContainer,
} from './styles';

import api from '~/services/api';

import BoxPayment from '~/components/Box/BoxPayment';
import ButtonDefault from '~/components/Buttons/ButtonDefault';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Box() {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);

  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });

    const parsedData = response.data.totalPaymentsReport.map((data) => ({
      id: data.id,
      name: data.name,
      total_payments_price: data.total_payments_price,
      payments: data.payments,
    }));
    setPayments(parsedData);

    const total = response.data?.total_payment_methods_price;
    // const totalParsed = total > 0 ? total.toFixed(2) : 0;

    setTotal(total);
  }, [finalDate, inicialDate]);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceCashierControl'}`
      );

      const { can_read } = response.data.FinanceCashierControl;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    try {
      getPayments();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getPayments]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <p>Controle o caixa de seu estabelecimento!</p>
        </div>
        <StyledLink to="/financial/payment">
          <ButtonDefault label="Formas de Pagamento" color="#28a745" />
        </StyledLink>{' '}
      </Header>
      <Card>
        <Row>
          <Col md="4">
            <p>{hourPhrase}</p>
          </Col>
          <Col md="8">
            <div style={{ float: 'right' }}>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setMonth}
                color="info"
              >
                Esse mês
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div style={{ float: 'right', marginRight: '15px' }}>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
        </Row>
        <BoxTotal>
          <BoxPayment
            name="Total"
            total={parseFloat(total).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}
            type="payments"
            color="#17a2b8"
          />
        </BoxTotal>
        <BoxOthers>
          {payments.map((payment) => (
            <BoxPayment
              name={payment.name}
              total={parseFloat(payment.total_payments_price).toLocaleString(
                'pt-BR',
                { minimumFractionDigits: 2 }
              )}
              payments={payment.payments}
              type="payments"
              color="#28a745"
              hasModal
            />
          ))}
        </BoxOthers>
      </Card>
    </Container>
  );
}

export default Box;
