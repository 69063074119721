import styled from 'styled-components';
import { Modal } from 'reactstrap';

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const NameActions = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: #4d4d4c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ActionsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    cursor: pointer;
    color: #ff1f2b;
    width: 20px;
    height: 20px;
  }
`;

export const Complements = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  span {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ComplementTag = styled.span`
  color: #3d3d3d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  border-radius: 100px;
  border: 1px solid #ff2b3a;
  display: flex;
  height: 20px;
  padding: 0px 10px;
  align-items: center;
`;

export const Comment = styled.div`
  display: flex;
  height: 20px;
  width: fit-content;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #f4f4f4;

  span {
    color: #3d3d3d;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const ModalObservation = styled(Modal)`
  .modal-content {
    padding: 16px;
  }

  h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #4d4d4c;
  }

  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #4d4d4c;
    resize: none;
  }
`;

export const ModalDelete = styled(Modal)`
  .modal-content {
    padding: 16px;
  }

  h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #4d4d4c;
  }
`;
