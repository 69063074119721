import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import "./calendar.css"
import { Calendar, momentLocalizer } from 'react-big-calendar'

function PersonalCalendar({ toggleNewSlotHourWithData, events, toggleEditEvent }) {
  const localizer = momentLocalizer(moment);

  const formats = {
    timeGutterFormat: 'H[h]',
    eventTimeRangeFormat: ({ start, end }) => {
      return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
    },
    eventTimeRangeStartFormat: ({ start }) => moment(start).format('HH:mm'),
    eventTimeRangeEndFormat: ({ end }) => moment(end).format('HH:mm'),
  };
  const days = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];

  const EmptyToolbar = () => null;
  return (
    <Calendar
      events={events}
      defaultDate={moment().toDate()}
      localizer={localizer}
      formats={formats}
      startAccessor="start"
      endAccessor="end"
      style={{ height: "100%" }}
      components={{
        toolbar: EmptyToolbar,
        header: ({ date }) => <span style={{ fontWeight: "bold" }}>{days[moment(date).day()]}</span>,
      }}
      view='week'
      step={60}
      selectable
      onSelectSlot={toggleNewSlotHourWithData}
      showMultiDayTimes={true}
      onSelectEvent={toggleEditEvent}
      onSelecting={() => false}
    />
  )
}

export default PersonalCalendar;