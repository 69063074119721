import { motion } from 'framer-motion';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

const red = '#ff2d3a';

export const Item = styled(motion(Link))`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  transform-origin: left;
`;

export const IconDiv = styled.div`
  min-width: 48px;
  height: 100%;
  color: ${({ hovered }) => (hovered ? red : '#ffffff')};
  background-color: ${red};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ hovered }) =>
    hovered &&
    `background-color: #FFECEC;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  clip-path: inset(-10px 0px -10px 0px);
  `}
`;

export const SectionDiv = styled.div`
  display: flex;
  padding: 0 10px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  cursor: pointer;

  span {
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }

  svg {
    color: #ff2d3a;
    width: 10px;
    height: 10px;
    ${({ open }) => open && 'transform: rotateY(180deg);'}
    transition: transform 0.2s linear;
  }

  ${({ hovered }) =>
    hovered &&
    `background-color: #FFECEC;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  clip-path: inset(-10px 0px -10px 0px);
  `}
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: ${({ topDist }) => topDist}px;
  left: 200px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fff;
  transition: transform 0.15s linear;
  transform: scaleX(${({ open }) => (open ? 1 : 0)});
  transform-origin: left;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid red;

  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubitemsDiv = styled.div`
  min-width: 210px;
  max-width: 840px;
  height: 160px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Subitem = styled(Link)`
  position: relative;
  display: flex;
  height: ${(props) => (props.isSubSub ? '40px' : '40px')};
  min-height: ${(props) => (props.isSubSub ? '40px' : '40px')};
  width: 210px;
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `pointer-events: none;
  cursor: default;`}

  color: #666;
  font-family: Poppins;
  font-size: ${(props) => (props.isSubSub ? '12px' : '14px')};
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  svg {
    z-index: 1;
    width: ${(props) => (props.isSubSub ? '5px' : '7px')};
    height: ${(props) => (props.isSubSub ? '5px' : '7px')};
    color: ${(props) => (props.isSubSub ? '#d9d9d9' : '#ff2d3a')};
    ${({ isSubSub }) => isSubSub && 'margin-left: 1px;'}
  }

  &:hover {
    background: #ffecec;
    color: #666;
  }

  &:active,
  &:focus,
  &:visited {
    color: #666;
  }
`;

export const Subsubitem = styled.div`
  display: flex;
  height: 20px;
  padding: 0px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #666;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 4px;
    height: 4px;
    color: #d9d9d9;
  }

  &:hover {
    background: #ffecec;
  }
`;

export const ThreadLine = styled.div`
  height: ${({ isFirst }) => (isFirst ? '20px' : '40px')};
  width: 1px;
  background-color: #d9d9d9;
  position: absolute;
  top: ${({ isFirst }) => (isFirst ? '0px' : '-20px')};
  left: 23px;
`;

export const SecondLine = styled.div`
  height: 20px;
  width: 1px;
  background-color: #d9d9d9;
  position: absolute;
  top: 20px;
  left: 23px;
`;
