import React, { useCallback, useState } from 'react';
import { RiKnifeLine } from 'react-icons/ri';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco, StyleKanbanWarning } from './styles';
import iconTakeat from '~/assets/img/icon-site.svg';
import OrderReadyDelivery from '../../Items/OrderReadyDelivery';
import { Button } from 'ui-kit-takeat';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import toast from 'react-hot-toast';
import FinishBillsInBatch from '~/components/FinishBillsInBatch';
import { FaCheck, FaExclamation, FaExclamationTriangle } from 'react-icons/fa';
import InsertPayment from "~/assets/img/insert_payment.jpeg"
import { IoInformationCircleOutline } from 'react-icons/io5';
export default function CardOrdersReady({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  getAllOrders,
  hasOrderActive,
  // paymentMethods,
  handleUpdate,
  countOrders,
}) {
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const {
    user
  } = useAuth()

  const style_kanban_warning = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",

  };


  // const getPaymentMethods = useCallback(async () => {
  //   const response = await api.get('restaurants/payment-methods');
  //   const parsedData = response.data.payment_methods.map((data) => ({
  //     id: data.id,
  //     label: data.name,
  //     available: data.available,
  //     keyword: data.keyword,
  //   }));
  //   setPaymentMethods(parsedData);
  // }, []);

  // useEffect(() => {
  //   getPaymentMethods();
  // }, [getPaymentMethods]);

  const handleCloseSessionsWithOnlinePayments = useCallback(async () => {
    try {
      const response = await api.put('restaurants/delivery/orders/close-paid')
      getAllOrders()
    } catch (error) {
      console.log(error.message)
      toast.error("Erro ao fechar comandas.")
    }
  }, [])

  //finish bills in batch
  const [isModalFinishBillsInBatchOpened, setIsModalFinishBillsInBatchOpened] = useState(false)
  function toggleModalFinishBillsInBatch() {
    setIsModalFinishBillsInBatchOpened(!isModalFinishBillsInBatchOpened)
  }

  const [isModalConfirmationOpened, setIsModalConfirmationOpened] = useState(false)
  function toggleModalConfirmFinishBills() {
    setIsModalConfirmationOpened(!isModalConfirmationOpened)
  }

  const [isModalCompletePaymentOpened, setIsModalCompletePaymentOpened] = useState(false)
  function toggleModalCompletePayment() {
    setIsModalCompletePaymentOpened(!isModalCompletePaymentOpened)
  }

  const [isCompletingPayments, setIsCompletingPayments] = useState(false)

  async function handleConfirmFinishBillsInBatch() {
    try {
      setIsCompletingPayments(true)
      await api.put("/restaurants/delivery/orders/fill-payments")
      setIsCompletingPayments(false)
      setIsModalFinishBillsInBatchOpened(!isModalFinishBillsInBatchOpened)
    } catch (error) {
      toast.error("Erro ao completar pagamentos.")
    }
  }


  return (
    <Bloco>
      <Modal isOpen={isModalFinishBillsInBatchOpened} toggle={toggleModalFinishBillsInBatch} size='lg'>
        <FinishBillsInBatch isDelivery={true} toggleModalFinishBillsInBatch={toggleModalFinishBillsInBatch} getSessions={getAllOrders} />
      </Modal>

      <Modal isOpen={isModalCompletePaymentOpened} toggle={toggleModalCompletePayment} size='lg'>
        <ModalBody style={{ height: 361 }}>
          <div>
            <h1 style={{ fontSize: 18, color: "#3d3d3d" }}>
              <strong>Pagamento das Comandas:</strong> Finalize com Rapidez!
            </h1>
            <p style={{ fontSize: 16, color: "#3d3d3d" }}>
              Para finalizar todas as comandas com pagamento já informado, clique em “Completar Pgmto.
              e Finalizar”. Se preferir finalizar apenas as comandas com pagamento completo,
              clique em “Finalizar Comandas”.
            </p>
            <div style={{ display: "flex", alignItems: "center", marginTop: 20, background: "#E8F8FF", border: "1px solid #01AFFF", borderRadius: 5 }}>
              <div style={{ display: "flex", justifyContent: "center", width: 100 }}>
                <IoInformationCircleOutline color='#01AFFF' size={32} />
              </div>
              <p>
                <strong>Dica: </strong>Finalizar com "Completar Pgmto. e Finalizar" é mais rápido,
                pois o sistema já possui as informações de pagamento.
                Escolha a opção que melhor lhe atende e finalize suas comandas com praticidade!
              </p>
            </div>
          </div>

        </ModalBody>
        <footer style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
          <Button inverted buttonColor='#FF2C3A' title='Voltar' onClick={toggleModalCompletePayment} />
          <Button
            buttonColor='#2EC9B7'
            inverted
            title={isCompletingPayments ? "Completando pagamentos" : "Completar Pgmto. e Finalizar"}
            onClick={() => { handleConfirmFinishBillsInBatch(); setIsModalCompletePaymentOpened(false) }} />
          <Button
            buttonColor='#2EC9B7'
            title='Confirmar'
            onClick={() => { toggleModalFinishBillsInBatch(); setIsModalCompletePaymentOpened(false) }} />
        </footer>
      </Modal>

      <Modal isOpen={isModalConfirmationOpened} toggle={toggleModalConfirmFinishBills}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <FaExclamationTriangle size={40} color='#FFA814' />
            <h1 style={{ fontSize: 18, fontWeight: "bold", marginTop: 10 }}>
              Apenas as comandas com ”Método de pagamento” selecionado serão finalizadas.
            </h1>
            <p>
              Há dois modos de selecionar o “Método de Pagamento”:
            </p>
            <img src={InsertPayment} alt="Imagem mostrando onde inserir pagamento na comanda" />
          </div>
          <footer style={{ display: "flex", justifyContent: "space-between" }}>
            <Button inverted buttonColor='#FF2C3A' title='Cancelar' onClick={toggleModalConfirmFinishBills} />
            <Button
              buttonColor='#2EC9B7'
              title='Confirmar'
              onClick={() => { toggleModalCompletePayment(); setIsModalConfirmationOpened(false) }}
            />
          </footer>
        </ModalBody>
      </Modal>
      <Header>
        <div>PRONTOS</div>
        <ItemsCount>{countOrders}</ItemsCount>
      </Header>
      <Content style={{ borderBottomRightRadius: 12 }}>
        <Button
          title="Fechar comandas"
          onClick={() => {
            toggleModalConfirmFinishBills()
          }}
          containerStyles={{
            width: '-webkit-fill-available',
            height: 30,
            margin: '5px 5px 0',
          }}
          inverted
          buttonColor='#3BD2C1'
        />

        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter((order) => !order.scheduled_to)
            .map((item) => (
              <OrderReadyDelivery
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                // paymentMethods={paymentMethods}
                getAllOrders={getAllOrders}
                handleUpdate={handleUpdate}
              />
            ))
        )}
        {!hasOrderActive && (
          <StyleKanbanWarning>
            <p style={{ color: "#666666" }}>Não há pedido</p>
            <p style={{ color: "#666666" }}>no momento</p>
          </StyleKanbanWarning>
        )}

      </Content>
    </Bloco>
  );
}