import React, { useCallback, useState } from 'react';

import { FaEllipsisV } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import { toast } from 'react-hot-toast';

import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import ReactModal from 'react-modal';

import RadioGroup from '~/components/RadioGroup';
import PersonalButton from '~/components/Buttons/PersonalButton';

import EditComplementCategory from '../Edit';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';

const duplicateOptions = [
  {
    value: 1,
    label: 'Duplicar grupo de complementos e seus complementos.',
    description:
      'Cria uma cópia do grupo e também cria uma cópia dos complementos associados.',
  },
  {
    value: 2,
    label:
      'Duplicar grupo de complementos e manter os vínculos com os complementos.',
    description: 'Cria uma cópia do grupo e mantém os complementos originais.',
  },
  {
    value: 3,
    label: 'Duplicar grupo de complementos e ignorar os complementos.',
    description: 'Cria uma cópia do grupo sem complementos associados.',
  },
];

function ComplementCategoryDropdown({
  category,
  getCategories,
  productsCategories,
  setProductsCategories,
  setCategories,
  categories,
  index,
  setComplementsCategoriesFiltered,
  fiscalGroups,
}) {
  const { user } = useAuth();
  const [modalEditCategoryData, setModalEditCategoryData] = useState();
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [count, setCount] = useState('');

  const canCreateComplementCategory =
    !user?.price_table || user?.price_table?.create_complement_categories;

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [dupOptionSelected, setDupOptionSelected] = useState(1);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function handleChangeEditCategory(cat) {
    setModalEditCategoryData(cat);
    toggleModalEditCategory();
  }

  async function handleDelete(id) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja deletar esta categoria de Complemento?'
        )
      ) {
        await api.delete(`/restaurants/product/complement-category/${id}`);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        getCategories();

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  const handleDuplicateComplementCategory = useCallback(
    async (category_id) => {
      try {
        let complements_action = 'keep';

        if (dupOptionSelected === 1) {
          complements_action = 'duplicate';
        } else if (dupOptionSelected === 2) {
          complements_action = 'keep';
        } else if (dupOptionSelected === 3) {
          complements_action = 'remove';
        }

        const response = await api.put(
          `/restaurants/complement-category/duplicate/${category_id}`,
          {},
          {
            params: {
              complements_action,
            },
          }
        );

        const duplicate_item = response.data;

        const newCategories = [...categories];

        newCategories.splice([index + 1], 0, duplicate_item);
        setCategories(newCategories);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Categoria duplicada com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar categoria');
      }
    },
    [categories, index, dupOptionSelected, setCategories]
  );

  return (
    <>
      <ReactModal
        onRequestClose={toggleModalEditCategory}
        isOpen={modalEditCategory}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <EditComplementCategory
          category={modalEditCategoryData}
          getCategories={getCategories}
          toggle={toggleModalEditCategory}
          setCount={setCount}
          productsCategories={productsCategories}
          setProductsCategories={setProductsCategories}
          setCategories={setCategories}
          categories={categories}
          setComplementsCategoriesFiltered={setComplementsCategoriesFiltered}
          fiscalGroups={fiscalGroups}
        />
      </ReactModal>

      <Modal
        isOpen={showDuplicateModal}
        toggle={() => setShowDuplicateModal(false)}
        size="lg"
        style={{ maxWidth: '534px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            padding: '24px 24px 0px 24px',
          }}
        >
          <strong style={{ fontSize: 18 }}>
            Como deseja duplicar o Grupo "{category?.name || ''}"?
          </strong>

          <FiX
            size={22}
            color="#FF2129"
            cursor="pointer"
            onClick={() => setShowDuplicateModal(false)}
          />
        </div>

        <ModalBody style={{ lineHeight: 1.5 }}>
          <RadioGroup
            selected={dupOptionSelected}
            labelStyle={{ marginTop: -2, fontWeight: 500 }}
            options={duplicateOptions}
            onClick={(v) => setDupOptionSelected(v)}
          />
        </ModalBody>

        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <PersonalButton
            type="submit"
            color="#58CEB1"
            message="Confirmar"
            onClick={() => {
              setDupOptionSelected(1);
              setShowDuplicateModal(false);
              handleDuplicateComplementCategory(category.id);
            }}
          />
        </ModalFooter>
      </Modal>

      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down">
        <DropdownToggle tag="span">
          {' '}
          <FaEllipsisV style={{ marginLeft: 20 }} title="Mostrar Opções" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              handleChangeEditCategory(category);
            }}
            disabled={!canCreateComplementCategory}
          >
            Editar Categoria
          </DropdownItem>
          {!category.is_multistore_child && !category.is_ifood && (
            <DropdownItem
              onClick={() => handleDelete(category.id)}
              disabled={!canCreateComplementCategory}
            >
              Excluir Item
            </DropdownItem>
          )}
          {!category.is_multistore_child && !category.is_ifood && (
            <DropdownItem
              onClick={() => setShowDuplicateModal(true)}
              disabled={!canCreateComplementCategory}
            >
              Duplicar Item
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ComplementCategoryDropdown;
